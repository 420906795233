<template>
  <li :class="{'active': isActiveCategory, 'has-child': hasChildren }" @click.prevent="$emit('toggleSubMenu')">
    <div class="nav-link">
      <link-manager class="text-decoration-none" :category="category" @onCategoryClicked="setCurrentCategory" />
    </div>
  </li>
</template>

<script>
import LinkManager from 'theme/components/Header/SubMenu/Layout/LinkManager.vue'

export default {
  props: {
    category: {
      type: Object,
      required: true
    },
    activeCategory: {
      type: Object,
      required: true
    }
  },
  computed: {
    getActiveCategory: {
      get () {
        return this.activeCategory
      },
      set (newVal) {
        this.$emit('onCategoryClicked', newVal)
      }
    },
    isActiveCategory () {
      if (Object(this.getActiveCategory).hasOwnProperty('id') && this.getActiveCategory.id === this.category.id) {
        return true
      }
      return false
    },
    hasChildren () {
      return this.category.childrenData.length
    }
  },
  methods: {
    setCurrentCategory ($event) {
      this.getActiveCategory = $event
    }
  },
  components: {
    LinkManager
  }
}
</script>
