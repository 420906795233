import Vue from 'vue'
import $ from 'jquery'
import { EventBus } from '@/helpers'

export default Vue.directive('append-html', {
  inserted: (el, binding) => {
    if ($(el).text().trim().length === 0) {
      updateContainer(el, binding)
    }
  },
  update: (el, binding) => {
    if (binding.value !== binding.oldValue) {
      updateContainer(el, binding)
    }
  }
})

function updateContainer (el, binding) {
  $(el).html(binding.value || '')
  EventBus.$emit('cms:update', { id: el.getAttribute('data-id') })
}
