function getItems () {
  return `{
    cart(cart_id: "{{cartId}}") {
      items {
        id
        product {
          name
          image {
            url
            label
          }
          sku
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
              regular_price {
                value
                currency
              }
              discount {
                percent_off
                amount_off
              }
            }
          }
          ... on ConfigurableProduct {
            configurable_options {
              label
              values {
                label
                value_index
              }
            }
          }
        }
        quantity
      }
    }
  }`
}

function addCoupon () {
  return `mutation ApplyCouponToCart($input: ApplyCouponToCartInput!) {
    applyCouponToCart(input: $input) {
      cart {
        items {
          product {
            name
          }
          quantity
        }
        applied_coupons {
          code
        }
        prices {
          discounts {
            amount {
              value
            }
            label
          }
          grand_total{
            value
            currency
          }
        }
      }
    }
  }`
  // return `{
  //   cart({{ cartId }}) {
  //     applied_coupon {
  //       code
  //     }
  //   }
  // `
}

function removeCoupon () {
  return `
  mutation RemoveCouponFromCart($input: RemoveCouponFromCartInput!) {
    removeCouponFromCart(input: $input) {
      cart {
        id
      }
    }
  }
  `
}

function clearLoggedCart () {
  return `mutation ClearCart($input: ClearCartInput) {
    clearCart (input: $input) {
      success
    }
  }`
}

export default {
  addCoupon,
  removeCoupon,
  getItems,
  clearLoggedCart
}
