import Graphql from '@/services/Graphql'
import Logger from '@/services/Logger'
import Config from 'theme/config'
import find from 'lodash/find'
import filter from 'lodash/filter'
import get from 'lodash/get'
class Blog {
  // constructor () { }
  decorateCategories (category, token, storeViewCode) {
    const _this = this
    if (category && category.items) {
      const indexBlogInCat = this.findBlogInCategories(category.items)
      if (indexBlogInCat.length) {
        return this.getCategories(token, storeViewCode).then(blogCategories => {
          for (let i in indexBlogInCat) {
            _this.attachBlogCategory(blogCategories, category.items, indexBlogInCat[i])
          }
          return category
        })
      }
    }
    return Promise.resolve(category)
  }

  getCategories (token, storeViewCode) {
    let tokenEmpty = ''
    return Graphql.genericCallCash({
      tokenEmpty,
      storeViewCode, // storeViewCode
      graphSchemaType: 'blogCategories',
      useCache: true
    })
      .then(resp => {
        return resp && resp.data && resp.data.blogCategories ? resp.data.blogCategories.items : []
      })
      .catch(err => {
        Logger.error(err)
        return []
      })
  }

  isCategoryBlog (category, identifierBlog) {
    return category.custom_attributes
      .find(cat => {
        if (cat.attribute_code === 'url_path') {
          let isBlog = cat.value.includes(identifierBlog)
          if (isBlog) {
            category.blogHelpers = {
              [cat.attribute_code]: cat.value,
              identifier: this.generateBlogIdentifier({ mainThreeIdentifier: false })
            }
          }
          return isBlog
        }
      })
  }

  findBlogInCategories (category) {
    let index = []
    let blogIdentifier = this.generateBlogIdentifier({ mainThreeIdentifier: true })
    for (let i in category) {
      let currentCat = category[i]
      if (currentCat.is_active) {
        if (this.isCategoryBlog(currentCat, blogIdentifier)) {
          index.push(i)
        }
      }
    }
    return index
  }

  attachBlogCategory (blogCategories, categories, index) {
    let blogIdentifier = get(categories[index], 'blogHelpers.identifier', this.generateBlogIdentifier({ mainThreeIdentifier: false }))
    let blogCategory = blogCategories.find(cat => cat.identifier === blogIdentifier)
    if (blogCategory) {
      categories[index].blogHelpers.id = blogCategory.category_id
      categories[index].blogHelpers.identifier = this.generateBlogIdentifier({ mainThreeIdentifier: false })
      categories.push({
        ...categories[index]
      })
      // push child on parent
      categories[index].children = this.getChildIds(blogCategory.category_id, blogCategories)
      // findAnd Attach child
      for (let i in blogCategories) {
        if (blogCategories[i].path && parseInt(blogCategories[i].path) === blogCategory.category_id && !!blogCategory.is_active) {
          categories.push(this.createCategoryByBlog(blogCategories[i], categories[index], blogCategories))
        }
      }
    }
  }

  createCategoryByBlog (blogCat, catParent, blogCats) {
    let urlKey = catParent && catParent.blogHelpers ? catParent.blogHelpers.url_path : ''
    let category = {
      available_sort_by: [],
      children: this.getChildIds(blogCat.category_id, blogCats),
      childrenData: [],
      custom_attributes: [],
      custom_layout: blogCat.custom_layout,
      featured_img: blogCat.featured_img,
      blog_category_id: blogCat.category_id,
      blog_category_parent_id: blogCat.parent_category_id,
      id: this.generateCategoryId(blogCat.category_id),
      include_in_menu: !!blogCat.include_in_menu,
      is_active: !!blogCat.is_active,
      name: blogCat.title,
      parent_id: catParent.id,
      path: `${catParent.path}/${blogCat.path}`,
      position: blogCat.position,
      meta_description: blogCat.meta_description,
      meta_keywords: blogCat.meta_keywords,
      meta_title: blogCat.meta_title,
      blogHelpers: {
        id: blogCat.category_id,
        category_parent_id: blogCat.parent_category_id,
        identifier: blogCat.identifier
      }
    }
    // catParent.children = catParent.children && catParent.children.length ? catParent.children + ',' + category.id : '' + category.id
    // add path
    const urlPathIndex = catParent.custom_attributes.findIndex(item => item.attribute_code === 'url_path')
    if (urlPathIndex) {
      if (urlKey) {
        catParent.custom_attributes[urlPathIndex].value = urlKey
      }
      category.custom_attributes.push({
        attribute_code: 'url_path',
        value: catParent.custom_attributes[urlPathIndex].value + '/' + blogCat.identifier
      })
    } else {
      category.custom_attributes.push({
        attribute_code: 'url_path',
        value: blogCat.identifier
      })
    }

    return category
  }

  getBreadcrumb ({ Vue, $store, $route, $router, title }) {
    let paths = [{
      text: Config.Theme.name,
      to: Vue.filter('localizedByNameCategories')('home', null, null, $store, $router),
      exact: true
    }]
    let parentCategory = $store.state.cms.blogParentCat
    if (parentCategory) {
      paths.push({
        text: parentCategory.name,
        to: `/${$store.state.ui.storeViewCode}/${parentCategory.url_path}.html`,
        active: !$route.params.cat
      })
      if ($route.params.cat) {
        let catIdentifier = $route.params.cat.replace('.html', '')
        let childCategory = find(parentCategory.childrenData, { url_path: `${parentCategory.url_path}/${catIdentifier}` })
        if (childCategory) {
          paths.push({
            text: childCategory.name,
            to: `/${$store.state.ui.storeViewCode}/${parentCategory.url_path}/${$route.params.cat}.html`,
            active: !title
          })
        }
        if (title) {
          paths.push({
            text: title,
            to: '#',
            active: true
          })
        }
      }
    }
    return paths
  }
  getChildIds (id, allBlogCats) {
    return filter(allBlogCats, { parent_category_id: id }).map((cat) => this.generateCategoryId(cat.category_id)).join(',')
  }
  generateCategoryId (currentId) {
    return 5000 + currentId
  }
  generateBlogIdentifier ({ mainThreeIdentifier = false }) {
    // blog could be:
    // single site. ex -> AQZ
    // multi site. ex -> MOS | AF | PH
    // in case of multisite environment blog have to be merged, to main category three, by category identifier
    // SPECIFY 2 CATEGORY IDENTIFIER
    // 1) identifier for main categoty three (magento category)
    // 2) identifier for blog subcategories
    let mainCategoryIdentifier = Config.Theme.default.blogIdentifier
    if (mainThreeIdentifier) {
      return mainCategoryIdentifier
    } else {
      // check for multisites
      let isMultiSite = Config.Theme.default.isMultiSiteBlog
      if (isMultiSite) {
        // return blogsubcategory identifier
        return `${mainCategoryIdentifier}-${Config.Theme.default.blogSubCatIdentifier}`
      } else {
        return mainCategoryIdentifier
      }
    }
  }
}

const classSingleton = new Blog()

export default classSingleton
