export default `query StoreConfig ($value: String){
  storeConfig (value: $value) {
    id
    code
    store_name
    locale
    root_category_id
    base_url
    base_currency_code
    category_url_suffix
    cms_home_page
    default_title
    default_description
    default_keywords
    product_url_suffix
    website_id
    videoSettings {
      pdp_video_loop
      pdp_video_muted
      pdp_video_autoplay
    }
    requestInvoiceSettings {
      is_enabled
      threshold
    }
    newsletterSettings {
      modal_media_url
      page_media_url
    }
    shippingSettings {
      price
    }
    seo_markup_seller {
      json
      show
    }
    seo_markup_speakable {
      json_category
      json_product
      json_page
    }
    seo_markup_website
    paymentMethodSettings {
      scalapayConfig {
        active
      }
      adyenConfig {
        client_key_live
        client_key_test
        live_endpoint_prefix
        merchant
        mode
      }
      amazonConfig {
        client_key
        merchant
        mode
      }
      paypalConfig {
        merchant
        mode
      }
    }
  }
}`
