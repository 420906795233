/*
 *     Copytop (c) 2020 Bcame S.r.l.. All tops reserved.
 *     Bcame S.r.l. Technology Business Unit
 *
 *     Company confidential.
 *
 *     This software is proprietary  to and embodies the confidential
 *     technology of Bcame S.r.l..  Possession, use, or copying of this
 *     software and media is authorized only pursuant to a valid written
 *     license from Bcame S.r.l.  or an authorized sublicensor.
 *
 *     Bcame S.r.l.
 */

<template>
  <transition leave-active-class="animate__animated animate__fadeOut">
    <div class="wrapp-topbar" v-show="showTopBar">
      <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      @after-leave="showTopBar = false">
        <div v-show="startAnimation" class="topbar-overlay" @click="closeTopBar" @mouseover="mouseover"></div>
      </transition>
      <transition
      enter-active-class="animate__animated animate__fadeIn animate__slideInDown animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__slideOutUp animate__faster"
      @before-enter="startAnimation = showTopBar = true"
      @enter="toggleContent(true)"
      @before-leave="toggleContent(false)"
      @after-leave="startAnimation = false">
        <div class="wrapp-topbar__content" v-show="topActiveComponent.component" :class="dynamicClass" ref="topBar">
          <div class="wrapp-topbar__content__body">
            <transition @after-enter="componentAfterEnter">
              <div class="wrapp-topbar__content__body__dynamic" v-show="showContent" :key="topActiveComponent.id">
                <component :is="topActiveComponent.component" v-bind="topActiveComponent.props" ref="componentCurrent"></component>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import $ from 'jquery'
import debounce from 'lodash/debounce'
import { EventBus } from '@/helpers'

export default {
  name: 'TopNavigation',
  props: {
    showGoBack: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showContent: false,
      showTopBar: false,
      startAnimation: false
    }
  },
  computed: {
    topNavHistory () {
      return this.$store.state.ui.topNavHistory
    },
    topActiveComponent () {
      let activeComp = this.$store.getters['ui/getTopActiveComponent']
      let historyComp = this.$store.state.ui.topNavHistory
      if (process.client) {
        let onlyForComponent = ['InstantSearchDesktop', 'ContactUs', 'ChooseLanguage', 'Login', 'MiniCart']
        if (activeComp.component && historyComp.length > 1 && historyComp[historyComp.length - 2].component.name === activeComp.component.name && onlyForComponent.includes(activeComp.component.name)) {
          this.closeTopBar()
          $('body').toggleClass('overflow-hidden', false)
          return {}
        }
        $('body').toggleClass('overflow-hidden', !!activeComp.component)
        let $header = $('.bcm-whole-header')
        if ($('body').hasClass('scroll-down') || $('body').hasClass('scroll-up')) {
          // mini header visible calc its height
          $header = $('.fixed-header')
        }
        $header.toggleClass('on-submenu', !!activeComp.component)
        if (this.$refs.topNavScrollbar) {
          const osInstance = this.$refs.topNavScrollbar.osInstance()
          osInstance.scrollStop().scroll(0)
        }
        if (activeComp.component && this.$refs.topBar) {
          let headerHeight = $header.outerHeight()
          let notificationBarHeight = $('.cms-content.header-banner').outerHeight()
          if (notificationBarHeight) {
            $(this.$refs.topBar).css({
              'padding-top': headerHeight + (notificationBarHeight - 20)
            })
          }
          $(this.$refs.topBar).css({
            'padding-top': headerHeight + 20
          })
          if (activeComp.component.name === 'MiniCart' && this.$store.state.cart && this.$store.state.cart.items && this.$store.state.cart.items.length === 0) {
            $(this.$refs.topBar).css({
              'height': 185
            })
          }
          if (activeComp.component.name === 'ContactUs') {
            $('.component-active-instantsearchdesktop').css({
              'max-height': 468
            })
          }
        }
      }
      return activeComp
    },
    dynamicClass () {
      let classObj = {}
      let topActiveComponent = this.$store.state.ui.topActiveComponent
      if (topActiveComponent && topActiveComponent.component && topActiveComponent.component.name) {
        classObj = {
          [`component-active-${topActiveComponent.component.name.toLowerCase()}`]: true
        }
      }
      return classObj
    },
    triggerMouseOver () {
      if (this.topActiveComponent && this.topActiveComponent.props) {
        return this.topActiveComponent.props.triggerMouseLeave
      } else {
        return false
      }
    }
  },
  methods: {
    closeTopBar () {
      this.animation = false
      this.$store.commit('ui/setTopActiveComponent', {})
      this.$store.commit('ui/showSearch', false)
      EventBus.$emit('TopNavigation::closed')
    },
    closeTopBarMouseover () {
      if (this.triggerMouseOver) {
        // topbar was open by props triggerMouseOver
        // overy emit with close mouseover close this type of topnavigation
        this.closeTopBar()
      } else {
        return false
      }
    },
    goBackHistory () {
      this.$store.dispatch('ui/loadTopActiveComponent', { isBack: true })
    },
    toggleContent (toggle) {
      this.showContent = toggle
      this.startAnimation = toggle
    },
    mouseover () {
      if (this.triggerMouseOver) {
        this.closeTopBar()
      } else {
        return false
      }
    },
    componentAfterEnter: debounce(function (element) {
      $('.wrapp-topbar__content').animate({
        height: element.offsetHeight + 125 + 40
      }, 300, function () {
        $(element).css({
          opacity: 1
        })
      })
    }, 400)
  },
  mounted () {
    EventBus.$on('TopNavigation::close', this.closeTopBar)
    EventBus.$on('TopNavigation::close-mouseover', this.closeTopBarMouseover)
    EventBus.$on('TopNavigation::new-height-size', this.componentAfterEnter)
  },
  destroyed () {
    EventBus.$off('TopNavigation::close', this.closeTopBar)
    EventBus.$off('TopNavigation::close-mouseover', this.closeTopBarMouseover)
    EventBus.$off('TopNavigation::new-height-size', this.componentAfterEnter)
  }
}
</script>
