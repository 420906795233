<template>
  <div class="card-product">
    <b-container fluid class="p-0">
      <b-row no-gutters class="align-items-center">
        <b-col md="5">
          <div class="product-media-wrapper">
            <div class="swiper-navigation">
              <div class="swiper-button-prev" slot="button-prev">
                <i class="icomoon-icon icon-chevron-left" />
              </div>
              <div class="product-media">
                <div v-swiper:currentProduct="optCurrentProduct">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide product-media__single_media" v-for="(image, index) in images" :key="index" :data-slide="index">
                      <image-gallery
                      :class="{ 'pointer': image.video_content === undefined }"
                      :imgPath="image.urlResize"
                      :alt="image.label || product.name"
                      :hasVideo="image.video_content ? image.video_content : {}"
                      :showAsBk="false"
                      :styleImage="{ maxWiwdth: '100%', width: '100%', height: 'auto' }"
                      :changeAble="true"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-next" slot="button-next">
                <i class="icomoon-icon icon-chevron-right" />
              </div>
            </div>
            <add-to-wishlist
              class="add-to-wishlist-desktop d-none d-md-block"
              :product="product"
              :productSelected="productSelected"
              :variant="filterActive"
              @cannotAdd="showErrors" />
          </div>
        </b-col>
        <b-col md="7">
          <div class="product-info">
            <div class="product-info__title">
              <template v-if="productLabel">
                <div class="listing-label d-none bottom-spaced d-md-inline-flex">
                  <span class="text">
                    {{productLabel}}
                  </span>
                </div>
              </template>
              <div class="product-name">
                <h1 class="text-left title" v-html="product.name" />
                <add-to-wishlist
                  class="add-to-wishlist-mobile d-block d-md-none text-right"
                  :product="product"
                  :productSelected="productSelected"
                  :variant="filterActive"
                  @cannotAdd="showErrors" />
              </div>
            </div>
            <div class="product-info__price">
              <product-price :product="productSelected" />
              <div class="vat-included" v-html="vatRules"></div>
            </div>
            <template v-if="productLabel">
              <div class="listing-label top-spaced bottom-spaced d-inline-flex d-md-none">
                <span class="text">
                  {{productLabel}}
                </span>
              </div>
            </template>
            <div class="selections" v-if="configurable_options.length">
              <!-- Configurable options -->
              <product-options
              v-show="!hiddenVariants"
              :product="product"
              :filterActive="filterActive"
              :variants="configurable_options"
              :defaultSelection="product.presetFilter"
              :hasOneSize="hasOneSize"
              :isQuickProduct="isQuickProduct"
              :fromQuickBuy="true"
              @changeFilter="variantChange"></product-options>
            </div>
            <div class="product-info__cart-action d-flex align-items-center">
              <add-to-cart
              :sku="getSku || ''"
              :append="true"
              :product="product"
              :variant="getVariantsSelected"
              :isOutOfStock="(hasOneSize || hiddenVariants) && notifyProcess"
              @cannotAdd="showErrors"
              @success="$emit('added-to-cart', $event)"
              />
            </div>
            <b-modal
              v-model="notifyModal"
              centered
              modal-class="bcm-modal std-modal"
              header-close-content='<i class="icomoon-icon icon-x"></i>'
              :hide-footer="true"
              ref="modalNotify"
            >
              <notify-me :product="product" :productSelected="productSelected" :variant="getVariantsSelected" :addToWishlist="true" :inputData.sync="notificationSent"></notify-me>
            </b-modal>
            <div class="product-info__view-more">
              <div class="product-info__view-more">
                <router-link :to="productLink" @click.native="$emit('go-to-detail', $event)" class="btn btn-secondary full-width">{{ $t('goToProduct') }}</router-link>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <absolute-loader :showLoader="showLoader"></absolute-loader>
  </div>
</template>

<script>
import $ from 'jquery'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'
import AddToCart from 'theme/components/AddToCart'
import AddToWishlist from 'theme/components/AddToWishlist/AddToWishlist'
import Config from 'config'
import ImageSlider from 'theme/components/Product/ImageSlider'
import NotifyMe from 'theme/components/Product/NotifyMe/NotifyMe.vue'
import ProductPrice from 'theme/components/Listing/ProductPrice'
import ProductOptions from 'theme/components/Product/ProductOptions'
import ImageGallery from 'theme/components/Product/Image'
import { EventBus } from '@/helpers'
import { find as _find, filter as _filter, map as _map } from 'lodash'
import { resizeService, removeProductMediaByTag } from 'theme/helpers'

export default {
  name: 'QuickClassicProduct',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  beforeMount () {
    this.initProduct()
  },
  mounted () {
    EventBus.$on('quickBuy:clickcolor', this.colorClickedFx)
    EventBus.$emit('track:productDetail', { fromQB: true })
    const _this = this
    if (process.client) {
      this.headerHeight = $('.bcm-whole-header').outerHeight()
      const slides = $('.product-media__single_media')
      _this.scrollSpy(slides)
      $(window).scroll(function () {
        _this.scrollSpy(slides)
      })
      this.setGalleryImages()
    }
    this.showLoader = true
    return this.$store.dispatch('cms/loadContent', Config.Theme.blockTaxRuleID)
      .finally(() => {
        this.showLoader = false
      })
  },
  destroyed () {
    global.productClickListener = false
    EventBus.$off('quickBuy:clickcolor', this.colorClickedFx)
    if (process.client) {
      setTimeout(function () {
        EventBus.$emit('algolia:unsetProduct')
      }, 2000)
    }
  },
  data () {
    return {
      images: [],
      optCurrentProduct: {
        slidesPerView: 1,
        watchOverflow: true,
        breakpoints: {
          0: {
            navigation: {
              nextEl: '.product-media-wrapper .swiper-button-next',
              prevEl: '.product-media-wrapper .swiper-button-prev'
            }
          },
          1024: {
            navigation: {
              nextEl: '.product-media-wrapper .swiper-button-next',
              prevEl: '.product-media-wrapper .swiper-button-prev'
            }
          }
        }
      },
      isQuickProduct: true,
      showLoader: false,
      notifyProcess: false,
      notifyModal: false,
      headerHeight: 0,
      currentSlideImage: 0,
      filterActive: {},
      notificationSent: false
    }
  },
  computed: {
    productLink () {
      if (this.groupedSelected) {
        // change url of product while color change
        let beforePath = '/' + this.$store.state.ui.codeLang + '/'
        return beforePath + this.groupedSelected.url_key + this.groupedSelected.url_suffix
      }
      return this.product && this.product.productLink ? this.product.productLink : ''
    },
    vatRules () {
      let content = this.$store.getters['cms/getContent'](Config.Theme.blockTaxRuleID)
      content = content.replace('{taxRate}', `${this.product.tax_rate}%`)
      return content
    },
    // productMedia () {
    //   // push all product image
    //   let media = [...this.allImageProduct]
    //   let firstVariant = this.productSelected.length ? { ...this.productSelected[0] } : { ...this.product }
    //   if (firstVariant && firstVariant.media_gallery) {
    //     let video = _find(firstVariant.media_gallery, 'video_content')
    //     video && media.push(video)
    //   }
    //   return media
    // },
    productLabel () {
      // 1 coming_soon is true - DEPRECATED
      // 2 product has carryover label show it over listing_label
      // 3 show if product has listing_label show it
      if (this.product) {
        // if (this.showComingSoon) {
        //   return this.$t('coming-soon')
        if (this.product.carryover) {
          return this.$t('carryover')
        } else if (this.product.listing_label) {
          return this.product.listing_label
        }
      }
      return ''
    },
    showComingSoon () {
      return this.product.coming_soon
    },
    configurable_options () {
      return this.product ? this.product.configurable_options || [] : []
    },
    getSku () {
      return this.product.__typename === 'GroupedProduct' ? this.product.configurableSku : this.product.sku
    },
    getVariantsSelected () {
      if (this.configurable_options.length === Object.keys(this.filterActive).length) {
        return { ...this.filterActive }
      } else {
        return null
      }
    },
    productSelected () {
      const _this = this
      // loop variant of product with every filter active
      let product = this.product

      if (this.product.variants) {
        let variants = this.product.variants
        for (let i in this.filterActive) {
          variants = _filter(variants, function (variant) {
            let attr = _find(variant.attributes, function (attibute) {
              if (attibute.value_index === _this.filterActive[i]) return true
              return false
            })
            return attr
          })
        }
        if (variants.length) {
          product = _map(variants, 'product')
        }
      }
      this.$store.commit('product/setTmpProdSelected', product)
      return Array.isArray(product) ? product : [product]
    },
    // allImageProduct () {
    //   // push all product image
    //   // image from variant
    //   let images = this.productSelected.length ? this.productSelected[0].media_gallery || [] : []
    //   const { width, height, quality, crop } = Config.Theme.productOpt.imageCrop
    //   images.forEach(image => {
    //     if (!image.urlResize) {
    //       image.urlResize = `${resizeService(image.url)}?${crop ? 'crop=true' : ''}&width=${width}${height ? '&height=' + height : ''}&q=${quality}`
    //     }
    //   })
    //   return images
    // },
    hiddenVariants () {
      return this.product && this.product.hidden_variants === 0 ? this.selectUniqueSize() : false
    },
    hasOneSize () {
      return this.product && this.product.variants && this.product.variants.length === 1
    }
  },
  methods: {
    initProduct () {
      // add method to do on init or onChange color
      // on product color change mounted will not call
      // use this method to refresh action on load and change
      if (this.hiddenVariants || this.hasOneSize) {
        this.selectUniqueSize()
      }
    },
    manageSizeAvailable ($event) {
      if ($event && $event === 1) {
        this.hasOneSize = true
        this.initProduct()
      }
    },
    selectUniqueSize () {
      let configurableOptionBySize = _find(this.product.configurable_options, { attribute_code: 'size' })
      this.product.presetFilter[configurableOptionBySize.attribute_id] = configurableOptionBySize.values[0].value_index
      return true
    },
    colorClickedFx (colorSelection) {
      // find urlkey
      const _this = this
      if (this.product.configurableParent && this.product.configurableParent.groupedVariation) {
        let currentGrouped = _find(this.product.configurableParent.groupedVariation, { color: colorSelection.value.value_index })
        if (currentGrouped) {
          let urlKey = '/' + this.$store.state.ui.codeLang + '/' + currentGrouped.url_key + currentGrouped.url_suffix
          this.showLoader = true
          let loaderTimeout = setTimeout(() => {
            this.showLoader = true
          }, 300)
          this.$store.dispatch('quickBuy/openQuickBuy', { url_key: urlKey })
            .then(product => {
              EventBus.$emit('quickBuy:resetFilterSize')
            })
            .finally(() => {
              _this.showLoader = false
              _this.setGalleryImages()
              clearTimeout(loaderTimeout)
            })
        }
      }
    },
    variantChange (variant) {
      if (variant.value) {
        this.$set(this.filterActive, variant.type, variant.value)
      } else {
        this.$delete(this.filterActive, variant.type)
      }
      if (variant.isNotificable) {
        this.notifyProcess = true
      } else {
        this.notifyProcess = false
      }
      this.$store.commit('quickBuy/setActiveFilter', this.filterActive)
      // this.notifyModal = (this.hiddenVariants || this.hasOneSize) ? false : this.notifyProcess
      this.product.presetFilter[variant.type] = variant.value
    },
    scrollSpy (slides) {
      let scrollTop = $(window).scrollTop()
      for (let i = 0; i < slides.length; i++) {
        let anchor = ($(slides[i]).offset().top - (this.headerHeight + 10))
        if (anchor <= scrollTop) {
          this.currentSlideImage = parseInt($(slides[i]).data('slide'))
        }
      }
    },
    showErrors ($event) {
      // Show size error
      // the error message in inside component
      // use event bus to reach it
      EventBus.$emit('cart:sizeError', $event)
    },
    setGalleryImages () {
      let gallery = this.productSelected.length ? this.productSelected[0].media_gallery || [] : []
      let images = removeProductMediaByTag({ tags: 'banner_image,video_thumbnail,feed_image,transparent', mediaGalleries: gallery, mediaEntries: this.product.media_gallery_entries })
      images.forEach((image) => {
        image.urlResize = resizeService({ url: image.url, color: 'f5f5f5' })
      })
      this.images = images
      return this.images
    }
  },
  components: {
    ImageSlider,
    ProductPrice,
    AbsoluteLoader,
    ProductOptions,
    AddToWishlist,
    NotifyMe,
    AddToCart,
    ImageGallery
  }
}
</script>
