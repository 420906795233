<template>
  <transition name="fadeIn">
    <div class="page-go-top scroll-top" v-show="isVisible" @click.prevent="goOnTop">
      <button class="btn btn-icon">
        <i class="bcm-icon bcm-icon-00-accordion"></i>
      </button>
    </div>
  </transition>
</template>

<script>
import $ from 'jquery'
const anchorValue = 480

export default {
  name: 'ScrollTop',
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    manageScroll () {
      let scrollTop = $(window).scrollTop()
      if (scrollTop >= anchorValue) {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    },
    goOnTop () {
      $('html, body').animate({
        scrollTop: 0
      }, 400)
    }
  },
  mounted () {
    const _this = this
    $(window).scroll(function () {
      _this.manageScroll()
    })
  }
}
</script>
