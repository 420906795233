export default `query Products(
      $search: String
      $currentPage: Int
      $filter: ProductAttributeFilterInput
      $pageSize: Int
      $sort: ProductAttributeSortInput,
      $id: Int
    ) {
    category (id: $id) {
      name
      display_mode
      cms_block{
        title
        identifier
      }
      breadcrumbs{
        category_id
        category_url_path
        category_url_key
        category_level
        category_id
        category_name
      }
      canonical_url
      custom_canonical_link
      href_lang_links{href, hreflang}
      meta_description
      meta_title
      meta_keywords
      meta_robots
      head_content_page
    }
    products(
      search: $search
      currentPage: $currentPage
      filter: $filter
      pageSize: $pageSize
      sort: $sort ) {
      items {
        __typename
        id
        sku
        master_sku
        name
        size
        stock_status
        canonical_url
        url_suffix
        url_key
        color
        color_label
        color_image
        new_from_date
        new_to_date
        listing_label
        carryover
        ... on GroupedProduct {
          configurableParent {
            ... on ConfigurableProduct {
              configurable_options {
                id
                label
                attribute_code
                attribute_id
                position
                product_id
                values {
                  value_index
                  label
                  default_label
                  store_label
                  use_default_value
                }
              }
              variants {
                product {
                  id
                  name
                  sku
                  stock_status
                  quantity
                  attribute_set_id
                  color
                  is_backinstock
                }
                attributes {
                  label
                  code
                  value_index
                }
              }
              groupedVariation{
                color
                name
                url_key
                url_suffix
                sku
                image {
                  label
                  url
                }
              }
            }
          }
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
        special_price
        special_from_date
        special_to_date
        image {
          label
          url
        }
        media_gallery_entries{
          disabled
          file
          id
          position
          types
        }
        media_gallery{
          position
          disabled
          label
          url
          ...on ProductVideo {
            position
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
      }
      total_count
      aggregations {
        attribute_code
        count
        label
        options {
          count
          label
          value
        }
      }
      aggregationsStore {
        attribute_code
        count
        label
        options {
          count
          label
          swatch
          type
          value
        }
      }
      sort_fields {
        default
        options{
          label,
          value
        }
      }
    }
  }`
