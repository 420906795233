import $ from 'jquery'
import { EventBus } from '@/helpers'
var attrSlickLoaded = 'data-bcm-slick-initialized'

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      if (global.slick) {
        var $elements = $('.widget-product-carousel-e01')
        $elements.each((index, element) => {
          let $element = $(element)
          let attr = $element.attr(attrSlickLoaded)
          // For some browsers, `attr` is undefined; for others,
          // `attr` is false.  Check for both.
          if (attr === undefined || attr === false) {
            /* SET MARKER IF SLIDER IS INITIALIZED */
            $element.attr(attrSlickLoaded, true)
            initializeSlick($element, $element.closest('div[data-appearance="carousel-e01"]'))
            if ($element.closest('div[data-appearance="carousel-e01"]').data('show-product-cta') === 'always') {
              cretaCallToAction($element.closest('div[data-appearance="carousel-e01"]'))
            }
          }
        })
      }
    })
  }
}

function initializeSlick ($element, $target) {
  $element.slick({
    autoplay: $target.data('autoplay'),
    autoplaySpeed: $target.data('autoplay-speed') || 0,
    fade: $target.data('fade'),
    infinite: $target.data('is-infinite') || false,
    arrows: $target.data('show-arrows'),
    centerMode: $target.data('center-mode'),
    centerPadding: $target.data('center-padding'),
    variableWidth: $target.data('variable-width'),
    adaptiveHeight: $target.data('adaptive-height'),
    speed: $target.data('speed'),
    cssEase: $target.data('css-ease'),
    slidesToShow: $target.data('slides-to-show'),
    slidesToScroll: $target.data('slides-to-scroll'),
    dots: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: $target.data('show-dots'),
          arrows: false
        }
      }
    ]
  })
}

function cretaCallToAction ($el) {
  $('.slick-slide', $el).each((index, element) => {
    let $element = $(element)
    let $wrapperActions = $element.find('.product-item-actions')
    $wrapperActions.append(`<a href type="button" class="actions action-custom ${$el.data('button-type')}">${$el.data('button-text')}</a>`)
  })
}
