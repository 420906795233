import { EventBus } from '@/helpers'

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      $('.image-composition-wrapper').not('.processed').each((wrapperIndex, el) => {
        $(el).addClass('processed')
        let $links = $(el).find('a')
        $links.each((aIndex, elem) => {
          let $srcImage = $(elem).find('img').attr('src')
          $(elem).attr('data-link-id', `${wrapperIndex}-${aIndex}`)
          $(el).append(`<div class="media-background" style="background-image: url('${$srcImage}'); background-size: ${$(el).css('background-size') || 'cover'}; display: none;" data-link-id="${wrapperIndex}-${aIndex}"></div>`)
        })
      })
    })

    $(document).on('mouseover', '.image-composition-wrapper a', function () {
      const $a = $(this)
      const $parent = $a.parents('.image-composition-wrapper')
      let currentIndex = $a.data('link-id')
      if ($(`.media-background[data-link-id="${currentIndex}"]`).is(':hidden')) {
        $parent.find('.media-background').fadeOut()
        $(`.media-background[data-link-id="${currentIndex}"]`).fadeIn()
      }
      $a.one('mouseleave', resetBackground.bind($a))
    })
  }
}
function resetBackground () {
  const $a = this
  const $row = $a.closest('div[data-content-type="row"]')
  $row.css('background-image', 'none')
}
