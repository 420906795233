<template>
  <div class="card-store">
    <div class="card-store-content">
      <div class="card-block card-store-content__title">
        <h6 class="title"><strong class="text-uppercase">{{ store.country_id }}</strong> - {{store.store_name}}</h6>
        <span class="stock_status"></span>
      </div>
      <div class="card-block card-store-content__description">
        <div class="description-line street-name">
          <span class="text">{{store.address}}, {{store.postcode}} {{store.region}}, {{store.country}}</span>
        </div>
        <div class="description-line phone-number">
          <span class="text">{{store.phone_number}}</span>
        </div>
        <div class="description-line email">
          <span class="text">{{store.email}}</span>
        </div>
      </div>
      <div class="card-block card-store-content__ctas">
        <button v-b-toggle="'accordion-' + store.code" class="btn btn-none">Orari</button>
        <router-link :to="{ name: 'Boutique', params: {store_code: store.code}}"  class="bcm-link bcm-link-secondary t-small">{{ $t('view-detail') }}</router-link>
      </div>
      <div class="card-block card-store-content__working-hours" v-if="store.working_hours">
        <b-collapse :id="'accordion-' + store.code" class="mt-2">
          <div class="wh-list" v-for="(wh, index) in store.working_hours" :key="index">
            <span class="label">{{index}} </span>
            <span class="wh">{{wh.  from}} - {{wh.to}}</span>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardBoutique',
  props: {
    store: {
      type: Object,
      required: true
    }
  }
}
</script>
