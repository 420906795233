<template>
  <transition
  enter-active-class="animate__animated animate__fadeIn"
  leave-active-class="animate__animated animate__fadeOut animate__faster"
  @before-enter="slideBeforeEnter"
  @after-enter="slideAfterEnter"
  @before-leave="slideBeforeLeave"
  name="post-slide-in">
    <div class="posts-slide" :class="componentClass" ref="postSlide" v-if="togglerSlide">
      <div class="section-overlay" @click="closeSlide">
        <div class="slide-header">
        <slot name="header-left" v-bind="currentPost">
          <transition name="fadeIn" mode="out-in">
            <div v-if="currentPost && currentPost.title" class="wrapp-header" :key="currentPost.title">
              <span class="title">{{ currentPost.title }}</span>
              <span class="separator"></span>
              <span class="content" v-html="currentPost.short_content"></span>
            </div>
          </transition>
        </slot>
        <button class="bcm-close close-slide" @click="closeSlide">
          <span></span>
          <span></span>
        </button>
      </div>
      </div>

      <div class="slide-content">
        <div class="slide-flow">
          <div class="slide-container">
            <div class="slide-post">
              <transition
              @enter="postEnter"
              @after-enter="postAfterEnter"
              name="slide-flip-in"
              mode="out-in">
                <template v-if="currentPost">
                  <slot name="slide-component" v-bind="currentPost">
                    <post-tile-default-slide :post="currentPost" :index="activePostIndex" :key="currentPost.post_id" :totalPosts="totalPosts" />
                  </slot>
                </template>
              </transition>
              <div class="slide-pagination" v-if="usePagination">
                <span class="current-slide">{{ activePostIndex }}</span>
                <span class="separator">/</span>
                <span class="total-post-count">{{totalPosts}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <absolute-loader :showLoader="showLoader"></absolute-loader>
                <div class="slide-navigation">
        <div class="wrapp-navigation">
          <button class="btn btn-none nav-icon prev" @click.prevent="loadPrevPost" :disabled="!prevEnabled">
            <i class="icomoon-icon icon-chevron-left" />
          </button>
          <button class="btn btn-none nav-icon next" @click.prevent="loadNextPost" :disabled="!nextEnabled">
            <i class="icomoon-icon icon-chevron-right" />
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { preventScroll } from 'theme/helpers'
import PostTileDefaultSlide from 'theme/components/Blog/PostsSlide/PostTileDefaultSlide'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'

export default {
  name: 'PostsSlide',
  props: {
    componentClass: {
      type: String,
      default: () => ''
    },
    showSlide: {
      type: Boolean,
      default: false
    },
    posts: {
      type: Array,
      default: () => {}
    },
    totalPosts: {
      type: Number,
      required: true
    },
    canLoadMore: {
      type: Boolean,
      default: () => false
    },
    currentIndex: {
      type: Number,
      required: true
    },
    // name of the parent method to call in case of load more
    parentLoadMoreCallback: {
      type: String,
      required: true
    },
    usePagination: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      currentSlideIndex: false,
      showLoader: false,
      slideShowPost: {},
      slideDirection: 'prev',
      navHeight: '50%'
    }
  },
  computed: {
    activePostIndex: {
      get () {
        if (this.currentSlideIndex !== false) {
          return this.currentSlideIndex
        } else {
          return this.currentIndex
        }
      },
      set (newValue) {
        this.currentSlideIndex = newValue
        return this.$store.commit('ui/setPostsSlide', { ...this.$props, currentIndex: newValue })
      }
    },
    currentPost () {
      return this.posts[this.activePostIndex]
    },
    togglerSlide: {
      get () {
        return this.showSlide
      },
      set (newValue) {
        return this.$store.commit('ui/setPostsSlide', { ...this.$props, showSlide: newValue })
      }
    },
    isMobile () {
      return this.$store.state.ui.isMobile
    },
    prevEnabled () {
      return this.activePostIndex
    },
    nextEnabled () {
      return this.activePostIndex + 1 < this.totalPosts
    },
    slideReachEnd () {
      return this.activePostIndex + 1 === this.posts.length
    }
  },
  methods: {
    slideChange (activeIndex = null) {
      return this.$emit('slideChange', activeIndex)
    },
    loadMorePosts () {
      // if user with slide opened reach end of loaded post
      // check if load more is availabe and load new posts
      return this.$parent[this.parentLoadMoreCallback](true)
        .then((res) => {
          return res
        })
    },
    loadPrevPost () {
      // const _this = this
      if (this.activePostIndex) {
        this.activePostIndex -= 1
        this.slideDirection = 'prev'
      }
      return this.slideChange(this.activePostIndex)
    },
    loadNextPost () {
      if (this.nextEnabled) {
        // check if reach end and loadMoreIsAvailable
        if (this.slideReachEnd && this.canLoadMore) {
          // load more post using props gave from parent component
          this.showLoader = true
          const _this = this
          setTimeout(() => {
            _this.loadMorePosts()
              .then(() => {
                // on complete
                // move on next slide
                _this.showLoader = false
                _this.activePostIndex += 1
                _this.slideDirection = 'next'
                return _this.slideChange(_this.activePostIndex)
              })
          }, 600)
        } else {
          // go directly on next slide
          this.activePostIndex += 1
          this.slideDirection = 'next'
          return this.slideChange(this.activePostIndex)
        }
      }
    },
    closeSlide () {
      this.currentSlideIndex = false
      this.togglerSlide = false
    },
    loadSlideByIndex (index) {
      this.activePostIndex = index
    },
    moveWithKeyboard (e) {
      let keyCode = e.code
      if (keyCode === 'ArrowRight') {
        return this.loadNextPost()
      }
      if (keyCode === 'ArrowLeft') {
        return this.loadPrevPost()
      }
    },
    // animations
    slideBeforeEnter () {
      preventScroll({})
    },
    slideAfterEnter (element) {
      this.setNavHeight(element)
    },
    slideBeforeLeave () {
      preventScroll({ remove: true })
    },
    postEnter (element, done) {
      this.$emit('animationEnter', element, done)
      this.setNavHeight(element)
      done()
    },
    postAfterEnter (element) {
      this.setNavHeight(element)
    },
    setNavHeight (element) {
      let $currentMedia = $(element).find('.post__featured-image')
      let slideHeight = $currentMedia.height()
      let slideTop = $currentMedia.offset().top
      this.navHeight = window.innerWidth < 1010 ? `${slideTop + (slideHeight / 2)}px` : '50%'
    }
  },
  mounted () {
    if (process.client) {
      window.addEventListener('keydown', this.moveWithKeyboard)
    }
  },
  destroyed () {
    window.removeEventListener('keydown', this.moveWithKeyboard)
  },
  components: {
    PostTileDefaultSlide,
    AbsoluteLoader
  }
}
</script>
