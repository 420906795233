<template>
  <div>
    <no-ssr>
      <overlay-scrollbars :options="{ overflowBehavior: {x: 'hidden', y: 'scroll'}, className: 'os-theme-minimal-dark' }" class="bcm-instant-search">
        <template>
          <ais-instant-search
            :search-client="searchClient"
            :index-name="getIndexName"
          >
          <ais-configure clickAnalytics="true" />
            <div class="bcm-instant-search-initial">
              <div class="search-box">
                <!-- search box -->
                <ais-search-box :class-names="getSearchBoxClassNames">
                  <template v-slot="{ currentRefinement, refine }">
                    <div class="bcm-search-box-form">
                      <input
                      type="search"
                      :value="currentRefinement"
                      autocorrect="off"
                      autocapitalize="off"
                      autocomplete="off"
                      spellcheck="false"
                      required="required"
                      maxlength="512"
                      aria-label="Search"
                      :placeholder="inputPlaceholder"
                      class="ais-SearchBox-input bcm-search-box-input"
                      @input="refineSearch(refine, $event.currentTarget.value)">
                      <button type="reset" title="Clear" class="ais-SearchBox-reset bcm-search-reset" @click.prevent="searchCancel(refine, '')" :class="{ 'fadeIn': searchBoxQuery }" ref="searchReset">
                        <span class="bcm bcm-link bcm-link-classic">{{ $t('clear') }}</span>
                      </button>
                    </div>
                    <i class="icomoon-icon icon-search nav-icon"></i>
                  </template>
                </ais-search-box>

                <!-- Manage Extra Info Color in Facets -->
                <ais-hits :transform-items="manageColorFilter" style="display: none" />

                <!-- configure search nbHist per page -->
                <ais-configure :hitsPerPage="12" />
              </div>
              <!-- CATEGORIES SUGGESTED LINK TO SHOW IN ALGOLIA  -->
              <div class="bcm-suggested-categories categories-suggested" v-show="isFirstUse && suggested.length" :class="{ 'fadeOut' : searchBoxQuery }">
                <h3 class="title">{{$t('search.refinements.categoriesSuggested')}}</h3>
                <ul>
                  <li v-for="(category, index) in suggested" :key="index">
                    <router-link
                      class="text-decoration-none"
                      :to="'listing' | localizedByNameCategories(category.category_url_path, null, $store, $router)"
                    >
                      <span class="link-label">{{ category.category_name }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
              <!-- CATEGORIES LINK TO SHOW IN ALGOLIA  -->
              <!-- v-show="getCategoriesFromMagento.length" -->
              <div class="bcm-suggested-categories categories-link" v-show="isFirstUse && categories.length" :class="{ 'fadeOut' : searchBoxQuery }">
                <h3 class="title">{{$t('search.refinements.categories')}}</h3>
                <ul>
                  <li v-for="(category, index) in categories" :key="index">
                    <router-link
                      class="text-decoration-none"
                      :to="'listing' | localizedByNameCategories(category.category_url_path, null, $store, $router)"
                    >
                      <span class="link-label">{{ category.category_name }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="wrapp-close">
                <button class="btn btn-none" @click.prevent="closeSearch">
                  <i class="icomoon-icon icon-x"></i>
                </button>
              </div>
            </div>

            <div class="bcm-instant-search-bottom" :class="{ 'fadeIn' : !isFirstUse, 'fadeOut': isFirstUse }">
              <!-- clear active filters (exclude: query search) -->
              <ais-clear-refinements>
                <div slot-scope="{ canRefine, refine, createURL }">
                  <a
                    :href="createURL()"
                    class="bcm-link bcm-link-classic"
                    @click.prevent="clearAllRefinements(refine, '')"
                    v-if="canRefine"
                  >
                    {{$t('search.refinements.clearAllRefinements')}}
                  </a>
                </div>
              </ais-clear-refinements>
              <!-- sort and filter -->
              <div class="bcm-instant-search-facets" v-show="totalItems">
                <!-- results stats -->
                <ais-stats :class-names="getStatsClassNames" :class="{ 'fadeIn' : !isFirstUse, 'fadeOut': isFirstUse }">
                  <template v-slot="{ nbHits }">
                    <span class="text">{{$t('search.stats.resultsFound')}} <span class="line" /><b class="hits-found">{{nbHits}}</b></span>
                  </template>
                </ais-stats>
                <!-- sort by -->
                <div class="bcm-instant-search-facets-sort">
                  <div class="filter">
                    <ais-sort-by :items="getSortCriteria">
                      <b-dropdown
                      :text="$t('search.refinements.sortBy')"
                      class="ais-dropdown "
                      slot-scope="{
                        items,
                        refine
                      }"
                      no-flip>
                        <no-ssr>
                          <b-form-group v-slot="{ ariaDescribedby }" id="checkbox-group-sort">
                            <b-form-radio
                              name="checkbox-sort"
                              v-for="item in items"
                              v-model="sortCriteriaSelected"
                              class="ais-refinement-list--checkbox"
                              :key="item.value"
                              :class="'facet-sort-' + item.value"
                              :value="item.value"
                              :aria-describedby="ariaDescribedby"
                              @change="refine(item.value);handleSort(item)"
                            >
                              {{$t('search.sort.' + item.label)}}
                            </b-form-radio>
                          </b-form-group>
                        </no-ssr>
                      </b-dropdown>
                    </ais-sort-by>
                  </div>
                </div>
                <!-- filters -->
                <div class="bcm-instant-search-facets-filters">
                  <!-- CATEGORIES ATTR -->
                  <!-- <div class="filter">
                    <ais-hierarchical-menu
                      :attributes="[
                        'categories.level0',
                        'categories.level1',
                        'categories.level2',
                        'categories.level3'
                      ]"
                      separator=" /// "
                    >
                      <b-dropdown
                        :text="$t('search.refinements.brands')"
                        class="ais-dropdown"
                        no-flip
                        slot-scope="{
                          items,
                          refine,
                          createURL
                        }"
                      >
                        <hierarchical-menu-list
                          :items="items"
                          :refine="refine"
                          :createURL="createURL"
                        />
                      </b-dropdown>
                    </ais-hierarchical-menu>
                  </div> -->
                  <!-- GENDER ATTR -->
                  <!-- <div class="filter">
                    <ais-refinement-list operator="or" attribute="gender">
                      <b-dropdown
                        :text="`${$t('search.refinements.gender')} ${facetGenderSelected.length ? `(${facetGenderSelected.length})`: ''}`"
                        class="ais-dropdown"
                        slot-scope="{
                          items,
                          refine
                        }"
                        no-flip>
                          <no-ssr>
                            <b-form-group v-slot="{ ariaDescribedby }" id="facet-gender">
                              <b-form-checkbox
                                name="checkbox-facet-gender"
                                v-for="item in items"
                                :key="item.value"
                                :class="'facet-gender-' + item.value"
                                v-model="facetGenderSelected"
                                :value="item.value"
                                @change="refine(item.value);handleFilter('gender_normalized', item)"
                                class="ais-refinement-list--checkbox"
                                :aria-describedby="ariaDescribedby"
                              >
                                {{item.label}}
                              </b-form-checkbox>
                            </b-form-group>
                          </no-ssr>
                      </b-dropdown>
                    </ais-refinement-list>
                  </div> -->
                  <!-- COLOR ATTR -->
                  <div class="filter">
                    <ais-refinement-list operator="or" attribute="color_filter.label">
                      <b-dropdown
                        :text="`${$t('color')} ${facetColorSelected.length ? `(${facetColorSelected.length})`: ''}`"
                        toggle-class=""
                        class="ais-dropdown"
                        slot-scope="{
                          items,
                          refine
                        }"
                        no-flip>
                          <no-ssr>
                            <b-form-group v-slot="{ ariaDescribedby }" id="checkbox-group-facet-color" class="checkbox_color_filter">
                              <div v-for="item in colorItems(items)" :key="item.value">
                                <b-form-checkbox
                                  name="checkbox-facet-color"
                                  v-if="isColorVisible(item)"
                                  v-model="facetColorSelected"
                                  class="ais-refinement-list--checkbox"
                                  :value="item.value"
                                  :class="'facet-color-' + item.value"
                                  :aria-describedby="ariaDescribedby"
                                  @change="refine(item.value);handleFilter('color_filter.label', item)"
                                >
                                  <instant-search-swatch-color :item="item" :colors="colorFilter"  />
                                </b-form-checkbox>
                              </div>
                            </b-form-group>
                          </no-ssr>
                      </b-dropdown>
                    </ais-refinement-list>
                  </div>
                  <!-- SIZE ATTR -->
                  <div class="filter">
                    <ais-refinement-list  attribute="size_filter.label">
                      <b-dropdown
                        :text="`${$t('search.refinements.size')} ${facetSizeSelected.length ? `(${facetSizeSelected.length})`: ''}`"
                        class="ais-dropdown"
                        slot-scope="{
                          items,
                          refine
                        }"
                        no-flip>
                          <no-ssr>
                            <b-form-group v-slot="{ ariaDescribedby }" id="checkbox-group-facet-size">
                              <b-form-checkbox
                                name="checkbox-facet-size"
                                v-for="item in items"
                                class="ais-refinement-list--checkbox"
                                v-model="facetSizeSelected"
                                :key="item.value"
                                :class="`facet-size-${item.value}`"
                                :value="item.value"
                                :aria-describedby="ariaDescribedby"
                                @change="refine(item.value);handleFilter('size_filter.label', item)"
                              >
                                {{item.label}}
                              </b-form-checkbox>
                            </b-form-group>
                          </no-ssr>
                      </b-dropdown>
                    </ais-refinement-list>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="!isFirstUse">
              <ais-infinite-hits :class-names="{
                'ais-InfiniteHits': 'bcm-hits',
                'ais-InfiniteHits-list': 'bcm-hits-list',
                'ais-InfiniteHits-item': 'bcm-hits-item'
                }"
                :transform-items="manageItems">
                <div class="bcm-instant-search-tile-container" slot="item" slot-scope="{ item }">
                  <instant-search-tile :item="item" :currentIndex="currentIndex"></instant-search-tile>
                </div>
                <template v-slot:loadMore="{ isLastPage, refineNext }">
                  <div class="pagination-load-more">
                    <a
                    class="bcm-link bcm-effect-hover"
                    v-show="!isLastPage"
                    @click.prevent="refineNext"
                    >
                      {{ $t('Load more') }}
                    </a>
                  </div>
                </template>
              </ais-infinite-hits>
            </div>
            <instant-search-quick-view v-show="quickViewInstantSearch"></instant-search-quick-view>
          </ais-instant-search>
        </template>
      </overlay-scrollbars>
    </no-ssr>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite'
import Config from 'config'
import InstantSearchTile from 'theme/components/InstantSearch/InstantSearchTile'
import InstantSearchQuickView from 'theme/components/InstantSearch/InstantSearchQuickView'
import HierarchicalMenuList from 'theme/components/InstantSearch/HierarchicalMenuList'
import InstantSearchSwatchColor from './InstantSearchSwatchColor'
import { EventBus } from '@/helpers'
import debounce from 'lodash/debounce'
// import { enablePreventScrollingSafari, disablePreventScrollingSafari } from 'theme/helpers'
import InstantSearchStats from 'theme/components/InstantSearch/InstantSearchStats.vue'
import get from 'lodash/get'

if (process.client) {
  require('instantsearch.css/themes/algolia-min.css')
}

export default {
  name: 'InstantSearchDesktop',
  data () {
    return {
      collapseFilter: false,
      collapseSort: false,
      facetCategoriesTree: [],
      facetColorSelected: [],
      facetSizeSelected: [],
      facetGenderSelected: '',
      sortCriteriaSelected: [],
      isFirstUse: true,
      searchBoxQuery: '',
      totalItems: 0,
      showComponent: false,
      colorFilter: [],
      categories: [],
      suggested: [],
      searchClient: algoliasearch(
        Config.algolia.applicationId,
        Config.algolia.searchOnlyApiKey
      ),
      inputPlaceholder: this.$t('search.searchBox.title.desktop'),
      searchFunction (helper) {
        /* if search is visible make initial search */
        if (!this.searchIsVisible) {
          return
        }
        /* if initial query is empty not trigger search */
        if (helper.state.query === '') {
          return
        }
        helper.search()
      },
      quickViewInstantSearch: false,
      storeViewCode: get(this.$store, 'state.ui.storeViewCode', 'default'),
      indexNamePrefix: Config.algolia.indexNamePrefix,
      indexNames: Config.algolia.indexNames,
      isFirstInput: true,
      currentIndex: ''
    }
  },
  computed: {
    getIndexName () {
      return this.indexNamePrefix + this.storeViewCode + this.indexNames['products']
    },
    getSearchBoxClassNames () {
      return Config.algolia.classNames.searchBox
    },
    getStatsClassNames () {
      return Config.algolia.classNames.stats
    },
    getSortCriteria () {
      let sortCriteria = [
        // { value: this.getIndexName, label: 'relevance' },
        { value: this.getIndexName + '_created_at_desc', label: 'newestFirst' },
        { value: this.getIndexName + '_price_default_asc', label: 'priceAsc' },
        { value: this.getIndexName + '_price_default_desc', label: 'priceDesc' }
        // { value: this.getIndexName + '_name', label: 'name' }
      ]
      return sortCriteria
    },
    searchIsVisible () {
      return this.$store.state.ui.showSearch
    }
  },
  mounted () {
    this.setInstantSearchDimensions(`60%`, `60%`)
    // const _this = this
    /* on search open change set dimesions */
    // EventBus.$on('quickView:change', function (value) {
    //   if (value) {
    //     enablePreventScrollingSafari()
    //     _this.setInstantSearchDimensions()
    //   } else {
    //     disablePreventScrollingSafari()
    //   }
    // })
    /* on resize change dimesions of search */
    // $(window).on('resize', () => {
    //   this.setInstantSearchDimensions()
    // })

    /* set default value in sort by */
    this.sortCriteriaSelected = [this.getIndexName]
    this.getCategories()
    if (process.client) {
      EventBus.$emit('algolia:init')
    }
  },
  methods: {
    refineSearch (refine, value) {
      this.searchBoxQuery = value
      // this.isFirstUse = false
      this.setInstantSearchDimensions(`100%`, `100%`)
      this.trackSearch(value)
      refine(value)
      this.afterRefine(value)
    },
    afterRefine: debounce(function (value) {
      this.searchBoxQuery = value
      this.isFirstUse = !value
    }, 400),
    trackSearch: debounce(function (value) {
      EventBus.$emit('track:searchSuggestion', { inputText: value })
    }, 3000),
    manageColorFilter (items) {
      let colors = []
      if (items.length !== this.colorFilter.length) {
        items.forEach(item => {
          colors.push(item.color_filter)
        })
        this.colorFilter = colors
      }
      return items
    },
    manageItems (items) {
      this.totalItems = items.length
      if (process.client) {
        const _this = this
        clearTimeout(window.algoliaResult)
        window.algoliaResult = setTimeout(function () {
          EventBus.$emit('algolia:listing', { items: items, currentIndex: _this.currentIndex })
        }, 500)
      }
      return items
    },
    setInstantSearchDimensions (height, maxHeight) {
      const $bcmInstantSearch = $('.component-active-instantsearchdesktop')
      $bcmInstantSearch.css({
        'height': height,
        'max-height': maxHeight
      })
    },
    clearPlaceholder () {
      this.inputPlaceholder = ''
    },
    closeSearch () {
      this.$store.commit('ui/setTopActiveComponent', {})
      this.$store.commit('ui/showSearch', false)
      EventBus.$emit('TopNavigation::closed')
    },
    beforeSearchShow () {
      this.showComponent = true
    },
    searchHidden () {
      if (this.$refs && this.$refs.searchReset) {
        this.$refs.searchReset.click()
      }
      this.isFirstUse = true
      this.showComponent = false
    },
    clearAllRefinements (refine, value) {
      refine.call()
      this.facetColorSelected = []
      this.facetSizeSelected = []
      this.facetGenderSelected = ''
      refine(value)
      this.closeCollapse()
    },
    closeCollapse () {
      this.collapseFilter = false
      this.collapseSort = false
    },
    searchCancel (refine) {
      this.clearAllRefinements(refine)
      // let buttonCanc = document.querySelector('.ais-ClearRefinements .bcm-link-classic')
      // if (buttonCanc) {
      //   buttonCanc.click()
      // }
      let _this = this
      setTimeout(function () {
        _this.refineSearch(refine, '')
      }, 10)
    },
    getCategories () {
      return this.$store.dispatch('ui/loadAlgoliaCategories')
        .then((res) => {
          this.categories = res.category
          this.suggested = res.suggested
        })
    },
    colorItems (items) {
      let sortedColors = items.sort(function (item1, item2) {
        return item1.label.localeCompare(item2.label)
      })
      return sortedColors
    },
    isColorVisible (item) {
      return true
      // return this.colorFilter.find(filter => filter.label === item.label)
    },
    handleFilter (type, item) {
      EventBus.$emit('algolia:filter', { item: item, type: type, currentIndex: this.currentIndex })
    },
    handleSort (item) {
      this.currentIndex = item.value
    }
  },
  components: {
    InstantSearchTile,
    InstantSearchQuickView,
    InstantSearchSwatchColor,
    HierarchicalMenuList,
    InstantSearchStats
  }
}
</script>
