
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default function extendStoreConfig (storeConfig) {
  storeConfig.getters = getters
  storeConfig.actions = actions
  storeConfig.mutations = mutations
  return storeConfig
}
