import { user } from '@/store/modules/user'
import { ui } from '@/store/modules/ui'
import { checkout } from '@/store/modules/checkout'
import { attribute } from '@/store/modules/attribute'
import { listing } from '@/store/modules/listing'
import { product } from '@/store/modules/product'
import { category } from '@/store/modules/category'
import { cart } from '@/store/modules/cart'
import { cms } from '@/store/modules/cms'
import { blog } from '@/store/modules/blog'
import { orders } from '@/store/modules/orders'
import { giftCard } from '@/store/modules/giftCard'
import { storeConfig } from '@/store/modules/storeConfig'
import { looksBook } from '@/store/modules/looksBook'
import { feelbuy } from './modules/feelbuy'
import { storeLocator } from './modules/storeLocator'
import { quickBuy } from './modules/quickBuy'
import { rma } from './modules/rma'

import rmaStore from './modules/rma/store'
import userStore from '@/store/modules/user/store'
import cartStore from '@/store/modules/cart/store'
import uiStore from '@/store/modules/ui/store'
import checkoutStore from '@/store/modules/checkout/store'

import extendCms from './modules/cms'
import extendListing from './modules/listing'
import extendUser from './modules/user'
import extendUi from './modules/ui'
import extendCategory from './modules/category'
import extendProduct from './modules/product'
import extendStoreConfig from './modules/storeConfig'
import extendCheckout from './modules/checkout'
import extendCart from './modules/cart'
import extendOrders from './modules/orders'

export default () => {
  return {
    modules: {
      user: extendUser(user()),
      ui: extendUi(ui()),
      checkout: extendCheckout(checkout()),
      attribute: attribute(),
      listing: extendListing(listing()),
      product: extendProduct(product()),
      category: extendCategory(category()),
      cms: extendCms(cms()),
      cart: extendCart(cart()),
      blog: blog(),
      orders: extendOrders(orders()),
      giftCard: giftCard(),
      feelbuy: feelbuy(),
      looksBook: looksBook(),
      storeLocator: storeLocator(),
      storeConfig: extendStoreConfig(storeConfig()),
      quickBuy: quickBuy(),
      rma: rma()
    },
    plugins: [
      rmaStore,
      userStore,
      cartStore,
      uiStore,
      checkoutStore
    ]
  }
}
