<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'Klavyio',
  created () {
    var script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.src = '//static.klaviyo.com/onsite/js/klaviyo.js?company_id=WfuF7k'
    document.head.appendChild(script)
  }
}
</script>
