
import { state } from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default function extendListing (storeListing) {
  storeListing.state = { ...storeListing.state, ...state(storeListing) }
  storeListing.getters = { ...storeListing.getters, ...getters }
  storeListing.mutations = { ...storeListing.mutations, ...mutations }
  storeListing.actions = { ...storeListing.actions, ...actions }
  return storeListing
}
