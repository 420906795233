import actions from '@/store/modules/user/actions'
import { DefaultShipping, DefaultBilling } from '@/store/modules/checkout/defaultValues'
import { EventBus, graphqlManageError } from '@/helpers'
import Config from 'config'
import Graphql from '@/services/Graphql'
import Magento from '@/services/Magento'
import Logger from '@/services/Logger'
import graphCustomer from 'theme/services/graphQlquery/customer'
import { manageAttributesLabel } from '@/helpers/utils'
import { getWishlistBySharingCode } from 'theme/services/graphQlquery/wishlist'
import { Newsletter, NewsletterStatuses } from '@/services/Newsletter'
import find from 'lodash/find'

function refreshProductOnWishlist (customer) {
  if (customer.wishlist && customer.wishlist.items.length) {
    customer.wishlist.items.forEach((item, index) => {
      let product = item.product
      product = { ...item.product, ...product.configurableParent }
      // find attributes
      product.presetFilter = {}
      item.selectedOptions.forEach(option => {
        product.presetFilter[option.option_id] = option.option_value
      })
      product.groupedVariation.map((groupedItem) => {
        groupedItem.attributesLabel = manageAttributesLabel(groupedItem.attributesLabel)
      })
      item.product = product
    })
  }
  return customer
}

actions.create = (context, data) => {
  var params = {
    password: data.password,
    customer: {
      prefix: data.prefix,
      gender: data.gender,
      dob: data.dob,
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      store_id: context.rootGetters['storeConfig/storeviewID'],
      extension_attributes: {
        // subscription_type: data.privacy.terms2 ? (data.newsletter && data.newsletter.receiving ? data.newsletter.receiving.join() : '') : '',
        privacy: data.privacy.terms1,
        is_subscribed: data.privacy.terms2,
        // customer_ip: context.state.ip             - now customer_ip will be passed as a empty string, because back-end call worked with this work-around and noone wants to check it up
        profiling: data.privacy.terms3 // ,
        // privacy: false
      }
    }
  }
  return Magento.post(
    Config.API.users.create.replace('{{storeViewCode}}', context.rootState.ui.storeViewCode),
    params,
    {
      headers: {
        'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
      }
    })
    .then((resp) => {
      if (resp.status === 200) {
        return context.dispatch('login', { username: data.email, password: data.password })
      }
      throw new Error('user not found')
    })
    .catch(err => {
      throw err
    })
}

actions.update = (context, data) => {
  // clean for update
  delete data.default_shipping
  delete data.default_billing
  delete data.addresses
  delete data.wishlist
  delete data.id
  let token = context.state.token
  if (data.newsletter && data.newsletter.receiving) {
    var newsletterReceiving = JSON.parse(JSON.stringify(data.newsletter.receiving))
    data.subscription_type = data.newsletter.receiving.join()
    delete data.newsletter
  }
  return Graphql.genericCall(
    {},
    token,
    context.rootState.ui.storeViewCode,
    'updateCustomer',
    {
      customerInput: data
    },
    false)
    .then(resp => {
      if (resp.data && resp.data.updateCustomer && resp.data.updateCustomer) {
        let customer = resp.data.updateCustomer.customer
        if (data.is_subscribed && newsletterReceiving) {
          // let payload = {
          //   email: data.email,
          //   ip: context.state.ip,
          //   subscription_type: newsletterReceiving.join()
          // }
          // Newsletter.subscribeCustomer(
          //   context.rootState.ui.storeViewCode,
          //   payload
          // ).then((res) => {
          //   if (res.statusText !== 'OK') {
          //     throw new Error('error')
          //   }
          //   EventBus.$emit('track:newsletter', { email: data.email })
          // }).catch((err) => {
          //   Logger.error(err)
          // })
        }
        refreshProductOnWishlist(customer)
        context.commit('setCustomer', customer)
        return resp.data.updateCustomer.customer
      } else {
        throw new Error(resp)
      }
    })
    .catch(err => {
      Logger.error(err)
      throw new Error('GenericError')
    })
}

actions.me = (context, { token, invalid = false, fromLogin, notRedirectLogoutAfterException = false }) => {
  if (context.state.current && !invalid) {
    return Promise.resolve(context.state.current)
  } else {
    // config, token, storeViewCode, graphSchemaType, variables = null, useCache = true
    return Graphql.genericCall(
      {},
      token,
      context.rootState.ui.storeViewCode,
      '',
      null,
      false,
      { query: graphCustomer })
      .then(resp => {
        if (resp && resp.data && resp.data.customer) {
          let customer = resp.data.customer

          // if has default shipping use it
          let defaultShipping = { ...DefaultShipping }
          let defaultBilling = { ...DefaultBilling }
          global.$store.dispatch('checkout/loadCountries')
            .then(() => {
              context.dispatch('presetAddressCheckout', { mode: 'shipping', mutationName: 'setShippingAddress', defaultAddress: defaultShipping, customer })
              context.dispatch('presetAddressCheckout', { mode: 'billing', mutationName: 'setBillingAddress', defaultAddress: defaultBilling, customer })
            })
          // refresh product on wishlist
          refreshProductOnWishlist(customer)
          context.commit('setCustomer', customer)
          EventBus.$emit('user:update')

          // if logged without cartid
          if (!fromLogin && context.rootState.cart.cartId === null) {
            context.dispatch('cart/syncServerCart', {}, { root: true })
          }
          return customer
        } else {
          if (!notRedirectLogoutAfterException) {
            context.dispatch('logout')
          }
          throw new Error('session expired')
        }
      })
      .catch(err => {
        if (!notRedirectLogoutAfterException) {
          context.dispatch('logout')
        }
        throw err
      })
  }
}

actions.getWishlistByShareCode = (ctx, { sharingCode, forShare = false }) => {
  let variables = {
    sharing_code: sharingCode
  }
  return Graphql.genericCall(
    {},
    ctx.rootState.user.token,
    ctx.rootState.ui.storeViewCode,
    'getWishlistBySharingCode',
    variables,
    false,
    { query: getWishlistBySharingCode } // this new theme graph schema has more priority
  )
    .catch(err => {
      console.error(err)
      throw err
    })
    .then((res) => {
      if (graphqlManageError(res)) {
        // eslint-disable-next-line
        throw { body: { result: graphqlManageError(res) } }
      }
      res.data.wishlist.items.map((item) => {
        item.product.shared = forShare
        let configurableOptions = item.product.configurable_options
        const colorAttr = find(configurableOptions, { 'attribute_code': 'color' })
        const sizeAttr = find(configurableOptions, { 'attribute_code': 'size' })
        let color = find(item.selectedOptions, function (test) {
          return test.option_id.toString() === colorAttr.attribute_id
        })
        item.product.presetFilter = {
          [colorAttr.attribute_id]: color.option_value
        }
        let size = find(item.selectedOptions, function (test) {
          return test.option_id.toString() === sizeAttr.attribute_id
        })
        if (size) {
          item.product.presetFilter[sizeAttr.attribute_id] = size.option_value
        }
        item.product.groupedVariation.map((groupedItem) => {
          groupedItem.attributesLabel = manageAttributesLabel(groupedItem.attributesLabel)
        })
      })
      return res.data.wishlist.items
    })
}

actions.subscribeCustomer = (context, { $event = new Event('subscribeNewsletter'), email, nlType }) => {
  return context.dispatch('getCustomerSubscription', email)
    .then((subscriber) => {
      if ((subscriber && subscriber.subscriber_status !== NewsletterStatuses.subscribed) || !subscriber) {
        return Newsletter.subscribeCustomer(context, { email, nlType })
          .then(async (subscriber) => {
            EventBus.$emit('trackTC:newsletter', { $event, email })
            if (context.getters['isLogged']) {
              await context.dispatch('me', { token: context.state.token, invalid: true })
            }
            return subscriber
          }).catch((err) => {
            Logger.error(err)
            return err
          })
      } else {
        return Promise.resolve({
          message: 'footer.newsletter-already-subscribed',
          alreadySubscribed: true
        })
      }
    })
    .catch((err) => {
      Logger.error(err)
      return err
    })
}

actions.unsubscribeCustomer = (context, { email }) => {
  return context.dispatch('getCustomerSubscription', email)
    .then((subscriber) => {
      if (subscriber && (subscriber.subscriber_status === NewsletterStatuses.subscribed || subscriber.subscriber_status === NewsletterStatuses.pending)) {
        return Newsletter.unsubscribeCustomer({
          email,
          subscriberId: subscriber.subscriber_id,
          confirmationCode: subscriber.subscriber_confirm_code,
          storeViewCode: context.rootState.ui.storeViewCode
        })
          .catch((err) => {
            Logger.error(err)
            return err
          })
      }
    })
    .catch((err) => {
      Logger.error(err)
      return err
    })
}

actions.getCustomerSubscription = (context, email) => {
  // 1 subscribed
  // 2 pending
  // 3 unsubscribed
  // 4 not active
  return Newsletter.subscribeList(email)
    .then((subscriber) => {
      return subscriber
    })
}

actions.newsletterConfirmation = (context, { email }) => {
  email = decodeURIComponent(email)
  return context.dispatch('getCustomerSubscription', email)
    .then((subscriber) => {
      if (subscriber && subscriber.subscriber_id && subscriber.subscriber_confirm_code) {
        return Newsletter.confirmSubscription({ email, subscriberId: subscriber.subscriber_id, confirmationCode: subscriber.subscriber_confirm_code })
          .then((res) => {
            return res
          }).catch((err) => {
            Logger.error(err)
            return err
          })
      } else {
        throw new Error('newsletter.no-subscriber-found')
      }
    })
    .catch((err) => {
      Logger.error(err)
      return err
    })
    .finally(() => {
      // after confirmation
      // refresh user if it is logged
      if (context.getters['isLogged']) {
        context.dispatch('me', { token: context.state.token, invalid: true })
      }
    })
}

export default {
  ...actions
}
