import $ from 'jquery'
import { EventBus } from '@/helpers'
import Config from 'config'
import { isMobile } from '@/helpers/utils'
import { toast } from '@/modules/notifications'

export default function init () {
  EventBus.$on('cms:update', () => {
    initCheckWishlist()
    setDimensionToBackgroundImage()
  })
  EventBus.$on('wishlist:update', () => {
    initCheckWishlist()
  })
  window.onresize = function () {
    setDimensionToBackgroundImage()
  }
  onClickAddToWishlist()
  onClickQuickBuy()
}

function onClickAddToWishlist () {
  $(document).on('click', '.action.towishlist', function (e) {
    e.preventDefault()
    // here trigger dispatch addToWishlist
    const Store = global.$store
    let $productCard = $(this).closest('.product-item-info')
    let dataProduct = $productCard.data('product')
    let isOnWishlist = global.$store.getters['user/isOnWishlist'](dataProduct)
    Store.commit('ui/showLoader')
    if (isOnWishlist) {
      Store.dispatch('user/removeFromWishlist', isOnWishlist.id).then(res => {
        $(this).removeClass('active')
        Store.commit('ui/hideLoader')
        // toast.success(global.$i18n.t('product-removed-to-wishlist'))
      })
    } else {
      EventBus.$emit('track:wishlist', { product: { ...this.product }, filters: { ...this.variant } })
      Store.dispatch('user/addToWishlist', { product: dataProduct, filters: dataProduct.presetFilter })
        .then((res) => {
          $(this).addClass('active')
          let componentToLoad = import('theme/components/Header/MiniWishlist')
          let method = isMobile() ? 'ui/loadRightActiveComponent' : 'ui/loadTopActiveComponent'
          Store.dispatch(method, { component: componentToLoad })
          if (Config.Theme.miniWishlistClosingTime) {
            setTimeout(() => {
              Store.commit(method, {})
              this.$root.$emit('bv::hide::modal', 'modal-quick-view')
            }, Config.Theme.miniWishlistClosingTime)
          } else {
            this.$root.$emit('bv::hide::modal', 'modal-quick-view')
          }
        })
        .finally(() => {
          Store.commit('ui/hideLoader')
        })
      // .then(res => {
      //   debugger
      //   toast.success(global.$i18n.t('product-added-to-wishlist'))
      //   $(this).addClass('active')
      // })
      // .catch(res => {
      //   const msg = res.body && res.body.result ? res.body.result : 'GenericError'
      //   toast.error(global.$i18n.t(msg))
      // })
      // .finally(() => {
      //   Store.commit('ui/hideLoader')
      // })
    }
  })
}

function setDimensionToBackgroundImage () {
  var attrImagePrepended = 'data-bcm-image-prepended'

  $(function () {
    let $elements = $(':not("img.invisible")[class*="background-image-"]')
    $elements.each(function () {
      let $element = $(this)
      let nativeClasses = $element.attr('class')
      let attr = $element.attr(attrImagePrepended)
      let imageUrl = $element.css('background-image').match(/^url\(["']?(.+?)["']?\)$/)
      if (imageUrl && (attr === undefined || attr === false)) {
        let imageSrc = imageUrl[1]

        /* create wrapper element */
        let $wrapper = $('<div>')

        /* set style of wrapper */
        let styleWrapper = {
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'background-attachment': $element.css('background-attachment') ? $element.css('background-attachment') : 'scroll',
          'background-position': $element.css('background-position') ? $element.css('background-position') : 'center',
          'position': 'relative',
          'width': String($element[0].style.width) || 'auto',
          'margin': $element.css('margin') ? $element.css('margin') : 'auto',
          'padding': $element.css('padding') ? $element.css('padding') : 'auto'
        }
        $wrapper.css(styleWrapper)

        $wrapper.addClass(nativeClasses)

        /* set style of $element */
        let styleElement = {
          'position': 'absolute',
          'top': '0',
          'right': '0',
          'bottom': '0',
          'left': '0',
          'background-image': 'none',
          'width': 'auto'
        }
        $element.css(styleElement)

        /* wrap and prepend tag img for set proportion on background */
        $wrapper = $element.wrap($wrapper).parent()
        $wrapper.prepend(`<img class="img-fluid invisible" src="${imageSrc}" />`)
        $wrapper.attr(attrImagePrepended, '')
      } else if (imageUrl && attr === '') {
        let $imgInvisible = $element.find('img.invisible')
        let oldImageUrl = $imgInvisible.attr('src')
        if (oldImageUrl !== imageUrl[1]) {
          $imgInvisible.attr('src', imageUrl[1])
        }
      }
    })
  })

  /* set height of image to element baground */
  /* $(function () {
    let $elements = $(s'div.pagebuilder-column')
    $elements.each(function () {
      let $element = $(this)
      let imageUrl = $element.css('background-image').match(/^url\(["']?(.+?)["']?\)$/)

      let dfd = new $.Deferred()

      if (imageUrl) {
        let image = new Image()
        image.onload = dfd.resolve
        image.onerror = dfd.reject
        image.src = imageUrl[1]
      } else {
        dfd.reject()
      }

      dfd.then(function () {
        $element.width(this.width)
        $element.height(this.height)
      })
    })
  }) */
}

function checkProductsCmsInWishlist ($productsInCms) {
  $productsInCms.each((index, element) => {
    var $element = $(element)
    var dataProduct = $element.data('product')

    if (global.$store.getters['user/isOnWishlist'](dataProduct)) {
      $element.find('.towishlist').addClass('active')
    }
  })
}

function initCheckWishlist () {
  const Store = global.$store
  var $productsInCms = $('.product-item-info:not(.checked)')
  if ($productsInCms.length) {
    if ((Store.state.user.current && Store.state.user.current.wishlist.sharing_code) || (Store.state.user.guest && Store.state.user.guest.wishlist.sharing_code)) {
      checkProductsCmsInWishlist($productsInCms)
    }
  }
}

function onClickQuickBuy () {
  global.$(document).on('click', '.product-item-actions .action-quickbuy button', (event) => {
    const Store = global.$store
    event.preventDefault()
    let href = global.$(event.currentTarget).attr('data-urlkey')
    let urlKey = href.slice(href.lastIndexOf('/') + 1)
    Store.commit('ui/showLoader')
    Store.dispatch('quickBuy/openQuickBuy', { url_key: urlKey })
      .catch(error => {
        toast.error(global.$i18n.t(error))
      })
      .finally(() => {
        Store.commit('ui/hideLoader')
      })
  })
}
