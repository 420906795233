export const graphSocialLogin = `
mutation SocialLogin($socialOptions: socialOptions!) {
  socialLogin(socialOptions: $socialOptions)
}`

// "socialOptions": {
//   "accessToken": "EAALBKRGaUagBANawKXpoNGgBYzyZBTubQrmSJwGbbZCzAvDJ9xtZBBh9i6ZB4etxS4jkbCP6yyX3G5fFoes0wJ7qsp7VDZC8XD24UBGU4BZBYGEHXoAg0MvfQmhYuxjAFwZCEriHlUzdoCcWyDsvtpnnNJK3TgUBIG0UZBwzf2nqWBOnwmZCeeQScuTz9J89KjOxZCTrEmvZC0j8gZDZD",
//   "accessExpireTime": "1607419621",
//   "expiresIn": 5579,
//   "graphDomain": "facebook",
//   "signedRequest": "ZSkJTC9jAt6jx0DX7mlzX5sRVr1Z6AxpNBv0VpUoS10.eyJ1c2VyX2lkIjoiMTgwNjEzMzA2OTAyMTIwIiwiY29kZSI6IkFRQi1DMUtKMVlfcDFjSXRscXZmUHMzeDRvUUVxYXN5cHJiVl9QTFdnMjVvZlVKdjJJNXlNb3VvcEYwYVNieDFfX0VudzNqOXNzcHdqWXEwYWZEZ0tyNUZCcmcxb1ZYb0JPUHEzYzFjNGhpenk5cW1ZcHJ3c3FXSGdwcHRMQTh3aThrb25FeUtjdkkyVHFTZDZsWGZMcGpTMTBwM2hpNE05Z3A0R3M5eDIwdkFZRWduNjFiS25MMGctVTdaOUlKZEFiM0w1RENpUnVxUlFlUl82Y1dBU2F1WFgtU2t6Ml9tTkNvQ0oyV0dYbzJYNldydFdqSTNCNWtSRVRFdDhLTkFEQnlFb0dFS1hFcTVLUllWSDFubERIS0dBS1ZnWHptTnNrNzB3ZkExS2dzME9QTzdMcnFvZENublNjLUZpX1hRXzN4NEVQWEN6UThJWVh0VE9VdWV0azdsIiwiYWxnb3JpdGhtIjoiSE1BQy1TSEEyNTYiLCJpc3N1ZWRfYXQiOjE1OTk2NDM2MjF9",
//   "userID": "180613306902120"
// }
