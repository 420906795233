import Config from 'config'

export default [
  {
    path: `/:lang(${Config.Theme.storeviewRegex})/amazonpayments`,
    name: 'amazoncheckout',
    component: () => import('./pages/Checkout.vue'),
    meta: {
      tplName: 'funnel_step2'
    }
  },
  {
    path: `/:lang(${Config.Theme.storeviewRegex})/amazonauthorize`,
    name: 'amazonAuthorize',
    component: () => import('./pages/Authorize.vue'),
    meta: {
      tplName: 'funnel_step2'
    }
  },
  {
    path: `/:lang(${Config.Theme.storeviewRegex})/amazoncomplete`,
    name: 'amazoncheckoutResponse',
    component: () => import('./pages/Complete.vue'),
    meta: {
      tplName: 'funnel_step2'
    }
  }
]
