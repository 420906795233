<template>
  <div class="product-info__color">
    <div class="color-title" v-show="showTitle">
      <span class="text-uppercase">{{$t('color')}}: </span><span class="text-capitalize d-inline-block"><strong>{{colorSelectedLabel}}</strong></span>
    </div>
      <no-ssr>
        <overlay-scrollbars :options="{ scrollbars: { autoHide: 'scroll' }, overflowBehavior: {y: 'hidden', x: 'scroll'} }" ref="newsletterScrollbar">
          <div class="color-list">
            <div class="swatch-option" v-for="sibiling in siblingProductsComplete" :key="sibiling.color" :class="{'active' : sibiling.color === colorSelected }">
              <div class="swatch-option__radio" :style="{backgroundImage: `url(${sibiling.color_image})`}" @click.prevent="change(sibiling)"></div>
            </div>
          </div>
        </overlay-scrollbars>
      </no-ssr>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    product: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    siblingProductsComplete () {
      const allColor = [].concat(this.siblingProducts)
      allColor.push({
        color: this.colorSelected,
        color_image: this.product.color_image,
        color_label: this.colorSelectedLabel,
        url_key: this.product.url_key,
        url_suffix: this.product.url_suffix
      })
      return _.sortBy(allColor, ['color'])
    },
    siblingProducts () {
      return this.product.sibling_products || []
    },
    colorSelected () {
      return this.product.color
    },
    colorSelectedLabel () {
      return this.product.color_label
    }
  },
  methods: {
    change (sibiling) {
      // const _this = this
      const finishPath = sibiling.url_key + sibiling.url_suffix
      let beforePath = ''
      if (this.$store.state.cms.currentComponent === 'ProductPage') {
        beforePath = this.$route.fullPath
        beforePath = beforePath.substr(0, beforePath.lastIndexOf('/') + 1)
        this.$router.push(beforePath + finishPath)
      }
      // else {
      //   // category path
      //   this.$store.commit('ui/showLoader')
      //   this.$store.dispatch('product/loadByUrlKey', sibiling.url_key + sibiling.url_suffix)
      //     .then(product => {
      //       _this.$store.dispatch('listing/manageQuickView', product)
      //     })
      //     .finally(() => {
      //       _this.$store.commit('ui/hideLoader')
      //     })
      // }
      this.$emit('siblingChange', sibiling)
    }
  }
}
</script>
