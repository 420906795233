import $ from 'jquery'
import { EventBus } from '@/helpers'
var attrSlickLoaded = 'data-bcm-slick-initialized'

export function init () {
  setStyleOnMouseEnterLeave()
  if (process.client) {
    EventBus.$on('cms:update', () => {
      if (global.slick) {
        var $elements = $('div[data-content-type="products"]')
        $elements.each((index, element) => {
          let $element = $(element)
          let attr = $element.attr(attrSlickLoaded)
          // For some browsers, `attr` is undefined; for others,
          // `attr` is false.  Check for both.
          if (attr === undefined || attr === false) {
            setStyleOfQuickBuyButton($element)
          }
        })
      }
    })
  }
}
function setStyleOfQuickBuyButton ($element) {
  let $buttons = $element.find('.action-quickbuy .quickbuy')
  /* $buttons.addClass('pagebuilder-button-link-secondary') */
  let productItem = $element.find('.product-item-details')
  if ($element.hasClass('mobile-carousel-with-two-product')) {
    let title = $element.find('.product-item-name a')
    let max = 0
    setTimeout(function () {
      title.each(function (index) {
        if ($(this).height() > max) {
          max = $(this).height()
        }
      })
      title.height(max)
    }, 200)
  }
  let max = 0
  setTimeout(function () {
    productItem.each(function (index) {
      if ($(this).height() > max) {
        max = $(this).height()
      }
    })
    $element.find('.slick-arrow').css('margin-top', '-' + max + 'px')
  }, 100)
  $buttons.text(global.$i18n.t('quickbuy'))
}

function setStyleOnMouseEnterLeave () {
  let initialStyle = {}
  $(document).on('mouseenter', '.pagebuilder-button-custom', function () {
    let $element = $(this)
    initialStyle = {
      'background-color': $element.css('background-color'),
      'color': $element.css('color')
    }
    let colorOnHover = $element.attr('color_on_hover')
    let backgroundColorOnHover = $(this).attr('background_color_on_hover')
    if (colorOnHover) {
      $element.css('color', $(this).attr('color_on_hover'))
    }
    if (backgroundColorOnHover) {
      $element.css('background-color', $(this).attr('background_color_on_hover'))
    }
  })
  $(document).on('mouseleave', '.pagebuilder-button-custom', function () {
    let $element = $(this)
    $element.css(initialStyle)
  })
}
