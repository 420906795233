import Logger from '@/services/Logger'
import { setToken as MagentoSetToken } from '@/services/Magento'

export default store => {
  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'user/setToken':
        const token = state.user.token || ''
        Logger.debug('************** user/setToken', token)
        if (process.client) {
          if (token) {
            global.$cookies.set('token', token)
          } else {
            global.$cookies.remove('token')
          }
        }
        MagentoSetToken(token)
        break
      case 'user/setCustomer':
        if (process.client) {
          const userStore = require('@/store/lib/storage').userStore
          userStore.setItem('customer', state.user.current)
        }
        break
      case 'user/setGuest':
        if (process.client) {
          const userStore = require('@/store/lib/storage').userStore
          userStore.setItem('guest', state.user.guest)
        }
        break
      case 'user/newUser':
        if (process.client) {
          const userStore = require('@/store/lib/storage').userStore
          userStore.setItem('newUser', mutation.payload)
        }
        break
      case 'user/guestSetWishlistShareCode':
        if (process.client) {
          const guestWishlistShareCode = require('@/store/lib/storage').guestWishlistShareCode
          guestWishlistShareCode.setItem('guestWishlist', state.user.guest.wishlist.sharing_code)
        }
        break
    }
  })
}
