export default `
  __typename
  sku
  ... on ConfigurableProduct {
    options {
      title
      required
      sort_order
    }
    configurable_options {
      attribute_code
      attribute_id
      values {
        value_index
        label
      }
    }
    variants {
      product {
        id
        sku
        stock_status
        quantity
        enabled
        out_of_stock_threshold
        is_backinstock
        attribute_set_id
        color
        special_from_date
        special_price
        special_to_date
        master_sku
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            value,
              currency
          }
          quantity
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          },
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          },
        },
      }
      attributes {
        label
        code
        value_index
      }
    }
    groupedVariation{
      color
      color_image
      color_label
      name
      url_key
      url_suffix
      sku
      image {
        label
        url
      }
      media_gallery {
        position
        label
        url
        ... on ProductVideo {
          position
          video_content {
            media_type
            video_provider
            video_url
            video_title
            video_description
            video_metadata
          }
        }
      }
    }
  }
`
