
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { state } from './state'

export default function extendProduct (storeProduct) {
  storeProduct.actions = { ...storeProduct.actions, ...actions }
  storeProduct.state = { ...storeProduct.state, ...state(storeProduct) }
  storeProduct.mutations = { ...storeProduct.mutations, ...mutations }
  storeProduct.getters = { ...storeProduct.getters, ...getters }
  return storeProduct
}
