import getters from './getters'
import actions from './actions'
import { state } from './state'
import mutations from './mutations'

export default function extendCart (storeCart) {
  storeCart.getters = { ...storeCart.getters, ...getters }
  storeCart.actions = { ...storeCart.actions, ...actions }
  storeCart.mutations = { ...storeCart.mutations, ...mutations }
  storeCart.state = { ...storeCart.state, ...state(storeCart) }
  return storeCart
}
