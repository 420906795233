<template>
  <div class="block-notify-me" :class="{ 'notification-sent': notifySent }">
    <h2 v-if="title && !notifySent" class="title">{{ title }}</h2>
    <div v-if="notifySent" class="title">
      <p class="mb-0">{{ $t('productPage.thanks') }}</p>
    </div>
    <div class="description">
      <p v-if="notifySent" class="notify-sent">{{ $t('productPage.notifySent') }}</p>
      <p v-if="!notifySent" class="backinstock-desc">{{ $t('productPage.backInStockDescription') }}</p>
    </div>
    <div v-show="!notifySent" class="block-email">
      <b-form ref="notify-form" data-vv-scope="form-notifyme" @submit.prevent="handleSubmit" novalidate>
        <div class="notify-form__action">
          <base-input
            name="email"
            v-validate="'required|email'"
            v-model="email"
            type="email"
            autocomplete="off"
            :placeholder="'* ' + $t('address.Email')"
            :validations="[
              {
                condition: errors.has('email'),
                text: errors.first('email')
              }
            ]"
          ></base-input>
          <button type="submit" class="btn btn-none notifyme-btn">
            {{$t('form-notify-me.submit-button')}}
          </button>
        </div>
        <base-check-box
          v-if="hasPrivacyCheckbox"
          class="email-privacy-checbox"
          id="email-privacy"
          v-validate="'required:true'"
          v-model="formCheckbox"
          name="email-privacy"
          :use_prevent='false'
          :label="labelLinkPrivacy"
          @label-clicked="onLabelClicked()"
          :validations="[{
              condition: errors.has('form-notifyme.email-privacy'),
              text: errors.first('form-notifyme.email-privacy')
          }]"
        ></base-check-box>
        <div v-if="!hasPrivacyCheckbox"
            class="email-privacy">
          <p v-html="labelLinkPrivacy"></p>
        </div>
      <button type="submit" class="btn btn-primary mobile">
        {{$t('form-notify-me.submit-button')}}
      </button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { toast } from '@/modules/notifications'
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import Logger from '@/services/Logger'
import { EventBus } from '@/helpers'
import { pathTranslate } from '@/helpers/routeHelpers'
import { navigateToPrivacyPolicy } from 'theme/helpers'

export default {
  inject: ['$validator'],
  name: 'NotifyMe',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    product: {
      type: Object,
      required: true
    },
    variant: {
      type: Array,
      default: null
    },
    addToWishlist: {
      type: Boolean,
      default: false
    },
    hasPrivacyCheckbox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      email: this.$store.state.user.current ? this.$store.state.user.current.email : '',
      formCheckbox: false,
      notifySent: false
    }
  },
  computed: {
    getProductByType () {
      // return product if it is array
      let theProductSelected = this.getProductSelected
      console.log(theProductSelected)
      if (Array.isArray(theProductSelected)) {
        if (process.client && theProductSelected.length !== 1) {
          console.log('Only accept single product')
          if (localStorage.getItem('notifyMySelect')) {
            let selected = JSON.parse(localStorage.getItem('notifyMySelect'))
            return this.getUserOptionSelected(theProductSelected, selected.opt) // theProductSelected[0] // console.error('Only accept single product')
          }
        }
        return theProductSelected[0]
      }
      return theProductSelected
    },
    getProductSelected () {
      let tempProduct = this.$store.state.product.tmpProdSelected
      if (tempProduct.length > 0) {
        return tempProduct
      } else if (this.product) {
        return this.product
      }
      return []
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'factoryPage', params: { slug: pathTranslate({ path: 'privacy' }), lang: this.$store.state.ui.urlCode } }).href
      if (this.hasPrivacyCheckbox) {
        // only if the privacy checkbox is present
        return this.$t('termsNotifyMeForCheckbox', { linkPrivacy })
      }
      return this.$t('termsNotifyMe', { linkPrivacy })
    }
  },
  mounted () {
    EventBus.$emit('wishlistTile:notificationSent', this.notifySent)
  },
  methods: {
    onLabelClicked () {
      navigateToPrivacyPolicy()
    },
    clearForm () {
      this.email = this.$store.state.user.current ? this.$store.state.user.current.email : ''
      this.formCheckbox = false
      this.$validator.reset()
    },
    handleSubmit (evt) {
      if (evt) {
        evt.preventDefault()
      }

      if (this.productId !== -1) {
        const _this = this
        this.$validator.validateAll('form-notifyme').then(isValid => {
          if (isValid) {
            // actions here
            // let productId = ((this.variant && this.variant[0])) ? this.variant[0].product.id : this.product.id
            _this.$store.state.ui.loader = true
            _this.$store.dispatch('product/setNotify', { email: _this.email, productId: _this.getProductByType.id })
              .then(res => {
                // if user is logged add product to wishlist
                if (_this.$store.state.user.current && _this.addToWishlist) {
                  _this.addProductToWishlist()
                }
                // toast.success(_this.$t('Request successful'))
                _this.notifySent = true
                _this.clearForm()
                EventBus.$emit('wishlistTile:notificationSent', _this.notifySent)
              })
              .catch(() => {
                toast.error(_this.$t('GenericError'))
              })
              .finally(() => { _this.$store.state.ui.loader = false })
          }
        })
      } else {
        Logger.error('product id not valid, got -1')
      }
    },
    addProductToWishlist () {
      this.$store.dispatch('user/addToWishlist', { product: this.product, filters: this.variant })
        .catch(res => {
          const msg = res.body && res.body.result ? res.body.result : 'GenericError'
          // logger error here
          Logger.error(msg)
        })
    },
    getUserOptionSelected (filtersVariants, option) {
      let variants = this.product.variants
      let setVariant = []
      for (let i in variants) {
        for (let j in filtersVariants) {
          if (variants[i].product.sku === filtersVariants[j].sku) {
            console.log(variants[i])
            setVariant.push(variants[i])
          }
        }
      }
      let selected = null
      for (let x in setVariant) {
        for (let y in setVariant[x].attributes) {
          if (setVariant[x].attributes[y].value_index === option.value_index) {
            selected = setVariant[x]
          }
        }
      }
      if (selected) return selected.product
      return filtersVariants[0]
    }
  },
  components: {
    BaseInput,
    BaseCheckBox
  }
}
</script>
