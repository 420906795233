/*
 *     Copyleft (c) 2020 Bcame S.r.l.. All lefts reserved.
 *     Bcame S.r.l. Technology Business Unit
 *
 *     Company confidential.
 *
 *     This software is proprietary  to and embodies the confidential
 *     technology of Bcame S.r.l..  Possession, use, or copying of this
 *     software and media is authorized only pursuant to a valid written
 *     license from Bcame S.r.l.  or an authorized sublicensor.
 *
 *     Bcame S.r.l.
 */

<template>
  <transition>
    <div class="wrapp-leftbar" v-show="showLeftBar">
      <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      @after-leave="showLeftBar = false">
        <div v-show="startAnimation" class="leftbar-overlay" @click="closeLeftBar"></div>
      </transition>
      <transition
      enter-active-class="animate__animated animate__fadeIn animate__slideInLeft animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__slideOutLeft animate__faster"
      @before-enter="startAnimation = showLeftBar = true"
      @after-enter="toggleContent(true)"
      @before-leave="toggleContent(false)"
      @after-leave="startAnimation = false">
      <div class="wrapp-leftbar__content" v-show="leftActiveComponent.component" :class="dynamicClass" :style="dynamicStyleForTopHeaderHeight">
        <div class="wrapp-leftbar__content__header">
          <slot name="header">
            <slot name="header-left-column">
              <button class="cta btn btn-icon arrow-left-icon" :class="{'visibility-hidden': (leftNavHistory.length <= 1 || showGoBack )}" @click.prevent="goBackHistory">
                <i class="cl-black bcm-icon bcm-icon-26-arrow-left"></i>
              </button>
            </slot>
            <slot name="header-center-column">
                <transition
                mode="out-in"
                enter-active-class="animate__animated animate__fadeInUp animate__faster"
                leave-active-class="animate__animated animate__fadeOutUp animate__faster"
                >
                  <div class="column-title mx-auto" :key="leftBarTitle">
                    <span class="title">{{ leftBarTitle }}</span>
                  </div>
                </transition>
            </slot>
            <slot name="header-right-column">
              <button class="bcm-close" type="button" @click="closeLeftBar">
                <span></span>
                <span></span>
              </button>
            </slot>
          </slot>
        </div>
        <no-ssr>
          <overlay-scrollbars class="wrapp-leftbar__content__body" :options="{ overflowBehavior: {x: 'hidden', y: 'scroll'}, className: 'os-theme-minimal-light' }" ref="leftNavScrollbar">
            <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut">
              <div class="wrapp-leftbar__content__body__dynamic" v-show="showContent" :key="leftActiveComponent.id">
                <component :is="leftActiveComponent.component" v-bind="leftActiveComponent.props"></component>
              </div>
            </transition>
          </overlay-scrollbars>
        </no-ssr>
      </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import $ from 'jquery'
import { EventBus } from '@/helpers'

export default {
  name: 'LeftNavigation',
  props: {
    showGoBack: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTitle: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      title: this.headerTitle,
      showContent: false,
      showLeftBar: false,
      startAnimation: false,
      topHeaderHeight: 0
    }
  },
  computed: {
    leftNavHistory () {
      return this.$store.state.ui.leftNavHistory
    },
    leftActiveComponent () {
      let activeComp = this.$store.getters['ui/getLeftActiveComponent']
      if (process.client) {
        $('body').toggleClass('overflow-hidden', !!activeComp.component)
        if (this.$refs.leftNavScrollbar) {
          const osInstance = this.$refs.leftNavScrollbar.osInstance()
          osInstance.scrollStop().scroll(0)
        }
      }
      return activeComp
    },
    dynamicClass () {
      let classObj = {}
      let leftActiveComponent = this.$store.state.ui.leftActiveComponent
      if (leftActiveComponent && leftActiveComponent.component && leftActiveComponent.component.name) {
        classObj = {
          [`component-active-${leftActiveComponent.component.name.toLowerCase()}`]: true
        }
      }
      return classObj
    },
    dynamicStyleForTopHeaderHeight () {
      if (!this.topHeaderHeight) {
        return {}
      }
      return {
        'top': `${this.topHeaderHeight}px`,
        'height': `calc(100% - ${this.topHeaderHeight}px)`
      }
    },
    leftBarTitle () {
      let title = ''
      if (this.leftActiveComponent && this.leftActiveComponent.props) {
        title = this.leftActiveComponent.props.headerTitle
      }
      return title
    }
  },
  methods: {
    closeLeftBar () {
      this.animation = false
      this.$store.commit('ui/setLeftActiveComponent', {})
      this.$store.commit('ui/isShowedLeftNav', false)
    },
    goBackHistory () {
      this.$store.dispatch('ui/loadLeftActiveComponent', { isBack: true })
    },
    toggleContent (toggle) {
      this.showContent = toggle
      this.startAnimation = toggle
    },
    scrollTo (scrollObject) {
      let scrollbar = this.$refs.leftNavScrollbar
      if (scrollbar) {
        const osInstance = scrollbar.osInstance()
        osInstance.scrollStop().scroll(scrollObject, 600)
      }
    }
  },
  mounted () {
    EventBus.$on('LeftNavigation:scrollTo', this.scrollTo)
    this.topHeaderHeight = $('.bcm-whole-header').height()
  },
  destroyed () {
    EventBus.$off('LeftNavigation:scrollTo', this.scrollTo)
  }
}
</script>
