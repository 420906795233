import $ from 'jquery'
import { EventBus, randomInRange } from '@/helpers'
import { isMobile } from '@/helpers/utils'

var attrProcessed = 'data-bcm-processed'
var posts = {}

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      $(function () {
        let $elements = $('.pagebuilder-slider.slider-world-of')
        $elements.each(function () {
          let $element = $(this)
          let attr = $element.attr(attrProcessed)
          if (attr === undefined || attr === false) {
            // set $el as processed
            $element.attr(attrProcessed, '')
            $element.attr('id', `pagebuilder-slider-${randomInRange()}`)
            let $slides = $element.find('[data-content-type="slide"]')
            // create map of slider that contains slides with posts
            posts[$element.attr('id')] = []
            $slides.each((index, element) => {
              let $slide = $(element)
              let slideName = $slide.data('slideName') || ''
              let postDetails = slideName.split('-')
              let title = postDetails.length > 0 ? postDetails[0] : slideName
              let shortContent = postDetails.length > 0 ? postDetails[1] : ''
              let $pagebuilderSlideWrapper = $slide.find('[data-background-images]')
              let $imageObject = JSON.parse($pagebuilderSlideWrapper.data('backgroundImages').replace(/\\(.)/mg, '$1')) || {}
              let imageSource = ''
              if ($imageObject && Object.keys($imageObject).length > 0) {
                imageSource = isMobile() && $imageObject.mobile_image
                  ? $imageObject.mobile_image
                  : $imageObject.desktop_image
              }
              posts[$element.attr('id')].push({
                post_id: index,
                title,
                first_image: imageSource,
                short_content: shortContent
              })
            })
          }
        })
      })
    })
    $(document).on('click', '.pagebuilder-slider.slider-world-of [data-content-type="slide"]', function (event) {
      let $slide = $(this)
      let $slider = $slide.closest('.pagebuilder-slider.slider-world-of')
      let index = $slide.closest('.slick-slide').data('slickIndex')
      openPostsSlider(posts[$slider.attr('id')], index)
    })
  }
}

function openPostsSlider (posts, currentIndex) {
  let props = {
    componentClass: 'world-of',
    posts,
    showSlide: true,
    canLoadMore: false,
    totalPosts: posts.length,
    parentLoadMoreCallback: '',
    currentIndex
  }
  global.$store.commit('ui/setPostsSlide', props)
}
