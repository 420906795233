export default `query StoreConfig ($value: String){
  storeConfig (value: $value) {
    id
    code
    store_name
    locale
    root_category_id
    requestInvoiceSettings {
      is_enabled
      threshold
    }
    absolute_footer
    base_url
    base_link_url
    base_media_url
    base_static_url
    base_currency_code
    secure_base_url
    catalog_default_sort_by
    category_url_suffix
    show_cms_breadcrumbs
    cms_home_page
    cms_no_cookies
    cms_no_route
    copyright
    default_title
    default_keywords
    default_description
    default_display_currency_code
    front
    head_includes
    head_shortcut_icon
    header_logo_src
    logo_alt
    list_mode
    list_per_page
    list_per_page_values
    no_route
    product_url_suffix
    website_id
    welcome
    newsletterSettings {
      modal_media_url
      page_media_url
    }
    seo_markup_seller {
      json
      show
    }
    seo_markup_speakable {
      json_category
      json_product
      json_page
    }
    seo_markup_website
    seo_markup_search_action
    paymentMethodSettings {
      adyenConfig {
        client_key_live
        client_key_test
        live_endpoint_prefix
        merchant
        mode
      }
      amazonConfig {
        client_key
        merchant
        mode
      }
      paypalConfig {
        merchant
        mode
      }
    }
  }
}`
