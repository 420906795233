import ProductConfig from './productConfig'
import Config from 'config'
const attrArr = JSON.stringify(Config.Theme.attributesToload)

export const relatedProduct = `
related_products {
  color
  url_key
  url_suffix
  sku
  name
  media_gallery_entries {
    file
    types
  }
  media_gallery {
    position
    label
    url
    ... on ProductVideo {
      video_content {
        video_url
      }
    }
  }
  master_sku
  ... on GroupedProduct {
    configurableParent {
      ... on ConfigurableProduct {
        configurable_options {
          attribute_code
          attribute_id
          values {
            value_index
            label
          }
        }
        variants {
          product {
            id
            sku
            stock_status
            quantity
            out_of_stock_threshold
            is_backinstock
            attribute_set_id
            color
            special_from_date
            special_price
            special_to_date
            master_sku
            price_tiers {
              discount {
                amount_off
                percent_off
              }
              final_price {
                value,
                  currency
              }
              quantity
            }
            price_range {
              minimum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
                regular_price {
                  value
                  currency
                }
              },
              maximum_price {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
                regular_price {
                  value
                  currency
                }
              },
            },
          }
          attributes {
            label
            code
            value_index
          }
        }
      }
    }
  }
  price_range {
    minimum_price {
      discount {
        amount_off
        percent_off
      }
      final_price {
        value
        currency
      }
      regular_price {
        value
        currency
      },
    },
    maximum_price {
      discount {
        amount_off
        percent_off
      }
      final_price {
        value
        currency
      }
      regular_price {
        value
        currency
      }
    },
  },
}
`
export default `
  ${relatedProduct}
  ... on GroupedProduct {
    __typename
    id
    name
    sku
    url_key
    url_suffix
    color
    listing_label
    video_link
    carryover
    master_sku
    sku
    size_scale_code
    size_guide_url
    size_scale_url
    hidden_variants
    tax_rate
    preorder_delivery
    attributesLabel (attributes_identifier: ${attrArr}) {
      label
      value
      attribute_id
      attribute_code
    }
    meta_title
    meta_description
    meta_keyword
    meta_robots
    stock_status
    head_content_page
    first_category
    last_breadcrumb {
      name
      url
    }
    seo_markup_product
    description { html }
    short_description { html }
    custom_canonical_link
    href_lang_links{href, hreflang}
    categories{
      id
      breadcrumbs{
        category_id
        category_name
        category_url_path
      }          
    }
    description {
      html
    }
    media_gallery_entries {
      file
      types
    }
    media_gallery {
      position
      label
      url
      ... on ProductVideo {
        position
        video_content {
          video_url
        }
      }
    }
    price_tiers {
      discount {
        amount_off
        percent_off
      }
      final_price {
        value,
          currency
      }
      quantity
    }
    price_range {
      minimum_price {
        discount {
          amount_off
          percent_off
        }
        final_price {
          value
          currency
        }
        regular_price {
          value
          currency
        },
      },
      maximum_price {
        discount {
          amount_off
          percent_off
        }
        final_price {
          value
          currency
        }
        regular_price {
          value
          currency
        }
      }
    }
    configurableParent{
      ${ProductConfig}
    }
  }
`
