<template>
  <div class="fake-select-size__container" :class="{'isUniSize': isUni}">
    <div class="fake-select-size" :class="{isOptionSelected}" @click.prevent="openSizeSelectorPanel()">
      <span class="placeholder">{{$t('size')}}</span>
      <div class="selected">
        <span v-if="selectedOption.label" class="selected-option">{{selectedOption.label}}</span>
        <i class="icomoon-icon icon-chevron-down"></i>
      </div>
    </div>
    <div v-if="!isFromQuickBuy" class="fake-select-size__actions" :class="{'visibility-hidden-sizeguide-child': isUni}">
      <div class="size-guide naiz-opener-container" :class="{'visibility-hidden-sizeguide': isUni}">
        <button class="btn btn-none bcm-link bcm-link-underline-primary" @click.prevent="openSizeGuide()">{{ $t('find-your-size') }}</button>
      </div>
      <div class="contact-us">
        <button class="btn btn-none bcm-link bcm-link-underline-primary" @click.prevent="openContactUs">{{ $t('route.contact-us') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getVariantByFilter } from 'theme/helpers'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import { EventBus } from '@/helpers'
import { toast } from '@/modules/notifications'
// import uniqBy from 'lodash/uniqBy'

export default {
  name: 'FakeSizeSelector',
  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({})
    },
    options: {
      type: Array,
      required: true
    },
    parentAttribute: {
      type: String,
      required: true
    },
    filterActive: {
      type: Object,
      required: true
    },
    defaultSelected: {
      type: Number,
      required: false,
      default: null
    },
    filterCode: {
      type: String,
      default: 'size'
    },
    isComingSoon: {
      type: Boolean,
      default: false
    },
    isFromQuickBuy: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      selectedOption: {},
      sizeGuideExist: false
    }
  },
  mounted () {
    if (process.client) {
      EventBus.$on('sizeSelectorPanel:selected', this.setSelected)
      EventBus.$on('sizeSelectorPanel:open', this.openSizeSelectorPanel)

      // on color changed unset size
      EventBus.$on('colorSelector:changed', this.setSelected)

      // naizfit window script
      // execute when it will be available
      window.nfsfo && window.nfsfo.update()
      this.naizFitSelector()
      this.naizFitFinder()
      this.ifHaveOneOption()
    }
  },
  destroyed () {
    EventBus.$off('sizeSelectorPanel:open', this.openSizeSelectorPanel)
  },
  computed: {
    getActiveCountry () {
      return this.$store.getters['ui/getActiveCountry']
    },
    isOptionSelected () {
      if (this.selectedOption.label) {
        return 'flex-end'
      }
    },
    getSizes () {
      let sizes = []
      if (this.$store.state.product.current) {
        const product = this.$store.state.product.current
        sizes = find(product.configurable_options, { 'attribute_code': 'size' })
        if (sizes) {
          sizes = sizes.values
        }
      }
      return sizes
    },
    getVariant () {
      return getVariantByFilter('size', this.selectedOption, this.product, true)
    },
    getSelectedSizeFromFakeSelect () {
      return this.selectedOption && this.selectedOption.label && this.getActiveCountry
        ? `${this.selectedOption.label} ${this.getActiveCountry}`
        : undefined
    },
    optionsByFilter () {
      let filters = []
      if (this.product) {
        filters = getVariantByFilter(this.filterCode, this.filterActive, this.product, false)
      } else {
        filters = this.$store.getters['product/getVariantByFilter'](this.filterCode, this.filterActive, false)
      }
      return filters && filters.length ? sortBy(filters, ['value']) : []
    },
    isUni () {
      /* let allVbYf = getVariantByFilter(this.filterCode, this.filterActive, this.product.configurableParent, false)
      allVbYf = uniqBy(allVbYf, 'value')
      return allVbYf.length <= 1 */
      if (this.options.length === 1) {
        // this.setSelected(this.optionsByFilter[0])
        return true
      }
      return false
    }
  },
  methods: {
    openSizeSelectorPanel () {
      // if (this.options.length === 1) return
      const _this = this
      const componentToLoad = import('theme/components/Product/Variants/SizeSelectorPanel')
      this.$store.dispatch('ui/loadRightActiveComponent',
        {
          component: componentToLoad,
          propsRightNavigation: {
            title: _this.$t('Select size')
          },
          props: {
            product: _this.product,
            filterActive: this.filterActive,
            options: _this.optionsByFilter,
            isComingSoon: this.isComingSoon,
            selectedOption: this.selectedOption
          }
        })
      if (process.client) {
        setTimeout(function () {
          window.naizjs && window.naizjs.naiz_recommend_size && window.naizjs.naiz_recommend_size()
        }, 100)
      }
      window.dataLayer.push({
        event: 'product_action',
        item_id: this.product.id,
        item_name: this.product.name,
        event_detail: 'size_dropdown'
      })
    },
    openSizeGuide () {
      const _this = this
      const componentToLoad = import('theme/components/Product/SizeGuide')
      this.$store.dispatch('ui/loadRightActiveComponent',
        {
          component: componentToLoad,
          propsRightNavigation: {
            title: _this.$t('Trova Taglia')
          },
          props: {
            product: _this.product,
            filterActive: this.filterActive,
            options: _this.optionsByFilter,
            isComingSoon: this.isComingSoon,
            selectedOption: this.selectedOption
          }
        })
      EventBus.$emit('track:productAction', { event: 'size_finder', product: { ...this.product } })
    },
    openContactUs () {
      const componentToLoad = import('theme/components/Modal/NeedHelp')
      this.$store.dispatch('ui/showDynamicModalComponent', {
        component: componentToLoad,
        propsBootstrapVueModal: {
          'hide-footer': true,
          'hide-header': false,
          'modal-class': 'modal-need-help',
          centered: true
        },
        props: {}
      })
      EventBus.$emit('track:productAction', { event: 'contact_us', product: { ...this.product } })
    },
    composeOption (option) {
      return {
        type: this.parentAttribute,
        value: option.value,
        isNotificable: !option.in_stock
      }
    },
    setSelected (option) {
      this.$emit('filterSelected', this.composeOption(option))
      this.selectedOption = option
      this.ifHaveOneOption()
    },
    sizeGuideExistCheck () {
      this.sizeGuideExist = this.product.size_scale_code && this.product.attributesLabel['gender'].value && this.product.attributesLabel['type'].value
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues.value, isNotificable: !newValues.in_stock }
    },
    showError (payload) {
      this.sizeError = payload
    },
    change (newValues) {
      if (this.requestSize) {
        if (newValues) {
          this.selected = newValues
          this.showError(false)
        }
      } else {
        if (newValues) {
          let notifiable = !newValues.in_stock && newValues.is_backinstock === 1
          this.selected = newValues
          if (notifiable) {
            if (this.$refs.sizeSelect) {
              this.$refs.sizeSelect.clearSelection()
              this.selected = null
            }
          }
          this.$emit('filterSelected', this.getFilterCompose(newValues))
          this.showError(false)
        }
      }
    },
    naizFitSelect (e) {
      let selectedFromNaizFit = this.optionsByFilter.find(el => el.label.includes(e.detail.size))
      if (selectedFromNaizFit && selectedFromNaizFit.quantity > 0) {
        this.change(selectedFromNaizFit)
        setTimeout(() => {
          if (process.client && !window.added) {
            document.querySelector('.btn-add-to-cart').click()
            document.querySelector('.naiz-modal-close').click()
            window.added = true
          }
        }, 50)
      } else {
        setTimeout(() => {
          if (process.client && !window.added) {
            document.body.classList.add('toasted-is-visible')
            toast.error(this.$t('notSizeAvailable'))
            document.querySelector('.naiz-modal-close').click()
          }
        }, 50)
        setTimeout(() => {
          if (process.client && !window.added) {
            document.body.classList.remove('toasted-is-visible')
            document.querySelector('.naiz-modal-close').click()
          }
        }, 2000)
      }
    },
    naizFitSelector () {
      window.removeEventListener('naiz_add_to_cart', this.naizFitSelect)
      window.addEventListener('naiz_add_to_cart', this.naizFitSelect)
      /* window.naizjs && window.naizjs.naiz_recommend_size && window.naizjs.naiz_recommend_size() */
    },
    naizFitFinder () {
      if (!window.onClickListenerAdded) {
        window.removeEventListener('naiz_size_finder', this.naizFitFind)
        window.addEventListener('naiz_size_finder', this.naizFitFind)
        window.onClickListenerAdded = true
      }
    },
    naizFitFind () {
      if (process.client) {
        window.dataLayer.push({
          event: 'product_action',
          item_id: this.product.id,
          item_name: this.product.name,
          event_detail: 'size_finder'
        })
      }
    },
    ifHaveOneOption () {
      // if (this.product.variants.length === 1) {
      if (this.options.length === 1) {
        this.setSelected(this.optionsByFilter[0])
      }
    }
  }
}
</script>
