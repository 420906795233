import { EventBus } from '@/helpers'
import { mobileDetector, getCurrentPrice } from '@/helpers/utils'

const trackListener = [
  'track:userRegistration',
  'track:login',
  'track:wishlist',
  'track:newsletter',
  'track:searchSuggestion',
  'track:clickProduct',
  'track:addToCart',
  'track:removeFromCart',
  'track:categoryList',
  'track:productDetail',
  'track:deleteProduct',
  'track:viewCart',
  'track:cart',
  'track:checkoutStep',
  'track:checkout',
  'track:sendShippingInfo',
  'track:sendShippingMethodInfo',
  'track:modifyCheckout',
  'track:thankYouPage',
  'track:cookieConsent',
  'track:productAction',
  'track:countryAndLanguage',
  'track:loginStatus'
]
class Tracker {
  constructor ({ store, gtm, app, router }) {
    this.store = store
    this.gtm = gtm
    this.app = app
    this.router = router
    this.firstCall = true
    this.activeListener()
  }

  evtTofx (evtName) {
    return evtName.replace('track:', '')
  }

  activeListener () {
    const _this = this
    trackListener.forEach(track => {
      if (_this.evtTofx(track) === 'thankYouPage') {
        EventBus.$on(track, _this[_this.evtTofx(track)].bind(_this))
      } else {
        EventBus.$on(track, _this[_this.evtTofx(track)].bind(_this))
      }
    })
    this.router.afterEach((to, from) => {
      // first time wait user load
      // console.log('trackEvent:GTM:afterEach')
      this.GTMinit()
    })
    // console.log('trackEvent:GTM:activeListener')
  }

  iCanTrack () {
    // PRE CONSENSO COME DA FOGLIO DI DEBUG.
    // return global.Cookiebot && global.Cookiebot.consent ? global.Cookiebot.consent.marketing : false
    return true
  }

  removeListener () {
    trackListener.forEach(track => {
      EventBus.$off(track)
    })
  }

  getChannel () {
    // m, t, d
    const _mobileDetector = mobileDetector()
    if (_mobileDetector) {
      if (_mobileDetector.mobile()) {
        return 'm'
      }
      if (_mobileDetector.tablet()) {
        return 't'
      }
      return 'd'
    }
    return 'not found'
  }

  getTemplateName () {
    let metaTpl = this.app.$route.meta.tplName
    this.router.currentRoute.name === 'home' ? metaTpl = 'homepage' : this.router.currentRoute.name === 'onepagecheckout' ? metaTpl = 'funnel_step2' : metaTpl = 'factoryPage'
    let path = this.router.currentRoute.path
    let locale = path.split('/')[1]
    switch (path) {
      case `/${locale}/`:
        metaTpl = 'homepage'
        break
      case `/${locale}/checkout/thank-you`:
        metaTpl = 'funnel_confirmation'
        break
      case `/${locale}/checkout/cart`:
        metaTpl = 'funnel_basket'
        break
      default:
        break
    }
    if (metaTpl === 'factoryPage') {
      const currentComponent = this.store.state.cms.currentComponent
      if (currentComponent === 'ProductPage') {
        metaTpl = 'product'
      } else if (currentComponent === 'CmsPage') {
        metaTpl = 'other_generic'
      } else if (currentComponent === 'CategoryPage') {
        if (this.store.state.ui.breadcrumb.length > 2) {
          metaTpl = 'category'
        } else {
          metaTpl = 'category_main'
        }
      }
    }
    metaTpl = metaTpl || 'other_generic'
    return metaTpl
  }

  pageType () {
    let metaTpl = this.app.$route.meta.tplName
    let metaPage = this.router.currentRoute.meta || null
    if (!metaPage) return
    let pageType = metaPage.pageType || null
    const currentComponent = this.store.state.cms.currentComponent
    if (metaTpl === 'factoryPage') {
      if (currentComponent === 'ProductPage') {
        pageType = 'Product Detail Page'
      } else if (currentComponent === 'CategoryPage') {
        pageType = 'Product List'
      }
    }
    if (!pageType) return
    if (window.dataLayer) {
      window.dataLayer.push({
        page_type: pageType
      })
    }
  }

  GTMinit () {
    if (!this.iCanTrack()) {
      return false
    }
    if (this.firstCall) {
      this.originalLocation()
      this.loginStatus()
    }
    this.pageType()
    this.gtm.enable(true)
    // console.log('trackEvent:GTM:init')
    // this.trackPage()
    switch (this.getTemplateName()) {
      case 'category_main':
      case 'category':
        this.categoryList()
        break
      case 'product':
        this.productDetail()
        break
    }
    this.firstCall = false
  }

  categoryList () {
    const currentCat = this.store.state.cms.currentComponent === 'CategoryPage' ? this.store.state.category.current : null
    const catName = currentCat && currentCat.name ? currentCat.name : ''
    const catId = currentCat && currentCat.id ? currentCat.id : 0
    this.gtm.trackEvent({
      event: 'categoryList',
      eventCategory: 'Ecommerce',
      eventAction: 'Product Impressions',
      ecommerce: {
        item_category: catId,
        category: catName,
        currencyCode: this.store.state.storeConfig.config.base_currency_code,
        impressions: this.getListProducts(currentCat)
      }
    })
  }

  categoryFromProduct (product) {
    let category = ''
    // if user is navigating from category page
    if (this.store.state.category && Object.keys(this.store.state.category.metaData).length > 1) {
      // set category to category name
      category = this.store.state.category.metaData.name
      // else if current product has categories
    } else if (product.categories) {
      // check if user has primary category attribute
      // and if it's in state categories list
      let primaryCategoryAttr = product.attributesLabel.primary_category
      let stateCategory = this.store.state.category
      let flatted = stateCategory.flatted
      let lastChild = flatted.find(cat => cat.id === primaryCategoryAttr.value)
      if (primaryCategoryAttr && primaryCategoryAttr.value !== 0 && stateCategory && flatted && lastChild) {
        // if yes set category to primary category name
        category = lastChild.name
      } else {
        // else get first breadcrumb from last category
        if (product.categories[product.categories.length - 1].breadcrumbs) {
          let breadcrumb = product.categories[product.categories.length - 1].breadcrumbs
          // set category to first breadcrumb name
          // ex: breadcrumbs: Saldi, Acquista per categoria, Vedi Tutto
          // set category to Saldi
          category = breadcrumb.length > 0 ? breadcrumb[breadcrumb.length - 1].category_name : ''
        }
      }
    }
    return category
  }

  categoryIdFromProduct (product) {
    let id = ''
    if (this.store.state.category && this.store.state.category.categoryId) {
      id = this.store.state.category.categoryId || ''
    } else if (product.categories) {
      // check if user has primary category attribute
      // and if it's in state categories list
      let primaryCategoryAttr = product.attributesLabel.primary_category
      let stateCategory = this.store.state.category
      let flatted = stateCategory.flatted
      let lastChild = flatted.find(cat => cat.id === primaryCategoryAttr.value)
      if (primaryCategoryAttr && primaryCategoryAttr.value !== 0 && stateCategory && flatted && lastChild) {
        // if yes set category to primary category name
        id = lastChild.id || ''
      } else {
        // get first breadcrumb from last category
        if (product.categories[product.categories.length - 1].breadcrumbs) {
          let breadcrumb = product.categories[product.categories.length - 1].breadcrumbs
          // set category to first breadcrumb name
          id = breadcrumb.length > 0 ? breadcrumb[breadcrumb.length - 1].category_id : ''
        } else {
          id = product.categories[product.categories.length - 1].id
        }
      }
    }
    return id
  }

  productDetail (fromQB, fromSwatch, color) {
    let currentProduct = this.store.state.cms.currentComponent === 'ProductPage' ? this.store.state.product.current : null
    if (fromQB) currentProduct = this.store.state.quickBuy.currentProduct
    let category = ''
    let categoryId = ''
    category = this.categoryFromProduct(currentProduct)
    categoryId = this.categoryIdFromProduct(currentProduct)
    // if user is navigating from category page
    const priceInfo = currentProduct ? getCurrentPrice(currentProduct) : null
    this.gtm.trackEvent({
      event: 'productDetail',
      eventCategory: 'Ecommerce',
      eventAction: 'Product Detail',
      ecommerce: {
        currencyCode: this.store.state.storeConfig.config.base_currency_code,
        item_category: categoryId,
        category: category,
        detail: {
          products: [{
            brand: 'Philosophy',
            sku: this.getSelectedSku(currentProduct),
            variant: fromSwatch ? color : this.getSelectedColorDetail(currentProduct),
            size: '',
            id: currentProduct ? currentProduct.id : '',
            name: currentProduct ? currentProduct.name : '',
            price: parseFloat(this.formatPrice(priceInfo ? parseFloat(priceInfo.current) : 0)),
            originalPrice: parseFloat(this.formatPrice(priceInfo ? parseFloat(priceInfo.originalPrice) : 0)),
            discount: priceInfo.discount.amount_off || 0,
            category: category
            // category2: currentProduct.last_breadcrumb.name
          }]
        }
      }
    })
  }

  searchSuggestion (inputText) {
    if (process.client) {
      if (inputText.inputText) {
        this.gtm.trackView('search', `${window.location.href}?q=${inputText.inputText}`)
        this.gtm.trackEvent({
          event: 'search', search: `${window.location.href}?q=${inputText.inputText}`
        })
      }
    }
  }

  userRegistration ({ method }) {
    this.gtm.trackEvent({
      event: 'sign_up',
      method: method
    })
  }

  login ({ method }) {
    this.gtm.trackEvent({
      event: 'login',
      method: method
    })
  }

  newsletter ({ position }) {
    this.gtm.trackEvent({
      event: 'newsletter',
      position: position
    })
  }

  wishlist ({ product, filters = {} }) {
    const priceInfo = product ? getCurrentPrice(product) : null
    // let category = product.categories[product.categories.length - 1]
    // let breadcrumb = category.breadcrumbs
    // let size
    // try {
    //   if (Object.keys(filters).length > 1) {
    //     size = this.getSizeForWishlist(product, filters)
    //   }
    //   size = ''
    // } catch (error) {
    //   console.log(error)
    // }
    this.gtm.trackEvent({
      event: 'addToWishlist',
      eventCategory: 'Ecommerce',
      eventAction: 'Add to Wishlist',
      category: this.store.state.category.current.name || this.store.state.product.current.last_breadcrumb.name || product.extension_attributes.firt_category,
      ecommerce: {
        currencyCode: this.store.state.storeConfig.config.base_currency_code,
        wish: {
          products: [{
            'id': product.id,
            'name': product.name,
            'brand': 'Philosophy',
            'price': priceInfo.current,
            'sku': this.getSelectedSku(product),
            'variant': this.getSelectedColorDetail(product),
            'size': this.getSelectedSizeDetail(product),
            'originalPrice': parseFloat(this.formatPrice(priceInfo ? parseFloat(priceInfo.originalPrice) : 0)),
            'discount': priceInfo.discount.amount_off || 0,
            'category': this.store.state.category.current.name || this.store.state.product.current.last_breadcrumb.name || product.extension_attributes.firt_category || '',
            // 'category2': breadcrumb[0] ? breadcrumb[0].category_name : '', // CategorycurrentProduct.last_breadcrumb.name,
            // 'category3': breadcrumb[1] ? breadcrumb[1].category_name : '', // CategorycurrentProduct.last_breadcrumb.name
            'quantity': 1
          }]
        }
      }
    })
  }

  getListProducts (currentCat) {
    let products = []
    if (currentCat) {
      let productsState = this.store.state.listing.list
      for (let i = 0; i < productsState.length; i++) {
        products.push(this.getInfoListProduct(productsState[i], i, currentCat.name))
      }
    }
    return products
  }

  getInfoListProduct (product, i, catName) {
    let priceInfo = getCurrentPrice(product)
    // const currentCat = this.store.state.cms.currentComponent === 'CategoryPage' ? this.store.state.category.current : null
    // const breadcrumb = currentCat ? this.store.state.ui.breadcrumb : null
    // console.log(breadcrumb)
    // let category = product.categories[product.categories.length - 1]
    // let breadcrumb = category.breadcrumbs
    return {
      'id': product.id,
      'sku': this.getSelectedSku(product),
      'variant': this.getSelectedColorDetail(product),
      'size': '',
      'name': product.name, // Name or ID is required.
      'price': priceInfo.current, // product all tax included
      'originalPrice': parseFloat(this.formatPrice(priceInfo ? parseFloat(priceInfo.originalPrice) : 0)),
      'discount': priceInfo.discount.amount_off || 0,
      'brand': 'Philosophy',
      'category': this.store.state.category.metaData.breadcrumbs.length >= 2 && this.store.state.category.metaData.breadcrumbs[1].category_name.trim() === 'Editorials' ? 'Editorials' : catName || '',
      // 'category2': catName,
      'list': 'listing',
      'position': i
    }
  }

  formatPrice (price) {
    return parseFloat(price).toFixed(2)
  }

  addToCart ({ product, prodComplete, fromQB }) {
    let categoryName = ''
    let parsedCategories = JSON.parse(localStorage.getItem('productConfigurableCategoryName'))
    if (parsedCategories) {
      categoryName = parsedCategories.find((category) => category.item_id === prodComplete.item_id)
      try {
        categoryName = categoryName.category.name
      } catch (error) {
        categoryName = ''
      }
    }
    if (fromQB) {
      let price = product ? product.price : prodComplete.price_incl_tax
      let originalPrice = parseFloat(this.formatPrice(prodComplete.extension_attributes.product_base_price))
      this.gtm.trackEvent({
        event: 'addToCart',
        eventCategory: 'Ecommerce',
        eventAction: 'Add to Cart',
        ecommerce: {
          currencyCode: this.store.state.cart.total.base_currency_code,
          add: {
            products: [{
              'brand': 'Philosophy',
              'id': this.getProductID(prodComplete),
              'sku': product.sku,
              'variant': this.getSelectedColor(prodComplete),
              'size': this.getSelectedSize(prodComplete),
              'name': product && product.name ? product.name : prodComplete.name,
              'price': price,
              'originalPrice': originalPrice,
              'discount': originalPrice - price,
              'category': categoryName,
              'quantity': Math.abs(product.newQty - product.oldQty)
            }]
          }
        }
      })
      return
    }
    let price = product ? product.price : prodComplete.price_incl_tax
    let originalPrice = parseFloat(this.formatPrice(prodComplete.extension_attributes.product_base_price))
    this.gtm.trackEvent({
      event: 'addToCart',
      eventCategory: 'Ecommerce',
      eventAction: 'Add to Cart',
      ecommerce: {
        currencyCode: this.store.state.cart.total.base_currency_code,
        add: {
          products: [{
            'id': this.getProductID(prodComplete),
            'brand': 'Philosophy',
            'sku': product.sku,
            'variant': this.getSelectedColor(prodComplete),
            'size': this.getSelectedSize(prodComplete),
            'name': product && product.name ? product.name : prodComplete.name,
            'price': price,
            'originalPrice': originalPrice,
            'discount': originalPrice - price,
            'category': categoryName,
            'quantity': Math.abs(product.newQty - product.oldQty)
          }]
        }
      }
    })
  }

  removeFromCart ({ product, prodComplete }) {
    let price = product ? product.price : prodComplete.price_incl_tax
    let originalPrice = parseFloat(this.formatPrice(prodComplete.extension_attributes.product_base_price))
    let categoryName = this.retrieveCategoryName(product)
    this.gtm.trackEvent({
      event: 'removeFromCart',
      eventCategory: 'Ecommerce',
      eventAction: 'Remove from Cart',
      ecommerce: {
        currencyCode: this.store.state.cart.total.base_currency_code,
        remove: {
          products: [{
            'id': this.getProductID(prodComplete),
            'name': product.name,
            'brand': 'Philosophy',
            'price': price,
            'sku': product.sku,
            'color': this.getSelectedColor(prodComplete),
            'size': this.getSelectedSizedetail(prodComplete),
            'originalPrice': originalPrice,
            'discount': originalPrice - price,
            'category': categoryName,
            'quantity': Math.abs(product.newQty - product.oldQty)
          }]
        }
      }
    })
  }

  viewCart () {
    let s = this.store
    this.store.dispatch('cart/totals').then(() => {
      let _this = this
      setTimeout(() => {
        let products = _this.store.state.cart.items
        this.genericEvent('viewCart', 1, 'viewCart', 'viewCart', products, s)
      }, 500)
    })
  }

  clickProduct ({ product }) {
    const priceInfo = product ? getCurrentPrice(product) : null
    let categoryName = ''
    try {
      categoryName = this.store.state.category.current.name
    } catch (error) {
      categoryName = ''
    }
    // let category = product.categories[product.categories.length - 1]
    // let breadcrumb = category.breadcrumbs
    this.gtm.trackEvent({
      event: 'productClick',
      ecommerce: {
        currencyCode: this.store.state.storeConfig.config.base_currency_code,
        click: {
          products: [
            {
              id: product.id,
              name: product.name,
              sku: product.sku,
              variant: '',
              size: '',
              brand: 'Philosophy',
              price: parseFloat(this.formatPrice(priceInfo ? parseFloat(priceInfo.current) : 0)),
              originalPrice: parseFloat(this.formatPrice(priceInfo ? parseFloat(priceInfo.originalPrice) : 0)),
              discount: priceInfo.discount.amount_off || 0,
              // category: breadcrumb ? breadcrumb[1].category_name : '', // main Category
              // category2: breadcrumb ? breadcrumb[0].category_name : '', // Category
              category: categoryName
            }
          ]
        }
      }
    })
  }

  deleteProduct ({ product }) {
    let price = product ? product.price_incl_tax : product.price
    let discount = product.discount_amount
    let originalPrice = parseFloat(this.formatPrice(product.extension_attributes.product_base_price))
    let categoryName = ''
    let parsedCategories = JSON.parse(localStorage.getItem('productConfigurableCategoryName'))
    if (parsedCategories) {
      categoryName = parsedCategories.find((category) => category.item_id === product.item_id)
      try {
        categoryName = categoryName.category.name
      } catch (error) {
        categoryName = ''
      }
    }
    this.gtm.trackEvent({
      event: 'removeFromCart',
      eventCategory: 'Ecommerce',
      eventAction: 'Remove from Cart',
      ecommerce: {
        currencyCode: this.store.state.storeConfig.config.base_currency_code,
        remove: {
          products: [{
            'id': this.getProductID(product),
            'name': product.name,
            'brand': 'Philosophy',
            'price': price,
            'sku': this.getSelectedSkuComplete(product),
            'variant': this.getSelectedColor(product),
            'size': this.getSelectedSize(product),
            'originalPrice': originalPrice,
            'discount': discount,
            'category': categoryName,
            'quantity': product.qty
          }]
        }
      }
    })
  }

  setOrderVal () {
    const order = this.store.state.orders.currentOrder
    const taxRate = this.store.getters['orders/getProduct'][0].tax_rate
    let revenue = parseFloat(order.base_grand_total)
    if (order) {
      this.gtm.trackEvent({
        ecommerce: {
          currencyCode: order.base_currency_code,
          purchase: {
            actionField: {
              id: parseInt(order.increment_id),
              revenue: revenue,
              tax: revenue - (revenue / (1 + taxRate)),
              shipping: parseFloat(order.shipping_amount - order.shipping_discount_amount),
              coupon: order.coupon_code ? order.coupon_code : ''
            },
            products: this.getOrderProducts()
          }
        }
      })
    }
  }

  modifyCheckout ({ step }) {
    window.dataLayer.push({
      event: 'modifyCheckout',
      stepNumber: step
    })
  }

  cart () {
    if (!this.iCanTrack()) {
      return false
    }
    let s = this.store
    this.store.dispatch('cart/totals').then(() => {
      let _this = this
      setTimeout(() => {
        let products = _this.store.state.cart.items
        this.genericEvent('cart', 1, 'cart', 'cart', products, s)
      }, 500)
    })
  }

  checkout () {
    if (!this.iCanTrack()) {
      return false
    }
    let s = this.store
    this.store.dispatch('cart/totals').then(() => {
      let _this = this
      setTimeout(() => {
        let products = _this.store.state.cart.items
        this.genericEvent('checkout', 2, 'checkout', 'checkout', products, s)
      }, 500)
    })
  }

  sendShippingInfo () {
    let s = this.store
    this.store.dispatch('cart/totals').then(() => {
      let _this = this
      setTimeout(() => {
        let products = _this.store.state.cart.items
        this.genericEvent('sendShippingInfo', 3, 'checkout', 'shipping_info', products, s)
      }, 500)
    })
  }

  sendShippingMethodInfo () {
    let s = this.store
    this.store.dispatch('cart/totals').then(() => {
      let _this = this
      setTimeout(() => {
        let products = _this.store.state.cart.items
        this.genericEvent('sendShippingMethodInfo', 4, 'checkout', 'shipping_info_2', products, s)
      }, 500)
    })
  }

  thankYouPage () {
    if (!this.iCanTrack()) {
      return false
    }
    this.store.dispatch('orders/getOrderById', { orderId: localStorage.getItem('orderId') }).then(res => {
      let taxRate = res.items[0].tax_percent / 100
      let order = res
      let prodObj = this.getIds(this.store.state.cart.tempItems)
      let revenue = parseFloat(order.base_grand_total)
      let shipping = parseFloat(order.shipping_amount - order.shipping_discount_amount)
      let tax = revenue - (revenue / (1 + taxRate))
      let netValue = ((revenue - shipping) / (1 + taxRate)).toFixed(2)
      this.gtm.trackEvent({
        event: 'purchase',
        eventCategory: 'Ecommerce',
        eventAction: 'Purchase',
        ecommerce: {
          currencyCode: order.base_currency_code,
          purchase: {
            actionField: {
              id: parseInt(order.increment_id),
              revenue: revenue,
              tax: tax,
              shipping: shipping,
              netValue: netValue,
              coupon: order.coupon_code ? order.coupon_code : ''
            },
            products: prodObj
          }
        }
      })
    }
    )
  }

  getProductID (product) {
    let productConfigurableIDS = localStorage.getItem('productConfigurableIDS')
    productConfigurableIDS = JSON.parse(productConfigurableIDS)
    let productId = productConfigurableIDS
    // let isThankYouPage = this.router.currentRoute.name === 'thankyou'
    if (productId) {
      // if (isThankYouPage) { // purchase
      //   productId = productId.find(item => item.sku === product.sku).id
      // } else { // other events
      try {
        productId = productId.find(item => item.item_id === product.item_id).id
      } catch (e) {
        productId = parseInt(product.extension_attributes.product_id) || product.id
      }
      // }
      return productId
    }
    return product.id || product.item_id
  }

  retrieveCategoryName (product) {
    try {
      let categories = JSON.parse(localStorage.getItem('productConfigurableCategoryName'))
      let categoryName = 'Philosophy'
      let findCategory = categories.find((item) => item.sku === (product.sku || product.extension_attributes.sku))
      if (Object.keys(findCategory).length > 0 && Object.keys(findCategory.category).length > 0) {
        categoryName = findCategory.category.name
      }
      return categoryName
    } catch (error) {
      return 'Philosophy'
    }
  }

  getIds (products) {
    if (process.client) {
      return products.map(product => {
        let price = parseFloat(this.formatPrice(parseFloat(product.price_incl_tax)))
        let originalPrice = product.extension_attributes.product_base_price
        return {
          'name': product.name,
          'id': this.getProductID(product),
          'brand': 'Philosophy',
          'price': price,
          'sku': this.getSelectedSkuComplete(product),
          'variant': this.getSelectedColor(product),
          'size': this.getSelectedSize(product),
          'category': this.retrieveCategoryName(product),
          'quantity': product.qty,
          'originalPrice': originalPrice,
          'discount': originalPrice - price || 0
        }
      })
    }
  }

  getIdsForThankyouPage (products) {
    if (process.client) {
      let categories = JSON.parse(localStorage.getItem('productConfigurableCategoryName'))
      return products.map(product => {
        console.log(product)
        let price = parseFloat(this.formatPrice(parseFloat(product.price_incl_tax))) || product.price_incl_tax
        let originalPrice = product.base_original_price || 0
        return {
          'name': product.name,
          'id': this.getProductID(product),
          'brand': 'Philosophy',
          'price': price,
          'sku': product.sku,
          'color': this.getSelectedColor(product),
          'size': this.getSelectedSizedetail(product),
          'category': categories.find((item) => item.sku === (product.sku || product.extension_attributes.sku)).category.name,
          'quantity': product.qty_ordered,
          'originalPrice': originalPrice,
          'discount': originalPrice - price || 0
        }
      })
    }
  }

  checkoutStep (step, option, productsTemp) {
    this.store.dispatch('cart/totals').then(() => {
      let _this = this
      let s = this.store
      let products = productsTemp || _this.store.state.cart.items
      setTimeout(() => {
        if (step === 2) {
          this.genericEvent('checkout', 2, 'checkout', '', products, s)
          // this.checkoutEvent(2)
        }
        if (step === 3) {
          this.genericEvent('sendShippingInfo', 3, 'checkout', '', products, s, option)
        }
        if (step === 4) {
          this.genericEvent('sendShippingMethodInfo', 4, 'checkout', '', products, s, option)
        }
      }, 500)
    })
  }

  genericEvent (type, step, eventName, option, products, ctx, checkoutOption) {
    window.dataLayer.ecommerce = null
    // let isThankYouPage = this.router.currentRoute.name === 'thankyou'
    // let prodObj = isThankYouPage ? this.getIdsForThankyouPage(products) : this.getIds(products)
    let funnelType = this.getFunnelType()
    let prodObj = this.getIds(products)
    let payload = {}
    payload.event = eventName
    payload.ecommerce = {
      currencyCode: ctx.state.storeConfig.config.base_currency_code,
      checkout: {
        actionField: { 'step': step, 'option': option },
        products: prodObj
      }
    }
    if (type === 'sendShippingInfo') {
      const billingAddress = ctx.state.checkout.shippingAddress
      payload.ecommerce.checkout.shipping_info = { shipping_info: billingAddress }
      if (checkoutOption) {
        payload.ecommerce.checkout.actionField.option = checkoutOption
      }
    }
    if (type === 'sendShippingMethodInfo') {
      const shippingMethod = ctx.state.checkout.shippingMethod
      payload.ecommerce.checkout.shipping_info = { shipping_info: shippingMethod }
      if (checkoutOption) {
        payload.ecommerce.checkout.actionField.option = checkoutOption
      }
    }
    if (type === 'checkout' || type === 'sendShippingInfo' || type === 'sendShippingMethodInfo') {
      payload.funnelType = funnelType
      payload.eventCategory = 'Ecommerce'
      payload.eventAction = 'Checkout'
    }
    if (type === 'viewCart') {
      payload.eventCategory = 'Ecommerce'
      payload.eventAction = 'View Cart'
    }
    this.gtm.trackEvent(payload)
  }

  getOrderProducts () {
    const products = this.store.getters['orders/getProduct']
    return products.map(product => ({
      'id': product.product_id,
      'brand': 'Philosophy',
      'sku': product.extension_attributes.sku,
      'color': this.getSelectedColor(product),
      'size': this.getSelectedSizedetail(product),
      'price': parseFloat(this.formatPrice(product.base_price_incl_tax)),
      'name': product.name,
      'category': this.store.state.category.current.name || this.store.state.product.current.last_breadcrumb.name || product.extension_attributes.firt_category,
      'quantity': product.qty_ordered,
      'originalPrice': parseFloat(this.formatPrice(parseFloat(product.extension_attributes.product_base_price))),
      'discount': product.discount_amount || 0
    }))
  }

  listingProductColor (product) {
    let color = product.configurableParent.configurable_options.find(option => option.attribute_id === '279')
    return color.values[0].label
  }

  getSizeForWishlist (product, filter) {
    let sizeDetail = filter[771]
    let variants = product.configurable_options.find(attr => attr.attribute_code === 'size')
    let size = variants.values.find(v => v.value_index === sizeDetail)
    return size.label
  }

  getSelectedColorDetail (product) {
    let colorDetail = product && product.presetFilter && product.presetFilter[279]
    return colorDetail && product && product.configurableParent && product.configurableParent.groupedVariation && product.configurableParent.groupedVariation.find(grouped => grouped.color === colorDetail)
      ? product.configurableParent.groupedVariation.find(grouped => grouped.color === colorDetail).color_label
      : ''
  }

  getSelectedSizeDetail (product) {
    if (!product || !product.configurable_options || !product.presetFilter || !product.presetFilter[771]) return ''
    let sizeDetail = product && product.presetFilter && product.presetFilter[771]
    let sizes = product.configurable_options.find(option => option.attribute_id === '771').values
    return sizes.find(size => size.value_index === sizeDetail) ? sizes.find(size => size.value_index === sizeDetail).label : ''
  }

  getSelectedColor (product) {
    let colorDetail = product && product.extension_attributes && product.extension_attributes.selected_options
    return colorDetail && colorDetail.find(option => option.attribute_code === 'color_detail').value
  }

  getSelectedSize (product) {
    let sizeDetail = product && product.extension_attributes && product.extension_attributes.selected_options
    return sizeDetail && sizeDetail.find(option => option.attribute_code === 'size').value
  }

  getSelectedSku (product) {
    if (product && product.configurableParent && product.configurableParent.groupedVariation && product.configurableParent.groupedVariation.length > 0) {
      if (product.configurableParent.groupedVariation[0].sku) {
        return product.configurableParent.groupedVariation[0].sku
      }
      return product.sku || ''
    }
    return product.sku || ''
  }

  getSelectedSkuComplete (product) {
    if (!product || !product.extension_attributes || !product.extension_attributes.sku) return ''
    return product.extension_attributes.sku
  }

  getFunnelType () {
    let userInfo = this.store.getters['checkout/userInfo']
    if (userInfo && userInfo.createUser) return 'registered'
    if (this.store.getters['user/isLogged']) return 'regular'
    return 'guest'
  }

  originalLocation () {
    let path = this.router.currentRoute.path
    let locale = path.split('/')[1]
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        originalLocation: `${document.location.protocol}//${document.location.hostname}/${locale}`
      })
    }
  }

  cookieConsent () {
    let consentInformation = ''
    if (window.Cookiebot) {
      consentInformation += '1:' + window.Cookiebot.consent.necessary.toString() + ','
      consentInformation += '2:' + window.Cookiebot.consent.preferences.toString() + ','
      consentInformation += '3:' + window.Cookiebot.consent.statistics.toString() + ','
      consentInformation += '4:' + window.Cookiebot.consent.marketing.toString()
      // consentInformation ex: '1:true,2:false,3:true,4:true'
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'consent_information': consentInformation
      })
    }
  }

  loginStatus () {
    try {
      const userStore = require('@/store/lib/storage').userStore
      let userID = localStorage.getItem('ZD-buid')
      if (!userStore) {
        this.pushLoginStatus(false)
        this.userID(' ')
        return
      }

      userStore.getItem('customer').then(customer => {
        if (customer !== null) {
          this.pushLoginStatus(true)
          this.userID(userID)
        } else {
          this.pushLoginStatus(false)
          this.userID(' ')
        }
      })
    } catch (e) {
      this.pushLoginStatus(false)
      this.userID(' ')
    }
  }

  pushLoginStatus (value) {
    let isLogged = value === true ? 'logged' : 'not logged'
    window.dataLayer = window.dataLayer || []
    if (window.dataLayer.filter((evt) => evt.loginStatus !== undefined)) {
      for (let i = 0; i < window.dataLayer.filter((evt) => evt.loginStatus).length; i++) {
        window.dataLayer.filter((evt) => evt.loginStatus !== undefined)[i].loginStatus = isLogged
      }
    }
    window.dataLayer.push({
      'loginStatus': isLogged
    })
  }

  userID (userID) {
    window.dataLayer = window.dataLayer || []
    if (window.dataLayer.filter((evt) => evt.userID !== undefined)) {
      for (let i = 0; i < window.dataLayer.filter((evt) => evt.userID).length; i++) {
        window.dataLayer.filter((evt) => evt.userID !== undefined)[i].userID = userID
      }
    }
    window.dataLayer.push({
      'userID': userID
    })
  }

  productAction ({ event, product }) {
    if (window.dataLayer) {
      window.dataLayer = window.dataLayer || []
      let eventDetail = ''
      switch (event) {
        case 'product-detail':
          eventDetail = 'tab_description'
          break
        case 'fit':
          eventDetail = 'tab_fit'
          break
        case 'shipping-return':
          eventDetail = 'tab_shipping_return'
          break
        default:
          eventDetail = event
          break
      }
      window.dataLayer.push({
        event: 'product_action',
        item_id: product.id,
        item_name: product.name,
        event_detail: eventDetail
      })
    }
  }

  countryAndLanguage ({ country }) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'country': country,
      'language': this.store.getters['ui/getCurrentLang'].replace('-', '/')
    })
  }
}

export default Tracker
