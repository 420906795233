export default `query Products(
      $currentPage: Int
      $filter: ProductAttributeFilterInput
      $pageSize: Int
    ) {
    products(currentPage: $currentPage, filter: $filter pageSize: $pageSize) {
      aggregations {
        attribute_code
        count
        label
        options {
          count
          label
          value
        }
      }
      aggregationsStore {
        attribute_code
        count
        label
        options {
          count
          label
          swatch
          type
          value
        }
      }
    }
  }`
