import Config from '@/config'
import Magento from '@/services/Magento'
import Graphql from '@/services/Graphql'
import { subscribeCustomer } from '@/services/graphQlquery/newsletterManager.js'

export const NewsletterStatuses = {
  subscribed: 1,
  pending: 2,
  unsubscribed: 3,
  not_active: 4
}

export const Newsletter = {
  subscribeCustomer (store, { email, nlType }) {
    // classic API call goes to infinite loop
    // move this call using Graphqls
    // const query = Config.API.newsletter.subscribeCustomer.replace('{{storeViewCode}}', storeViewCode)
    // const headers = {
    //   'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
    // }
    // return Magento.post(query, email, { headers })
    let token = ''
    if (store.rootGetters['user/isLogged']) {
      token = store.rootState.user.token
    }
    let storeViewCode = store.rootState.ui.storeViewCode
    let useInlineQuery = {
      query: subscribeCustomer
    }
    let variable = {
      email,
      nl_type: nlType
    }
    return Graphql.genericCall(
      {},
      token, storeViewCode,
      '',
      variable, false,
      useInlineQuery
    )
      .then(res => {
        if (res && res.data && res.data.subscribeEmailToNewsletterPlus) {
          return res.data.subscribeEmailToNewsletterPlus.status
        } else {
          throw new Error('newsletter.cannot-subscribe')
        }
      })
      .catch(err => {
        return err
      })
  },
  unsubscribeCustomer ({ email, subscriberId, confirmationCode, storeViewCode }) {
    let url = Config.API.newsletter.unsubscribeCustomer
      .replace('{{storeViewCode}}', storeViewCode)
      .replace('{{subscriber_id}}', subscriberId)
    return Magento.post(url,
      {
        confirmation_code: confirmationCode
      },
      {
        headers: {
          'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
        }
      })
      .then(res => {
        return res
      })
      .catch(err => {
        return err
      })
  },
  confirmSubscription ({ email, subscriberId, confirmationCode }) {
    let url = Config.API.newsletter.confirmSubscription
      .replace('{{subscriber_id}}', subscriberId)
    return Magento.post(url,
      {
        confirmation_code: confirmationCode
      },
      {
        headers: {
          'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
        }
      })
      .then(res => {
        if (res && res.data) {
          return res.data
        } else {
          throw new Error('generic-error')
        }
      })
      .catch(err => {
        return err
      })
  },
  subscribeList (email) {
    let url = Config.API.newsletter.subscriberList
    url += `?searchCriteria[filterGroups][0][filters][0][field]=subscriber_email&
    searchCriteria[filterGroups][0][filters][0][value]=${encodeURIComponent(email)}`
    let storeId = global.$store.getters['storeConfig/storeviewID']
    if (storeId) {
      url += `&searchCriteria[filterGroups][1][filters][0][field]=store_id&
      searchCriteria[filterGroups][1][filters][0][value]=${storeId}`
    }
    return Magento.get(url,
      {
        headers: {
          'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
        }
      })
      .then(res => {
        // 1 subscribed
        // 2 pending
        // 3 unsubscribed
        // 4 not active
        if (res.data && res.data.items.length) {
          return res.data.items[0]
        } else {
          return false
        }
      })
      .catch(err => {
        return err
      })
  }
}

export default { Newsletter, NewsletterStatuses }
