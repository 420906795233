import { EventBus } from '@/helpers'
import Config from 'theme/config'
import get from 'lodash/get'

const prefixEvent = 'algolia'
const trackListener = [
  `${prefixEvent}:init`,
  `${prefixEvent}:listing`,
  `${prefixEvent}:showProduct`,
  `${prefixEvent}:filter`,
  `${prefixEvent}:addToCart`,
  `${prefixEvent}:setProduct`,
  `${prefixEvent}:unsetProduct`
]

class SearchInsight {
  constructor ({ enabled, store }) {
    /* eslint-disable */
    this.enabled                     = enabled
    this.store                       = store
    this.applicationId               = Config.algolia.applicationId
    this.apiKey                      = Config.algolia.searchOnlyApiKey
    this.useCookie                   = true
    this.storeViewCode               = get(this.store, 'state.ui.storeViewCode', 'default'),
    this.indexNamePrefix             = Config.algolia.indexNamePrefix
    this.indexNames                  = Config.algolia.indexNames
    this.debug                       = false
    this.inputSelector               = '.ais-SearchBox-input'
    window.algoliaFilters            = []
    window.currentProductFromAlgolia = {}
    window.currentIndexProduct       = undefined
    this.activeListener()
    /* eslint-enable */
  }

  trackAlgoliaAnalytics (event, payload) {
    window.aa(event, payload)
  }

  initPayload () {
    return {
      appId: this.applicationId,
      apiKey: this.apiKey,
      useCookie: this.useCookie
    }
  }

  init () {
    if (!this.isEnabled()) return
    window.aa = require('search-insights')
    this.trackAlgoliaAnalytics('init', this.initPayload())
  }

  evtTofx (evtName) {
    return evtName.replace(`${prefixEvent}:`, '')
  }

  isEnabled () {
    return this.enabled
  }

  activeListener () {
    const _this = this
    trackListener.forEach(track => { EventBus.$on(track, _this[_this.evtTofx(track)].bind(_this)) })
  }

  sendEvent ({ items, type = 'view', eventName, index }) {
    /* eslint-disable */
    if (!this.isEnabled()) return
    let payload       = {}
    payload.index     = index
    payload.eventType = type
    payload.eventName = eventName
    payload.ObjectIDs = items.map((item) => { return item.objectID })
    if (items.length === 1) {
      payload.queryID = items[0]['__queryID']
    }
    if (this.debug) console.log(payload)
    window.aa('sendEvents', [payload])
    /* eslint-enable */
  }

  clickFilter ({ filters, eventName, index }) {
    if (!this.isEnabled()) return
    /* eslint-disable */
    let payload = this.filtersPayload({ index, eventName: eventName, filters: filters })
    if (this.debug) console.log(payload)
    this.trackAlgoliaAnalytics('clickedFilters', payload)
    if (window.algoliaFilters.length > 0) this.convertedFilters(index)
    /* eslint-enable */
  }

  convertedFilters (index) {
    if (!this.isEnabled()) return
    /* eslint-disable */
    let payload = this.filtersPayload({ index, eventName: 'applied_filters', filters: window.algoliaFilters })
    payload.eventType = 'view'
    if (this.debug) console.log(payload)
    this.trackAlgoliaAnalytics('convertedFilters', payload)
    /* eslint-enable */
  }

  filtersPayload ({ index, filters, eventName }) {
    return {
      index: index,
      eventName: eventName,
      filters: filters
    }
  }

  clickObject ({ items, eventName, index }) {
    if (!this.isEnabled()) return
    /* eslint-disable */
    let payload = this.objectPayload( {index: index, items: items.map((item) => { return item.objectID }), eventName: eventName} )
    if (items.length === 1) {
      payload.queryID = items[0]['__queryID']
      payload.positions = [items[0]['__position']]    
    }
    this.trackAlgoliaAnalytics('clickedObjectIDs', payload)
    /* eslint-enable */
  }

  addToCart () {
    /* eslint-disable */
    let currentProduct = window.currentProductFromAlgolia
    if (Object.keys(window.currentProductFromAlgolia).length > 0) {
      let payload = {}
      payload.eventName = 'add_to_cart'
      payload.queryID   = currentProduct['__queryID']
      payload.objectIDs = [currentProduct.objectID]
      payload.index     = window.currentIndexProduct || this.productsIndex()
      if (this.debug) console.log(payload)
      this.trackAlgoliaAnalytics('convertedObjectIDsAfterSearch', payload)
      setTimeout(function () {
        window.currentIndexProduct       = undefined
        window.currentProductFromAlgolia = {}
      }, 1500)
    }
    /* eslint-enable */
  }

  setProduct (payload) {
    window.currentProductFromAlgolia = payload.product
  }

  unsetProduct () {
    window.currentProductFromAlgolia = {}
  }

  viewedObjects ({ items, eventName, index }) {
    if (!this.isEnabled()) return
    /* eslint-disable */
    let payload = this.objectPayload( {index: index, items: items.map((item) => { return item.objectID }), eventName: eventName} )
    if (payload.objectIds.length === 0 ) return
    if (this.debug) console.log(payload)
    this.trackAlgoliaAnalytics('viewedObjectIDs', payload)
    /* eslint-enable */
  }

  objectPayload ({ items, eventName, index }) {
    return {
      index: index,
      eventName: eventName,
      objectIds: items
    }
  }

  productsIndex () {
    return this.indexNamePrefix + this.storeViewCode + this.indexNames['products']
  }

  currentIndex (payload) {
    return payload.currentIndex.length > 0 ? payload.currentIndex : this.productsIndex()
  }

  handleFilterQuery (payload, filterValue) {
    if (payload.item.isRefined) window.algoliaFilters = window.algoliaFilters.filter(e => e !== filterValue)
    else window.algoliaFilters.push(filterValue)
  }

  listing (payload) {
    if (!this.isEnabled()) return
    let inputValue = document.querySelector(this.inputSelector)
    if (!inputValue) return
    if (inputValue.value.length > 0) this.viewedObjects({ items: payload.items, eventName: 'show_products', index: this.currentIndex(payload) })
  }

  showProduct (payload) {
    if (!this.isEnabled()) return
    window.currentIndexProduct = this.currentIndex(payload)
    this.clickObject({ items: [payload.product], eventName: 'click_product', index: this.currentIndex(payload) })
  }

  filter (payload) {
    /* eslint-disable */
    if (!this.isEnabled()) return
    let filterValue = `${payload.type}:${payload.item.value}`
    this.handleFilterQuery(payload, filterValue)
    this.clickFilter({ eventName: payload.item.isRefined ? 'remove_filter' : 'add_filter', index: this.currentIndex(payload), filters: [filterValue] })
    /* eslint-enable */
  }
}

export default SearchInsight
