/*
 *     Copytop (c) 2020 Bcame S.r.l.. All tops reserved.
 *     Bcame S.r.l. Technology Business Unit
 *
 *     Company confidential.
 *
 *     This software is proprietary  to and embodies the confidential
 *     technology of Bcame S.r.l..  Possession, use, or copying of this
 *     software and media is authorized only pursuant to a valid written
 *     license from Bcame S.r.l.  or an authorized sublicensor.
 *
 *     Bcame S.r.l.
 */

<template>
   <div class="wrapp-select-language" v-if="currentCountry">
     <div class="wrapp-content" ref="wrappContent" :class="{'shipping-picker-opened' : isShippingOpened }">
       <div class="first-selectors">
        <div class="title-section">
          <h2 class="h2 title">{{ $t('chooseLanguage.titleSection') }}</h2>
        </div>
        <div class="shipping-selector">
          <p class="title">{{ $t('chooseLanguage.shippingTo') }}</p>
            <language-selector-with-box-search :name="'shipping'" :options="listCountriesFormatted" :selected-value="currentCountryFormatted"></language-selector-with-box-search>
        </div>
        <div class="language-selector">
          <p class="title">{{ $t('chooseLanguage.language') }}</p>
          <language-selector-with-box-search :name="'language'" :options="listLocalesFormatted" :selected-value="currentLocaleFormatted"></language-selector-with-box-search>
        </div>
      </div>
      <transition name="fadeIn">
        <div class="shipping-countries" v-show="shippingPicker">
          <div class="shipping-countries-close">
            <button class="btn btn-none" @click.prevent="emptyComponent()">
              <svg data-trigger-countries="close" class="o-icon o-icon--arrow is-visible">
                <use xlink:href="#arrow-up"></use>
              </svg>
            </button>
          </div>
          <component :is="shippingPicker" />
        </div>
      </transition>
     </div>
   </div>
</template>

<script>
import { isMobile } from '@/helpers/utils'
import { find, orderBy } from 'lodash'
import LanguageSelectorWithBoxSearch from '../Form/LanguageSelectorWithBoxSearch'
import Config from 'config'

export default {
  name: 'ChooseLanguage',
  data () {
    return {
      shippingPicker: null,
      isShippingOpened: false
    }
  },
  computed: {
    countryColumns () {
      let countries = this.$store.getters['ui/getCountries']
      let columns = []
      let configColumns = Config.Theme.columnsByCountry
      let countryAvailable = {}
      Object.keys(countries).forEach((item) => {
        countryAvailable[item] = true
      })
      for (let i in configColumns) {
        columns[i] = []
        for (let subCol in configColumns[i]) {
          if (countries[configColumns[i][subCol]]) {
            columns[i].push({
              area: configColumns[i][subCol],
              countries: countries[configColumns[i][subCol]]
            })
            countryAvailable[configColumns[i][subCol]] = false
          }
        }
      }
      columns[2] = []
      for (let available in countryAvailable) {
        if (countryAvailable[available]) {
          columns[2].push({
            area: available,
            countries: countries[available]
          })
        }
      }
      return columns
    },
    countryColumnsNotEmpty () {
      return this.countryColumns.filter((column, index) => (column.length > 0 && column[index]))
    },
    listCountriesFormatted () {
      // countries formatting for shipping selector
      const allAreaCountries = this.countryColumnsNotEmpty.reduce((a, b) => a.concat(b))
      const listCountries = allAreaCountries.map((areaCountries) => {
        return areaCountries.countries.map((country) => {
          country.area = areaCountries.area
          return country
        })
      }).reduce((a, b) => a.concat(b)).filter(country => country.store_view.startsWith('ph_'))
      const listCountriesFormatted = listCountries.map((country) => ({
        originalObj: country,
        label: country.country_name,
        url: country.base_url
      }))
      return orderBy(listCountriesFormatted, 'label')
    },
    listLocalesFormatted () {
      // locales formatting for language selector
      const listLocalesFormatted = (this.currentCountry && this.currentCountry.locales) ? this.currentCountry.locales.map((locale) => ({
        originalObj: locale,
        label: this.$t(`localizeLang.${locale.language}`),
        url: locale.base_url
      })) : []
      return orderBy(listLocalesFormatted, 'label')
    },
    currentCountry () {
      return this.$store.state.ui.currentCountry
    },
    currentStoreView () {
      return this.$store.state.ui.storeViewCode
    },
    currentLanguage () {
      let currentLang = null
      if (this.currentCountry && this.currentCountry.locales) {
        currentLang = find(this.currentCountry.locales, { store_view: this.currentStoreView })
      }
      return currentLang
    },
    currentCountryFormatted () {
      // current country formatting for default value of shipping selector
      return this.currentCountry ? this.listCountriesFormatted.find((country) =>
        (country.originalObj.country === this.currentCountry.country && country.originalObj.country_name === this.currentCountry.country_name)
      ) : null
    },
    currentLocaleFormatted () {
      // current locale formatting for default value of language selector
      return this.currentLanguage ? this.listLocalesFormatted.find((locale) =>
        (locale.originalObj.language === this.currentLanguage.language && locale.originalObj.store_view === this.currentLanguage.store_view)
      ) : null
    }
  },
  methods: {
    openChCountry () {
      const componentToLoad = import('theme/components/Language/CountriesList')
      if (isMobile()) {
        // Open new component
        this.$store.dispatch('ui/loadLeftActiveComponent', { component: componentToLoad, props: { headerTitle: 'Shipping' } })
      } else {
        componentToLoad.then((res) => {
          this.shippingPicker = res.default
          this.isShippingOpened = true
        })
      }
    },
    emptyComponent () {
      this.isShippingOpened = false
    },
    redirectLocale (baseUrl) {
      location.href = baseUrl
    }
  },
  components: {
    LanguageSelectorWithBoxSearch
  }
}
</script>
