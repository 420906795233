<template>
  <a :href="categoryLinkGenerator" @click.prevent="followLink($event, categoryLinkGenerator)" @mouseenter="onMouseOver($event, categoryLinkGenerator)" @mouseleave="onMouseLeave()" class="text-decoration-none">
    <slot name="content">
      <span class="link-label">{{ showLabel }}</span>
    </slot>
  </a>
</template>

<script>
import Vue from 'vue'
import find from 'lodash/find'
import { EventBus } from '@/helpers'

const layoutMap = {
  'default': './Default',
  'world-of': './WorldOf'
}

export default {
  name: 'LinkManager',
  props: {
    category: {
      type: Object,
      required: true
    },
    forceClickable: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      currentTimeout: ''
    }
  },
  computed: {
    showLabel () {
      if (this.label) {
        return this.label
      } else {
        return this.category.name
      }
    },
    categoryIsClickable () {
      if (this.forceClickable) {
        return true
      } else {
        let attr = find(this.category.custom_attributes, { attribute_code: 'is_clickable' })
        if (attr && Boolean(parseInt(attr.value))) return true
        return false
      }
    },
    categoryLinkGenerator () {
      if (this.categoryIsClickable) {
        let attr = find(this.category.custom_attributes, { attribute_code: 'ext_category' })
        if (attr && attr.value) {
          return attr.value
        } else {
          return Vue.filter('localizedByNameCategories')('listing', this.category.url_path, null, this.$store, this.$router)
        }
      } else {
        return 'javascript:void(0)'
      }
    },
    categoryLayout () {
      let componentToFind = find(this.category.custom_attributes, { attribute_code: 'custom_frontend_layout' })
      return componentToFind ? componentToFind.value : 'default'
    }
  },
  methods: {
    loadComponentInSubMenu () {
      const _this = this
      let componentToLoad = import(`${layoutMap[this.categoryLayout]}`)
      this.$store.dispatch('ui/loadTopActiveComponent', { component: componentToLoad, forceIdentifier: _this.category.id, props: { category: _this.category, triggerMouseLeave: true } })
      EventBus.$emit('Nav::closeSearchBox')
      return this.$emit('onCategoryClicked', _this.category)
    },
    followLink (event, url) {
      if (url === 'javascript:void(0)') {
        event.preventDefault()
        return this.loadComponentInSubMenu()
      } else {
        if (typeof url === 'string' && url.startsWith('http')) {
          // is external
          location.href = url
        } else {
          // somethimes if route is loaded
          // the nav remain open
          // using this method to add a loader for a while
          // in this way a layer override over status
          const _this = this
          this.$store.commit('ui/showPlainLoader')
          setTimeout(() => {
            _this.$router.push({ path: url })
            _this.$store.commit('ui/hidePlainLoader')
          }, 200)
        }
      }
    },
    onMouseOver (event, url) {
      let delay = 300
      let _this = this
      if (url === 'javascript:void(0)') {
        event.preventDefault()
        clearTimeout(this.currentTimeout)
        clearTimeout(window.setNavTime)
        window.setNavTime = setTimeout(() => {
          return _this.loadComponentInSubMenu()
        }, delay)
      } else {
        return false
      }
    },
    onMouseLeave () {
      clearTimeout(window.setNavTime)
    }
  },
  watch: {
    $route (to, from) {
      EventBus.$emit('TopNavigation::close')
    }
  }
}
</script>
