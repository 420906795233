<template>
  <div class="media-zoomer-desktop zoomer-container" ref="zoomerContainer" @mousemove="overPosition" @touchmove="overPosition($event, useTouch = true)">
    <div class="current-image" @click.prevent="$emit('close-zoomer', true)">
      <img
      :src="activeImage"
      :style="{ transform: `translate(${userPosition})`}"
      class="current-image__src" />
    </div>
    <div class="zoomer-close">
      <button type="button" class="btn btn-icon btn-close" @click.prevent="$emit('close-zoomer', true)">
        <i class="icomoon-icon icon-x cl-black"></i>
      </button>
    </div>
    <div class="action-container">
      <no-ssr>
        <overlay-scrollbars
        class="thumb-list"
        ref="thumbScrollbar"
        :options="{ paddingAbsolute: false, scrollbars: { visibility: 'h' } } ">
        <ul class="nav flex-md-column">
          <li v-for="(image, index) in images" :key="index" :class="{'active-index': (imageIndex === index) }">
            <img :src="getThumbnail(image)" class="thumb-image" @click.prevent="inspectPhoto(index)" />
          </li>
        </ul>
        </overlay-scrollbars>
      </no-ssr>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { optimizeImage } from '../../../helpers'

export default {
  name: 'ZoomerDesktop',
  props: {
    images: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    activeindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      imageIndex: this.activeindex,
      activeImage: '',
      userPosition: null
    }
  },
  computed: {
    imgObj () {
      return {
        src: this.activeImage,
        error: require('@/assets/image/no-image.png'),
        loading: (this.size === 'LG' ? require('@/assets/image/dump.png') : require('@/assets/image/dump-square.png'))
      }
    }
  },
  methods: {
    inspectPhoto (index) {
      this.imageIndex = index
      this.activeImage = optimizeImage(this.images[index].url)
      return this.activeImage
    },
    getThumbnail (image) {
      if (image.urlResize) return optimizeImage(image.urlResize)
      if (image.url) return optimizeImage(image.url)
      return ''
    },
    overPosition: _.throttle(function (e, useTouch = false) {
      let windowData = {
        width: $(window).width(),
        height: $(window).height(),
        halfWidth: $(window).width() / 2,
        halfHeight: $(window).height() / 2,
        imageElWidth: $('.current-image__src').width(),
        imageElHeight: $('.current-image__src').height()
      }
      let el = $('.current-image')[0]
      const y = this.getOffset(el)
      // get coordinates Y - X from mobile or desktop
      let pageY = (useTouch) ? e.changedTouches[0].pageY : e.pageY
      let pageX = (useTouch) ? e.changedTouches[0].pageX : e.pageX
      let centerPosImageH = (windowData.imageElHeight - windowData.height) / 2
      let centerPosImageW = (windowData.imageElWidth - windowData.width) / 2
      let moveImageY = (-centerPosImageH * (pageY - y.top)) / windowData.halfHeight
      let moveImageX = 0
      if (windowData.imageElWidth > windowData.width) {
        moveImageX = (-centerPosImageW * (pageX - y.top)) / (windowData.width / 1.5)
      }
      this.userPosition = `${parseInt(moveImageX)}px, ${parseInt(moveImageY)}px`
      return this.userPosition
    }, 200),
    getOffset (el) {
      let rect = el.getBoundingClientRect()
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
  },
  mounted () {
    document.querySelector('html').classList.add('no-overflow')
    this.inspectPhoto(this.activeindex)
  },
  destroyed () {
    document.querySelector('html').classList.remove('no-overflow')
  }
}
</script>
