import Logger from '@/services/Logger'
export default store => {
  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'cart/updCartItems':
        if (process.client) {
          const cartStore = require('@/store/lib/storage').cartStore
          cartStore.setItem('items', state.cart.items)
        }
        break
      case 'cart/updCartTempItems':
        if (process.client) {
          const cartStore = require('@/store/lib/storage').cartStore
          cartStore.setItem('tempItems', state.cart.tempItems)
        }
        break
      case 'cart/updPullItems':
        if (process.client) {
          const cartStore = require('@/store/lib/storage').cartStore
          cartStore.setItem('pullItems', state.cart.pullItems)
        }
        break
      case 'cart/updCart':
        if (process.client) {
          const cartStore = require('@/store/lib/storage').cartStore
          cartStore.setItem('total', state.cart.total)
        }
        break
      case 'cart/updCartId':
        Logger.debug('************** cart/updCartId', state.cart.cartId || '')
        if (process.client) {
          global.$cookies.set('cartId', state.cart.cartId || '')
          global.$cookies.set('sync-cartId', state.ui.codeLang + '-' + (state.cart.cartId || ''))
          global.$cookies.set('cartIdMask', state.cart.cartIdMask || '')
        }
        break
      case 'cart/updCartIdMask':
        Logger.debug('************** cart/updCartIdMask', state.cart.cartIdMask || '')
        if (process.client) {
          global.$cookies.set('cartIdMask', state.cart.cartIdMask || '')
        }
        break
    }
  })
}
