export default `query ProductsByCategory(
    $category_ids: String
    $id: Int
    $currentPage: Int
    $pageSize: Int
  ) {
    category(id: $id) {
      name
      display_mode
      cms_block {
        title
        identifier
      }
      bc_cat_type_name
      cms_block_lower
      bcame_cms_block_lower {
        title
        identifier
      }
      breadcrumbs {
        category_id
        category_url_path
        category_url_key
        category_level
        category_id
        category_name
      }
      canonical_url
      custom_canonical_link
      href_lang_links {
        href
        hreflang
      }
      meta_description
      meta_title
      meta_keywords
      meta_robots
      head_content_page
    }
    ProductsByCategory(
      category_ids: $category_ids
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      total_count
      items {
        __typename
        id
        sku
        master_sku
        name
        color
        stock_status
        url_suffix
        url_key
        categories {
          breadcrumbs {
            category_id
            category_name
            category_url_path
          }
        }
        new_from_date
        new_to_date
        listing_label
        carryover
        attributesLabel(
          attributes_identifier: [
            "description"
            "washing1"
            "washing2"
            "washing3"
            "washing4"
            "washing5"
            "washing6"
            "fitting_filter"
            "fitting"
            "technical_composition"
          ]
        ) {
          label
          value
          attribute_id
          attribute_code
        }
        ... on GroupedProduct {
          configurableParent {
            ... on ConfigurableProduct {
              configurable_options {
                id
                label
                attribute_code
                attribute_id
                position
                product_id
                values {
                  value_index
                  label
                  default_label
                  store_label
                  use_default_value
                }
              }
              groupedVariation {
                color
                name
                url_key
                url_suffix
                sku
                image {
                  label
                  url
                }
              }
            }
          }
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
        special_price
        special_from_date
        special_to_date
        image {
          label
          url
        }
        media_gallery_entries {
          disabled
          file
          id
          position
          types
        }
        media_gallery {
          position
          disabled
          label
          url
          ... on ProductVideo {
            position
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        ... on AwGiftCardProduct {
          aw_gc_allow_delivery_date
          aw_gc_allow_open_amount
          aw_gc_amounts
          aw_gc_custom_message_fields
          aw_gc_days_order_delivery
          aw_gc_description
          aw_gc_expire
          aw_gc_open_amount_max
          aw_gc_open_amount_min
          aw_gc_pool
          aw_gc_email_templates {
            image_url
            name
            value
          }
          aw_gc_type
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            label
            attribute_code
            attribute_id
            values {
              value_index
              label
              default_label
              store_label
            }
          }
          variants {
            product {
              id
              sku
              stock_status
              name
              quantity
              price_tiers {
                final_price {
                  value
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              special_price
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
                maximum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              media_gallery {
                label
                url
                position
              }
              image {
                url
                label
              }
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
      }
      aggregations {
        attribute_code
        count
        label
        options {
          count
          label
          value
        }
      }
      aggregationsStore {
        attribute_code
        count
        label
        options {
          count
          label
          swatch
          type
          value
        }
      }
    }
  }`
