import $ from 'jquery'
import { EventBus } from '@/helpers'
import { isMobile } from '@/helpers/utils'
var attrSlickLoaded = 'data-bcm-video-initialized'

let jarallax
let jarallaxVideo

if (process.client) {
  let jj = require('jarallax')
  jarallax = jj.jarallax
  jarallaxVideo = jj.jarallaxVideo

  jarallaxVideo()
}

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      if (jarallax) {
        $(function () {
          let $elements = $('div[data-video-src]')
          let options = {}
          $elements.each(function () {
            let $element = $(this)
            let attr = $element.attr(attrSlickLoaded)
            let parallaxSpeed = $element.data('enableParallax') !== 1
              ? 1
              : parseFloat($element.data('parallaxSpeed'))
            if ($element.data('background-type') === 'video') {
              if (attr === undefined || attr === false) {
                /* SET MARKER IF SLIDER IS INITIALIZED */
                $element.attr(attrSlickLoaded, true)
                options = {
                  imgSrc: isMobile() && $element.data('videoFallbackSrcMobile')
                    ? $element.data('videoFallbackSrcMobile')
                    : $element.data('videoFallbackSrc'),
                  imgElement: appendPictureToElement($element).get(0),
                  videoSrc: isMobile() && $element.data('video-src-mobile')
                    ? $element.data('video-src-mobile')
                    : $element.data('video-src'),
                  speed: !isNaN(parallaxSpeed)
                    ? parallaxSpeed
                    : 0.5,
                  videoLoop: $element.data('videoLoop'),
                  videoPlayOnlyVisible: $element.data('videoPlayOnlyVisible'),
                  videoLazyLoading: $element.data('videoLazyLoad'),
                  disableVideo: false,
                  elementInViewport: $element.data('elementInViewport') && $element[0].querySelector($element.data('elementInViewport'))
                }
                checkElementType(options, $element)
              }
            }
          })
        })
      }
    })
  }
}

function checkElementType (options, $element) {
  const $contentType = $element.parent('[data-content-type]')
  if ($contentType && $contentType.data('content-type') === 'slide') {
    if (!$element.data('videoAutoplay')) {
      createVideoButton($element)
    } else {
      initJarallax(options, $element)
    }
  } else {
    initJarallax(options, $element)
  }
}

function initJarallax (options, $element) {
  $element.addClass('jarallax')
  $element.attr('data-jarallax', '')

  jarallax($element[0], options)
  /* on started video - https://github.com/nk-o/video-worker#events */
  $element[0].jarallax.video && $element[0].jarallax.video.on('started', function () {
    if ($element[0].jarallax.$video) {
      if (!$element.data('videoMute')) {
        $element[0].jarallax.video.unmute()
        $element[0].jarallax.video.setVolume(100)
      } else {
        $element[0].jarallax.video.mute()
      }

      // hide pitcure when video started
      $element[0].jarallax.image.$default_item.style.visibility = 'hidden'
      $element[0].jarallax.image.$default_item.style.opacity = 0

      // hide pitcure when video started
      $element[0].jarallax.$video.style.visibility = 'visible'
    }
  })
  /* on ended - https://github.com/nk-o/video-worker#events */
  $element[0].jarallax.video && $element[0].jarallax.video.on('ended', function () {
    // show pitcure when video ended and loop is disabled
    if (!$element[0].jarallax.video.options.loop) {
      $element[0].jarallax.image.$default_item.style.visibility = 'visible'
      $element[0].jarallax.image.$default_item.style.opacity = 1
    }
  })
}

function appendPictureToElement ($element) {
  /* append picture to $element for get native dimensions of fallback image */

  let videoFallbackSrc = $element.data('videoFallbackSrc')
  let videoFallbackSrcMobile = $element.data('videoFallbackSrcMobile')

  if (!videoFallbackSrc && !videoFallbackSrcMobile) {
    console.log('no-fallback')
  }

  let $picture = $(`
  <picture>
    <source media="(max-width: 1024px)" srcset="${videoFallbackSrcMobile}">
    <img class="img-fluid" src="${videoFallbackSrc}" alt="fallback image">
  </picture>`)
  $picture.appendTo($element)
  return $picture
}

function createVideoButton ($element) {
  let $pagebuilderPosterContent = $element.find('.pagebuilder-poster-content')
  if ($pagebuilderPosterContent) {
    $('<div data-content-type="bcame_pagebuildervideobutton"><button class="bcm-video-button bcm-video-button-primary"></button></div>').appendTo($pagebuilderPosterContent)
    let $videoButton = $pagebuilderPosterContent.find('.bcm-video-button')
    $videoButton.attr({
      'src': $element.data('videoSrc'),
      'data-mobile-src': $element.data('videoSrcMobile'),
      'data-video-play-in-modal': true,
      'data-video-play-in-modal-mobile': true,
      'data-video-has-overlay': true,
      'data-video-autoplay': true,
      'data-video-muted': $element.data('videoMute'),
      'data-video-loop': $element.data('videoLoop'),
      'data-video-show-controls': $element.data('videoControls'),
      'data-skip-reg-exp-test': true
    })
  }
}
