import { EventBus } from '@/helpers'
import { mobileDetector, getCurrentPrice } from '@/helpers/utils'
import { getProductUrl } from '@/helpers/routeHelpers'

const trackListener = [
  'track:TCMinit',
  'track:searchSuggestion',
  'track:userRegistration',
  'track:newsletter',
  'track:login',
  'track:wishlist',
  'track:addToCart',
  'track:clickProduct',
  'track:deleteProduct',
  'track:cart',
  'track:checkout',
  'track:thankYouPage'
]

// Math.round((#VALUE#/(1+(#ORDER_TAX#/100)) + Number.EPSILON) * 100) / 100
// TF WITH SF: Math.round((#VALUE_ATI_WITH_SF#/(1+(#ORDER_TAX#/100))) * 100) / 100
// TF WITHOUT SF: Math.round((#VALUE_ATI_WITHOUT_SF#/(1+(#ORDER_TAX#/100))) * 100) / 100
class Tracker {
  constructor ({ store, wrapper, app, router }) {
    this.store = store
    this.wrapper = wrapper
    this.app = app
    this.router = router
    this.firstCall = true
    // this.loadContainer()
    this.activeListener()
  }
  evtTofx (evtName) {
    return evtName.replace('track:', '')
  }

  activeListener () {
    const _this = this
    trackListener.forEach(track => {
      // if (_this.evtTofx(track) === 'thankYouPage') {
      //   EventBus.$once(track, _this[_this.evtTofx(track)].bind(_this))
      // } else {
      // }
      EventBus.$on(track, _this[_this.evtTofx(track)].bind(_this))
    })
    if (this.iCanTrack() && this.firstCall === true) {
      EventBus.$emit('track:TCMinit')
      this.reloadAllContainer()
      this.TCMinit()
      this.firstCall = false
    }
    // listener route change
    this.router.afterEach((to, from) => {
      // first time wait user load
      // console.log('trackEvent:afterEach')
      if (from.name !== 'register' && to.name !== 'myProfile') {
        this.TCMinit()
      }
      this.triggerTpl()
      // this.setCategoryVal()
    })
    // console.log('trackEvent:activeListener')
  }

  reloadAllContainer (options) {
    this.wrapper.reloadAllContainers(options)
  }

  removeListener () {
    trackListener.forEach(track => {
      EventBus.$off(track)
    })
  }

  iCanTrack () {
    return global.Cookiebot && global.Cookiebot.consent ? global.Cookiebot.consent.marketing : false
    // return true
  }

  /**
   * ------TRACK INIT
   */
  TCMinit () {
    if (this.iCanTrack()) {
      // console.log('trackEvent:initTC')
      window.trackEnable = true
      const tplName = this.getTemplateName()
      // this.reloadAllContainer()
      // if (process.client) {
      //   window.tC.container.reload()
      // }
      this.loadContainer()
      let path = this.router.currentRoute.path
      let locale = path.split('/')[1]
      let country = locale.split('_')[0]
      let language = locale.split('_')[1]
      this.wrapper.setTcVars({
        env_template: tplName,
        env_work: process.env.VUE_APP_PRODUCTION === 'true' ? 'prod' : 'test',
        env_channel: this.getChannel(),
        env_language: this.store.getters['storeConfig/language'] ? this.store.getters['storeConfig/language'] : language,
        env_country: this.store.getters['ui/getCountry'] ? this.store.getters['ui/getCountry'] : this.store.state.ui.currentCountry ? this.store.state.ui.currentCountry.country : country,
        env_currency: this.getCurrency()
      })
      // this.setUserVar(tplName)
      if (tplName === 'category' || tplName === 'category_main' || tplName === 'category_editorials') { // listing page
        this.setCategoryVal()
      }
      if (tplName === 'product') {
        this.setProductVal()
      }
      if (tplName === 'funnel_confirmation') { // thank_you_page
        this.wrapper.setTcVars({
          order_id: ''
        })
        this.setOrderVal('funnel_confirmation')
      }
      if (tplName === 'funnel_step2') {
        this.checkout()
      }
      if (tplName === 'funnel_basket') {
        this.setBasketVal('funnel_basket')
      }
      // if (tplName === 'registration') {
      //   this.userRegistration()
      // }
    }
    this.firstCall = false
  }

  /**
   * check if is Mobile, tablet or Desktop
   */
  getChannel () {
    // m, t, d
    const _mobileDetector = mobileDetector()
    if (_mobileDetector) {
      if (_mobileDetector.mobile()) {
        return 'm'
      }
      if (_mobileDetector.tablet()) {
        return 't'
      }
      return 'd'
    }
    return 'not found'
  }

  loadContainer () {
    // Creation of TC Containers
    // if (this.iCanTrack()) {
    this.wrapper.addContainer('container_head', `https://cdn.tagcommander.com/4382/tc_main.js`, 'head')
    this.wrapper.addContainer('container_body', `https://cdn.tagcommander.com/4382/tc_head.js`, 'body')
    // this below for testing in stage env
    // this.wrapper.addContainer('container_head', `https://cdn.tagcommander.com/5935/tc_main.js`, 'head')
    // this.wrapper.addContainer('container_body', `https://cdn.tagcommander.com/5935/tc_head.js`, 'body')
    // }
  }

  getTemplateName () {
    let metaTpl = this.app.$route.meta.tplName
    this.router.currentRoute.name === 'home' ? metaTpl = 'homepage' : this.router.currentRoute.name === 'onepagecheckout' ? metaTpl = 'funnel_step2' : metaTpl = 'factoryPage'
    let path = this.router.currentRoute.path
    let locale = path.split('/')[1]
    switch (path) {
      case `/${locale}/`:
        metaTpl = 'homepage'
        break
      case `/${locale}/checkout/thankyou`:
        metaTpl = 'funnel_confirmation'
        break
      case `/${locale}/checkout/cart`:
        metaTpl = 'funnel_basket'
        break
      default:
        break
    }
    const currentComponent = this.store.state.cms.currentComponent
    if (metaTpl === 'factoryPage') {
      if (currentComponent === 'ProductPage') {
        metaTpl = 'product'
      } else if (currentComponent === 'CmsPage') {
        metaTpl = 'newsletter_form'
      } else if (currentComponent === 'CategoryPage') {
        if (this.store.state.ui.breadcrumb.length > 2) {
          metaTpl = 'category'
        } else {
          metaTpl = 'category_main'
        }
      }
    }
    metaTpl = metaTpl || 'other_generic'
    return metaTpl
  }
  getCurrency () {
    return this.store.state.storeConfig.config.base_currency_code
  }

  triggerTpl () {
    // lunch trigger update tpl
    if (global.tC && global.tC.event && global.tC.event.container_reload) {
      this.wrapper.captureEvent('container_reload', $(document)[0], {
        'id': 'container_reload'
      })
    } else {
      setTimeout(this.triggerTpl.bind(this), 1000)
    }
  }

  userRegistration () {
    this.setUserVar('myaccount_confirmation')
    this.wrapper.captureEvent('userRegistration')
  }

  login () {
    this.setUserVar('myaccount_login')
    this.wrapper.captureEvent('login')
  }

  searchSuggestion (value) {
    this.wrapper.setTcVars({
      env_template: 'internal_search',
      search_keyword: value.inputText
    })
    this.triggerTpl()
  }

  setUserVar (tmplName = '') {
    tmplName = tmplName || 'factoryPage'
    const userInfo = this.store.state.user.current ? this.store.state.user.current : this.store.state.user.guest
    const newUser = this.store.state.user.newUser
    this.wrapper.setTcVars({
      'user_id': userInfo ? userInfo.id : '', // to do -- call return null
      'env_template': tmplName,
      'user_gender': '',
      'user_age': '',
      'user_recency': '', // last order date
      'user_frequency': 0, // last order number
      'user_amount': 0, // total orders
      'user_email': userInfo ? userInfo.email : '',
      'user_email_optin': userInfo ? (userInfo.is_subscribed ? 1 : 0) : 0,
      'user_newcustomer': newUser ? 1 : 0,
      'env_country': this.store.state.ui.currentCountry ? this.store.state.ui.currentCountry.country : ''
    })
    // this.reloadAllContainer()
  }

  setCategoryVal () {
    const currentCat = this.store.state.cms.currentComponent === 'CategoryPage' ? this.store.state.category.current : null
    // const breadcrumb = currentCat ? this.store.state.ui.breadcrumb : null
    const catName = currentCat && currentCat.name ? currentCat.name : ''
    this.wrapper.setTcVars({
      'page_cat1': this.store.state.category.metaData.breadcrumbs.length >= 2 && this.store.state.category.metaData.breadcrumbs[1].category_name.trim() === 'Editorials' ? 'Editorials' : catName || '',
      // 'page_cat2': catName || '',
      'page_error': this.app.$route.meta.tplName && this.app.$route.meta.tplName === 'other_errors' ? '404' : '',
      'category_id': currentCat ? currentCat.id : '',
      'category_name': currentCat ? currentCat.name : '',
      'list_products': this.getListProducts(currentCat),
      'env_country': this.store.state.ui.currentCountry ? this.store.state.ui.currentCountry.country : '',
      'env_template': this.store.state.category.metaData.breadcrumbs.length >= 2 && this.store.state.category.metaData.breadcrumbs[1].category_name.trim() === 'Editorials' ? 'category_editorials' : 'category_main'
    })
  }

  async setBasketVal (env_template = '') {
    const currentCart = this.store.state.cart.cartId
    const totals = this.store.state.cart.total
    // const subTotal = parseFloat(totals.subtotal_incl_tax)
    const tot = parseFloat(totals.base_grand_total)
    const shippingAmount = parseFloat(totals.base_shipping_amount)
    const shippingDiscount = parseFloat(totals.base_shipping_discount_amount)
    // eslint-disable-next-line
    const shipping = shippingAmount - shippingDiscount
    // const discountAmount = parseFloat(Math.abs(totals.base_discount_amount))
    let orderAmountAtiWithoutSF = tot - shipping
    let taxRate = 22
    if (this.store.state.cart.total && this.store.state.cart.total.items && this.store.state.cart.total.items.length > 0) {
      taxRate = this.store.state.cart.total.items[0].tax_percent
    }
    if (currentCart && Object.keys(totals).length) {
      this.wrapper.setTcVars({
        'env_template': env_template,
        'basket_id': currentCart,
        // Order amount ATI (all tax included) with shipping fee
        'order_amount_ati_with_sf': tot,
        // Order amount ATI (all tax included) without shipping fee
        'order_amount_ati_without_sf': orderAmountAtiWithoutSF,
        // Order discount ATI (all tax included) - let empty if no discount
        'order_discount_ati': Math.abs(parseFloat(this.formatPrice(totals.base_discount_amount))),
        // Order amount TF (tax free) with shipping fee
        'order_amount_tf_with_sf': Math.round((tot / (1 + (taxRate / 100))) * 100) / 100,
        // Order amount TF (tax free) without shipping fee
        'order_amount_tf_without_sf': Math.round((orderAmountAtiWithoutSF / (1 + (taxRate / 100))) * 100) / 100,
        // Order discount TF (tax free) - let empty if no discount
        'order_discount_tf': parseFloat(this.formatPrice(this.getDiscountNoTax(totals))),
        // Payment methods (VISA, CB,MASTERCARD,AMEX,…) - JavaScript array if several payment methods for the same order
        'order_promo_code': totals.coupon_code ? totals.coupon_code : '',
        // Number of different products into the order
        'order_products_number': totals.items_qty,
        'env_country': this.store.state.ui.currentCountry ? this.store.state.ui.currentCountry.country : '',
        'order_products': this.getOrderProducts(totals.items).filter(item => item),
        'order_tax': taxRate
      })
    }
  }

  getListProducts (currentCat) {
    let products = []
    if (currentCat) {
      let productsState = this.store.state.listing.list
      for (let i = 0; i < productsState.length; i++) { // i < 3 && ---- in loop to limit products in array
        products.push(this.getInfoListProduct(productsState[i]))
      }
    }
    return products
  }

  getCategoryListProducts (currentCat) {
    let products = []
    if (currentCat) {
      let productsState = this.store.state.listing.list
      for (let i = 0; i < productsState.length; i++) { // i < 3 && ---- in loop to limit products in array
        products.push(this.getListingCategoryFromProduct(productsState[i]))
      }
    }
    return products
  }

  getListingCategoryFromProduct (product) { // used for editorials (no listing available)
    let category = product.categories[product.categories.length - 1]
    let breadcrumb = category.breadcrumbs
    return {
      'category': breadcrumb[0] ? breadcrumb[0].category_name : '' // Category
      // 'category2': catName
    }
  }

  getInfoListProduct (product) {
    const priceInfo = product ? getCurrentPrice(product) : null
    let originalPrice = parseFloat(this.formatPrice(priceInfo.originalPrice)).toFixed(2) || 0
    let currentPrice = parseFloat(this.formatPrice(priceInfo.current)).toFixed(2) || 0
    let discount = (originalPrice - currentPrice) || 0
    let taxRate = product.tax_rate || 0
    taxRate = (1 + (taxRate / 100)) || 0
    return {
      'list_product_id': product.id,
      'list_product_name': product.name,
      'list_product_originalprice_ati': this.convertToNumber(originalPrice),
      'list_product_discount_ati': this.convertToNumber(discount),
      'list_product_currentprice_ati': this.convertToNumber(currentPrice),
      'list_product_originalprice_tf': this.convertToNumber((originalPrice / taxRate)) || 0,
      'list_product_discount_tf': this.convertToNumber((discount / taxRate)) || 0,
      'list_product_currentprice_tf': this.convertToNumber((currentPrice / taxRate)) || 0,
      'list_product_instock': product.stock_status === 'IN_STOCK' ? 1 : 0,
      'list_product_url_page': getProductUrl({ store: this.store, product }),
      'list_product_url_picture': product.media_gallery.length ? product.media_gallery[0].url : ''
    }
  }

  convertToNumber (value, digit = 2) {
    return parseFloat(parseFloat(value).toFixed(digit))
  }

  setProductVal () {
    let currentProduct = this.store.state.cms.currentComponent === 'ProductPage' ? this.store.state.product.current : null
    // let UIBreadcrumb = this.store.state.ui.breadcrumb[0] ? this.store.state.ui.breadcrumb[0].text : ''
    const priceInfo = currentProduct ? getCurrentPrice(currentProduct) : null
    let originalPrice = parseFloat(this.formatPrice(priceInfo.originalPrice)).toFixed(2) || 0
    let currentPrice = parseFloat(this.formatPrice(priceInfo.current)).toFixed(2) || 0
    let discount = (originalPrice - currentPrice) || 0
    let taxRate = currentProduct.tax_rate || 0
    let breadcrumb = []
    let category = {}

    if (currentProduct && currentProduct.categories && currentProduct.categories.length > 0) {
      breadcrumb = currentProduct.categories[currentProduct.categories.length - 1].breadcrumbs ? currentProduct.categories[currentProduct.categories.length - 1].breadcrumbs : []
    }
    category = this.store.state.category || {}

    // try {
    //   breadcrumb = currentProduct.categories[currentProduct.categories.length - 1].breadcrumbs
    //   category = this.store.state.category
    // } catch (err) {
    //   breadcrumb = []
    //   category = {}
    // }
    let breadcrumbCategoryName = breadcrumb.length > 0 ? breadcrumb[0].category_name : ''
    // let taxRate = 22
    taxRate = (1 + (taxRate / 100)) || 0

    this.wrapper.setTcVars({
      'env_template': 'product',
      'product_id': currentProduct ? this.getProductID(currentProduct) : '',
      'product_name': currentProduct ? currentProduct.name : '',
      'product_url_page': currentProduct ? getProductUrl({ store: this.store, product: currentProduct }) : '',
      'product_url_picture': currentProduct ? (currentProduct.media_gallery.length ? currentProduct.media_gallery[0].url : '') : '',
      'product_originalprice_ati': this.convertToNumber(originalPrice),
      'product_discount_ati': this.convertToNumber(discount),
      'product_currentprice_ati': this.convertToNumber(currentPrice),
      'product_originalprice_tf': this.convertToNumber((originalPrice / taxRate)) || 0,
      'product_discount_tf': this.convertToNumber((discount / taxRate)) || 0,
      'product_currentprice_tf': this.convertToNumber((currentPrice / taxRate)) || 0,
      'category_name': Object.keys(category.metaData).length > 1 ? category.metaData.name : breadcrumbCategoryName,
      'category_id': category.id,
      'page_cat1': Object.keys(category.metaData).length > 1 ? category.metaData.name : breadcrumbCategoryName,
      'product_description': currentProduct ? currentProduct.description.html : '',
      'product_instock': currentProduct.stock_status === 'IN_STOCK' ? 1 : 0
    })
  }

  /**
   * ---- TRACK setOrderVal (thank_you_page)
   */
  setOrderVal (envTpl = '') {
    // const order = this.store.state.orders.currentOrder
    const country = this.store.state.ui.currentCountry.country
    let orderId = localStorage.getItem('orderId')
    if (orderId) {
      this.store.dispatch('orders/getOrderById', { orderId: localStorage.getItem('orderId') }).then(res => {
        let taxRate = res.items[0].tax_percent
        let order = res
        const tot = parseFloat(order.base_grand_total)
        // const discountAmount = parseFloat(Math.abs(order.base_discount_amount))
        // let baseSubtotalATI = this.convertToNumber(this.formatPrice(order.base_subtotal_incl_tax))
        let discountATI = Math.abs(this.convertToNumber(this.formatPrice(order.base_discount_amount)))
        // let amountTaxFree = tot - (tot / (1 + (taxRate / 100)))
        // let amountTaxFreeNoSF = amountTaxFree - order.base_shipping_amount
        // let orderAmountAtiWithoutSF = baseSubtotalATI - discountAmount
        // Math.round((#VALUE_ATI_WITH_SF#/(1+(#ORDER_TAX#/100))) * 100) / 100
        const shippingAmount = parseFloat(order.base_shipping_amount)
        const shippingDiscount = parseFloat(order.base_shipping_discount_amount)
        // eslint-disable-next-line
        const shipping = shippingAmount - shippingDiscount
        // const discountAmount = parseFloat(Math.abs(totals.base_discount_amount))
        let orderAmountAtiWithoutSF = tot - shipping
        this.wrapper.setTcVars({
          // Order amount TF (tax free) without shipping fee
          'env_template': envTpl,
          'order_amount_ati_with_sf': tot,
          'order_amount_ati_without_sf': orderAmountAtiWithoutSF,
          'order_amount_tf_with_sf': Math.round((tot / (1 + (taxRate / 100))) * 100) / 100,
          'order_amount_tf_without_sf': Math.round((orderAmountAtiWithoutSF / (1 + (taxRate / 100))) * 100) / 100,
          // Order amount ATI (all tax included) without shipping fee
          'order_id': order.increment_id,
          'order_payment_methods': order.payment.method,
          'user_email': order.customer_email,
          'env_country': country,
          'env_currency': this.getCurrency(),
          'basket_id': order.quote_id,
          // Order amount ATI (all tax included) with shipping fee
          // Order discount ATI (all tax included) - let empty if no discount
          'order_discount_ati': discountATI,
          'product_discount_ati': discountATI,
          // Order amount TF (tax free) with shipping fee
          // Order discount TF (tax free) - let empty if no discount
          'order_discount_tf': discountATI / (1 + (taxRate / 100)),
          'product_discount_tf': discountATI / (1 + (taxRate / 100)),
          // Payment methods (VISA, CB,MASTERCARD,AMEX,…) - JavaScript array if several payment methods for the same order
          'order_promo_code': order.coupon_code ? order.coupon_code : '',
          // Number of different products into the order
          'order_products_number': order.total_item_count,
          'order_newcustomer': 1,
          'order_products': this.getOrderProducts(order.items).filter(product => product) // to remove undefined in the array
          // 'order_tax': taxRate
        })
      })
    }
  }

  getOrderProducts (products) {
    let categories = JSON.parse(localStorage.getItem('productConfigurableCategoryName'))
    return products.map(product => {
      if (product.extension_attributes) {
        const originalPriceTf = parseFloat(this.formatPrice(parseFloat(product.extension_attributes.product_base_price)) / (product.tax_percent / 100 + 1)).toFixed(2)
        const discountTf = parseFloat((this.formatPrice(product.extension_attributes.product_base_price - product.base_price_incl_tax)) / (product.tax_percent / 100 + 1)).toFixed(2)
        let currentCategory = ''
        try {
          currentCategory = categories.find((item) => item.sku === (product.sku || product.extension_attributes.sku)).category.name
        } catch (err) {
          currentCategory = ''
        }
        return {
          'order_product_id': this.getProductID(product),
          'order_product_sku': product.sku || product.extension_attributes.sku,
          'order_product_name': product.name,
          'order_product_quantity': product.qty_ordered || product.qty,
          'category_name': currentCategory,

          // all tax include
          'order_product_originalprice_ati': parseFloat(this.formatPrice(product.extension_attributes.product_base_price)),
          'order_product_discount_ati': parseFloat(this.formatPrice(product.extension_attributes.product_base_price - product.base_price_incl_tax)),
          'order_product_currentprice_ati': parseFloat(this.formatPrice(product.base_price_incl_tax)),

          // tax free
          'order_product_originalprice_tf': parseFloat(this.formatPrice(originalPriceTf)),
          'order_product_discount_tf': parseFloat(this.formatPrice(discountTf)),
          'order_product_currentprice_tf': parseFloat(this.formatPrice(originalPriceTf - discountTf))
        }
      }
    })
  }

  formatPrice (price) {
    return parseFloat(price).toFixed(2)
  }

  // copied from mod_tbuy_tagcommander
  getItemDiscountAmount (product, tax = true) {
    let discountAmount = product.discount_amount || 0
    let discountPercent = 1 + product.discount_percent / 100
    if (tax) {
      return discountAmount
    }
    return discountAmount / discountPercent
  }

  /**
   *
   * copied from mod_tbuy_tagcommander
   */
  getDiscountNoTax (order) {
    let totalDiscountNoTax = 0
    let discountAmount
    let discountPercent
    order.items.forEach(item => {
      discountAmount = item.discount_amount
      discountPercent = 1 + item.discount_percent / 100
      totalDiscountNoTax += discountAmount / discountPercent
    })
    return totalDiscountNoTax
  }

  getOrderAmountNoTax (order) {
    let taxRate = 0
    order.items.forEach(item => {
      taxRate = item.tax_rate
    })
    return order.base_grand_total - order.base_grand_total / (1 + taxRate / 100)
  }

  getProductID (product) {
    let productConfigurableIDS = localStorage.getItem('productConfigurableIDS')
    productConfigurableIDS = JSON.parse(productConfigurableIDS)
    let productId = productConfigurableIDS
    let isThankYouPage = this.router.currentRoute.name === 'thankyou'
    if (productId) {
      if (isThankYouPage) { // purchase
        let obj = productId.find(item => item.sku === product.sku)
        if (obj && Object.keys(obj).length > 0) {
          productId = obj.id
        } else {
          productId = product.id
        }
      } else { // other events
        try {
          productId = productId.find(item => item.item_id === product.item_id).id
        } catch (e) {
          productId = product.id
        }
      }
      return productId
    }
    return product.id
  }

  getIds (products) {
    if (process.client) {
      return products.map(product => {
        return {
          'name': product.name,
          'id': this.getProductID(product),
          'price': parseFloat(this.formatPrice(parseFloat(product.price_incl_tax))),
          'category': product.extension_attributes.first_category,
          'quantity': product.qty,
          'originalPrice': product.extension_attributes.product_base_price,
          'discount': product.discount_amount || 0
        }
      })
    }
  }

  wishlist ({ $event, product }) {
    if (!this.iCanTrack()) {
      return false
    }
    const priceInfo = getCurrentPrice(product)
    this.wrapper.setTcVars({
      'id': 'add_to_wishlist',
      'product_id': product.id,
      'product_sku': product.sku,
      'product_name': product.name,
      'product_originalprice_ati': parseFloat(this.formatPrice(priceInfo ? (priceInfo.regularPrice ? priceInfo.regularPrice : priceInfo.current) : 0))

    })
    this.wrapper.captureEvent('addToWishlist')
  }
  /**
   * --------TRACK adToCart
   */
  addToCart ({ $event, product, prodComplete }) {
    if (!this.iCanTrack()) {
      return false
    }
    let params = {
      'id': 'add_to_cart',
      'product_id': product ? this.getProductID(product) : prodComplete.id,
      'product_sku': product ? product.sku : '',
      'product_name': product ? product.name : prodComplete.name
    }
    if (prodComplete) {
      params['product_sku'] = prodComplete.extension_attributes ? prodComplete.extension_attributes.sku : ''
      params['product_originalprice_ati'] = this.formatPrice(prodComplete.price_incl_tax)
      params['product_discount_ati'] = prodComplete.discount_amount ? this.formatPrice(prodComplete.discount_amount) : ''
      params['product_currentprice_ati'] = prodComplete.price_incl_tax ? this.formatPrice(prodComplete.price_incl_tax) : ''
      params['product_url_picture'] = prodComplete.extension_attributes ? prodComplete.extension_attributes.image_url : ''
    } else {
      params['product_originalprice_ati'] = this.formatPrice(product.price)
      params['product_currentprice_ati'] = this.formatPrice(product.price)
    }
    this.wrapper.captureEvent('addToCart', product ? $event.target : $event, params)
  }
  /**
   * ----------Track click product from category page
   */
  clickProduct ({ $event, product }) {
    if (!this.iCanTrack()) {
      return false
    }
    const priceInfo = getCurrentPrice(product)
    this.wrapper.captureEvent('clickProduct', $event.target, {
      'id': 'click_prod',
      'product_id': product.id,
      'product_sku': product.sku,
      'product_name': product.name,
      'product_originalprice_ati': this.formatPrice(priceInfo ? (priceInfo.regularPrice ? priceInfo.regularPrice : priceInfo.current) : 0),
      'product_discount_ati': this.formatPrice(priceInfo ? priceInfo.discount : 0),
      'product_currentprice_ati': this.formatPrice(priceInfo ? priceInfo.current : 0),
      'product_url_page': getProductUrl({ store: this.store, product }),
      'product_url_picture': product.image ? product.image.url : undefined
    })
  }

  /**
 * delete product
 */
  deleteProduct ({ $event, product }) {
    if (!this.iCanTrack()) {
      return false
    }
    this.wrapper.captureEvent('delete_product', $event.target, {
      'id': 'delete_product',
      'product_id': product.item_id,
      'product_sku': product.extension_attributes ? product.extension_attributes.sku : undefined,
      'product_url_picture': product.extension_attributes ? product.extension_attributes.image_url : undefined,
      'product_name': product.name,
      'product_originalprice_ati': this.formatPrice(product.price),
      'product_currentprice_ati': this.formatPrice(product.price)
    })
  }

  cart () {
    if (!this.iCanTrack()) {
      return false
    }
    this.wrapper.captureEvent('cart')
    this.setBasketVal('basket')
  }

  checkout () {
    if (!this.iCanTrack()) {
      return false
    }
    this.wrapper.captureEvent('checkout')
    this.setBasketVal('funnel_step2')
  }

  thankYouPage () {
    if (!this.iCanTrack()) {
      return false
    }
    this.wrapper.captureEvent('thankYouPage')
    this.setOrderVal('funnel_confirmation')
  }

  /**
   *  track newsletter
   */
  newsletter ({ $event, email }) {
    if (!this.iCanTrack()) {
      return false
    }
    this.setUserVar('newsletter_confirmation')
    this.wrapper.captureEvent('nl_form', $event.target, {
      'id': 'nl_form',
      'user_email': email
    })
  }
}

export default Tracker
