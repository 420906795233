<template>
  <div>
    <div class="product-info__title">
      <b-row class="align-items-center">
        <b-col>
          <div class="product-name">
            <h1 class="text-left title" v-html="product.name" />
          </div>
        </b-col>
        <b-col md="4">
          <div class="iteraction-icons">
            <ul class="nav ml-auto justify-content-end">
              <li>
                <no-ssr>
                  <social-sharing
                  :value="sharingTip"
                  :title="product.name"
                  :description="product.description.html || product.name"
                  v-on:close="opensharingTip"
                  ></social-sharing>
                </no-ssr>
              </li>
              <li>
                <add-giftcard-to-wishlist
                :enableAdd="canAddTo"
                :product="product"
                :productSelected="productSelected"
                :variant="getVariantsSelected"
                @addToWishlist="addGiftCardToWishlist" />
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="product-info__price d-flex align-items-center justify-content-between">
      <product-price :product="productSelected" />
      <span class="vat-included t-very-small cl-gray">{{ $t('vat-info') }}</span>
    </div>
    <div class="gift-card-recipient">
      <form @submit.stop.prevent="handleSubmit" class="giftcard-form" data-vv-scope="formGiftCard">
        <b-form-checkbox v-model="isAGift" name="isAGift" class="is-a-gift">{{$t('productPage.is-a-gift')}}</b-form-checkbox>
        <div class="giftcard-form__user-info">
          <b-collapse id="collapse-coupon" v-model="isAGift" class="giftcard-form__gift">
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <base-input
                    name="heading"
                    v-model="giftForm.aw_gc_headline"
                    type="text"
                    :autofocus="true"
                    :placeholder="$t('productPage.heading')"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <base-input
                    name="sender_name"
                    v-validate="{ required: this.isAGift }"
                    v-model="giftForm.aw_gc_sender_name"
                    type="text"
                    :autofocus="true"
                    :placeholder="'* ' +$t('productPage.senderName')"
                    :validations="[{
                      condition: errors.has('formGiftCard.sender_name'),
                      text: errors.first('formGiftCard.sender_name')
                    }]"
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <base-input
                    name="sender_email"
                    v-validate="{ required: this.isAGift, email: true }"
                    v-model="giftForm.aw_gc_sender_email"
                    type="text"
                    :autofocus="true"
                    :placeholder="'* ' +$t('productPage.senderEmail')"
                    :validations="[{
                      condition: errors.has('formGiftCard.sender_email'),
                      text: errors.first('formGiftCard.sender_email')
                    }]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <base-input
                    name="recipient_name"
                    v-validate="{ required: this.isAGift }"
                    v-model="giftForm.aw_gc_recipient_name"
                    type="text"
                    :autofocus="true"
                    :placeholder="'* ' +$t('productPage.recipientName')"
                    :validations="[{
                      condition: errors.has('formGiftCard.recipient_name'),
                      text: errors.first('formGiftCard.recipient_name')
                    }]"
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <base-input
                    name="recipient_email"
                    v-validate="{ required: this.isAGift, email: true }"
                    v-model="giftForm.aw_gc_recipient_email"
                    type="text"
                    :autofocus="true"
                    :placeholder="'* ' +$t('productPage.recipientEmail')"
                    :validations="[{
                      condition: errors.has('formGiftCard.recipient_email'),
                      text: errors.first('formGiftCard.recipient_email')
                    }]"
                  ></base-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <base-textarea
                    name="sender_message"
                    v-model="giftForm.aw_gc_message"
                    :autofocus="true"
                    :placeholder="$t('productPage.senderMessage')"
                    :rows="12"
                  ></base-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-collapse>
          <b-row v-show="!isAGift" class="giftcard-form__self-gift">
            <b-col md="6">
              <b-form-group>
                <base-input
                  name="recipient_name"
                  v-validate="{ required: !this.isAGift }"
                  v-model="giftForm.aw_gc_recipient_name"
                  type="text"
                  :autofocus="true"
                  :placeholder="'* ' +$t('productPage.recipientName')"
                  :validations="[{
                    condition: errors.has('formGiftCard.recipient_name'),
                    text: errors.first('formGiftCard.recipient_name')
                  }]"
                ></base-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <base-input
                  name="recipient_email"
                  v-validate="{ required: !this.isAGift, email: true }"
                  v-model="giftForm.aw_gc_recipient_email"
                  type="text"
                  :autofocus="true"
                  :placeholder="'* ' +$t('productPage.recipientEmail')"
                  :validations="[{
                    condition: errors.has('formGiftCard.recipient_email'),
                    text: errors.first('formGiftCard.recipient_email')
                  }]"
                ></base-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <button class="btn btn-black btn-add-to-cart full-width" type="submit">{{ $t('add-to-cart') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import ClassicProduct from '@/components/Product/Layout/ClassicProduct'
import SocialSharing from '@/components/Modal/SocialSharing'
import AddGiftcardToWishlist from '@/components/Product/GiftCard/AddGiftcardToWishList'
import BaseInput from '@/components/Form/BaseInput'
import BaseTextarea from '@/components/Form/BaseTextarea'
import { toast } from '@/modules/notifications'

export default {
  mixins: [ClassicProduct],
  inject: ['$validator'],
  name: 'GiftCardForm',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isFormValid: false,
      isAGift: false,
      giftForm: {
        aw_gc_sender_name: '',
        aw_gc_sender_email: '',
        aw_gc_headline: '',
        aw_gc_message: '',
        aw_gc_recipient_name: '',
        aw_gc_recipient_email: '',
        aw_gc_delivery_date: null
      }
    }
  },
  methods: {
    validateForm () {
      return this.$validator.validateAll('formGiftCard')
    },
    async handleSubmit (evt) {
      const self = this
      const qty = 1
      let valid = await this.validateForm()
      if (valid) {
        self.$store.commit('ui/showLoader')
        self.product['aw_giftcard_options'] = { ...self.giftForm }
        self.$store.dispatch('cart/update', { sku: self.product.sku, qty: qty, product: self.product, append: true, variant: null, getGiftInfoFromOptions: false })
          .then(res => {
            toast.success(self.$t('product-added-to-cart'))
          })
          .catch((err) => {
            let message = self.$t('GenericError')
            let error = err.message
            if (error) {
              message = error
            }
            toast.error(message)
          })
          .finally(() => {
            self.$store.commit('ui/hideLoader')
          })
      }
    },
    addGiftCardToWishlist () {

    }
  },
  components: {
    BaseInput,
    BaseTextarea,
    AddGiftcardToWishlist,
    SocialSharing
  }
}
</script>
