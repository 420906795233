<template>
    <div class="block-options">
        <div class="my-3" v-for="options in productOptions" :key="options.id">
            <span>{{options.label}}</span>
            <div class="d-flex align-items-center">
                <div class="option" v-for="option in options.values" :key="option.value_index">
                    <div class="swatch-option text mr-2"><b>{{option.label}}</b></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    productOptions: {
      type: Array,
      default: () => []
    }
  }
}
</script>
