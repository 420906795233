import $ from 'jquery'
import { EventBus } from '@/helpers'
var attrSlickLoaded = 'data-bcm-slick-initialized'

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      if (global.slick) {
        var $elements = $('div[data-content-type="products"]')
        $elements.each((index, element) => {
          let $element = $(element)
          let attr = $element.attr(attrSlickLoaded)
          // For some browsers, `attr` is undefined; for others,
          // `attr` is false.  Check for both.
          if (attr === undefined || attr === false) {
            moveFlipImage($element)
            setStyleOfQuickBuyButton($element) // for set height of title
          }
        })
      }
    })
  }
}

function moveFlipImage ($element) {
  let $products = $element.find('.product-item')
  $products.each((index, product) => {
    let $product = $(product)
    let $flipImage = $product.find('.product-flip-image')
    let $wrapper = $product.find('.product-image-wrapper')
    if ($flipImage) {
      $flipImage.appendTo($wrapper)
    }
  })
}

function setStyleOfQuickBuyButton ($element) {
  let attr = $element.attr('data-appearance') // ==carousel
  if (attr === 'carousel') {
    let title = $element.find('.product-item-name a')
    let max = 0
    setTimeout(function () {
      title.each(function (index) {
        if ($(this).height() > max) {
          max = $(this).height()
        }
      })
      title.height(max)
    }, 50)
  }
}
