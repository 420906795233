<template>
  <div>
    <no-ssr>
      <b-form-group>
        <b-form-radio-group
          :options="options"
          v-model="selected"
          text-field="label"
          value-field="value_index"
          @change="change"
        />
      </b-form-group>
    </no-ssr>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    parentAttribute: {
      type: String,
      required: true
    },
    defaultSelected: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      selected: this.defaultSelected
    }
  },
  methods: {
    change (newValues) {
      this.$emit('filterSelected', this.getFilterCompose(newValues))
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues }
    }
  }
}
</script>
