import { customerOut } from './customer'

export default `mutation UpdateCustomer ($customerInput: CustomerInput!) {
  Bcame_set_profiling (input: $customerInput)
  updateCustomer (input: $customerInput) {
    customer{
      ${customerOut}
    }
  }
}`
