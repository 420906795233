<template>
  <div class="product-info__color">
    <div class="color-title">
      <span><span class="text-uppercase">{{$t('color')}}: </span> <strong>{{colorLabel}}</strong> </span>
    </div>
    <color-swatch :options="options" :fromQuickBuy="fromQuickBuy" :parentAttribute="parentAttribute" :defaultSelected="defaultSelected" :thumbBgColor="thumbBgColor" @filterSelected="change($event)"></color-swatch>
  </div>
</template>

<script>
import ColorSwatch from './ColorSwatch/ColorSwatch'
import { EventBus } from '@/helpers'

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    parentAttribute: {
      type: String,
      required: true
    },
    filterActive: {
      type: Object,
      required: true
    },
    defaultSelected: {
      type: String | Number,
      required: false,
      default: null
    },
    filterCode: {
      type: String,
      default: 'size'
    },
    thumbBgColor: {
      type: String,
      required: false,
      default: 'f5f5f5'
    },
    product: {
      type: Object,
      required: false
    },
    fromQuickBuy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    ColorSwatch
  },
  data () {
    return {
      colorSelectedLabel: null
    }
  },
  methods: {
    change (newValues) {
      let newSelection = newValues.value.value_index
      let grouped = this.product.configurableParent.groupedVariation
      let selected = grouped.filter(g => g.color === newSelection)
      this.colorSelectedLabel = selected[0].color_label
      this.$emit('filterSelected', this.getFilterCompose(newValues.value.value_index))
      EventBus.$emit('colorSelector:changed', {})
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues }
    }
  },
  computed: {
    colorLabel () {
      try {
        return this.colorSelectedLabel
        // return this.product.configurableParent.groupedVariation.find(color => { return color.sku === this.product.sku }).color_label
      } catch (error) {
        return this.product.configurableParent.groupedVariation[0].color_label
      }
    }
  }
}
</script>
