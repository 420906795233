export default `
  __typename
  ... on ConfigurableProduct {
    variants {
      product {
        id
        name
        sku
        stock_status
        quantity
        preorder_delivery
        out_of_stock_threshold
        is_backinstock
        attribute_set_id
        color
        enabled
        media_gallery {
          label
          url
        }
        special_from_date
        special_price
        special_to_date
        master_sku
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          },
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
            regular_price {
              value
              currency
            }
          },
        },
      }
      attributes {
        label
        code
        value_index
      }
    }
    configurable_options {
      attribute_code
      attribute_id
      values {
        value_index
        label
      }
    }
    groupedVariation{
      color
      name
      image {
        label
        url
      }
      hidden_variants
      media_gallery {
        label
        url
        ... on ProductVideo {
          video_content {
            video_url
          }
        }
      }
    }
  }
`
