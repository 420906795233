import getters from '@/store/modules/user/getters'
import find from 'lodash/find'
import isMatch from 'lodash/isMatch'

getters.isOnWishlist = (state) => (product) => {
  let match = false
  let current = state.current
  if (current && current.wishlist) {
    current.wishlist.items.forEach(item => {
      if (item.product && item.product.configurable_options) {
        let configurableOptions = item.product.configurable_options
        // find attribute color
        const colorAttr = find(configurableOptions, { 'attribute_code': 'color' })
        var groupedSelected = {
          [colorAttr.attribute_id]: item.product.presetFilter[colorAttr.attribute_id]
        }
      }
      // check product.configurableSku before product.sku
      if ((product.master_sku || product.sku) === item.product.sku && isMatch(product.presetFilter, groupedSelected)) {
        match = item
      }
    })
  } else {
    let guestWishlist = (state.guest && state.guest.wishlist) ? state.guest.wishlist.items : []
    guestWishlist.forEach(item => {
      if (item.product && item.product.configurable_options) {
        let configurableOptions = item.product.configurable_options
        // find attribute color
        const colorAttr = find(configurableOptions, { 'attribute_code': 'color' })
        var groupedSelected = {
          [colorAttr.attribute_id]: item.product.presetFilter[colorAttr.attribute_id]
        }
      }
      // check product.configurableSku before product.sku
      if ((product.master_sku || product.configurableSku || product.sku) === item.product.sku && isMatch(product.presetFilter, groupedSelected)) {
        match = item
      }
    })
  }
  return match
}

export default {
  ...getters
}
