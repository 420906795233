<template>
  <div class="wrapp-stores-list">
    <div class="wrapp-stores-list__content" v-if="storesByProduct">
      <div class="wrapp-stores-list__content__list" v-if="storesByProduct.stores_count > 0">
        <template v-for="store in storesByProduct.stores_available">
          <card-store :key="store.code" :store="store"></card-store>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CardStore from './CardStore'
export default {
  name: 'StoresList',
  computed: {
    storesByProduct () {
      return this.$store.getters['storeLocator/getStoresByProduct']
    }
  },
  components: {
    CardStore
  }
}
</script>
