
/* eslint-disable */
import $, { data } from 'jquery'
import { EventBus } from '@/helpers'
var attrProcessed = 'data-bcm-processed'

export function init() {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      $(function () {
        let $elements = $('[data-content-type="column"][data-background-type="video"]')
        $elements.each(function () {
          let $element = $(this)
          let nativeClasses = $element.attr('class')

          // I remove the class that assigns the background
          if (nativeClasses) {
            nativeClasses = nativeClasses.replace('background-image-', '')
          }

          let attr = $element.attr(attrProcessed)
          if (attr === undefined || attr === false) {
            // create wrapper element
            $('<div class="pagebuilder-column-wrapper">').appendTo($element)
            let $wrapper = $element.find('.pagebuilder-column-wrapper')

            let classesToRemove = 'pagebuilder-column jarallax'
            $wrapper.addClass(nativeClasses)
            $wrapper.removeClass(classesToRemove)
            // $wrapper.css($element.css())

            // get children of columns
            let $elementChildren = $element.children().not('[id*="jarallax-container"]')
            
            if ($elementChildren.length > 0) {
              $elementChildren.each(function () {
                let $tempEl = $(this)
                $wrapper.append($tempEl)
              })
            }

            // processed
            $element.attr(attrProcessed, '')
          }
        })
      })
    })
  }
}
