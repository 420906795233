export default `
query GetBlogCategory($categoryId: String) {
  blogCategory (id: $categoryId ){
    category_id
    parent_category_id
    filtered_content_ext
    content
    category_url
    category_url_path
    identifier
    title
    meta_title
    meta_keywords
    meta_description
    canonical_url
    is_hero
    featured_img
    path
    position
    include_in_menu
    is_active
    display_mode
    page_layout
    layout_update_xml
    custom_layout
    custom_layout_update_xml
    posts_count
  }
}`
