<template>
  <div>
    <div v-if="hasVideo && hasVideo.length > 0">
      <video autoplay loop muted class="ff-video">
        <source :src="hasVideo" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div v-else>
      <img
        v-lazy="imgObj"
        :alt="alt"
        :title="alt"
        :key="imgObj.src"
        :style="styleImage"
      />
    </div>
  </div>
</template>

<script>
import { getThumbnailPath } from '@/helpers'
export default {
  props: {
    imgPath: {
      type: String,
      default: ''
    },
    hasVideo: {
      type: String
    },
    size: {
      type: String,
      default: ''
    },
    styleImage: {
      type: Object,
      default () { return {} }
    },
    changeAble: {
      type: Boolean,
      default: () => false
    },
    alt: {
      type: String,
      default: 'Image'
    },
    defaultLoading: {
      default: () => require('@/assets/image/dump-square.png')
    }
  },
  computed: {
    imgObj () {
      return {
        src: getThumbnailPath(this.imgPath, this.size),
        error: this.defaultLoading,
        loading: (this.size === 'LG' ? require('@/assets/image/dump.png') : this.defaultLoading)
        // loading: require('@/assets/image/loading.gif')
      }
    },
    src () {
      return this.changeAble ? this.imgObj.src : this.imgObj.loading
    }
  }
}
</script>
