// ACCORDION FOR MAGENTO CC PAGEBUILDER
// USE CLASS .accordion-links to use this feature
import { EventBus } from '@/helpers'
import $ from 'jquery'

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      const $anchor = $('.accordion-links')
      if ($anchor.length > 0) {
        // start replacing
        $anchor.each((index, el) => {
          let $currentEl = $(el)
          let additionalClasses = ''
          if (!$currentEl.parent().hasClass('bcm-accordion')) {
            $currentEl.attr('style', '')
            $currentEl.wrap(`<div class="bcm-accordion acc-${index} ${additionalClasses}" />`)
            $currentEl.find('[data-content-type="button-item"]').first().addClass('accordion-title').prependTo(`.acc-${index}`)
            if ($currentEl.children().length) {
              $currentEl.parent().addClass('has-child')
            }
          }
        })
      }
    })
    $(document).on('click', '.bcm-accordion.has-child .accordion-title', function (e) {
      let $parent = $(this).parents('.bcm-accordion')
      $parent.toggleClass('accordion-opened')
      $parent.find('.accordion-links').slideToggle()
    })
  }
}
