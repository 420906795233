export const getFeelBuyAttributes = `{
  customAttributeMetadata(
    attributes: [
      {
        attribute_code: "aqa_want_to"
        entity_type: "4"
      }
      {
        attribute_code: "aqa_my_style"
        entity_type: "4"
      }
      {
        attribute_code: "aqa_wish_i_were"
        entity_type: "4"
      }
    ]
  ) {
    items {
      attribute_code
      attribute_type
      attribute_options {
      value
      label
    }
    }
  }
}`
