// import Config from '@/config'

// const attrArr = JSON.stringify(Config.Theme.attributesToload)

export default `query Products(
      $search: String
      $currentPage: Int
      $filter: ProductAttributeFilterInput
      $pageSize: Int
      $sort: ProductAttributeSortInput
    ) {
    products(
      search: $search
      currentPage: $currentPage
      filter: $filter
      pageSize: $pageSize
      sort: $sort ) {
      total_count
    }
  }`
