import Vue from 'vue'

import {
  BadgePlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormSelectPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PaginationNavPlugin,
  SidebarPlugin,
  TabsPlugin
} from 'bootstrap-vue'

[
  BadgePlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormFilePlugin,
  FormSelectPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavPlugin,
  NavbarPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PaginationNavPlugin,
  SidebarPlugin,
  TabsPlugin
].forEach(comp => {
  Vue.use(comp)
})
