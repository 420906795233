import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=2a5cba84&"
import script from "./Breadcrumb.vue?vue&type=script&lang=js&"
export * from "./Breadcrumb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "Breadcrumb.vue"
export default component.exports