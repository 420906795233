<template>
  <div class="wrapp-options">
    <template>
      <div v-for="variant in variants" :key="variant.attribute_id" class="wrapp-options__generic">
        <div v-if="availableComponent[variant.attribute_code] && variant.values" class="wrapp-options__generic__single-option">
          <component
          :options="variant.values"
          :parentAttribute="variant.attribute_id"
          :thumbBgColor="thumbBgColor"
          :filterActive="filterActive"
          :filterCode="variant.attribute_code"
          :product="product"
          :isComingSoon="isComingSoon"
          :defaultSelected="hasSelectedOption(variant.attribute_id)"
          :is="availableComponent[variant.attribute_code]"
          @filterSelected="filterChanged"
          :fromQuickBuy="fromQuickBuy" />
        </div>
        <div v-else>
          <checkbox-filter
          :options="variant.values"
          :parentAttribute="variant.attribute_id"
          :defaultSelected="hasSelectedOption(variant.attribute_id)"
          @filterSelected="filterChanged"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// load component custom
import ColorSelector from './Variants/ColorSelector'
// import SizeSelector from './Variants/SizeSelector'
import FakeSizeSelector from './Variants/FakeSizeSelector'
// default component
import CheckboxFilter from './Variants/CheckboxFilter'
import { EventBus } from '@/helpers'
import { mapState } from 'vuex'
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'

export default {
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    variants: {
      type: Array,
      required: true
    },
    fromQuickBuy: {
      type: Boolean,
      required: false
    },
    filterActive: {
      type: Object,
      required: true
    },
    defaultSelection: {
      type: Object,
      required: false
    },
    isComingSoon: {
      type: Boolean,
      default: false
    },
    thumbBgColor: {
      type: String,
      required: false,
      default: 'f5f5f5'
    }
  },
  data () {
    return {
      componentToLoad: null
    }
  },
  computed: {
    ...mapState({
      loadVariants: state => state.product.loadConfigurable
    }),
    availableComponent () {
      return {
        'color': ColorSelector,
        'size': FakeSizeSelector
      }
    }
  },
  methods: {
    hasSelectedOption (variantId) {
      if (this.defaultSelection && this.defaultSelection[variantId]) {
        return this.defaultSelection[variantId]
      } else {
        return null
      }
    },
    filterChanged (filterComposed) {
      EventBus.$emit('product:filterChange', filterComposed)
      this.$emit('changeFilter', filterComposed)
    }
  },
  components: {
    AbsoluteLoader,
    CheckboxFilter,
    FakeSizeSelector
  }
}
</script>
