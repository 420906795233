export default `query getVariantExt($sku: String) {
  getVariantExt (sku: $sku) {
    variant {
      sku
      name
      minQty
      qtySalable
      stockStatus
      size
    }
  }
}`
