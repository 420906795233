
import { state } from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default function extendCategory (storeCategory) {
  storeCategory.getters = getters
  storeCategory.actions = actions
  storeCategory.mutations = { ...storeCategory.mutations, ...mutations }
  storeCategory.state = { ...storeCategory.state, ...state() }
  return storeCategory
}
