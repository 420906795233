var attrSlickLoaded = 'data-bcm-slick-initialized'

export function init () {
  if (process.client) {
    $('body').on('widget:slickInstagramFeedInit', (event, placeholder) => {
      if (getSlick()) {
        let $element = $(placeholder.slickElement)
        let attr = $element.attr(attrSlickLoaded)
        // For some browsers, `attr` is undefined; for others,
        // `attr` is false.  Check for both.
        if (attr === undefined || attr === false) {
          /* SET MARKER IF SLIDER IS INITIALIZED */
          $element.attr(attrSlickLoaded, true)
          let responsive480 = placeholder.slickSettings.responsive.find(item => item.breakpoint === '480')
          if (responsive480) {
            responsive480.settings.slidesToShow = 2
          }
          initializeSlick($element, placeholder.slickSettings)
          $(document).on('click', '.aminst-feed-link', function () {
            openPopup($(this))
          })
        }
      }
    })
  }
}

function openPopup ($element) {
  let $currentPost = $element.parents('.aminst-feed-post')
  let $currentPostLink = $element
  if ($currentPostLink.data('popup-enabled') === 1) {
    event.preventDefault()
    let modalObject = {
      imagePlaceholder: '',
      image: $currentPost.find('.aminst-feed-img').attr('src'),
      permalink: $currentPostLink.attr('href'),
      description: replaceDescription($currentPost.find('.aminst-feed-caption').text()),
      likeCount: $currentPost.find('.aminst-feed-likes').text(),
      commentCount: $currentPost.find('.aminst-feed-comments').text(),
      product_entity: {
        name: $currentPost.find('.post-relation .post-relation__name').text(),
        price: $currentPost.find('.post-relation .post-relation__price').text(),
        url: $currentPost.find('.post-relation .post-relation__url').text()
      }
    }
    return global.$store.dispatch('ui/showDynamicModalComponent', {
      component: import('theme/components/Modal/Generic/Instagram.vue'),
      props: {
        postInfo: modalObject
      },
      modalProps: {
        modalClass: 'bcm-modal modal-instagram',
        hideFooter: true
      }
    })
  } else {
    return true
  }
}

function replaceDescription (text) {
  text = replaceHashTag(text)
  text = replaceTaggedAccount(text)
  return text
}

function replaceHashTag (text) {
  // replace hashtag and tagged account
  const instagramHashTagLink = 'https://www.instagram.com/explore/tags'
  text = text.replace(/#(\w+)/g, `<a href="${instagramHashTagLink}/$1" class="hashtag-link" target="_blank">#$1</a>`)
  return text
}

function replaceTaggedAccount (text) {
  const instagramAccountLink = 'https://www.instagram.com'
  text = text.replace(/@(\w+)/g, `<a href="${instagramAccountLink}/$1" class="hashtag-link" target="_blank">@$1</a>`)
  return text
}

function sanitizeBreakpoints (settings) {
  settings.arrows = true
  settings.slidesToShow = 3
  settings.slidesToScroll = 1
  settings.responsive = [{
    breakpoint: 1024,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '90px',
      arrows: true
    }
  }]
  return JSON.parse(JSON.stringify(settings))
}

function initializeSlick ($element, settings) {
  $element.slick(sanitizeBreakpoints(settings))
}

async function getSlick () {
  if (global.slick) {
    return global.slick
  } else {
    const slick = await require('slick-carousel/slick/slick').default
    global.slick = slick
    return slick
  }
}
