// import Vue from 'vue'
import * as types from '../store/mutation-types'
import { zendeskStorage } from '../index'

export async function afterRegistration ($store) {
  $store.subscribe((mutation, state) => {
    const type = mutation.type
    if (type.endsWith(types.TICKETS)) {
      zendeskStorage.setItem('tickets-history', state.zendesk.userTickets).catch((reason) => {
        console.error(reason) // it doesn't work on SSR
      }) // populate cache
    }
  })
}
