import Router from 'vue-router'
import Config from 'theme/config'
import { pathTranslate } from '@/helpers/routeHelpers'
import { setToken as MagentoSetToken } from '@/services/Magento'
// import Logger from '@/services/Logger'

import { EventBus } from '@/helpers'

export default () => {
  const routes = [
    {
      path: '/',
      redirect: { name: 'home', params: { lang: '_' } }
    },
    {
      path: `/:lang(${Config.Theme.storeviewRegex})`,
      // path: `/:lang`,
      component: () => import('@/components/Lang.vue'),
      meta: {
        root: true
      },
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('theme/pages/Home.vue'),
          meta: {
            tplName: 'homepage',
            pageType: 'Homepage'
          }
        },
        {
          path: pathTranslate({ path: 'reset-password' }), // /?token=GbLLDEuDsXNAG5BLNXhaKCFtIPsZ4dEa&email=...'
          name: 'forgotPassword',
          component: () => import('./pages/ForgotPassword.vue'),
          meta: {
            tplName: 'myaccount_other',
            pageType: 'Account Page'
          }
        },
        /* {
          path: pathTranslate({ path: 'block' }), // /?token=GbLLDEuDsXNAG5BLNXhaKCFtIPsZ4dEa&email=...'
          name: 'block',
          component: () => import('./pages/ForgotPassword.vue'),
          meta: {
            tplName: 'myaccount_other'
          }
        }, */
        {
          path: 'customer/account/changeforgotten', // /?token=GbLLDEuDsXNAG5BLNXhaKCFtIPsZ4dEa&email=...'
          name: 'resetPassword',
          component: () => import('./pages/ResetPassword.vue'),
          meta: {
            tplName: 'myaccount_other',
            pageType: 'Account Page'
          }
        },
        {
          path: 'customer/account/login',
          name: 'login',
          component: () => import('./pages/PageLogin.vue'),
          meta: {
            tplName: 'myaccount_other',
            pageType: 'Account Page'
          }
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('./pages/Register.vue'),
          meta: {
            tplName: 'myaccount_other',
            pageType: 'Account Page'
          }
        },
        {
          path: 'wishlist',
          name: 'wishlist',
          redirect: () => {
            let token = null
            if (process.client) {
              if (global.$cookies) {
                token = global.$cookies.get('token') || null
              }
            } else {
              // server side
              if (global.ctx && global.ctx.cookie) {
                token = global.ctx.cookie['token'] || null
              }
            }
            if (token) {
              return { name: 'myWishList' }
            } else {
              return { name: 'myGuestWishList' }
            }
          }
        },
        {
          path: 'wishlist/shared/:id',
          name: 'wishlistShared',
          component: () => import('./pages/SharedWishlist.vue'),
          meta: {
            tplName: 'myaccount_other',
            pageType: 'Account Page'
          }
        },
        {
          path: 'my-guest-wishlist',
          name: 'myGuestWishList',
          component: () => import('./pages/Guest/MyGuestWishlist.vue'),
          meta: {
            tplName: 'myaccount_other',
            pageType: 'Account Page'
          }
        },
        {
          path: 'returns-and-refunds/init',
          name: 'createGuestReturn',
          component: () => import('./pages/CreateGuestReturn.vue'),
          meta: {
            tplName: 'myaccount_other',
            pageType: 'Account Page'
          }
        },
        {
          path: 'returns-and-refunds/new/:id/:email',
          name: 'newGuestReturn',
          component: () => import('./pages/MyAccount/MyReturnsNew.vue'),
          meta: {
            tplName: 'myaccount_other',
            title: 'newRma',
            pageType: 'Account Page'
          }
        },
        {
          path: 'returns-and-refunds/summary/:id',
          name: 'rmaGuestSummary',
          component: () => import('./pages/MyAccount/MyReturnSummary.vue'),
          meta: {
            tplName: 'myaccount_other',
            title: 'rmaSummary',
            pageType: 'Account Page'
          }
        },
        {
          path: 'returns-and-refunds/complete',
          name: 'returnGuestComplete',
          component: () => import('./pages/MyAccount/MyReturnComplete.vue'),
          meta: {
            tplName: 'myaccount_other',
            pageType: 'Account Page'
          }
        },
        {
          path: 'my-account',
          meta: {
            tplName: 'myaccount_other',
            auth: { name: 'login' }
          },
          component: () => import('./pages/MyAccount/MyAccount.vue'),
          children: [
            {
              path: 'my-profile',
              name: 'myProfile',
              component: () => import('./pages/MyAccount/MyProfile.vue'),
              meta: {
                accountPage: true,
                accountMenu: true,
                menuIconName: 'icomoon-icon icon-star',
                tplName: 'myaccount_other',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-wishlist',
              name: 'myWishList',
              component: () => import('./pages/MyAccount/MyWishlist.vue'),
              meta: {
                tplName: 'myaccount_other',
                accountPage: true,
                accountMenu: true,
                menuIconName: 'icomoon-icon icon-heart-wishlist',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-addresses',
              name: 'myAddresses',
              component: () => import('./pages/MyAccount/MyAddresses.vue'),
              meta: {
                accountPage: true,
                accountMenu: true,
                menuIconName: 'icomoon-icon icon-noun_Address_3001133',
                tplName: 'myaccount_other',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-addresses/:id',
              name: 'editAddress',
              component: () => import('./pages/MyAccount/MyAddressesEdit.vue'),
              meta: {
                tplName: 'myaccount_other',
                auth: { name: 'login' },
                title: 'addEditAddress',
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-returns',
              name: 'myReturns',
              component: () => import('./pages/MyAccount/MyReturns.vue'),
              meta: {
                tplName: 'myaccount_other',
                accountPage: true,
                accountMenu: true,
                menuIconName: 'icomoon-icon icon-refund',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'newsletter/manage',
              name: 'myNewsletter',
              component: () => import('./pages/MyAccount/MyNewsletter.vue'),
              meta: {
                tplName: 'myaccount_other',
                accountPage: true,
                accountMenu: false,
                menuIconName: '',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-returns/edit/:id',
              name: 'editReturns',
              component: () => import('./pages/MyAccount/MyReturnsEdit.vue'),
              meta: {
                tplName: 'myaccount_other',
                auth: { name: 'login' },
                title: 'detailRma',
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-returns/new',
              name: 'newReturns',
              component: () => import('./pages/MyAccount/MyReturnsNew.vue'),
              meta: {
                tplName: 'myaccount_other',
                auth: { name: 'login' },
                title: 'newRma',
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-returns/summary/:id',
              name: 'rmaSummary',
              component: () => import('./pages/MyAccount/MyReturnSummary.vue'),
              meta: {
                tplName: 'myaccount_other',
                title: 'rmaSummary',
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-returns/complete',
              name: 'returnComplete',
              component: () => import('./pages/MyAccount/MyReturnComplete.vue'),
              meta: {
                tplName: 'myaccount_other',
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-cards',
              name: 'myCards',
              component: () => import('./pages/MyAccount/MyCards.vue'),
              meta: {
                tplName: 'myaccount_other',
                accountPage: true,
                accountMenu: true,
                menuIconName: 'icomoon-icon icon-carte',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-tickets',
              name: 'myTickets',
              component: () => import('./pages/MyAccount/MyTickets.vue'),
              meta: {
                accountPage: true,
                accountMenu: true,
                menuIconName: 'icomoon-icon icon-richieste',
                tplName: 'myaccount_other',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-tickets/:id',
              name: 'ticketDetail',
              component: () => import('./pages/MyAccount/TicketDetail.vue'),
              meta: {
                accountPage: true,
                accountMenu: false,
                menuIconName: '',
                tplName: 'myaccount_other',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-gift-cards',
              name: 'myGiftCards',
              component: () => import('./pages/MyAccount/MyGiftCards.vue'),
              meta: {
                tplName: 'myaccount_other',
                accountPage: true,
                accountMenu: false,
                menuIconName: '',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-orders',
              name: 'myOrders',
              component: () => import('./pages/MyAccount/MyOrders.vue'),
              meta: {
                tplName: 'myaccount_other',
                accountPage: true,
                accountMenu: true,
                menuIconName: 'icomoon-icon icon-box2',
                auth: { name: 'login' },
                pageType: 'Account Page'
              }
            },
            {
              path: 'my-orders/:id',
              name: 'myOrder',
              component: () => import('./pages/MyAccount/MySingleOrder.vue'),
              meta: {
                tplName: 'myaccount_other',
                auth: { name: 'login' },
                title: 'my-order',
                pageType: 'Account Page'
              }
            }
          ]
        },
        {
          path: pathTranslate({ path: 'follow-your-order' }),
          name: 'FollowYourOrder',
          component: () => import('./pages/FollowOrder.vue'),
          meta: {
            tplName: 'other_generic'
          }
        },
        {
          path: pathTranslate({ path: 'book-an-appointment' }),
          name: 'BookAnAppointment',
          component: () => import('./pages/BookAnAppointment.vue'),
          meta: {
            tplName: 'other_generic'
          }
        },
        {
          path: pathTranslate({ path: 'personal-shopper' }),
          name: 'FashionExpert',
          component: () => import('./pages/FashionExpert.vue'),
          meta: {
            tplName: 'other_generic'
          }
        },
        {
          path: pathTranslate({ path: 'terms-and-conditions' }),
          component: () => import('./pages/TermsAndConditions/TermsAndConditions.vue'),
          children: [
            {
              path: '',
              redirect: pathTranslate({ path: 'conditions-of-sale' })
            },
            {
              path: ':slug',
              name: 'genericTerms',
              component: () => import('./pages/Factory.vue'),
              meta: {
                tplName: 'other_generic',
                basePath: pathTranslate({ path: 'terms-and-conditions' })
              }
            }
          ]
        },
        /* STORE LOCATOR */
        {
          path: pathTranslate({ path: 'boutique' }) + '.html',
          name: 'Boutiques',
          component: () => import('./pages/StoreLocator/StoreLocator.vue'),
          meta: {
            tplName: 'other_store'
          }
        },
        {
          path: pathTranslate({ path: 'boutique' }) + '/:store_code',
          name: 'Boutique',
          component: () => import('./pages/StoreLocator/StoreDetails.vue'),
          meta: {
            tplName: 'other_store'
          }
        },
        /* END STORE LOCATOR */
        {
          path: pathTranslate({ path: 'corporate' }) + '/' + pathTranslate({ path: 'work-with-us' }),
          name: 'WorkWithUs',
          component: () => import('./pages/CustomerCare/WorkWithUs.vue'),
          meta: {
            tplName: 'other_generic'
          }
        },
        {
          path: pathTranslate({ path: 'contacts' }),
          name: 'ContactUs',
          component: () => import('./pages/CustomerCare/ContactUs.vue'),
          meta: {
            tplName: 'other_generic',
            pageType: 'Customer Service Page'
          }
        },
        {
          path: pathTranslate({ path: 'customer-care' }),
          component: () => import('./pages/CustomerCare/CustomerCare.vue'),
          children: [
            {
              name: 'CustomerCare',
              path: '',
              redirect: to => ({
                name: 'genericCustomerCare',
                params: { slug: pathTranslate({ path: 'contacts' }), lang: to.params.lang }
              })
            },
            {
              path: pathTranslate({ path: 'track-order' }),
              name: 'trackOrder',
              component: () => import('./pages/TrackOrder.vue'),
              meta: {
                tplName: 'myaccount_other'
              }
            },
            {
              path: ':slug',
              name: 'genericCustomerCare',
              component: () => import('./pages/Factory.vue'),
              meta: {
                tplName: 'other_generic',
                pageType: 'Customer Service Page',
                basePath: pathTranslate({ path: 'customer-care' })
              }
            }
          ]
        },
        {
          path: 'feel-buy.html',
          name: 'Feel&Buy',
          component: () => import('./pages/FeelBuy.vue'),
          meta: {
            tplName: 'other_generic'
          }
        },
        {
          path: 'feel-buy.html/results',
          name: 'FeelBuyListing',
          component: () => import('./pages/FeelBuyListing.vue'),
          meta: {
            tplName: 'other_generic'
          }
        },
        {
          path: pathTranslate({ path: 'unsubscribe' }),
          name: 'unsubscribe',
          component: () => import('./pages/Unsubscribe.vue'),
          meta: {
            tplName: 'newsletter_form'
          }
        },
        /**
        * START BLOG
        */
        {
          path: 'world-of.html/:cat?',
          name: 'blog-landing',
          component: () => import('./pages/WorldOf/WorldOf.vue'),
          meta: {
            parentBlog: 'world-of', // pathTranslate({ path: 'blog' })
            tplName: 'blog_page',
            pageType: 'Blog'
          }
        },
        {
          path: 'world-of/:cat?',
          name: 'blog-landing-detail',
          component: () => import('./pages/WorldOf/WorldOf.vue'),
          meta: {
            parentBlog: 'world-of', // pathTranslate({ path: 'blog' }),
            tplName: 'blog_page',
            pageType: 'Blog'
          }
        },
        /**
         * END BLOG
         */
        {
          path: 'search.html',
          name: 'search',
          component: () => import('./pages/Listing.vue'),
          meta: {
            search: true,
            pageType: 'Product List',
            tplName: 'internal_search'
          }
        },
        {
          path: 'onestepcheckout',
          name: 'onepagecheckout',
          // meta: { auth: { name: 'cart' } },
          component: () => import('theme/pages/CheckoutOnePage.vue'),
          meta: {
            tplName: 'funnel_step2'
          }
        },
        {
          path: 'subscribe',
          name: 'subscribe',
          component: () => import('./pages/NewsletterPage.vue'),
          redirect: { name: 'newsletter' },
          children: [
            {
              path: 'newsletter',
              name: 'newsletter',
              component: () => import('./pages/NewsletterPage.vue')
            }
          ]
        },
        {
          path: 'checkout',
          component: () => import('@/pages/Checkout.vue'),
          children: [
            {
              path: '',
              name: 'checkout'
            },
            {
              path: 'cart',
              name: 'cart',
              component: () => import('./components/Checkout/Cart.vue'),
              meta: {
                tplName: 'funnel_basket'
              }
            },
            {
              path: 'shipping',
              name: 'shipping',
              meta: { auth: { name: 'cart' } },
              component: () => import('@/components/Checkout/Shipping.vue')
            },
            {
              path: 'billing',
              name: 'billing',
              meta: { auth: { name: 'cart' } },
              component: () => import('@/components/Checkout/Billing.vue')
            },
            {
              path: 'pre-summary',
              name: 'preSummary',
              component: () => import('@/components/Checkout/PreSummary.vue')
            },
            {
              path: 'thank-you',
              name: 'thankyou',
              component: () => import('./components/Checkout/Thankyou.vue'),
              meta: {
                tplName: 'funnel_confirmation'
              }
            }
          ]
        },
        {
          path: 'page-not-found',
          name: 'page-not-found',
          meta: {
            tplName: 'other_errors',
            pageType: 'Error Page'
          },
          component: () => import('./pages/PageNotFound.vue')
          /* ,
          redirect: { name: 'home' } */
        }
      ]
    },
    {
      path: '*',
      redirect: { name: `home`, params: { lang: '_' } }
    }
    // ,
    // {
    //   path: '*',
    //   redirect: `${process.env.VUE_APP_BASE_PATH}/_`
    //   // redirect: { name: `home`, params: { lang: 'defa_lt' } }
    // }
  ]
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) {
        return {
          selector: to.hash
        }
      }

      let position = savedPosition || { x: 0, y: 0 }
      setTimeout(() => {
        EventBus.$emit('listing:scrollBehavior', position)
      }, 100)
      return position
    }
  })

  router.beforeEach((to, from, next) => {
    if (process.client) {
      MagentoSetToken(global.$cookies.get('token') || '')
      global.$('html').attr('data-class', '')
      EventBus.$emit('router-change')
    }
    next()
  })

  router.afterEach((to, from) => {
    if (process.client) {
      let blackListMap = [
        'factoryPage',
        'blog-landing',
        'blog-landing-detail'
      ]
      if (!blackListMap.includes(to.name)) {
        global.$store.commit('category/setCurrent', {})
      }
    }
  })

  return router
}
