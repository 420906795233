<template functional>
  <ul class="hierarchical-menu-list">
    <li class="hierarchical-menu-list-item" v-for="item in props.items" :key="item.value">
      <b-form-checkbox
        :class="'facet-category-' + item.value"
        :value="item.value"
        @change="props.refine(item.value)"
        class="ais-refinement-list--checkbox"
      >
        {{ item.label }}
      </b-form-checkbox>
      <hierarchical-menu-list
        v-if="item.data"
        :items="item.data"
        :refine="props.refine"
        :createURL="props.createURL"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HierarchicalMenuList'
}
</script>
