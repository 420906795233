export default `query getCustomerRma ($id: String){
  rmasExt (id: $id){
    items {
      id
      guest_id
      status
      status_name
      status_color
      created_at
      messages{
        message
        attachments{
          url
          name
        }
        date
        type
      }
      orders {
        order_id
        order_number_label
        order_number
        items {
          item_sku
          image_url
          item_name
          qty_requested
          item_order_price
          reason_id
          reason_name
          condition_id
          condition_name
          resolution_id
          resolution_name
        }
      }
    }
  }
}`
