<template>
  <svg class="lds-svg" width="200px"  height="200px"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;"><g transform="translate(20 50)">
    <circle cx="0" cy="0" r="6" :fill="fill" transform="scale(0.2467 0.2467)">
      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
    </circle>
    </g><g transform="translate(40 50)">
    <circle cx="0" cy="0" r="6" :fill="fill" transform="scale(0.0147424 0.0147424)">
      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
    </circle>
    </g><g transform="translate(60 50)">
    <circle cx="0" cy="0" r="6" :fill="fill" transform="scale(0.0977985 0.0977985)">
      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
    </circle>
    </g><g transform="translate(80 50)">
    <circle cx="0" cy="0" r="6" :fill="fill" transform="scale(0.401392 0.401392)">
      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
    </circle>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgLoader',
  props: {
    fill: {
      type: String,
      required: false,
      default: '#000'
    }
  }
}
</script>
