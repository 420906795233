export default `
  prices{
    grand_total{
      value
      currency
    }
  }
  items{id}
  email
  selected_payment_method{
    code
  }
  available_payment_methods{
    title
    code
  }
  billing_address{
    firstname
    lastname
    city
    country{
      code
      label
    }
    region{
      code
      region_id
    }
    street
    telephone
    postcode
    vat_id
  }
  shipping_addresses {
    firstname
    lastname
    city
    country{
      code
      label
    }
    region{
      code
      region_id
    }
    street
    telephone
    postcode
    selected_shipping_method{
      carrier_code
      method_code
    }
    available_shipping_methods {
      amount {
        currency
        value
      }
      method_description
      available
      carrier_code
      carrier_title
      method_code
      method_title
    }
  }`
