import '@babel/polyfill'
import 'theme/scss/_styles.scss'
import Vue from 'vue'
import App from 'theme/App.vue'
import i18n from './i18n'
import filters from './filters'
import './directives'
import { authCookie, tokenOrRedirect } from './middlewares/auth.middleware'
import { init as storeInit } from './middlewares/store.middleware'
import { init as redirectInit } from './middlewares/redirect.middleware' // replaced by app-word MAGENTO
import { init as uiInit } from 'theme/middlewares/ui.middleware'
import Config from 'config'
import Logger from '@/services/Logger'
import routesTail from 'theme/routesTail'

Vue.config.productionTip = true

if (process.env.NODE_ENV === 'production') {
  Vue.config.silent = true
  Vue.config.productionTip = false
}

Logger.debug('START SERVER.....')

export default ({ router, store }) => {
  const dd = new Date()
  Logger.debug(`${dd.toJSON()} - ****Enter MAIN***`)
  router.addRoutes(routesTail)
  global.$router = router
  // load filters
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })
  // Concat theme middleware
  let themeMiddlewareMethod = []
  let themeMiddleware = Config.Theme.middlewares
  for (let i in themeMiddleware) {
    themeMiddlewareMethod.push(themeMiddleware[i][i])
  }

  return new Vue({
    router,
    store,
    i18n: i18n(store),
    middlewares: [
      authCookie,
      tokenOrRedirect,
      redirectInit,
      storeInit,
      uiInit
    ].concat(themeMiddlewareMethod),
    render: h => h(App)
  })
}
