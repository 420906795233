<template>
  <div class="bg-zoomer">
    <div class="media-zoomer-mobile zoomer-container" ref="zoomerContainer">
      <div class="action-container">
        <div class="action-container__pagination"></div>
        <div class="zoomer-close">
          <button type="button" class="btn btn-icon btn-close" @click.prevent="$emit('close-zoomer', true)">
            <i class="icomoon-icon icon-x cl-black"></i>
          </button>
        </div>
      </div>
      <div v-swiper:zoomer="zommerOptions" class="zoommer-slider">
        <div class="swiper-wrapper">
          <div class="swiper-slide current-image" v-for="(image, index) in images" :key="index">
            <img :src="getThumbnail(image)" class="thumb-image current-image__src" />
          </div>
        </div>
        <div class="swiper-navigation">
          <div class="slide-next">
            <i class="icomoon-icon icon-chevron-right" />
          </div>
          <div class="slide-prev">
            <i class="icomoon-icon icon-chevron-left" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { optimizeImage } from '../../../helpers'
export default {
  name: 'ZoomerMobile',
  props: {
    images: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    activeindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      imageIndex: this.activeindex,
      activeImage: '',
      zommerOptions: {
        slidesPerView: 1,
        watchOverflow: true,
        pagination: {
          el: '.zoomer-container .action-container__pagination',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.zoomer-container .slide-next',
          prevEl: '.zoomer-container .slide-prev'
        },
        breakpoint: {
          320: {
            allowTouchMove: true
          },
          1025: {
            allowTouchMove: false
          }
        }
      }
    }
  },
  computed: {
    imgObj () {
      return {
        src: this.activeImage,
        error: require('@/assets/image/no-image.png'),
        loading: (this.size === 'LG' ? require('theme/assets/image/dump.png') : require('theme/assets/image/dump-square.png'))
      }
    },
    isMobile () {
      return this.$store.state.ui.isMobile
    }
  },
  methods: {
    inspectPhoto (index) {
      this.imageIndex = index
      this.zoomer.slideTo(index)
    },
    getThumbnail (image) {
      if (image.urlResize) return optimizeImage(image.urlResize)
      if (image.url) return optimizeImage(image.url)
      return ''
    }
  },
  mounted () {
    document.querySelector('html').classList.add('no-overflow')
    this.inspectPhoto(this.activeindex)
  },
  destroyed () {
    document.querySelector('html').classList.remove('no-overflow')
  }
}
</script>
