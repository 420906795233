export default store => {
  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'rma/setReturn':
        const rma = require('theme/store/lib/storage').rma
        rma.setItem('rma', state.rma.return)
        if (process.client) {
          localStorage.setItem('rma', JSON.stringify(state.rma.return))
        }
        break
    }
  })
}
