export default `
  query ProductsConfigurables($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      total_count
      items {
        ... on ConfigurableProduct {
          variants {
            product {
              id
              name
              sku
              stock_status
              quantity
              attribute_set_id
              image {
                label
                url
              }
              media_gallery {
                position
                label
                url
                ... on ProductVideo {
                  position
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
              }
              ... on PhysicalProductInterface {
                weight
              }
              special_from_date
              special_price
              special_to_date
              price_tiers {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value,
                    currency
                }
                quantity
              }
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  regular_price {
                    value
                    currency
                  }
                },
                maximum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  regular_price {
                    value
                    currency
                  }
                },
              },
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`
