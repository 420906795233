// import Config from '@/config'

// const attrArr = JSON.stringify(Config.Theme.attributesToload)

export default `query Products(
      $search: String
      $currentPage: Int
      $filter: ProductAttributeFilterInput
      $pageSize: Int
      $sort: ProductAttributeSortInput
    ) {
    products(
      search: $search
      currentPage: $currentPage
      filter: $filter
      pageSize: $pageSize
      sort: $sort ) {
      items {
        __typename
        id
        sku
        master_sku
        name
        color
        stock_status
        url_suffix
        url_key
        new_from_date
        new_to_date
        tax_rate
        first_category
        listing_label
        ... on GroupedProduct {
          configurableParent {
            ... on ConfigurableProduct {
              configurable_options {
                attribute_code
                attribute_id
                values {
                  label
                }
              }
              variants {
                product {
                  sku
                  color
                  is_backinstock
                  out_of_stock_threshold
                  quantity
                  stock_status
                }
              }
            }
          }
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          quantity
        }
        special_price
        special_from_date
        special_to_date
        media_gallery_entries {
          file
          types
        }
        media_gallery {
          position
          disabled
          label
          url
        }
      }
      total_count
      sort_fields{
        default
        options{
          label,
          value
        }
      }
    }
}`
