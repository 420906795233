<template>
  <button class="hamburger" type="button" @click.prevent="$emit('click', $event)">
    <div class="hamburger-inner">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'HamburgerButton'
}
</script>
