<template>
  <p>{{getStats}}</p>
</template>

<script>
export default {
  name: 'InstantSearchStats',
  props: {
    nbOfResultFound: {
      type: Number,
      required: true
    },
    nbOfPage: {
      type: Number,
      required: true
    },
    nbOfResutlsPerPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    getStats () {
      let showFrom = (this.nbOfResutlsPerPage * this.nbOfPage) + 1
      let showTo = this.nbOfResutlsPerPage * (this.nbOfPage + 1)
      if (showFrom === this.nbOfResultFound) {
        return `${showFrom} ${this.$t('algolia.stats.outOf')} ${this.nbOfResultFound} ${this.$t('algolia.stats.resultsFound')}`
      } else if (showTo > this.nbOfResultFound) {
        return `${showFrom}-${this.nbOfResultFound} ${this.$t('algolia.stats.outOf')} ${this.nbOfResultFound} ${this.$t('algolia.stats.resultsFound')}`
      }
      return `${showFrom}-${showTo} ${this.$t('algolia.stats.outOf')} ${this.nbOfResultFound} ${this.$t('algolia.stats.resultsFound')}`
    }
  }

}
</script>
