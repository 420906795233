/**
 * WishList Mutations
 */
import Config from 'config'

const attrArr = JSON.stringify(Config.Theme.wishlistAttributesToload)

export const saveWishlistItem = `
mutation SaveWishlistItem($wishlistItem: WishlistItemInput!, $sharingCode: ID) {
  saveWishlistItem(wishlistItem: $wishlistItem, sharingCode: $sharingCode)
}`

export const addGifCardToWishlist = `
  mutation AwGcAddGiftCardToWishList($awGcGiftCardOptionsInput: awGcGiftCardOptionsInput!) {
    awGcAddGiftCardToWishList(aw_giftcard_options: $awGcGiftCardOptionsInput)
  }`

export const removeWishlistItem = ` 
mutation RemoveProductFromWishlist($itemId: ID!, $sharingCode: ID) {
  removeProductFromWishlist(itemId: $itemId, sharingCode: $sharingCode)
}
`

export const moveAllToCart = ` 
mutation MoveWishlistToCart {
  moveWishlistToCart
}
`

export const updateProductsInWishlist = `
mutation UpdateProductsInWishlist($wishlistId: ID! $wishlistItems: [WishlistItemInput!]) {
  updateProductsInWishlist(wishlistId: $wishlistId wishlistItems: $wishlistItems) {
    wishlist {
      id
    }
  }
}`

export const shareWishlist = `
mutation ShareWishlist($share: ShareWishlistInput!) {
  shareWishlist(input: $share)
}
`

export const getWishlistBySharingCode = `
query GetWishlistBySharingCode($sharing_code: ID!) {
  wishlist(sharing_code: $sharing_code) {
    creators_name
    items_count
    name
    sharing_code
    updated_at
    items {
      added_at
      aw_giftcard_options {
        aw_gc_sender_name
      }
      description
      id
      is_in_stock
      qty
      product {
        sku
        color
        color_image
        color_label
        hidden_variants
        ... on ConfigurableProduct {
          name
          stock_status
          groupedVariation{
            attributesLabel (attributes_identifier: ${attrArr}) {
              label
              value
              attribute_id
              attribute_code
            }
            size_scale_code
            description {
              html
            }
            url_key
            url_suffix
            color
            color_image
            color_label
            name
            sku
            hidden_variants
            image {
              label
              url
            }
          }
          configurable_options {
            id,
            label
            attribute_code
            attribute_id
            position
            product_id
            values {
              value_index
              label
              default_label
              store_label
              use_default_value
              swatch_data{
                value
              }
            }
          }
          variants {
            product {
              is_backinstock
              id
              name
              sku
              stock_status
              quantity
              attribute_set_id
              color
              hidden_variants
              image {
                label
                url
              }
              special_from_date
              special_price
              special_to_date
              price_tiers {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value,
                    currency
                }
                quantity
              }
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  regular_price {
                    value
                    currency
                  }
                },
                maximum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                  regular_price {
                    value
                    currency
                  }
                }
              }
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
      }
      selectedOptions {
        option_id
        option_value
        label
        value
        attribute_code
        color_image
        swatch
        type
      }
    }
  }
}
`
