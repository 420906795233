export default class Meta {
  $store = {}

  constructor ($store) {
    this.$store = $store
  }

  getTitle (metaTitle) {
    let title = this.$store.state.cms.meta.title
    return title || this.$store.getters['storeConfig/metaTitle']
  }

  getDescription (metaDescription) {
    let description = this.$store.state.cms.meta.description
    return description || this.$store.getters['storeConfig/metaDescription']
  }

  getKeyword (metaKeyword) {
    return metaKeyword || this.$store.getters['storeConfig/metaKeyword']
  }

  getHrefLang () {
    return this.$store.state.ui.hrefLang
  }

  getRobots () {
    return this.$store.state.ui.metaRobots || process.env.VUE_APP_INDEX
    // return process.env.VUE_APP_INDEX
  }

  getJsonLD () {
    let jsonStruArr = []
    // markup site
    if (this.$store.state.storeConfig.config.seo_markup_website) {
      jsonStruArr.push(this.$store.state.storeConfig.config.seo_markup_website)
    }
    if (this.$store.state.storeConfig.config.seo_markup_seller &&
        this.$store.state.storeConfig.config.seo_markup_seller.show === 'all_pages' &&
        this.$store.state.storeConfig.config.seo_markup_seller.json) {
      jsonStruArr.push(this.$store.state.storeConfig.config.seo_markup_seller.json)
    }
    if (this.$store.state.ui.jsonLD && this.$store.state.ui.jsonLD.length) {
      jsonStruArr = jsonStruArr.concat(this.$store.state.ui.jsonLD)
    }
    return jsonStruArr
  }

  getCanonical () {
    return this.$store.state.ui.canonical
  }

  getBreadcrumb () {
    let breadcrumb = this.$store.state.ui.breadcrumb
    let lastBreadcrumb = {}
    breadcrumb.pop()
    lastBreadcrumb['active'] = true
    lastBreadcrumb['text'] = this.$store.state.category.current.name || ''
    // lastBreadcrumb['to'] = '/' + this.$store.state.ui.codeLang + '/' + this.$store.state.category.current.url_path
    lastBreadcrumb['to'] = `/${this.$store.state.ui.codeLang}/${this.$store.state.category.current.url_path ? this.$store.state.category.current.url_path : ''}`
    breadcrumb.push(lastBreadcrumb)
    return breadcrumb
  }
}
