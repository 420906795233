export default `query getCustomerRmas{
  rmasExt{
    items {
      id
      guest_id
      status
      status_name
      status_color
      created_at
      orders {
        order_id
        order_number
        items {
          image_url
          item_name
          qty_requested
        }
      }
    }
  }
}`
