import { render, staticRenderFns } from "./ClassicProduct.vue?vue&type=template&id=694b1024&"
import script from "./ClassicProduct.vue?vue&type=script&lang=js&"
export * from "./ClassicProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "ClassicProduct.vue"
export default component.exports