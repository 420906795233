<template>
  <div class="media-comp-wrapper">
    <template v-if="mediaVideo">
      <video-manager
      :src="mediaVideo.video_url"
      :videoPoster="mediaVideo.video_poster"
      :showAsBk="showAsBk"
      :key="mediaVideo.video_url"
      class="bcm-video" />
    </template>
    <template v-else>
      <img
        v-lazy="imgObj"
        :alt="alt"
        :title="alt"
        :key="imgObj.src"
        :style="styleImage"
        :class="{ 'background-loading': useBackgroundLoading }"
      />
    </template>
  </div>
</template>

<script>
import { getThumbnailPath } from '@/helpers'
import VideoManager from 'theme/components/Product/VideoManager'
import { optimizeImage } from 'theme/helpers'

export default {
  props: {
    imgPath: {
      type: String,
      default: ''
    },
    hasVideo: {
      type: Object,
      default () { return {} }
    },
    size: {
      type: String,
      default: ''
    },
    styleImage: {
      type: Object,
      default () { return {} }
    },
    alt: {
      type: String,
      default: 'Image'
    },
    showAsBk: {
      type: Boolean,
      default: true
    },
    changeAble: {
      type: Boolean,
      default: () => false
    },
    defaultLoading: {
      default: () => require('theme/assets/image/dump-square.png')
    },
    useBackgroundLoading: {
      type: Boolean,
      default: () => false
    },
    whitePlaceholder: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    imgObj () {
      let placeholder = ''
      if (!this.useBackgroundLoading) {
        placeholder = (this.size === 'LG'
          ? this.whitePlaceholder
            ? require('theme/assets/image/placeholder-white.png')
            : require('theme/assets/image/dump.png')
          : this.defaultLoading)
      }
      let imgSrc = getThumbnailPath(this.imgPath, this.size)
      if (imgSrc && imgSrc.includes('placeholder') && this.whitePlaceholder) {
        imgSrc = imgSrc.replace('placeholder', 'placeholder-white')
      }
      let imageOject = {
        src: optimizeImage(imgSrc),
        error: placeholder,
        loading: placeholder
      }
      return imageOject
    },
    src () {
      return this.changeAble ? this.imgObj.src : this.imgObj.loading
    },
    mediaVideo () {
      // check if currentImage is video or not
      if (Object.keys(this.hasVideo).length > 0) {
        return this.hasVideo
      } else {
        return false
      }
    }
  },
  components: {
    VideoManager
  }
}
</script>
