import { EventBus, randomInRange } from '@/helpers'

export function init () {
  if (process.client) {
    let columnsImage = {}
    EventBus.$on('cms:update', () => {
      let $griddedRow = $('.gridded-row').not('.processed')
      if ($griddedRow.length) {
        $griddedRow.addClass('processed')
        let $columns = $griddedRow.find('.pagebuilder-column')
        let currentImageIndex = 1
        $columns.each((index, el) => {
          console.log(el.dataset.backgroundImages)
          if (el.dataset.backgroundImages !== '{}') {
            el.dataset.indexImg = currentImageIndex
            $(el).append(`<span class="numbered-column">${el.dataset.indexImg}</span>`)
            $(el).on('click', function (event) {
              openGriddedRowPopup(event, { parentRow: $griddedRow, columnsImage, currentIndex: parseInt(el.dataset.indexImg - 1) })
            })
            currentImageIndex += 1
          }
        })
      }
    })
  }
}

function openGriddedRowPopup (event, payload) {
  let imageToShow = []
  if (!payload.parentRow.hasClass('slide-processed')) {
    let randomRange = randomInRange()
    payload.parentRow.attr('data-processed-id', randomRange)
    payload.parentRow.addClass('slide-processed')
    imageToShow = columnsImageManager(payload.columnsImage, randomRange, payload.parentRow)
  } else {
    // already processed
    // get id from dom
    // get image from map by id
    // if image doesn't exist reload it by $parentRow
    imageToShow = columnsImageManager(payload.columnsImage, payload.parentRow.attr('data-processed-id'), payload.parentRow)
  }
  // load slider here
  openSliderWithImage({ posts: imageToShow, currentIndex: payload.currentIndex })
}

function openSliderWithImage ({ posts, currentIndex }) {
  let categoryTitle = global.$store.state.blog.category.title
  posts = posts.map((item, index) => {
    return {
      post_id: index,
      title: item.title || categoryTitle,
      first_image: item,
      short_content: `${global.$i18n.t('posts-slide.look')} ${index + 1} / ${posts.length}`
    }
  })
  let props = {
    componentClass: 'gridded-slide-post',
    posts,
    showSlide: true,
    canLoadMore: false,
    totalPosts: posts.length,
    parentLoadMoreCallback: '',
    currentIndex
  }
  global.$store.commit('ui/setPostsSlide', props)
}

function columnsImageManager (columnsImage, randomRange, $parentRow) {
  if (randomRange) {
    if (columnsImage[randomRange]) {
      // images existing
      return columnsImage[randomRange]
    } else {
      columnsImage[randomRange] = getImageByColumn($parentRow)
      return columnsImage[randomRange]
    }
  } else {
    let randomRange = randomInRange()
    columnsImage[randomRange] = getImageByColumn($parentRow)
    return columnsImage[randomRange]
  }
}

function getImageByColumn ($parentRow) {
  let $columns = $parentRow.find('.img-fluid.invisible')
  let columnsImage = []
  $columns.each((index, el) => {
    columnsImage.push($(el).attr('src'))
  })
  return columnsImage
}
