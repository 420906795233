export default `query getAlgoliaCategory {
  getAlgoliaCategory {
    category {
      category_id
      category_url_path
      category_name
    }
    suggested {
      category_id
      category_url_path
      category_name
    }
  }
}`
