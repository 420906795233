import Graphql from '@/services/Graphql'
import each from 'lodash/each'
import indexOf from 'lodash/indexOf'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
// import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import { minMaxFromPriceArr } from '@/helpers/utils'
// import Logger from '@/services/Logger'
import Config from 'config'
import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'

const removeFromFilter = ['category_id', 'price']
const sortFilters = ['microtype', 'size_filter', 'color_filter']

export function getProductColor (product, defaultColor) {
  // some products
  // like perfumes has no color setted.
  // in this cases get first color available by its configurable parent
  if (defaultColor) {
    return defaultColor
  } else {
    let parentProduct = product.configurableParent
    let parentVariants = parentProduct.variants
    if (parentVariants && parentVariants.length) {
      return parentVariants[0].product.color
    } else {
      return false
    }
  }
}

export function loadJustProducts (ctx, { query, searchGraph, categoryId }) {
  return Graphql.genericCallCash({
    token: ctx.rootState.user.token, // token
    storeViewCode: ctx.rootState.ui.storeViewCode, // storeViewCode
    useInlineQuery: query,
    variables: searchGraph, // variable
    useCache: true
  })
    .then(async res => {
      console.log(res)
      let listing = await listingProductManager(ctx, { res, categoryId, page: searchGraph.currentPage })
      if (listing && listing.category) {
        ctx.commit('category/setMetaData', listing.category, { root: true })
      }
      return listing
    })
}

export function prepareListingVariables (ctx, { categoryId, query }) {
  let searchGraph = {
    filter: {},
    currentPage: Math.ceil(ctx.state.pagination.start / ctx.state.pagination.size) + 1,
    pageSize: ctx.state.pagination.size
  }

  if (categoryId) {
    searchGraph.filter.category_id = {
      'in': ['' + categoryId] // .concat(currentCat.childrenAllIds.split(','))
    }
    searchGraph.id = parseInt(categoryId)
  } else {
    if (query !== undefined) {
      searchGraph.search = query
    }
  }

  // sort order
  const [sort, dir] = ctx.state.sort.split('_')
  searchGraph.sort = {
    [sort]: (dir && dir === 'desc' ? 'DESC' : 'ASC')
  }
  each(ctx.state.filters.chosen, (value, key) => {
    if (value && value.length) {
      if (key === 'price') {
        let priceMinMax = minMaxFromPriceArr(value)
        priceMinMax.from = '' + priceMinMax.from
        priceMinMax.to = '' + priceMinMax.to
        searchGraph.filter[key] = priceMinMax
      } else {
        searchGraph.filter[key] = { 'in': value }
      }
    }
  })
  return searchGraph
}

export async function listingProductManager (ctx, { res, categoryId, page }) {
  // const isViewAll = res.data.category && res.data.category.bc_cat_type_name === 'view-all'
  let listing = res.data // isViewAll ? { products: res.data.ProductsByCategory } : res.data
  setDisabledFilter(ctx, categoryId)
  // if (isViewAll) {
  //   if (listing.products) {
  //     let filters = manageFilters(listing.products.aggregationsStore, ctx)
  //     ctx.commit('setAllCategoryFilters', filters)
  //     ctx.commit('setAvailableFilters', { filters })
  //   }
  // }
  if (listing.products) {
    ctx.commit('setPagination', { ...ctx.state.pagination, total: listing.products.total_count })
  }
  let hasListingEntity = listingEntity(listing)
  // let listingContainProduct = listingHasProduct(hasListingEntity, listing)
  // if (res.errors && !listingContainProduct) {
  //   // avoid to reset listing if product is loaded
  //   Logger.error('listing call error: ', res)
  //   ctx.dispatch('resetListing')
  //   return false
  // }
  // find for banners and products Banners
  // this attributes are setted inside current category
  // if find it save on store -> state.category.current -> heroBanner | heroProduct
  setListingHeroes(ctx, categoryId)
  // create flatted categories
  if (hasListingEntity) {
    // filter for valid items
    let items = []
    each(listing.products.items, (item, index) => {
      if (item) {
        // set item position using $page
        // using range of 100 elements to includes block of product into different range
        // page 1 -> 100 to 199
        // page 2 -> 200 to 299
        // so on
        // item.position = page * 100 + (index + 1)
        item.configurableSku = item.master_sku
        if (item.color && item.configurableParent && item.configurableParent.configurable_options) {
          let configurableOptions = item.configurableParent.configurable_options
          // find attribute color
          const colorAttr = find(configurableOptions, { 'attribute_code': 'color' })
          item.presetFilter = {
            [colorAttr.attribute_id]: item.color
          }
          // COME DA RICHIESTA 07/02/2022
          // request from TKT MOS-174:
          // la label “Coming soon” deve comparire se tutti i semplici hanno il preorder attivo (un prodotto che non ha stock è in preorder se ha una salable_quantity -> ADP form skype),
          // nel caso anche solo un simple non ha più il preorder attivo la label deve essere spenta.
          // solution -> Coming soon is respected if all variant has out_of_stock_threshold and quantity <= 0. that's why search for his inverse.
          // let filteredVariant = filter(item.configurableParent.variants, function (variant) {
          //   return variant.product.color === item.color
          // })
          // todo add attribute backorder before filter
          // if false, no label needed
          // let isComingSoon = filter(filteredVariant, function (variant) {
          //   return variant.product.quantity > 0 && (Math.abs(variant.product.out_of_stock_threshold) - variant.product.quantity) >= 0
          // })
          // item.coming_soon = isComingSoon.length === filteredVariant.length
          item.coming_soon = 0
        }
        item.media_gallery = sortBy(item.media_gallery, ['position'])
        items.push(item)
      }
    })
    let uniqItemBySku = uniqBy(listing.products.items, 'sku')
    // let uniqItemBySku = uniqBy(uniqItemByName, 'name')

    // uniqItemBySku = items
    if (listing.products.sort_fields && listing.products.sort_fields.options) {
      ctx.commit('setSortable', customSort(listing.products.sort_fields.options))
    }
    // let uniqItemByName = uniqBy(listing.products.items, 'sku')
    // let uniqItemBySku = uniqBy(uniqItemByName, 'name')
    // these will be sent to listing/mutation.js
    // await ctx.commit('setTemporaryProducts', { products: listing.products.items, concat: (ctx.state.pagination.start !== 0) })
    await ctx.commit('setTemporaryProducts', { products: uniqItemBySku, concat: (ctx.state.pagination.start !== 0) })
  }
  return listing
}

export function listingEntity (listing) {
  if (listing.products && listing.products.items) {
    return true
  } else {
    return false
  }
}

export function listingHasProduct (hasListingEntity, listing) {
  if (hasListingEntity && listing.products.items.length) {
    return true
  } else {
    return false
  }
}

export function customSort (sort) {
  let newSort = []
  each(sort, item => {
    if (item.value !== 'position') {
      // desc
      newSort.push({
        label: item.value + '_desc', // global.$i18n && global.$i18n.t(item.value + '_desc'),
        value: item.value + '_desc'
      })
      // add asc
      newSort.push({
        label: item.value + '_asc', // global.$i18n && global.$i18n.t(item.value + '_asc'),
        value: item.value
      })
    }
  })
  return newSort
}

export function manageFilters (filters, ctx) {
  let disabledFilters = ctx.state.filters.disabled && !Array.isArray(ctx.state.filters.disabled) ? ctx.state.filters.disabled.split(',') : []
  disabledFilters = disabledFilters.concat(removeFromFilter)
  filters = filters.filter(agg => {
    let useFitler = !disabledFilters.includes(agg.attribute_code)
    if (useFitler) {
      // clean label empty
      let sortIndex = indexOf(sortFilters, agg.attribute_code)
      if (sortIndex !== -1) {
        agg['sortPosition'] = sortIndex
      }
      agg.options = agg.options.filter(opt => opt.label)
      if (agg.options.length === 0) {
        return false
      }
    }
    return useFitler
  })

  return sortBy(filters, ['sortPosition'])
}

// Find disabled_filter from category
// ad store it as disable filter
export function setDisabledFilter (store, currentCatID) {
  if (currentCatID) {
    let searchCat = store.rootState.category.flatted.find(flat => flat.id === parseInt(currentCatID))
    if (searchCat) {
      const disabledFiltersOnListing = searchCat.custom_attributes.find(attribute => attribute.attribute_code === 'disabled_filter')
      if (disabledFiltersOnListing) {
        store.commit('setDisabledFilters', disabledFiltersOnListing.value)
      } else {
        store.commit('setDisabledFilters', [])
      }
    }
  }
}

export function setListingHeroes (store, currentCatID) {
  let searchCat = null
  if (currentCatID) {
    searchCat = store.rootState.category.flatted.find(flat => flat.id === parseInt(currentCatID))
    if (searchCat) {
      const customAttributeBannerHero = searchCat.custom_attributes.find(attribute => attribute.attribute_code === 'banner_hero')
      const customAttributeProductHero = searchCat.custom_attributes.find(attribute => attribute.attribute_code === 'product_hero')
      if (searchCat.custom_attributes.length > 0 && customAttributeBannerHero && customAttributeBannerHero.value) {
        searchCat['heroBanners'] = JSON.parse(customAttributeBannerHero.value).map(heroBanner => ({
          block_identifier: heroBanner.content,
          row_start: heroBanner.row_from,
          row_end: heroBanner.row_to,
          column_start: heroBanner.collumn_from,
          column_end: heroBanner.collumn_to,
          number_of_mobile_product: heroBanner.number_of_mobile_product
        }))
        store.dispatch('cms/loadContents', searchCat.heroBanners.map(heroBanner => heroBanner.block_identifier), { root: true })
      }
      if (searchCat.custom_attributes.length > 0 && customAttributeProductHero && customAttributeProductHero.value) {
        searchCat['heroProducts'] = JSON.parse(customAttributeProductHero.value).map(heroProduct => ({
          grouped_sku: heroProduct.sku,
          row_start: heroProduct.row_from,
          row_end: heroProduct.row_to,
          column_start: heroProduct.collumn_from,
          column_end: heroProduct.collumn_to
          // number_of_mobile_product: heroProduct.number_of_mobile_product
        }))
      }
      return searchCat.id
    }
  }
  return searchCat
}

// LISTING.VUE component
// method used on it (LISTING.VUE)
// cloned by src/pages/listing to keep the code clean
// on 10.01.21 moved this methods here to avoid mixins by listing (core) and listing theme
// in this way the theme component is fully responsible of listing flow
export function getCurrentCategory (store, route, returnJustId = false) {
  if (store.state.category.categoryId) {
    let currentCat = find(store.state.category.flatted, { id: parseInt(store.state.category.categoryId) })
    if (returnJustId && currentCat) {
      return currentCat.id
    } else {
      return currentCat
    }
  }
  return undefined
}

export function filterProducts (store, route, categoryId) {
  store.commit('ui/showLoader')
  return store.dispatch('listing/filterProducts', {
    categoryId: categoryId,
    q: route.meta.search ? route.query.q : undefined,
    router: route
  })
    .catch(() => {
      store.commit('ui/hideLoader')
    })
    .finally(() => {
      store.commit('category/setCurrent', getCurrentCategory(store, route))
      // ** reset load more anyway
      store.commit('listing/setLoadMore', false)
      store.commit('ui/hideLoader')
    })
}

/**
 * sync sort
 */
export function syncSort (store, route) {
  if (!isUndefined(route.query.sort)) {
    store.commit('listing/setSort', route.query.sort)
  } else {
    store.commit('listing/setSort', Config.Theme.sort)
  }
  if (!isUndefined(route.query.dir)) {
    store.commit('listing/setSortOrientation', route.query.dir)
  } else {
    store.commit('listing/setSortOrientation', Config.Theme.sortOrientation)
  }
}

/**
 * sync start and size page with url
 */
export function syncPagination (store, route) {
  let pagination = {
    start: 0,
    size: Config.Theme.paginationSize,
    page: 1
  }
  if (!isUndefined(route.query.start)) {
    pagination.start = parseInt(route.query.start)
  }
  if (!isUndefined(route.query.size)) {
    pagination.size = parseInt(route.query.size)
  }
  if (!isUndefined(route.query.page)) {
    pagination.page = parseInt(route.query.page)
  }
  if (!isEmpty(pagination)) {
    store.commit('listing/setPagination', { ...pagination })
  }
}

/**
 * sync grid system
 */
export function syncGridSystem (store, route) {
  if (!isUndefined(route.query.grid)) {
    store.commit('listing/setColumns', parseInt(route.query.grid))
  } else {
    store.commit('listing/setColumns', Config.Theme.gridCol)
  }
}

/**
 * sync filters from url
 */
export function syncFilters (store, route) {
  if (!isUndefined(route.query.filters)) {
    try {
      const queryFilter = JSON.parse(route.query.filters)
      store.commit('listing/setFiltersChosen', queryFilter)
    } catch (err) {
      console.error('error parse filter from URL', err)
    }
  } else {
    store.commit('listing/setFiltersChosen', {})
  }
}

export function ManageAndSortAggregrateSizeFilter (filters) {
  try {
    if (filters instanceof Array) {
      let sizeToSort = []
      let newFilters = cloneDeep(filters)
      let sortingMap = Config.sizes
      let newSort = []
      for (let i = 0; i < newFilters.length; i++) {
        if (sortingMap.includes(newFilters[i].html)) {
          sizeToSort.push(newFilters[i])
          filters[i].html = filters[i].html.concat('nosort')
        }
      }
      let orderedSizes = sizeToSort.sort((a, b) => sortingMap.indexOf(a.html) - sortingMap.indexOf(b.html))
      filters.concat(orderedSizes).forEach((option) => {
        if (!option.html.includes('nosort')) {
          newSort.push(option)
        }
      })
      return newSort
    } else {
      return filters
    }
  } catch (err) {
    return filters
  }
}
