export function state () {
  return {
    // right navigation panel
    rightActiveComponent: {},
    rightNavHistory: [],
    showRightLoader: false,
    // left navigation panel
    leftActiveComponent: {},
    leftNavHistory: [],
    showLeftLoader: false,
    isShowedLeftNav: false,
    // top navigation panel
    topActiveComponent: {},
    topNavHistory: [],
    showTopLoader: false,
    // submenu navigation panel
    subMenuActiveComponent: {},
    dynamicModalComponent: {},
    // postsSlide
    postsSlide: {
      currentIndex: 0,
      totalPosts: 0,
      parentLoadMoreCallback: ''
    },
    subMenuHistory: [],
    showSubMenuLoader: false,
    bodyClass: null,
    isHero: false,
    headerHeight: 0,
    plainLoader: false,
    algoliaCategories: {}
  }
}
