export default `query vault {
    customerPaymentTokens {
        items {
            public_hash
            payment_method_code
            type
            details
        }
    }
}`
