// ACCORDION FOR MAGENTO CC PAGEBUILDER
// to use this feature set a proper pagebuilder column and add to it the class 'advanced-accordion'
// then add a button inside the column (which will be the title of the accordion) and add your content below, still inside that column
// USE CLASS .accordion-links to use this feature
import { EventBus } from '@/helpers'

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      const $anchor = $('.advanced-accordion:visible')
      if ($anchor.length > 0) {
      // start replacing
        $anchor.each((index, el) => {
          let $currentEl = $(el)
          let additionalClasses = ''
          if (!$currentEl.parent().hasClass('bcm-accordion')) {
            $currentEl.attr('style', '')
            $currentEl.wrap(`<div class="bcm-accordion bcm-advanced-accordion acc-${index} ${additionalClasses}" />`)
            $currentEl.find('[data-content-type="button-item"]').first().addClass('accordion-title').prependTo($currentEl.parent())
            if ($currentEl.children().length) {
              $currentEl.parent().addClass('has-child')
            }

            // passing true param for firstClick
            $currentEl.trigger('click', [ true ])
          }
        })
      }
    })
    $(document).on('click', '.bcm-accordion.bcm-advanced-accordion', function (_, firstClick) {
      let $elem = $(this)
      if ($elem.hasClass('has-child')) {
        !firstClick && $elem.toggleClass('accordion-opened')
        $elem.find('.advanced-accordion').slideToggle()
      }
    })
  }
}
