// import Config from '@/config'

// const attrArr = JSON.stringify(Config.Theme.attributesToload)

export default `query Products($id: Int) {
  category (id: $id) {
    name
    display_mode
    cms_block{
      title
      identifier
    }
    cms_block_lower
    bcame_cms_block_lower {
      title
      identifier
    }
    breadcrumbs{
      category_id
      category_url_path
      category_url_key
      category_level
      category_id
      category_name
    }
    canonical_url
    custom_canonical_link
    href_lang_links{href, hreflang}
    meta_description
    meta_title
    meta_keywords
    meta_robots
    head_content_page
  }
}`
