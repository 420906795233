<template>
  <svg class="svg-sprites" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="arrow-down" viewBox="0 0 10 6">
      <title>arrow-down</title>
      <path d="M1 1l4 4 4-4" fill-rule="evenodd"></path>
    </symbol>
    <symbol id="arrow-left" viewBox="0 0 9 16">
      <title>arrow-right</title>
      <path d="M7.774 1L1.278 8l6.496 7" fill-rule="evenodd"></path>
    </symbol>
    <symbol id="arrow-right" viewBox="0 0 9 16">
      <path d="M1.226 1l6.496 7-6.496 7"></path>
    </symbol>
    <symbol id="arrow-up" viewBox="0 0 10 6">
      <title>arrow-down</title>
      <path d="M1 5l4-4 4 4" fill-rule="evenodd"></path>
    </symbol>
    <symbol id="bag" viewBox="0 0 40 40">
      <path id="bag"
        d="M30.2 34.2H9.8V14.4h4v3h1.7v-3h9v3h1.7v-3h4v19.8zM15.5 10.1c0-2.4 2-4.4 4.5-4.4s4.5 2 4.5 4.4v2.6h-9v-2.6zm10.7 2.6v-2.6c0-3.3-2.8-6-6.2-6-3.4 0-6.2 2.7-6.2 6v2.6H8.1v23.2H32V12.7h-5.8z">
      </path>
    </symbol>
    <symbol id="close" viewBox="0 0 16 15">
      <title>close</title>
      <path d="M1.99 1.49l12.103 12.103m0-12.103L1.99 13.593" fill-rule="evenodd" stroke-linecap="square"></path>
    </symbol>
    <symbol id="direction" viewBox="0 0 15 13">
      <g fill="#999" fill-rule="evenodd">
        <path d="M10.111 0l4.89 4.638-4.89 4.637-.688-.724 4.123-3.913L9.423.725z"></path>
        <path d="M14.069 4.138v1H1.222v6.891h-1V4.14z"></path>
      </g>
    </symbol>
    <symbol id="user" viewBox="0 0 386.578 410.079">
      <g id="Raggruppa_1" data-name="Raggruppa 1" transform="translate(-661.871 -357)">
        <g id="Head" transform="translate(754 357)" fill="#fff" stroke="#333" stroke-width="5">
          <circle cx="103" cy="103" r="103" stroke="none"/>
          <circle cx="103" cy="103" r="100.5" fill="none"/>
        </g>
        <path id="Body" d="M675.371,766.079V595.206H1059.95V766.079" transform="translate(-11)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="5"/>
      </g>
    </symbol>
    <symbol id="facebook" viewBox="0 0 40 40">
      <path id="facebook"
        d="M28.897 1.782l-4.28-.007c-4.808 0-7.916 3.478-7.916 8.86v4.086h-4.303c-.37 0-.672.327-.672.733v5.92c0 .404.301.733.672.733H16.7v14.935c0 .404.302.733.674.733h5.612c.373 0 .674-.329.674-.733V22.107h5.03c.373 0 .673-.33.673-.733l.004-5.92a.777.777 0 0 0-.198-.52.645.645 0 0 0-.476-.213H23.66v-3.464c0-1.664.364-2.509 2.35-2.509l2.884-.002c.372 0 .672-.329.672-.733V2.518c0-.407-.3-.734-.67-.736">
      </path>
    </symbol>
    <symbol id="hearth" viewBox="0 0 20 17">
      <path fill="#393939" fill-rule="evenodd"
        d="M16.813 8.792l-6.819 6.793L3.19 8.78a3.929 3.929 0 0 1-1.16-2.797A3.91 3.91 0 0 1 3.187 3.19a3.923 3.923 0 0 1 2.79-1.156c1.056 0 2.052.412 2.8 1.16l.862.863a.513.513 0 0 0 .73 0l.854-.855a3.935 3.935 0 0 1 2.797-1.16c1.053 0 2.046.412 2.793 1.156a3.926 3.926 0 0 1 1.157 2.797 3.91 3.91 0 0 1-1.157 2.797m.733-6.323a4.94 4.94 0 0 0-3.522-1.458c-1.332 0-2.583.52-3.526 1.462l-.492.492-.5-.5A4.966 4.966 0 0 0 5.976 1c-1.328 0-2.58.519-3.518 1.458A4.935 4.935 0 0 0 1 5.984a4.95 4.95 0 0 0 1.465 3.522l7.167 7.166c.099.1.233.153.362.153.13 0 .264-.05.363-.149l7.181-7.155A4.953 4.953 0 0 0 19 5.995a4.93 4.93 0 0 0-1.454-3.526"></path>
    </symbol>
    <symbol id="instagram" viewBox="0 0 40 40">
      <path
        d="M28.273 2H11.727C6.364 2 2 6.364 2 11.727v16.546C2 33.636 6.364 38 11.727 38h16.546C33.636 38 38 33.636 38 28.273V11.727C38 6.364 33.636 2 28.273 2zm6.819 26.273a6.826 6.826 0 0 1-6.82 6.819H11.728a6.826 6.826 0 0 1-6.819-6.82V11.728a6.826 6.826 0 0 1 6.82-6.819h16.545a6.826 6.826 0 0 1 6.819 6.82v16.545z">
      </path>
      <path
        d="M25.78 14.22a8.017 8.017 0 0 0-4.524-2.277 8.066 8.066 0 0 0-2.369 0 8.02 8.02 0 0 0-5.3 3.176 8.02 8.02 0 0 0-1.5 5.994 8.02 8.02 0 0 0 3.175 5.3 8.012 8.012 0 0 0 5.994 1.5 8.02 8.02 0 0 0 5.3-3.176 8.02 8.02 0 0 0 1.5-5.993 8.016 8.016 0 0 0-2.276-4.524zm-4.951 10.817a5.128 5.128 0 0 1-3.834-.96 5.13 5.13 0 0 1-2.032-3.391 5.13 5.13 0 0 1 .96-3.834 5.13 5.13 0 0 1 3.391-2.032 5.158 5.158 0 0 1 1.515 0 5.135 5.135 0 0 1 4.35 4.351 5.17 5.17 0 0 1-4.35 5.866zM29.1 9.446c-.382 0-.757.155-1.028.426-.271.27-.426.645-.426 1.028s.155.757.426 1.028c.27.27.646.426 1.028.426s.758-.155 1.028-.426c.271-.27.426-.646.426-1.028 0-.383-.155-.758-.426-1.028a1.464 1.464 0 0 0-1.028-.426z">
      </path>
    </symbol>
    <symbol id="pinterest" viewBox="0 0 40 40">
      <path id="pinterest"
        d="M16.948 25.81c-.944 4.956-2.094 9.708-5.507 12.19-1.052-7.492 1.547-13.117 2.755-19.09-2.059-3.472.248-10.462 4.588-8.74 5.342 2.119-4.626 12.911 2.066 14.261 6.988 1.406 9.84-12.151 5.507-16.56-6.259-6.367-18.222-.146-16.75 8.97.357 2.227 2.653 2.904.917 5.98-4.007-.89-5.202-4.058-5.05-8.28.25-6.91 6.197-11.75 12.163-12.42 7.545-.847 14.627 2.776 15.604 9.89 1.101 8.03-3.404 16.725-11.474 16.1-2.187-.172-3.104-1.257-4.819-2.301">
      </path>
    </symbol>
    <symbol id="play" viewBox="0 0 8 10">
      <path fill="#fff"
        d="M.614.056A.41.41 0 0 0 0 .41v8.82a.41.41 0 0 0 .614.355l7.181-4.41a.41.41 0 0 0 0-.71L.615.056z"
        fill-rule="evenodd"></path>
    </symbol>
    <symbol id="search" viewBox="0 0 40 40">
      <path transform="rotate(-44.193 32.099 32.208)" d="M31.3 23.5h1.5v17.3h-1.5z"></path>
      <path
        d="M15.8 31C7.5 31 .7 24.2.7 15.9S7.5.8 15.8.8 31 7.6 31 15.9 24.2 31 15.8 31zm0-28.7C8.3 2.3 2.2 8.4 2.2 15.9s6.1 13.6 13.6 13.6 13.6-6.1 13.6-13.6c.1-7.5-6-13.6-13.6-13.6z">
      </path>
    </symbol>
    <symbol id="twitter" viewBox="0 0 40 40">
      <path id="twitter"
        d="M34.65 8.258c1.653-1.039 2.914-2.685 3.52-4.648a15.46 15.46 0 0 1-5.087 2.036C31.63 4.023 29.549 3 27.253 3c-4.41 0-7.99 3.759-7.99 8.39 0 .662.073 1.298.204 1.911-6.648-.351-12.532-3.685-16.48-8.767a8.65 8.65 0 0 0-1.084 4.222c0 2.909 1.416 5.482 3.556 6.978-1.309-.036-2.533-.422-3.613-1.049v.102c0 4.07 2.757 7.464 6.407 8.24a7.878 7.878 0 0 1-3.613.138c1.023 3.333 3.97 5.753 7.467 5.83-2.737 2.246-6.185 3.582-9.93 3.582-.642 0-1.272-.038-1.902-.111 3.543 2.384 7.74 3.768 12.258 3.768 14.7 0 22.736-12.785 22.736-23.87l-.022-1.086a16.61 16.61 0 0 0 3.994-4.347 15.385 15.385 0 0 1-4.59 1.327z">
      </path>
    </symbol>
    <symbol id="youtube" viewBox="0 0 40 40">
      <path id="youtube"
        d="M15 26V13.766L26.748 19.9 15 26m22.607-14.487c-.452-1.969-2.06-3.42-3.995-3.636-4.582-.513-9.22-.515-13.838-.513-4.618-.002-9.257 0-13.84.513-1.933.216-3.54 1.667-3.992 3.636-.644 2.8-.651 5.86-.651 8.744s0 5.944.644 8.745c.45 1.967 2.058 3.42 3.994 3.636 4.582.511 9.22.515 13.838.511 4.62.004 9.257 0 13.838-.51 1.935-.217 3.542-1.67 3.995-3.637.644-2.8.646-5.86.646-8.745s.006-5.943-.639-8.744">
      </path>
    </symbol>
    <symbol id="calendar" version="1.1" x="0px" y="0px" viewBox="0 0 48 60" enable-background="new 0 0 48 48"
      xml:space="preserve">
      <g>
        <path fill="#000000" d="M47.5,47h-47V4.1h47V47z M2.5,45h43V6.1h-43V45z" />
        <rect x="23" y="24.3" fill="#000000" width="2" height="2" />
        <rect x="28.7" y="24.3" fill="#000000" width="2" height="2" />
        <rect x="34.4" y="24.3" fill="#000000" width="2" height="2" />
        <rect x="40.1" y="24.3" fill="#000000" width="2" height="2" />
        <rect x="5.9" y="30.7" fill="#000000" width="2" height="2" />
        <rect x="11.6" y="30.7" fill="#000000" width="2" height="2" />
        <rect x="17.3" y="30.7" fill="#000000" width="2" height="2" />
        <rect x="23" y="30.7" fill="#000000" width="2" height="2" />
        <rect x="28.7" y="30.7" fill="#000000" width="2" height="2" />
        <rect x="34.4" y="30.7" fill="#000000" width="2" height="2" />
        <rect x="40.1" y="30.7" fill="#000000" width="2" height="2" />
        <rect x="5.9" y="37.1" fill="#000000" width="2" height="2" />
        <rect x="11.6" y="37.1" fill="#000000" width="2" height="2" />
        <rect x="17.3" y="37.1" fill="#000000" width="2" height="2" />
        <rect x="23" y="37.1" fill="#000000" width="2" height="2" />
        <rect x="28.7" y="37.1" fill="#000000" width="2" height="2" />
        <rect x="34.3" y="1" fill="#000000" width="2" height="8.2" />
        <rect x="23" y="1" fill="#000000" width="2" height="8.2" />
        <rect x="11.7" y="1" fill="#000000" width="2" height="8.2" />
        <rect x="5" y="16.9" fill="#000000" width="38" height="2" />
      </g>
    </symbol>
    <symbol id="pdf" viewBox="0 0 512 512">
      <path d="M494.479,138.557L364.04,3.018C362.183,1.09,359.621,0,356.945,0h-194.41c-21.757,0-39.458,17.694-39.458,39.442v137.789
        H44.29c-16.278,0-29.521,13.239-29.521,29.513v147.744C14.769,370.761,28.012,384,44.29,384h78.787v88.627
        c0,21.71,17.701,39.373,39.458,39.373h295.238c21.757,0,39.458-17.653,39.458-39.351V145.385
        C497.231,142.839,496.244,140.392,494.479,138.557z M359.385,26.581l107.079,111.265H359.385V26.581z M44.29,364.308
        c-5.42,0-9.828-4.405-9.828-9.82V206.744c0-5.415,4.409-9.821,9.828-9.821h265.882c5.42,0,9.828,4.406,9.828,9.821v147.744
        c0,5.415-4.409,9.82-9.828,9.82H44.29z M477.538,472.649c0,10.84-8.867,19.659-19.766,19.659H162.535
        c-10.899,0-19.766-8.828-19.766-19.68V384h167.403c16.278,0,29.521-13.239,29.521-29.512V206.744
        c0-16.274-13.243-29.513-29.521-29.513H142.769V39.442c0-10.891,8.867-19.75,19.766-19.75h177.157v128
        c0,5.438,4.409,9.846,9.846,9.846h128V472.649z" />
      <path
        d="M132.481,249.894c-3.269-4.25-7.327-7.01-12.173-8.279c-3.154-0.846-9.923-1.269-20.308-1.269H72.596v84.577h17.077
        v-31.904h11.135c7.731,0,13.635-0.404,17.712-1.212c3-0.654,5.952-1.99,8.856-4.01c2.904-2.019,5.298-4.798,7.183-8.336
        c1.885-3.538,2.827-7.904,2.827-13.096C137.385,259.634,135.75,254.144,132.481,249.894z M117.856,273.173
        c-1.288,1.885-3.067,3.269-5.337,4.154s-6.769,1.327-13.5,1.327h-9.346v-24h8.25c6.154,0,10.25,0.192,12.288,0.577
        c2.769,0.5,5.058,1.75,6.865,3.75c1.808,2,2.712,4.539,2.712,7.615C119.789,269.096,119.144,271.288,117.856,273.173z" />
      <path d="M219.481,263.452c-1.846-5.404-4.539-9.971-8.077-13.702s-7.789-6.327-12.75-7.789c-3.692-1.077-9.058-1.615-16.096-1.615
        h-31.212v84.577h32.135c6.308,0,11.346-0.596,15.115-1.789c5.039-1.615,9.039-3.865,12-6.75c3.923-3.808,6.942-8.788,9.058-14.942
        c1.731-5.039,2.596-11.039,2.596-18C222.25,275.519,221.327,268.856,219.481,263.452z M202.865,298.183
        c-1.154,3.789-2.644,6.51-4.471,8.163c-1.827,1.654-4.125,2.827-6.894,3.519c-2.115,0.539-5.558,0.808-10.327,0.808h-12.75v0
        v-56.019h7.673c6.961,0,11.635,0.269,14.019,0.808c3.192,0.692,5.827,2.019,7.904,3.981c2.077,1.962,3.692,4.692,4.846,8.192
        c1.154,3.5,1.731,8.519,1.731,15.058C204.596,289.231,204.019,294.394,202.865,298.183z" />
      <polygon points="294.827,254.654 294.827,240.346 236.846,240.346 236.846,324.923 253.923,324.923 253.923,288.981 289.231,288.981 289.231,274.673 253.923,274.673 253.923,254.654" />
    </symbol>
    <symbol id="package" viewBox="0 0 512 512">
      <path d="M438.802,207.962l-121.676-41.566l33.5-45.355h-39.583V0.003H200.957V121.04h-39.584l33.5,45.355L73.198,207.962
        L0,290.652l66.806,23.179v137.557L256,511.997l189.194-60.609V313.831L512,290.652L438.802,207.962z M230.8,151.04V30.003h50.402
        V151.04h10.097L256,198.83l-35.299-47.79H230.8z M213.561,191.696L256,249.153l42.439-57.457l84.168,28.753L256,261.101
        l-126.607-40.652L213.561,191.696z M52.054,276.977l34.372-38.831l141.708,45.5l-40.247,40.458L52.054,276.977z M256,480.511
        L96.648,429.462V324.185l99.145,34.399L256,298.061l60.206,60.524l99.145-34.399v105.276h0.001L256,480.511z M324.112,324.105
        l-40.247-40.458l141.708-45.5l34.373,38.83L324.112,324.105z" />
    </symbol>
    <symbol id="delivery" viewBox="0 0 512 512">
      <path d="M511.943,461.383c-1.039-21.268-15.819-38.673-35.383-44.133V214.206H244.242v201.319h-32.819V173.574h-30v43.393h-43.6
        c-25.209,0-45.719-20.407-45.719-45.492v-48.043c24.625-8.842,42.278-32.323,42.278-59.836C134.383,28.529,105.712,0,70.472,0
        S6.561,28.529,6.561,63.595c0,23.89,13.314,44.736,32.94,55.603L0.001,162.775v179.904h31.605v168.45h30v-168.45h30.601V231.563
        c12.696,9.566,28.499,15.254,45.616,15.254h43.6v198.559h39.385c-2.676,6.47-3.912,13.499-3.56,20.694
        c1.26,25.792,22.749,45.93,48.428,45.929c0.79,0,1.585-0.018,2.383-0.057c26.718-1.292,47.398-23.973,46.1-50.559
        c-0.277-5.661-1.535-11.044-3.59-16.006h108.026c-2.676,6.47-3.912,13.499-3.56,20.694c0.629,12.878,6.261,24.742,15.857,33.406
        c9,8.126,20.475,12.524,32.542,12.524c0.801,0,1.605-0.019,2.411-0.058C492.562,510.649,513.242,487.969,511.943,461.383z
        M62.208,312.827H30.001V174.249l32.104-35.416v32.642c0,1.315,0.035,2.623,0.103,3.921V312.827z M36.561,63.595
        c0-18.605,15.211-33.743,33.91-33.743c18.698,0,33.911,15.138,33.911,33.743c0,18.605-15.213,33.742-33.911,33.742
        S36.561,82.2,36.561,63.595z M279.412,476.105c-3.322,3.644-7.872,5.782-12.812,6.021c-4.907,0.231-9.675-1.45-13.338-4.757
        c-3.662-3.306-5.811-7.833-6.051-12.748s1.457-9.628,4.779-13.272c3.209-3.52,7.567-5.617,12.311-5.972h2.862
        c9.144,0.716,16.572,8.106,17.029,17.456C284.432,467.747,282.734,472.461,279.412,476.105z M274.242,415.525V244.057h71.159
        v52.825h30v-52.825h71.159v171.468H274.242z M477.198,476.104c-3.322,3.644-7.872,5.782-12.812,6.021
        c-4.922,0.233-9.676-1.451-13.338-4.757c-3.662-3.306-5.811-7.833-6.051-12.748s1.457-9.628,4.779-13.272
        c3.322-3.644,7.872-5.782,12.812-6.021c0.305-0.015,0.606-0.022,0.909-0.022c9.798,0,17.999,7.685,18.48,17.527
        C482.219,467.747,480.521,472.46,477.198,476.104z" />
    </symbol>
    <symbol id="check" viewBox="0 0 79.536 79.536">
      <path d="M39.769,0C17.8,0,0,17.8,0,39.768c0,21.965,17.8,39.768,39.769,39.768
        c21.965,0,39.768-17.803,39.768-39.768C79.536,17.8,61.733,0,39.769,0z M34.142,58.513L15.397,39.768l7.498-7.498l11.247,11.247
        l22.497-22.493l7.498,7.498L34.142,58.513z" />
    </symbol>
    <symbol id="arrow-link" viewBox="0 0 484.5 484.5">
      <polygon points="433.5,114.75 433.5,216.75 96.9,216.75 188.7,124.95 153,89.25 0,242.25 153,395.25 188.7,359.55 96.9,267.75 484.5,267.75 484.5,114.75" />
    </symbol>
    <symbol id="arrow-back2" viewBox="0 0 512 512">
      <path
        d="M5.8855,12.4808 C6.1315,12.7348 6.5395,12.7348 6.7945,12.4808 C7.0395,12.2348 7.0395,11.8268 6.7945,11.5808 L2.1885,6.9758 L17.3855,6.9758 C17.7395,6.9758 18.0225,6.6928 18.0225,6.3388 C18.0225,5.9868 17.7395,5.6928 17.3855,5.6928 L2.1885,5.6928 L6.7945,1.0988 C7.0395,0.8448 7.0395,0.4338 6.7945,0.1898 C6.5395,-0.0632 6.1315,-0.0632 5.8855,0.1898 L0.1905,5.8858 C-0.0635,6.1318 -0.0635,6.5398 0.1905,6.7828 L5.8855,12.4808 Z" />
    </symbol>
    <symbol id="arrow-back" width="18px" height="13px" viewBox="0 0 18 13">
      <!-- Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch -->
      <title>Fill 1</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g id="DEBUG-MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="change-country-2" transform="translate(-34.000000, -31.000000)" fill="#1E201D">
          <g id="Group-11" transform="translate(34.000000, 31.000000)">
            <g id="Page-1">
              <path
                d="M5.8855,12.4808 C6.1315,12.7348 6.5395,12.7348 6.7945,12.4808 C7.0395,12.2348 7.0395,11.8268 6.7945,11.5808 L2.1885,6.9758 L17.3855,6.9758 C17.7395,6.9758 18.0225,6.6928 18.0225,6.3388 C18.0225,5.9868 17.7395,5.6928 17.3855,5.6928 L2.1885,5.6928 L6.7945,1.0988 C7.0395,0.8448 7.0395,0.4338 6.7945,0.1898 C6.5395,-0.0632 6.1315,-0.0632 5.8855,0.1898 L0.1905,5.8858 C-0.0635,6.1318 -0.0635,6.5398 0.1905,6.7828 L5.8855,12.4808 Z"
                id="Fill-1"></path>
            </g>
          </g>
        </g>
      </g>
    </symbol>
    <symbol id="apple" viewBox="0 0 305 305" style="fill:#000000; enable-background:new 0 0 305 305;" xml:space="preserve">
      <path id="XMLID_229_" d="M40.738,112.119c-25.785,44.745-9.393,112.648,19.121,153.82C74.092,286.523,88.502,305,108.239,305
          c0.372,0,0.745-0.007,1.127-0.022c9.273-0.37,15.974-3.225,22.453-5.984c7.274-3.1,14.797-6.305,26.597-6.305
          c11.226,0,18.39,3.101,25.318,6.099c6.828,2.954,13.861,6.01,24.253,5.815c22.232-0.414,35.882-20.352,47.925-37.941
          c12.567-18.365,18.871-36.196,20.998-43.01l0.086-0.271c0.405-1.211-0.167-2.533-1.328-3.066c-0.032-0.015-0.15-0.064-0.183-0.078
          c-3.915-1.601-38.257-16.836-38.618-58.36c-0.335-33.736,25.763-51.601,30.997-54.839l0.244-0.152
          c0.567-0.365,0.962-0.944,1.096-1.606c0.134-0.661-0.006-1.349-0.386-1.905c-18.014-26.362-45.624-30.335-56.74-30.813
          c-1.613-0.161-3.278-0.242-4.95-0.242c-13.056,0-25.563,4.931-35.611,8.893c-6.936,2.735-12.927,5.097-17.059,5.097
          c-4.643,0-10.668-2.391-17.645-5.159c-9.33-3.703-19.905-7.899-31.1-7.899c-0.267,0-0.53,0.003-0.789,0.008
          C78.894,73.643,54.298,88.535,40.738,112.119z" />
      <path id="XMLID_230_" d="M212.101,0.002c-15.763,0.642-34.672,10.345-45.974,23.583c-9.605,11.127-18.988,29.679-16.516,48.379
          c0.155,1.17,1.107,2.073,2.284,2.164c1.064,0.083,2.15,0.125,3.232,0.126c15.413,0,32.04-8.527,43.395-22.257
          c11.951-14.498,17.994-33.104,16.166-49.77C214.544,0.921,213.395-0.049,212.101,0.002z" />
    </symbol>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcons'
}
</script>
