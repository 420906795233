import $ from 'jquery'
import { EventBus } from '@/helpers'
var attrSlickLoaded = 'data-bcm-slick-initialized'

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      if (global.slick) {
        var $elements = $('.widget-product-carousel-d01')
        $elements.each((index, element) => {
          let $element = $(element)
          let attr = $element.attr(attrSlickLoaded)
          // For some browsers, `attr` is undefined; for others,
          // `attr` is false.  Check for both.
          if (attr === undefined || attr === false) {
            /* SET MARKER IF SLIDER IS INITIALIZED */
            $element.attr(attrSlickLoaded, true)
            initializeSlick($element, $element.closest('div[data-appearance="carousel-d01"]'))
            if ($element.closest('div[data-appearance="carousel-d01"]').data('show-product-cta') === 'always') {
              cretaCallToAction($element.closest('div[data-appearance="carousel-d01"]'))
            }
          }
        })
      }
    })
  }
}

function initializeSlick ($element, $target) {
  $element.slick({
    useTransform: true,
    useCSS: true,
    waitForAnimate: true,
    autoplay: $target.data('autoplay'),
    autoplaySpeed: $target.data('autoplay-speed') || 0,
    fade: $target.data('fade'),
    infinite: $target.data('is-infinite') || false,
    arrows: true,
    speed: $target.data('speed'),
    cssEase: $target.data('css-ease'),
    slidesToShow: 1,
    slidesToScroll: 1
  })
}

function cretaCallToAction ($el) {
  $('.slick-slide', $el).each((index, element) => {
    let $element = $(element)
    let $wrapperActions = $element.find('.product-item-details')
    $wrapperActions.append(`<a href class="product-item-cta ${$el.data('button-type')}">${$el.data('button-text')}</a>`)
  })
}
