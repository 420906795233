import $ from 'jquery'
import { EventBus } from '@/helpers'
import { isMobile } from '@/helpers/utils'
import throttle from 'lodash/throttle'

var attrSlickLoaded = 'data-bcm-slick-initialized'
var attrProcessed = 'data-bcm-processed'
var allCarousel = []
export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      if (global.slick) {
        var $elements = $('.pagebuilder-slider, .widget-product-carousel')
        $elements.each((index, element) => {
          let $element = $(element)
          let attr = $element.attr(attrSlickLoaded)
          // For some browsers, `attr` is undefined; for others,
          // `attr` is false.  Check for both.
          if (attr === undefined || attr === false) {
            /* SET MARKER IF SLIDER IS INITIALIZED */
            $element.attr(attrSlickLoaded, true)
            /* to add euroSymbol */
            let euroSymbol = document.querySelectorAll('.widget-product-carousel .price-box span.currency-symbol')
            for (const symbol of euroSymbol) {
              if (window.$store.state.storeConfig.config.base_currency_code === 'EUR') {
                symbol.innerHTML = '€'
              }
            }
            // exclude promobar slider
            if (!$element.hasClass('promobar')) {
              $element.on('init', function (event, slick) {
                // if ($element.hasClass('widget-product-carousel')) {
                //   moveActionWishlistInParentContainer($element)
                // }
                if ($element.hasClass('pagebuilder-slider')) {
                  setClassesOfPaddingsInWrapper($element)
                }
                if ($element.hasClass('show-progress-bar')) {
                  let $progressBar = createProgressBar($element)
                  let slideIndex = $element.find('.slick-active').last().data('slickIndex')
                  $progressBar.data('lastSlideIndex', slideIndex)
                  setProgress(slick.slideCount, $element, $progressBar)

                  $element.on('afterChange', function (event, slick) {
                    setProgress(slick.slideCount, $element, $progressBar)
                  })

                  $element.on('breakpoint', function (event, slick) {
                    // when breakpoint is hit create progress again, set new $el in dom
                    $element.slick('unslick')
                    // $progressBar = createProgressBar($element)

                    initializeSlick($element, $element.hasClass('widget-product-carousel')
                      ? $element.parent()
                      : $element)
                  })
                }
              })
            }

            let singleCarousel = initializeSlick($element, $element.hasClass('widget-product-carousel')
              ? $element.parent()
              : $element)
            allCarousel.push(singleCarousel)
          }
        })
        initAutoplayCarousel(allCarousel)
      }
    })
  }
}
function initializeSlick ($element, $target) {
  let infiniteLoop = false
  if ($target.hasClass('infiniteloop')) {
    infiniteLoop = true
  }
  let continuos = null
  if ($target.hasClass('continuous-carousel-mobile') && isMobile) {
    continuos = {
      speed: 5000,
      autoplay: true,
      autoplaySpeed: 1,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      dots: false
    }
    infiniteLoop = true
  }
  // let autoPlaySpeed = continuos.speed || $target.data('autoplay-speed')
  let settings = {
    autoplay: $target.data('autoplay'),
    autoplaySpeed: $target.data('autoplay-speed') || 0,
    fade: $target.data('fade'),
    infinite: $target.data('is-infinite') || infiniteLoop,
    arrows: $target.data('show-arrows'),
    centerMode: $target.data('center-mode'),
    centerPadding: $target.data('center-padding'),
    variableWidth: $target.data('variable-width'),
    adaptiveHeight: $target.data('adaptive-height'),
    speed: $target.data('speed'),
    cssEase: $target.data('css-ease'),
    slidesToShow: $target.data('slides-to-show'),
    slidesToScroll: $target.data('slides-to-scroll'),
    dots: ((!$element.hasClass('promobar')) ? $target.data('show-dots') : false),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: false, // continuos && continuos.autoplay ? continuos.autoplay : $target.data('autoplay'),
          autoplaySpeed: continuos && continuos.autoplaySpeed ? continuos.autoplaySpeed : $target.data('autoplay-speed') || 0,
          arrows: $target.data('show-arrows-in-mobile') || false,
          dots: continuos && continuos.dots ? continuos.dots : ((!$element.hasClass('promobar')) ? $target.data('show-dots-in-mobile') : false) || false,
          variableWidth: continuos && continuos.variableWidth ? continuos.variableWidth : $target.data('variable-width') || $target.data('variable-width') || false,
          speed: continuos && continuos.speed ? continuos.speed : $target.data('speed'),
          cssEase: continuos && continuos.cssEase ? continuos.cssEase : $target.data('css-ease'),
          slidesToShow: continuos && continuos.slidesToShow ? continuos.slidesToShow : $target.data('slides-to-show-in-mobile') || $target.data('slides-to-show-in-mobile') || $target.data('slides-to-show') || 1,
          slidesToScroll: continuos && continuos.slidesToScroll ? continuos.slidesToScroll : $target.data('slides-to-scroll')
        }
      }
    ]
  }
  let a = $element.slick(settings)

  // fix refresh promobar slider
  if ($element.hasClass('promobar')) {
    $element.slick('refresh')
  }
  return a
}
// function moveActionWishlistInParentContainer ($element) {
//   let $actions = $element.find('.action-wishlist')
//   $actions.each((index, element) => {
//     let $action = $(element)
//     let $container = $action
//       .closest('.product-item-info')
//       .find('.product-image-wrapper')
//     $action.appendTo($container)
//   })
// }

function createProgressBar ($element) {
  let $progressBar = $('<div class="slick-progress" role="progressbar" data-min-value="0" data-max-value="100"><span class="slider__label sr-only"></div>')
  $element.append($progressBar)
  return $progressBar
}

function calcProgress (slideCount, slidesToShow, slidesToScroll, $element) {
  let breakPoint = 0
  let counter = 0
  let pagerQty = 0

  if ($element.data('infinite') === true) {
    if (slideCount <= slidesToShow) {
      ++pagerQty
    } else {
      while (breakPoint < slideCount) {
        ++pagerQty
        breakPoint = counter + slidesToScroll
        counter += slidesToScroll <= slidesToShow ? slidesToScroll : slidesToShow
      }
    }
  } else if ($element.data('centerMode') === true) {
    pagerQty = slideCount
  } else if (!$element.data('asNavFor')) {
    pagerQty = 1 + Math.ceil((slideCount - slidesToShow) / slidesToScroll)
  } else {
    while (breakPoint < slideCount) {
      ++pagerQty
      breakPoint = counter + slidesToScroll
      counter += slidesToScroll <= slidesToShow ? slidesToScroll : slidesToShow
    }
  }

  return pagerQty - 1
}

function setProgress (slideCount, $element, $progressBar) {
  let $progressBarLabel = $progressBar.find('.slider__label')
  let slidesToShow = isMobile() ? $element.data('slides-to-show-in-mobile') : $element.data('slides-to-show')
  let slidesToScroll = $element.data('slides-to-scroll')
  let lastSlideIndex = $progressBar.data('lastSlideIndex')
  let currentSlideIndex = $element.find('.slick-active').last().data('slickIndex')
  let size = 1 / (calcProgress(slideCount, slidesToShow, slidesToScroll, $element)) * 100
  let value = parseFloat($progressBar.attr('data-value')) || 0
  if (currentSlideIndex > lastSlideIndex) {
    value += size
  } else if (currentSlideIndex < lastSlideIndex) {
    value -= size
  }
  // set last index for next iteration
  // $progressBar.attr('data-last-slide-index', currentSlideIndex)
  $progressBar.data('lastSlideIndex', currentSlideIndex)
  $progressBar.css('background-size', (value) + '% 100%').attr('data-value', value)
  $progressBarLabel.text(value + '% completed')
}

function setClassesOfPaddingsInWrapper ($element) {
  let $slides = $element.find('div[data-content-type="slide"]')
  let paddingClass = 'p-'
  let wrapperClasses = []

  $slides.each((index, slide) => {
    let $slide = $(slide)
    let attr = $slide.attr(attrProcessed)
    if (attr === undefined || attr === false) {
      let $pagebuilderOverlay = $slide.find('.pagebuilder-overlay')
      let classes = $slide.attr('class') ? $slide.attr('class').split(' ') : []
      if (classes.length) {
        for (let i = 0; i < classes.length; i++) {
          if (classes[i].includes(paddingClass)) {
            wrapperClasses.push(classes[i])
          }
        }
      }
      $slide.removeClass(wrapperClasses.join(' '))
      $pagebuilderOverlay.addClass(wrapperClasses.join(' '))
      $slide.attr(attrProcessed, '')
    }
  })
}

function initAutoplayCarousel (listCarousel) {
  if (isMobile) {
    $(window).on('scroll', throttle(function () {
      listCarousel.forEach(el => {
        if (el.hasClass('autoplay-active')) {
          return
        }
        let scrollTop = $(window).scrollTop()
        let windowHeight = window.innerHeight
        let elScrollTop = el.offset().top
        let heightEl = el.innerHeight()
        let parentElement = el.closest('[data-appearance="carousel"]')
        /* // console.log('distance:', scrollTop + windowHeight)
        console.log(el.offset().top)
        console.log(el) */
        /* if (el.hasClass('continuous-carousel-mobile')) console.log('class:', el.hasClass('continuous-carousel-mobile')) */
        /* console.log(parentElement.hasClass('continuous-carousel-mobile')) */
        if (scrollTop + windowHeight - heightEl > elScrollTop && (el.hasClass('continuous-carousel-mobile') || parentElement.hasClass('continuous-carousel-mobile'))) {
          el.slick('slickPlay')
          el.addClass('autoplay-active')
          console.log('autoplya')
        }
      })
    }, 200))
  }
}
