<template>
  <transition name="slideTop">
    <component
      v-show="getLayout"
      :is="getLayout"
      :activeindex="activeindex"
      :images="images"
      @close-zoomer="$emit('close-zoomer', true)">
    </component>
  </transition>
</template>

<script>
import { isMobile } from '@/helpers/utils'
import ZoomerDesktop from 'theme/components/Product/MediaZoomer/ZoomerDesktop'
import ZoomerMobile from 'theme/components/Product/MediaZoomer/ZoomerMobile'

export default {
  name: 'Zoomer',
  props: {
    images: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    activeindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    getLayout () {
      if (isMobile()) {
        return 'ZoomerMobile'
      } else {
        return 'ZoomerDesktop'
      }
    }
  },
  components: {
    ZoomerDesktop,
    ZoomerMobile
  }
}
</script>
