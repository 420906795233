export default {
  messages: {
    required: 'Questo é un campo obbligatorio',
    requireTrue: 'Questo é un campo obbligatorio',
    phoneNumber: 'Numero di telefono non valido',
    alpha: 'Inserire solo caratteri alfabetici',
    email: 'Email non valida',
    min: (fieldName, params) => `La ${fieldName} deve avere almeno ${params[0]} caratteri`,
    confirmed: (fieldName) => `La ${fieldName} non corrisponde a quella inserita`,
    numeric: 'Solo valori numerici',
    length: 'La lunghezza non è corretta',
    max: 'Il campo non può contenere più di 400 caratteri',
    uniqueEmail: 'Email già presente nel sistema, accedi o cambia mail',
    passwordM: fieldName => `La ${fieldName} deve avere almeno 1 numero, 1 carattere maiuscolo e uno minuscolo`,
    fiscalCode: 'P.IVA/Codice Fiscale non valido',
    VAT: 'P.IVA/Codice Fiscale non valido'
  }
}
