export default `query orderTemplate ($id: String, $email: String){
  orderTemplate (id: $id, email: $email){
    order_id
    order_number
    customer_name
    customer_email
    items {
      item_sku
      item_name
      image_url
      selectedOptions {
        label
        value
        option_id
        option_value
        attribute_code
        color_image
        swatch
        type
      }
      item_order_price
      qty_requested
      qty_available
      order_item_id
    }
    shipping_info{
      firstname
      lastname
      street
      city
      region
      postcode
      country_id
      telephone
    }
    dictionary {
      conditions{
        value
        label
      }
      reasons{
        value
        label
      }
      resolutions{
        value
        label
      }
    }
  }
}`
