import { each as _each, isEmpty as _isEmpty, sortBy as _sortBy } from 'lodash'
import Config from 'config'
import cloneDeep from 'lodash/cloneDeep'
import remove from 'lodash/remove'

export function formatAddress (addrr) {
  return `${addrr.firstname} ${addrr.lastname}<br>${addrr.street.join(' ')}<br>${addrr.city}, ${addrr.region.region_code} ${addrr.postcode} ${addrr.country_id}`
}

export function resizeService ({ url, opt = {}, color = 'f5f5f5', checkout = false }) {
  let _url = url
  if (url && url.startsWith('//')) {
    url = 'https:' + url
  }
  if (url.includes('/cache/')) {
    url = _url = url.replace(/(\/cache\/)[^/][^/\r\n]*/i, '')
  }
  if (checkout && url && url.startsWith(process.env.VUE_APP_MEDIA_BASE)) {
    _url = url.replace(process.env.VUE_APP_MEDIA_BASE, Config.API.img.resizeUrl)
    _url = _url.replace('/pub/media/', '')
  }
  if (checkout && url && url.startsWith(process.env.VUE_APP_MEDIA_BASE_STAGE)) {
    _url = url.replace(process.env.VUE_APP_MEDIA_BASE_STAGE, Config.API.img.resizeUrl)
  }

  opt['color'] = color
  if (opt && Object.keys(opt).length) {
    let i = 0
    _url += '?'
    for (let key in opt) {
      _url += `${i ? '' : '&'}${key}=${opt[key]}`
      i++
    }
  }
  return _url
}

/* prevent scrolling on ios */
let scrollPosition = 0

export function enablePreventScrollingSafari () {
  const $body = document.querySelector('body')
  scrollPosition = window.pageYOffset
  $body.style.overflow = 'hidden'
  $body.style.position = 'fixed'
  $body.style.top = `-${scrollPosition}px`
  $body.style.width = '100%'
}

export function disablePreventScrollingSafari () {
  const $body = document.querySelector('body')
  $body.style.removeProperty('overflow')
  $body.style.removeProperty('position')
  $body.style.removeProperty('top')
  $body.style.removeProperty('width')
  window.scrollTo(0, scrollPosition)
}

export function preventScroll ({ remove = false }) {
  if (remove) {
    disablePreventScrollingSafari()
  } else {
    enablePreventScrollingSafari()
  }
}

function getConfigurableOptMap (configurableOptions) {
  let map = {}
  let options = configurableOptions
  if (options) {
    options.forEach(confOpt => {
      map[confOpt.attribute_id] = confOpt.attribute_code
    })
  }
  return map
}

function isOutOfStock (option) {
  console.log(isOutOfStock, '11')
  let inStock = option.quantity > 0
  let notifyme = inStock || (!inStock && option.is_backinstock === 1)
  let comingSoon = (option.quantity > 0 && (Math.abs(option.out_of_stock_threshold) - option.quantity) > 0)
  if (!notifyme && !comingSoon) {
    if (!inStock) {
      return true
    }
  }
  return false
}

export function getVariantByFilter (skip = 'size', filterActive, product, removeOutOfStock = false) {
  // remove out of stock options
  // if product is not in notifyme (is_backinstock)
  // if option is not in coming_soon (backinstock != 0 && trashold - quantity > 0)
  let options = []
  let skipDuplicate = {}
  let filter = _isEmpty(filterActive) ? {} : filterActive
  let confMap = getConfigurableOptMap(product.configurable_options)
  let variants = product.variants
  let exitFor
  let attributeMap
  const canPushIn = (option) => {
    if (removeOutOfStock) {
      return !isOutOfStock(option) // return negation to avoid push
    } else {
      return true
    }
  }
  if (variants) {
    for (let i in variants) {
      exitFor = false
      attributeMap = {}
      if (variants[i].attributes.length) {
        _each(variants[i].attributes, item => {
          attributeMap[item.code] = item
        })
        if (!skipDuplicate[attributeMap[skip]]) {
          for (let key in filter) {
            if (confMap[key] !== skip) {
              if (attributeMap[confMap[key]].value_index !== filter[key]) {
                exitFor = true
                break
              }
            }
          }
          if (!exitFor && canPushIn(variants[i].product)) {
            options.push({
              label: attributeMap[skip].label,
              value: attributeMap[skip].value_index,
              value_index: attributeMap[skip].value_index,
              is_pre_order: (variants[i].product.quantity > 0 && (Math.abs(variants[i].product.out_of_stock_threshold) - variants[i].product.quantity) >= 0),
              is_backinstock: variants[i].product.is_backinstock,
              min_qty: variants[i].product.out_of_stock_threshold,
              quantity: variants[i].product.quantity,
              in_stock: variants[i].product.quantity > 0,
              enabled: variants[i].product.enabled
            })
            skipDuplicate[attributeMap[skip].value_index] = true
          }
        }
      }
    }
  }
  let sortedOption = _sortBy(options, ['label'])
  return sortedOption
}

// override -> src/store/modules/checkout/defaultValues.js
export const DefaultAddress = {
  firstname: '',
  lastname: '',
  street: [],
  city: '',
  company: '',
  fax: '',
  region: { region: '', region_code: '', region_id: 0 },
  postcode: '',
  country_id: '',
  telephone: '',
  default_shipping: false,
  default_billing: false,
  useotheraddress: false
}

export const DefaultShipping = {
  ...DefaultAddress,
  shippingMethod: Config.Theme.default.shippingMethod,
  shippingMethodAdditional: {}
}

export async function navigateToPrivacyPolicy ($store = false, targetBlank = true) {
  const $scopedStore = $store || global.$store
  const urlCode = $scopedStore.state.ui.urlCode
  let routeHelpers = await require('@/helpers/routeHelpers')
  if (routeHelpers) {
    let path = '/' + urlCode + '/' + routeHelpers.pathTranslate({ path: 'privacy' })
    if (!targetBlank) {
      global.$router.push({ path })
    } else {
      window.open(path, '_blank')
    }
  } else {
    // do anything
  }
}

export function removeProductMediaByTag ({ tags, mediaGalleries, mediaEntries }) {
  let arraytags = tags.split(',')
  // let newImages = []
  // let files = []
  let images = cloneDeep(mediaGalleries)
  arraytags.forEach((tag) => {
    mediaEntries.forEach((mediaEntry) => {
      if (mediaEntry.types.includes(tag) || mediaEntry.types.find(type => type.includes(tag))) {
        remove(images, (img) => {
          return img.url.includes(mediaEntry.file)
        })
      }
    })
  })
  return images
}

export function getProductMediaByTag ({ tag, mediaGalleries, mediaEntries }) {
  const mediaEntryByTag = mediaEntries.find(mediaEntry => mediaEntry.types.includes(tag))
  // fallback, if don't find the entry with the specified tag, then return the first default image from gallery
  return mediaEntryByTag ? mediaGalleries.find(media => media.url.includes(mediaEntryByTag.file)) : mediaGalleries[0]
}

export function chatSettings () {
  let storeCode = global.$store.state.ui.codeLang
  let storeLang = storeCode.split('_')[1]
  let departments = 'PHILOSOPHY | EN'
  let language = 'lingua_en'
  let country = 'country_en'
  switch (storeLang) {
    case 'it':
      departments = 'PHILOSOPHY | IT'
      language = 'lingua_it'
      country = 'country_it'
      break
    case 'en':
      if (storeCode !== 'us_en' || storeCode !== 'ca_en') {
        departments = 'PHILOSOPHY | EN'
        language = 'lingua_en'
        country = 'country_en'
      } else {
        departments = 'PHILOSOPHY | US'
        language = 'lingua_en'
        country = 'country_us'
      }
      break
    case 'fr':
      if (storeCode !== 'ca_fr') {
        departments = 'PHILOSOPHY | FR'
        language = 'lingua_fr'
        country = 'country_fr'
      } else {
        departments = 'PHILOSOPHY | US'
        language = 'lingua_fr'
        country = 'country_us'
      }
      break
    case 'de':
      departments = 'PHILOSOPHY | DE'
      country = 'country_de'
      break
    case 'es':
      if (storeCode !== 'us_es') {
        departments = 'PHILOSOPHY | ES'
        country = 'country_es'
      } else {
        departments = 'PHILOSOPHY | US'
        language = 'lingua_es'
        country = 'country_us'
      }
      break
    case 'ru':
      departments = 'PHILOSOPHY | RU'
      country = 'country_ru'
  }

  return {
    webWidget: {
      launcher: {
        chatLabel: {
          '*': 'Chat Philosophy'
        }
      },
      chat: {
        departments: {
          enabled: [],
          select: `${departments}`
        },
        title: {
          '*': 'Chat Philosophy'
        },
        concierge: {
          avatarPath: 'https://www.philosophyofficial.com/skin/frontend/philosophy/default/images/philosophy-logo-header-white.svg',
          name: 'Chat Philosophy Customer Care',
          title: { '*': 'Live support' }
        },
        tags: ['zopim_chat_philosophy', 'philosophy', `${language}`, `${country}`]
      }
    }
  }
}

export function checkInventory (store, ctx) {
  let cartItems = store.state.cart.items
  let isPreorder = cartItems.every(cartItem => cartItem.extension_attributes.is_preorder)
  if (isPreorder) {
    // if all products in cart is in preorder, then don't check the argo inventory
    return Promise.reject(new Error('nothing to check'))
  } else {
    // not the whole cart is in preorder
    let country = (store.state.ui.currentCountry.country).toLowerCase()
    let skus = {}
    // build skus object filtering those whose product is on preorder
    cartItems.forEach(cartItem => {
      if (!cartItem.extension_attributes.is_preorder) {
        skus[cartItem.extension_attributes.sku] = cartItem.qty
      }
    })
    return store.dispatch(ctx, {
      brand: 'Philosophy',
      store_view: store.state.ui.storeViewCode,
      country: country,
      products: skus
    })
  }
}

export function optimizeImage (url) {
  if (url.includes('/cache/')) {
    url = url.replace(/(\/cache\/)[^/][^/\r\n]*/i, '')
  }

  if (url.includes('?optimize=high&fit=bounds&height=&width=')) {
    return url.replace('?optimize=high&fit=bounds&height=&width=', '')
  }
  return url
}
