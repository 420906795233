import find from 'lodash/find'
import isMatch from 'lodash/isMatch'
import reduce from 'lodash/reduce'

export default {
  isLogged (state) {
    return !!state.token
  },
  hasAddresses (state) {
    let countries = global.$store.state.checkout.countries
    countries = countries.map((country) => country.id)
    let addresses = null
    if (state.current && state.current.addresses.length > 0) {
      addresses = state.current.addresses.reduce((result, address) => {
        if (countries.indexOf(address.country_id) !== -1) {
          return result.concat(address)
        }
        return result
      }, [])
    }
    return addresses && addresses.length > 0
  },
  getWishlistItems (state) {
    return (state.current && state.current.wishlist && state.current.wishlist.items) ? state.current.wishlist.items : []
  },
  isOnWishlist: (state) => (product) => {
    let match = false
    let current = state.current
    if (current && current.wishlist) {
      current.wishlist.items.forEach(item => {
        if (item.product && item.product.configurable_options) {
          let configurableOptions = item.product.configurable_options
          // find attribute color
          const colorAttr = find(configurableOptions, { 'attribute_code': 'color' })
          var groupedSelected = {
            [colorAttr.attribute_id]: item.product.presetFilter[colorAttr.attribute_id]
          }
        }
        // check product.master_sku before product.sku
        if ((product.master_sku || product.sku) === item.product.sku && isMatch(product.presetFilter, groupedSelected)) {
          match = item
        }
      })
    } else {
      let guestWishlist = (state.guest && state.guest.wishlist) ? state.guest.wishlist.items : []
      guestWishlist.forEach(item => {
        if (item.product && item.product.configurable_options) {
          let configurableOptions = item.product.configurable_options
          // find attribute color
          const colorAttr = find(configurableOptions, { 'attribute_code': 'color' })
          var groupedSelected = {
            [colorAttr.attribute_id]: item.product.presetFilter[colorAttr.attribute_id]
          }
        }
        if ((product.master_sku || product.sku) === item.product.sku && isMatch(product.presetFilter, groupedSelected)) {
          match = item
        }
      })
    }
    return match
  },
  getGuestWishlistItems (state) {
    return state.guest.wishlist.items
  },
  getGuestSharingCode (state) {
    return state.guest.wishlist.sharing_code
  },
  badge (state, getters) {
    let startCountingFrom
    if (getters.isLogged && state.current) {
      startCountingFrom = state.current.wishlist.items
    } else {
      startCountingFrom = state.guest.wishlist ? state.guest.wishlist.items : []
    }
    return reduce(startCountingFrom, (sum, item) => sum + item.qty, 0)
  }
}
