export default {
  messages: {
    required: 'required field',
    requireTrue: 'required field',
    phoneNumber: 'invalid telephone',
    alpha: 'only contain alphabetic characters',
    email: 'The email value is not valid',
    min: (fieldName, params) => `minimum number of characters ${params[0]}`,
    confirmed: 'The password confirmation does not match.',
    numeric: 'just numeric value',
    length: 'incorrect length',
    max: 'Only 400 characters allowed',
    uniqueEmail: 'email exist, loggin or change email',
    passwordM: fieldName => `The ${fieldName} need at least 1 number, 1 uppercase and 1 lowercase character`,
    fiscalCode: 'Invalid fiscal or VAT code',
    VAT: 'Invalid fiscal or VAT code'
  }
}
