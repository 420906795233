<template>
  <no-ssr>
    <div class="product-relationships">
      <div class="slider-wrapper" :class="[sliderIdentifier]">
        <div class="swiper-navigation">
          <div class="swiper-button-prev" slot="button-prev">
            <i class="icomoon-icon icon-chevron-left" />
          </div>
          <div class="products__wrapper">
            <swiper :options="sliderOptions">
              <swiper-slide v-for="(item, index) in validItems" :key="`${sliderIdentifier}-${index}`" class="swiper-slide" @click="slideClicked(true, index)">
                <slot name="slide-item" v-bind="item">
                  <product-tile-relationships :product="item" :enabledQuickBuy="enabledQuickBuy"></product-tile-relationships>
                </slot>
              </swiper-slide>
            </swiper>
          </div>
          <div class="swiper-button-next" slot="button-next">
            <i class="icomoon-icon icon-chevron-right" />
          </div>
        </div>
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </div>
  </no-ssr>
</template>

<script>
import ProductTileRelationships from 'theme/components/Product/ProductRelationships/ProductTileRelationships'

export default {
  name: 'ProductRelationships',
  props: {
    sliderIdentifier: {
      type: String,
      default: 'correlated-slide-wrapper'
    },
    options: {
      type: Object,
      default: () => {
        return {
          slidesPerView: 2,
          spaceBetween: 5,
          watchOverflow: true,
          pagination: {
            el: `{{identifier}} .swiper-pagination`,
            clickable: true
          },
          breakpoints: {
            0: {
              navigation: {
                nextEl: `{{identifier}} .swiper-navigation .swiper-button-next`,
                prevEl: `{{identifier}} .swiper-navigation .swiper-button-prev`
              }
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
              navigation: {
                nextEl: `{{identifier}} .swiper-navigation .swiper-button-next`,
                prevEl: `{{identifier}} .swiper-navigation .swiper-button-prev`
              }
            }
          }
        }
      }
    },
    slideItems: {
      type: Array,
      required: true
    },
    enabledQuickBuy: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      sliderOptions: {}
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.ui.isMobile
    },
    validItems () {
      if (this.slideItems && this.slideItems.length) {
        return this.slideItems.filter((item) => item)
      } else {
        return []
      }
    }
  },
  methods: {
    slideClicked (slideIndex) {
      return this.$emit('slideClicked', slideIndex)
    }
  },
  mounted () {
    if (process.client) {
      if (this.sliderIdentifier) {
        let mergedOptions = { ...this.options }
        this.sliderOptions = JSON.parse(JSON.stringify(mergedOptions).replaceAll('{{identifier}}', `.${this.sliderIdentifier}`))
      } else {
        this.sliderOptions = { ...this.options }
      }

      // equal height
      /* let _this = this
      let max = 0
      setTimeout(() => {
        console.log(_this)
        let recentlyItems = document.querySelector('.recently-viewed')
        let relatedItems = document.querySelector('.related-product')
        if (recentlyItems) {
          max = 0
          let titles = recentlyItems.querySelectorAll('.card-product__info__intro__link')
          titles.forEach(title => {
            if (title.offsetHeight > max) {
              max = title.offsetHeight
            }
          })
          titles.forEach(title => {
            title.style.height = max + 'px'
          })
        }
        if (relatedItems) {
          max = 0
          debugger
          let titles = relatedItems.querySelectorAll('.card-product__info__intro__link')
          titles.forEach(title => {
            debugger
            if (title.offsetHeight > max) {
              debugger
              max = title.offsetHeight
            }
          })
          titles.forEach(title => {
            title.style.height = max + 'px'
          })
        }
      }, 150) // end equal height */
    }
  },
  components: {
    ProductTileRelationships
  }
}
</script>
