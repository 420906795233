import $ from 'jquery'
import { EventBus } from '@/helpers'
// import { isMobile } from '@/helpers/utils'

var attrProcessed = 'data-bcm-processed'

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      buildWidget()
    })
    $(document)
      .on({
        click: function (event) {
          event.stopPropagation()
          let $figure = $(this)
          let $hyperlink = $figure.find('a')
          if ($hyperlink) {
            if ($hyperlink.attr('target') === '_blank') {
              window.open($hyperlink.attr('href'), '_blank')
            } else {
              window.location.href = $hyperlink.attr('href')
            }
          }
        }
      }, '.widget-e figure')
  }
}

function buildWidget () {
  $(function () {
    let $elements = $('.widget-e .pagebuilder-column-group')
    $elements.each(function () {
      let $element = $(this)
      let attr = $element.attr(attrProcessed)
      if (attr === undefined || attr === false) {
        // set $el as processed
        $element.attr(attrProcessed, '')

        $element.on('init', function (event, slick) {
          createCaption($element)
          createBackgroundAnimation($element.parent())
        })

        initializeSlick($element, $element.parent())
      }
    })
  })
}

function initializeSlick ($element, $target) {
  let settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  }
  $element.slick(settings)
}

function createCaption ($element) {
  let $slides = $element.find('.slick-slide')
  $slides.each(function () {
    let $slide = $(this)
    let $div = $slide.children(':first')
    let title = $slide.find('figure img').first().attr('title')
    $div.append(`<p class="pagebuilder-column-caption">${title}</p>`)
  })
}

function createBackgroundAnimation ($element) {
  $element.closest('.widget-e').append('<div class="background"></div>')
}
