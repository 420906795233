<template>
  <div class="wrapp-variant">
    <span v-show="name">{{name}} : </span>
    <span class="text">{{ printText }}</span>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'VariantTile',
  props: {
    name: {
      type: String,
      required: false,
      default: () => ''
    },
    text: {
      type: String,
      required: false,
      default: () => ''
    },
    useConverter: {
      type: Boolean,
      required: false,
      default: () => true
    },
    converterActive: {
      type: String,
      required: false,
      default: () => 'cm'
    }
  },
  computed: {
    printText () {
      // if text contain size leave
      // else add it to conversion in inch or cm
      if (this.useConverter) {
        let regex = /(Taglia|Size|Körpermaße|Talle|Taille|サイズ|尺寸|Размер)/iu
        let numberRegex = /[1-9]/
        if (!regex.test(this.name) && numberRegex.test(this.text)) {
          return `${Vue.filter('convertToMeasure')(this.text, this.converterActive)}  ${this.$t(`productTabs.sizeGuide.measureUnit-${this.converterActive}`)}`
        } else {
          return this.text
        }
      } else {
        return this.text
      }
    }
  }
}
</script>
