import { processInclude } from '@/helpers/utils'
import products from './products/index'
import videoButton from './video-button/index'
import buttonItem from './button-item/index'
import column from './column/index'
import utils from './utils'
import accordions from './accordions'
import rows from './rows'

export function init () {
  if (process.client) {
    processInclude(products)
    processInclude(videoButton)
    processInclude(buttonItem)
    processInclude(column)
    processInclude(utils)
    processInclude(accordions)
    processInclude(rows)
  }
}
