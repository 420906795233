export default store => {
  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'ui/setCountry':
        let country
        if (process.client) {
          if (state.ui.currentCountry && state.ui.currentCountry.country) {
            country = state.ui.currentCountry.country
          } else if (state.ui.codeLang) {
            country = state.ui.codeLang.split('_')[0]
          } else {
            country = null
          }
          // country = state.ui.currentCountry && state.ui.currentCountry.country ? state.ui.currentCountry.country : null
          global.$cookies.set('geolocator:country', country)
        }
        break
      case 'ui/setStateArea':
        if (process.client) {
          global.$cookies.set('geolocator:state', state.ui.currentState)
        }
        break
      case 'ui/sizeCountrySelected':
        if (process.client && window.sessionStorage) {
          window.sessionStorage.setItem('sizeCountrySelected', state.ui.sizeCountrySelected)
        }
        break
      case 'ui/setListingColumns':
        if (process.client && window.localStorage) {
          window.localStorage.setItem('listingColumns', state.ui.listingColumns)
        }
        break
    }
  })
}
