export default `query GetPosts($currentPage: Int, $pageSize: Int, $sort: [String] = ["DESC"], $sortFiled: String = "publish_time", $filters: BlogPostsFilterInput) {
  blogPosts(
    currentPage: $currentPage,
    pageSize: $pageSize,
    sort: $sort,
    sortFiled: $sortFiled,
    filter: $filters
  ) {
    total_count
    items {
      identifier
      post_id
      title
      video_url
      short_content
      publish_time
      short_filtered_content
      post_url
      tags {
        tag_id
        title
        tag_url
      }
      first_image
      featured_image
      meta_title
      meta_keywords
      meta_description
      og_title
      og_description
      og_image
      og_type
    }
    total_pages
  }
}`
