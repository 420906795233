<template>
  <div class="video-manager">
    <div class="video-wrapper" :class="{ 'is-plaing': isInPlay, 'has-poster': !isAutoplay && videoPoster, 'poster-is-inline': !showAsBk, 'no-video-found': !isValidVideo }">
      <div class="video-url-background" v-show="!isAutoplay && videoPoster">
        <div class="wrapp-media" v-lazy:background-image="videoPoster" v-show="showAsBk"></div>
        <img v-lazy="videoPoster" class="img-fluid" v-show="!showAsBk">
        <div class="video-player" v-if="isValidVideo">
          <button class="btn btn-icon" @click.prevent="playClick">
            <i class="icomoon-icon icon-play-rounded" />
          </button>
        </div>
      </div>
      <template v-if="isValidVideo">
        <div class="wrapp-video-pause" v-show="!isAutoplay">
          <button class="btn btn-icon" @click.prevent="pauseClick">
            <i class="icomoon-icon icon-x" />
          </button>
        </div>
      </template>
      <div class="video-placeholder" ref="video-placeholder" :class="{ 'no-controls': !showControls }"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/helpers'

export default {
  name: 'VideoManager',
  props: {
    src: {
      type: String,
      required: true
    },
    videoPoster: {
      type: String,
      default: () => ''
    },
    autoplay: {
      type: Boolean,
      default: () => null
    },
    loop: {
      type: Boolean,
      default: () => null
    },
    muted: {
      type: Boolean,
      default: () => null
    },
    showControls: {
      type: Boolean,
      default: false
    },
    showAsBk: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      VideoWorker: null,
      isFirstInteraction: true,
      isInPlay: false,
      isValidVideo: false,
      videoManager: {}
    }
  },
  computed: {
    isAutoplay () {
      if (this.autoplay) {
        return this.autoplay
      } else {
        return this.$store.getters['storeConfig/getPDPVideoAutoPlay']
      }
    },
    isMuted () {
      if (this.muted) {
        return this.muted
      } else {
        return this.$store.getters['storeConfig/getPDPVideoMute']
      }
    },
    isLoop () {
      if (this.loop) {
        return this.loop
      } else {
        return this.$store.getters['storeConfig/getPDPVideoLoop']
      }
    }
  },
  methods: {
    retrieveVideo (videoSrc) {
      if (videoSrc) {
        const _this = this
        this.isValidVideo = true
        this.videoManager = new window.VideoWorker(videoSrc, {
          autoplay: _this.isAutoplay,
          mute: _this.isMuted,
          loop: _this.isLoop,
          accessibilityHidden: true,
          showControls: _this.showControls
        })

        this.isInPlay = _this.isAutoplay
        if (this.videoManager.isValid()) {
          // retrieve iframe/video dom element.
          // videoObject.getVideo(appendVideoInDom)
          this.videoManager.getVideo((video) => {
            const $parent = video.parentNode
            const $videoPlaceholder = _this.$refs['video-placeholder']
            $videoPlaceholder.appendChild(video)
            $parent.parentNode.removeChild($parent)
          })

          this.videoManager.on('play', () => {
            _this.isFirstInteraction = false
          })

          this.videoManager.on('pause', () => {
            _this.isFirstInteraction = false
          })
        }
      }
    },
    muteVideo () {
      this.videoManager.mute()
    },
    playClick () {
      // if is first time that video is rendered
      // before play add muted to avoid browser error
      if (this.isFirstInteraction) {
        this.muteVideo()
      }
      this.isInPlay = true
      this.videoManager.play()
    },
    pauseClick () {
      this.isInPlay = false
      this.videoManager.pause()
    }
  },
  mounted () {
    if (process.client) {
      if (!window.VideoWorker) {
        window.VideoWorker = require('video-worker').default
      }
      EventBus.$on('currentProductSlideChange', this.muteVideo)
      this.retrieveVideo(this.src)
    }
  }
}
</script>
