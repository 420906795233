<template>
    <div class="color-list d-flex align-items-center">
      <div class="swatch-option" v-for="(option, index) in this.newOptions" :key="index" :class="{active : option.value_index === colorSelectedIndex }">
        <div class="swatch-option__radio" :style="{backgroundImage: `url(${(imageUrl(option.url))})`}" @click.prevent="clickChange(option)"></div>
      </div>
    </div>
</template>

<script>
import { find, findIndex } from 'lodash'
import { EventBus } from '@/helpers'
import { resizeService } from 'theme/helpers'
import { optimizeImage } from '../../../../helpers'

export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    parentAttribute: {
      type: String,
      required: true
    },
    defaultSelected: {
      type: String | Number,
      required: false,
      default: null
    },
    thumbBgColor: {
      type: String,
      required: false,
      default: 'f5f5f5'
    },
    fromQuickBuy: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      colorSelectedIndex: null,
      newOptions: this.options
    }
  },
  mounted () {
    // set first color id
    if (this.defaultSelected) {
      let activeOption = find(this.options, ['value_index', this.defaultSelected])
      if (activeOption) {
        return this.change(activeOption)
      }
    }
    this.change(this.options[0])
  },
  methods: {
    clickChange (newValue) {
      if (this.fromQuickBuy) {
        EventBus.$emit('quickBuy:clickcolor', { attrID: this.parentAttribute, value: newValue })
      } else {
        EventBus.$emit('product:clickcolor', { attrID: this.parentAttribute, value: newValue })
      }

      if (newValue.value_index !== this.colorSelectedIndex) {
        EventBus.$emit('sizeSelectorPanel:selected', {})
      }
      this.newOptions = [...this.options]
      let selected = findIndex(this.newOptions, ['value_index', newValue.value_index])
      this.swapPositions(this.newOptions, selected, 0)
      let activeOption = find(this.newOptions, ['value_index', newValue.value_index])
      this.change(activeOption)
    },
    swapPositions (array, a, b) {
      [array[a], array[b]] = [array[b], array[a]]
    },
    change (newValues) {
      this.colorSelectedIndex = newValues.value_index
      this.$emit('filterSelected', this.getFilterCompose(newValues))
    },
    getFilterCompose (newValues) {
      return { type: this.parentAttribute, value: newValues }
    },
    thumbnailImage (url) {
      let color = this.thumbBgColor
      return resizeService({ url: url, color })
    },
    imageUrl (url) {
      return optimizeImage(url)
    }
  }
}
</script>
