/**
 * $filter = ex: { sku: { eq: "SKU"}}
 * $search = "SKU"
 */
import GroupedProduct from './ext/productGrouped'

function getProduct () {
  return `
  query Products($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      total_count
        items {
          __typename
          url_key
          master_sku
          ${GroupedProduct}
        }
      }
    }`
}

const product = getProduct()
export default product
