<template>
  <div :data-id="id" v-append-html="getContent" class="cms-content" />
</template>
<script>

export default {
  name: 'CmsContent',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    getContent () {
      return this.$store.getters['cms/getContent'](this.id)
    }
  }
}
</script>
