export default `query cmsPage ($id: Int, $identifier: String) {
  cmsPage (id: $id, identifier: $identifier) {
    active
    identifier
    url_key
    title
    content_ext
    content_heading
    meta_title
    meta_description
    meta_keywords
  }
}`
