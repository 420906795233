
import { state } from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default function extendUi (storeUi) {
  storeUi.getters = getters
  storeUi.actions = actions
  storeUi.mutations = { ...storeUi.mutations, ...mutations }
  storeUi.state = { ...storeUi.state, ...state() }
  return storeUi
}
