import Config from 'config'

const attrArr = JSON.stringify(Config.Theme.wishlistAttributesToload)

const configurableProduct = `
name
groupedVariation{
  attributesLabel (attributes_identifier: ${attrArr}) {
    label
    value
    attribute_id
    attribute_code
  }
  color
  hidden_variants
  name
  sku
  image {
    label
    url
  }
}
configurable_options {
  id,
  label
  attribute_code
  attribute_id
  position
  product_id
  values {
    value_index
    label
    default_label
    store_label
    use_default_value
    swatch_data{
      value
    }
  }
}
variants {
  product {
    is_backinstock
    id
    name
    sku
    stock_status
    quantity
    attribute_set_id
    color
    hidden_variants
    image {
      label
      url
    }
    special_from_date
    special_price
    special_to_date
    price_tiers {
      discount {
        amount_off
        percent_off
      }
      final_price {
        value,
          currency
      }
      quantity
    }
    price_range {
      minimum_price {
        discount {
          amount_off
          percent_off
        }
        final_price {
          value
          currency
        }
        regular_price {
          value
          currency
        }
      },
      maximum_price {
        discount {
          amount_off
          percent_off
        }
        final_price {
          value
          currency
        }
        regular_price {
          value
          currency
        }
      }
    }
  }
  attributes {
    label
    code
    value_index
  }
}
`

export const customerAddress = `
  id
  default_shipping
  default_billing
  city
  country_code
  firstname
  lastname
  company
  fax
  postcode
  region{
    region_code
    region
    region_id       
  }
  region_id
  street
  telephone
`
export const customerOut = `  
  email
  default_shipping
  default_billing
  firstname
  lastname
  date_of_birth
  is_subscribed
  subscription_type
  gender
  taxvat
  prefix
  profiling
  addresses{
    ${customerAddress}
  }
  wishlist {
    id
    items_count
    sharing_code
    items {
      id
      qty
      is_in_stock
      product {
        sku
        color
        hidden_variants
        ... on ConfigurableProduct {
          ${configurableProduct}
        }
      }
      selectedOptions {
        option_id
        option_value
        label
        value
        attribute_code
        color_image
        swatch
        type
      }
    }
  }
`
export default `query GetCustomer {
  customer{
    id
    ${customerOut}
  }
}`
