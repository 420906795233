import { init as jquerySmoothScroll } from './jQuerySmoothScroll'
import { init as slickInit } from './slickInit'
import { init as slickInstagramFeedInit } from './slickInstagramFeedInit'
import { init as jarallaxInit } from './jarallaxInit'
import { init as accordionInit } from './accordion'
import { init as imageComposition } from './imageComposition'
import { init as accordionPageBuilder } from './accordionPageBuilder'
import { init as pagebuilder } from './pagebuilder/index'
import { init as widgetB01 } from './widget-b01'
import { init as widgetE } from './widget-e'
import { init as sliderWorldOf } from './sliderWorldOf'

export default {
  jquerySmoothScroll,
  slickInit,
  slickInstagramFeedInit,
  jarallaxInit,
  accordionInit,
  imageComposition,
  accordionPageBuilder,
  pagebuilder,
  widgetB01,
  widgetE,
  sliderWorldOf
}
