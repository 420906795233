import GroupedProduct from './ext/productGroupedLight'
export default `
  query ProductsConfigurables($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      total_count
      items {
        __typename
        master_sku
        ${GroupedProduct}
      }
    }
  }
`
