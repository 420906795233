import Vuex from 'vuex'
import { extendStore } from '@/helpers/extendCore'
import Logger from '@/services/Logger'
import Config from 'config'

export function initStore (toRoute, store) {
  Logger.debug('init store')
  let token = null
  let cartId = null
  let syncCart = null

  if (process.client) {
    if (global.$cookies) {
      cartId = global.$cookies.get('cartId') || null
      token = global.$cookies.get('token') || null
      syncCart = global.$cookies.get('sync-cartId') || null
    }
  } else {
    // server side
    if (global.ctx && global.ctx.cookie) {
      cartId = global.ctx.cookie['cartId'] || null
      token = global.ctx.cookie['token'] || null
      syncCart = global.ctx.cookie['sync-cartId'] || null
    }
  }
  Logger.debug('***token:', token)
  Logger.debug('***cartId:', cartId)
  // check if clean token cart
  if (syncCart !== store.state.ui.codeLang + '-' + cartId) {
    cartId = null
  }

  if (Config.Theme.externalCheckout) {
    if (toRoute && toRoute.name === 'thankyou') {
      if (toRoute.query && toRoute.query.token) {
        if (!token) {
          // user regitered
          store.commit('user/newUser', true)
        }
        token = toRoute.query.token
        if (process.client) {
          global.$cookies && global.$cookies.set('token', token)
        } else {
          global.ctx.cookie['token'] = token
        }
      }
    }
  }

  store.commit('cart/updCartId', cartId)
  store.commit('user/setToken', token)
}

export default () => {
  let storeObj = extendStore()
  /* const isPresentErrorHandler = !!store.state.errorHandler
  if (isPresentErrorHandler) {
    store.unregisterModule('errorHandler')
  } */

  let newStore = new Vuex.Store(storeObj)
  Logger.debug('**************** NEW STORE ************')
  global.$store = newStore
  return newStore
}
