export default `query cardList{
  cardList {
    expiryMonth
    expiryYear
    holderName
    number
    type
    recurringDetailReference
  }
}`
