<template>
  <span>
    {{item.label}}
    <span class="swatch-color" :style="style"/>
  </span>
</template>

<script>
import { find } from 'lodash'

export default {
  name: 'InstantSearchSwatchcolor',
  props: {
    item: {
      type: Object,
      required: true
    },
    attrCode: {
      type: String,
      default: 'color_filter'
    },
    colors: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    style () {
      let styleBk = 'background: grey'
      if (this.colors) {
        let matchColor = find(this.colors, { label: this.item.value })
        if (matchColor && matchColor.value) {
          if (matchColor.value.startsWith('#')) {
            styleBk = `background: ${matchColor.value};`
          } else {
            styleBk = `background: center / contain no-repeat url(${matchColor.value})`
          }
        }
      }
      return styleBk
    }
  }
}
</script>
