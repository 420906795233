var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-notify-me",class:{ 'notification-sent': _vm.notifySent }},[(_vm.title && !_vm.notifySent)?_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.notifySent)?_c('div',{staticClass:"title"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('productPage.thanks')))])]):_vm._e(),_c('div',{staticClass:"description"},[(_vm.notifySent)?_c('p',{staticClass:"notify-sent"},[_vm._v(_vm._s(_vm.$t('productPage.notifySent')))]):_vm._e(),(!_vm.notifySent)?_c('p',{staticClass:"backinstock-desc"},[_vm._v(_vm._s(_vm.$t('productPage.backInStockDescription')))]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.notifySent),expression:"!notifySent"}],staticClass:"block-email"},[_c('b-form',{ref:"notify-form",attrs:{"data-vv-scope":"form-notifyme","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"notify-form__action"},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"name":"email","type":"email","autocomplete":"off","placeholder":'* ' + _vm.$t('address.Email'),"validations":[
            {
              condition: _vm.errors.has('email'),
              text: _vm.errors.first('email')
            }
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('button',{staticClass:"btn btn-none notifyme-btn",attrs:{"type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.$t('form-notify-me.submit-button'))+"\n        ")])],1),(_vm.hasPrivacyCheckbox)?_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:('required:true'),expression:"'required:true'"}],staticClass:"email-privacy-checbox",attrs:{"id":"email-privacy","name":"email-privacy","use_prevent":false,"label":_vm.labelLinkPrivacy,"validations":[{
            condition: _vm.errors.has('form-notifyme.email-privacy'),
            text: _vm.errors.first('form-notifyme.email-privacy')
        }]},on:{"label-clicked":function($event){return _vm.onLabelClicked()}},model:{value:(_vm.formCheckbox),callback:function ($$v) {_vm.formCheckbox=$$v},expression:"formCheckbox"}}):_vm._e(),(!_vm.hasPrivacyCheckbox)?_c('div',{staticClass:"email-privacy"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.labelLinkPrivacy)}})]):_vm._e(),_c('button',{staticClass:"btn btn-primary mobile",attrs:{"type":"submit"}},[_vm._v("\n      "+_vm._s(_vm.$t('form-notify-me.submit-button'))+"\n    ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }