// ACCORDION FOR MAGENTO CC PAGEBUILDER
// USE CLASS .accordion-links to use this feature
import { EventBus } from '@/helpers'
import $ from 'jquery'

export function init () {
  if (process.client) {
    EventBus.$on('cms:update', () => {
      $('li[data-content-type="faq-item"]>div').hide()
    })
  }
  $(document).on('click', 'li[data-content-type="faq-item"]>h3', function (e) {
    let $this = $(this)
    $this.toggleClass('expand')
    if ($this.hasClass('expand')) {
      $this.next().slideDown()
    } else {
      $this.next().slideUp()
    }
  })
}
