// check if category rendere block or product
// if render only block prevent loading product
// import Config from '@/config'

// const attrArr = JSON.stringify(Config.Theme.attributesToload)

export default `query Products($id: Int) {
  category (id: $id) {
    display_mode
  }
}`
