import { relatedProduct } from './productGrouped'

export default `
  query ProductsRelated($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      total_count
      items {
        ${relatedProduct}
      }
    }
  }`
