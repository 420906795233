import axios from 'axios'

const axiosInstace = axios.create({
  baseURL: process.env.VUE_APP_MAGENTO,
  headers: {
    'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
  },
  timeout: 25000 // (time is in ms)
})

export function setToken (tokenId) {
  axiosInstace.defaults.headers.Authorization = `Bearer ${tokenId || process.env.VUE_APP_MAGENTO_TOKEN}`
}

export default axiosInstace
