import actions from '@/store/modules/cart/actions'
import Config from 'config'
import Magento from '@/services/Magento'
import Graphql from '@/services/Graphql'
import { getSuffixGuest, giftCardFormDecorator } from '@/helpers'
import Bus from '@/services/Bus'

actions.update = async (ctx, {
  sku,
  itemId,
  qty,
  product,
  append = false,
  variant,
  getGiftInfoFromOptions = true,
  noSync = false
}) => {
  // TODO verify if check stock and pull is essential
  // return ctx.dispatch('checkStock', sku)

  var itemFromCart
  var newQty = qty
  const commonScript = () => {
    let findFor = itemId || sku
    itemFromCart = ctx.state.items.find(item => {
      return item.item_id === findFor || item.sku === findFor
    })
    if (newQty < 1 && itemFromCart) {
      return ctx.dispatch('delete', itemFromCart.item_id)
    }
  }

  // check product type
  if (product && product.aw_giftcard_options && product.aw_giftcard_options.aw_gc_recipient_email) {
    // add giftcard to cart
    return ctx.dispatch('getCartIDMask', {})
      .then(() => {
        commonScript()
        let filters = {
          giftCardItem: {
            cart_id: ctx.state.cartIdMask,
            cart_items: [{
              aw_giftcard_option: {
                ...giftCardFormDecorator({ giftOptions: product.aw_giftcard_options, product, getFromOptions: getGiftInfoFromOptions })
              },
              data: {
                quantity: newQty,
                sku: product.sku ? product.sku : product.product.sku
              }
            }]
          }
        }
        return Graphql.genericCall(
          {},
          ctx.rootState.user.token,
          ctx.rootState.ui.storeViewCode,
          'addGiftCardOnCart',
          filters,
          false
        )
          .then(res => {
            if (res && res.errors) {
              throw res.errors[0]
            } else {
              return res
            }
          })
          .catch(error => {
            throw error
          })
      })
      .then((res) => {
        if (res.data && res.data.extension_attributes) {
          res.data.item_id = parseInt(res.data.extension_attributes.track_product_id)
        }
        if (!noSync) {
          return ctx.dispatch('syncServerCart', {}).then(() => res.data)
        }
        return res.data
      })
  } else {
    // add product to cart
    return ctx.dispatch('getCartID', {})
      .then(() => {
        commonScript()
        const params = {
          cartItem: {
            item_id: itemFromCart ? itemFromCart.item_id : undefined,
            qty: newQty,
            quoteId: ctx.state.cartId,
            sku: sku
          }
        }
        // configurable product
        if (variant) {
          let configurableItemOptions = []
          for (let key in variant) {
            configurableItemOptions.push({
              option_id: key,
              option_value: variant[key]
            })
          }
          params.cartItem.product_option = {
            extension_attributes: {
              configurable_item_options: configurableItemOptions
            }
          }
        }
        let url = append ? Config.API.cart[`add${getSuffixGuest(ctx.rootGetters)}`] : Config.API.cart[`update${getSuffixGuest(ctx.rootGetters)}`]
        const axiosCall = append ? Magento.post : Magento.put
        return axiosCall(url
          .replace('{{storeViewCode}}', ctx.rootState.ui.storeViewCode)
          .replace('{{token}}', ctx.rootState.user.token || '')
          .replace('{{itemId}}', itemFromCart ? itemFromCart.item_id : '')
          .replace('{{cartId}}', ctx.state.cartId), params)
      })
      .then((res) => {
        // ADD GENERIC SHIPMENT TO CART
        let url = ''
        if (!ctx.rootGetters['user/isLogged']) {
          url = Config.API.cart['shippingInfoGuest']
            .replace('{{storeViewCode}}', ctx.rootState.ui.storeViewCode)
            .replace('{{cartId}}', ctx.state.cartId)
        } else {
          // ADD GENERIC SHIPMENT TO CART
          url = Config.API.cart['shippingInfo']
            .replace('{{storeViewCode}}', ctx.rootState.ui.storeViewCode)
        }
        // CHECK IF URL AND MAKE CALL FOR GENERIC SHIPMENT
        if (url) {
          Magento.post(
            url,
            {
              addressInformation: {
                shipping_address: {
                  countryId: ctx.rootState.ui.currentCountry.country
                },
                shipping_carrier_code: process.env.VUE_APP_MAGENTO_STANDARD_EXP_CARRIER_CODE || 'flatrateone',
                shipping_method_code: process.env.VUE_APP_MAGENTO_STANDARD_EXP_METHOD_CODE || 'flatrate'
              }
            },
            {
              parameters: {
                cartID: ctx.state.cartId
              }
            },
            {
              headers: {
                'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
              }
            })
            .then((resp) => {
            })
            .catch(err => {
              throw err
            })
        }
        if (!noSync) {
          ctx.dispatch('totals')
          return ctx.dispatch('syncServerCart', {}).then(() => res.data)
        }
        return res.data
      })
  }
}

actions.checkinventory = (ctx, params) => {
  let query = Config.API.argo.busCheckinventory
  return Bus.post(query, { ...params })
    .then(res => {
      ctx.commit('cart/setArgoCheckInventory', res.data, { root: true })
      return res.data
    })
    .catch(error => {
      throw error
    })
}

// actions.getCartID = (ctx, { invalid = false }) => {
//   if (global.$cookies.get('cartIdPersisted') && !ctx.rootGetters['user/isLogged']) {
//     ctx.commit('updCartId', global.$cookies.get('cartIdPersisted'))
//     return global.$cookies.get('cartIdPersisted')
//   } else if (global.$cookies.get('cartIdMask') && ctx.rootGetters['user/isLogged']) {
//     ctx.commit('updCartId', global.$cookies.get('cartIdMask'))
//     return global.$cookies.get('cartIdMask')
//   } else {
//     let url = Config.API.cart[`create${getSuffixGuest(ctx.rootGetters)}`].replace('{{storeViewCode}}', ctx.rootState.ui.storeViewCode)
//     return Magento.post(url)
//       .then(resp => {
//         global.$cookies.set('cartIdPersisted', resp.data)
//         ctx.commit('updCartId', resp.data)
//         return ctx.state.cartId
//       })
//   }
// }

export default {
  ...actions
}
