/* eslint-disable */
import $, { data } from 'jquery'
import { EventBus } from '@/helpers'
import { enablePreventScrollingSafari, disablePreventScrollingSafari } from 'theme/helpers'

var attrProcessed = 'data-bcm-processed'
var youtubeRegExp = new RegExp("^(?:https?:\/\/|\/\/)?(?:www\\.|m\\.)?" + "(?:youtu\\.be\/|youtube\\.com\/(?:embed\/|v\/|watch\\?v=|watch\\?.+&v=))([\\w-]{11})(?![\\w-])")
var vimeoRegExp = new RegExp("https?:\/\/(?:www\\.|player\\.)?vimeo.com\/(?:channels\/" + "(?:\\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\\d+)\/video\/|video\/|)(\\d+)(?:$|\/|\\?)")

let VideoWorker

export function init () {
  if (process.client) {
    VideoWorker = require('video-worker').default;
    EventBus.$on('cms:update', () => {
      addWatchTheVideoCTA()
    })
    $(document).on('click', '.bcm-video-button', function () {
      let $element = $(this)
      retrieveVideo($element.attr('src'), $element.data(), (video) => {
        appendVideoInDom(video, $element)
      })
    })
    $(document).on('click', '.watch-video-cta', function () {
      let $element = $(this)
      let $videoButton = $element.siblings('.bcm-video-button')
      if ($videoButton) {
        $videoButton.click()
      }
    })
    $(document).on('click', '.bcm-video-button__close', function () {
      let $closestOverlay = $(this).closest('.bcm-video-button__overlay')
      $closestOverlay.fadeOut(300, function () {
        disablePreventScrollingSafari()
        $(this).remove()
      })
    })
  }
}

function isHosted (src) {
  return vimeoRegExp.test(src) || youtubeRegExp.test(src)
}

function retrieveVideo (videoSrc, data, appendVideoInDom) {
  videoSrc = isHosted(videoSrc) ? videoSrc : videoSrc.substring(videoSrc.lastIndexOf('.') + 1) + ':' + videoSrc
  const videoObject = new VideoWorker(videoSrc, {
    autoplay: data.videoAutoplay,
    mute: data.videoMuted,
    loop: data.videoLoop,
    showContols: data.videoShowControls
  })
  
  if (videoObject.isValid()) {
    // retrieve iframe/video dom element.
    videoObject.getVideo(appendVideoInDom);
    videoObject.on('ended', (event) => {
      /* not triggered if videoLoop is true */
      if (!data.videoLoop) {
        let $video = isHosted(videoSrc) ? $(event.target.h) : $(event.target) 
        $video.next('.bcm-video-button__close').click()
      }
    });
  }
}

function appendVideoInDom (video, $element) {
  let data = $element.data()
  let $videoOverlay = $('<div class="bcm-video-button__overlay"></div>')
  let $closestRow = $element.closest('div[data-content-type="row"]')

  if (isHosted($element.attr('src'))) {
    video.setAttribute('loading', 'lazy')
  } else {
    video.setAttribute('preload', 'metadata')
    video.setAttribute('poster', data.videoFallbackSrc || '')
  }

  if (data.videoPlayFullWidth) {
    video.classList.add('full-width')
  }

  if (data.videoPlayInModal) {
    $videoOverlay.addClass('bcm-video-button__overlay--fixed')
    enablePreventScrollingSafari()
  } else {
    
  }

  /* check if exist invisible image for dimension of bg images*/
  if ($closestRow.parent('div[data-bcm-image-prepended]').length) {
    $closestRow.css('position', 'absolute')
  } else {
    $closestRow.css('position', 'relative')
  }

  $videoOverlay.appendTo($closestRow)
  $videoOverlay.prepend(video, $('<div class="bcm-video-button__close"></div>'))
  $videoOverlay.fadeIn('slow')
}

function addWatchTheVideoCTA () {
  let $elements = $('[data-content-type="bcame_pagebuildervideobutton"]')
  $elements.each(function () {
    let $element = $(this)
    let attr = $element.attr(attrProcessed)
    if (attr === undefined || attr === false) {
      $element.attr(attrProcessed, '')
      if ($element.hasClass('show-cta')) {
        const buttonType = $element.hasClass('show-cta--secondary')
          ? 'pagebuilder-button-link-secondary'
          : 'pagebuilder-button-link-primary'
        $element.append($(`<button class="${buttonType} watch-video-cta">${global.$i18n.t('watchTheVideo')}</button>`))
      }
    }
  })
}