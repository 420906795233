<template>
  <div class="text" v-html="formattedText"></div>
</template>

<script>
export default {
  name: 'PrintText',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedText () {
      if (this.text.includes('|')) {
        // start splitting
        let listBase = '<ul class="pl-3 mb-0">'
        let listLi = this.text.split('|')
        listLi.forEach(li => {
          if (li) {
            listBase += `<li>${li}</li>`
          }
        })
        listBase += '</ul>'
        return listBase
      } else {
        return this.text
      }
    }
  }
}
</script>
