import Router from 'vue-router'
import { setToken as MagentoSetToken } from '@/services/Magento'
import Config from '@/config'

export default () => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) {
        return {
          selector: to.hash
        }
      }
      return { x: 0, y: 0 }
      /* if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      } */
    },
    routes: [
      {
        path: '/',
        redirect: { name: 'home', params: { lang: process.env.VUE_APP_STOREVIEWCODE } }
      },
      {
        path: `/:lang(${Config.Theme.storeviewRegex_router})`,
        component: () => import('./components/Lang.vue'),
        children: [
          {
            path: `/:lang(${Config.Theme.storeviewRegex_router})`,
            name: 'home',
            component: () => import('./pages/Home.vue')
          },
          {
            path: 'my-account',
            name: 'myAccount',
            component: () => import('./pages/MyAccount/MyAccount.vue')
          },
          {
            path: 'orders',
            name: 'orders',
            component: () => import('./pages/Orders.vue')
          },
          {
            path: 'shop/:pathCategories*',
            name: 'listing',
            component: () => import('./pages/Listing.vue')
          },
          {
            path: Config.Theme.default.blogRoute + '/:slug*',
            name: 'blog',
            component: () => import('./pages/Blog.vue')
          },
          {
            path: 'p/:sku',
            name: 'product',
            component: () => import('./pages/Product.vue')
          },
          {
            path: 'checkout',
            component: () => import('./pages/Checkout.vue'),
            children: [
              {
                path: 'checkout',
                name: 'checkout',
                redirect: 'cart'
              },
              {
                path: 'cart',
                name: 'cart',
                component: () => import('./components/Checkout/Cart.vue')
              },
              {
                path: 'shipping',
                name: 'shipping',
                meta: { auth: { name: 'cart' } },
                component: () => import('./components/Checkout/Shipping.vue')
              },
              {
                path: 'billing',
                name: 'billing',
                meta: { auth: { name: 'cart' } },
                component: () => import('./components/Checkout/Billing.vue')
              },
              {
                path: 'one',
                name: 'onepagecheckout',
                // meta: { auth: { name: 'cart' } },
                component: () => import('./components/Checkout/OnePage.vue')
              },
              {
                path: 'pre-summary',
                name: 'preSummary',
                component: () => import('./components/Checkout/PreSummary.vue')
              },
              {
                path: 'thankyou',
                name: 'thankyou',
                component: () => import('./components/Checkout/Thankyou.vue')
              }
            ]
          },
          {
            path: 'pagina-non-trovata',
            name: 'it-IT_page-not-found',
            component: () => import('./pages/PageNotFound.vue')
          },
          {
            path: 'page-not-found',
            name: 'page-not-found',
            component: () => import('./pages/PageNotFound.vue')
          },
          /* {
            path: 'static/:slug',
            name: 'staticPage',
            component: () => import('./pages/Static.vue')
          }, */
          {
            path: ':slug',
            name: 'staticPage',
            component: () => import('./pages/Static.vue')
          }// ,
          /* {
            path: '*',
            redirect: { name: 'it-IT_page-not-found' }
          } */
        ]
      },
      {
        path: '*',
        redirect: { name: `${process.env.VUE_APP_STOREVIEWCODE}_page-not-found`, params: { lang: process.env.VUE_APP_STOREVIEWCODE } }
      }
    ]
  })

  const whiteList = ['thankyou', 'cart']
  router.beforeEach((to, from, next) => {
    const store = global.$store
    MagentoSetToken(store.state.user.token)
    // sync cart
    if (process.client) {
      if (!whiteList.includes(to.name)) {
        if (store.state.cart.cartId) {
          store.dispatch('cart/syncServerCart', {})
        }
      }
    }
    next()
  })

  return router
}
