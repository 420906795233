export default `query GetPosts($allPosts: Boolean, $filters: BlogPostsFilterInput) {
  blogPosts(
    allPosts: $allPosts
    filter: $filters
  ) {
    items {
      tags {
        tag_id
        title
        identifier
        is_active
        position
      }
    }
  }
}`
