<template>
  <div>
    <b-modal
      :modal-class="getClassFromProp('modal-class')"
      :dialog-class="getClassFromProp('dialog-class')"
      :content-class="getClassFromProp('content-class')"
      :header-class="getClassFromProp('header-class')"
      :title-class="getClassFromProp('title-class')"
      :body-class="getClassFromProp('body-class')"
      :footer-class="getClassFromProp('footer-class')"
      v-bind="getPropsBootstrapVueModal"
      v-model="showModal"
      @shown="modalShowed"
      @hidden="onCloseModal">
      <template v-if="modalFullyShowed">
        <component :class="[dynamicClass]" :is="getComponent" v-bind="getProps"></component>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from '@/helpers'
export default {
  name: 'DynamicModal',
  data () {
    return {
      modalFullyShowed: false,
      defaultModalClasses: {
        'modal-class': 'dynamic-modal',
        'dialog-class': 'dynamic-modal__dialog',
        'content-class': 'dynamic-modal__dialog__content',
        'header-class': 'dynamic-modal__dialog__content__header',
        'title-class': 'dynamic-modal__dialog__content__header__title',
        'body-class': 'dynamic-modal__dialog__content__body',
        'footer-class': 'dynamic-modal__dialog__content__footer'
      }
    }
  },
  computed: {
    showModal: {
      get () {
        return Object.keys(this.$store.getters['ui/getDyamicModalComponent']).length > 0
      },
      set (newValue) {
        return newValue
      }
    },
    getComponent () {
      const observer = this.$store.getters['ui/getDyamicModalComponent']
      if (observer && observer.component) {
        return observer.component
      } else {
        return ''
      }
    },
    getPropsBootstrapVueModal () {
      const observer = this.$store.getters['ui/getDyamicModalComponent']
      if (observer && observer.propsBootstrapVueModal) {
        return observer.propsBootstrapVueModal
      }
      return {}
    },
    getProps () {
      const observer = this.$store.getters['ui/getDyamicModalComponent']
      if (observer && observer.props) {
        return observer.props
      }
      return {}
    },
    dynamicClass () {
      let classObj = {}
      if (this.getComponent && this.getComponent.name) {
        classObj = {
          [`dynamic-modal__component--${this.getComponent.name.toLowerCase()}`]: true
        }
      } else {
        classObj = ['dynamic-modal__component']
      }
      return classObj
    }
  },
  methods: {
    modalShowed () {
      setTimeout(() => {
        this.modalFullyShowed = true
      }, 300)
    },
    getClassFromProp (propName) {
      if (this.getPropsBootstrapVueModal && this.getPropsBootstrapVueModal[propName]) {
        return this.getPropsBootstrapVueModal[propName]
      }
      return this.defaultModalClasses[propName]
    },
    onCloseModal () {
      this.$store.commit('ui/setDynamicModalComponent', {})
      this.modalFullyShowed = false
    }
  },
  mounted () {
    EventBus.$on('dynamicModal:close', this.onCloseModal)
  },
  destroyed () {
    EventBus.$off('dynamicModal:close', this.onCloseModal)
  }
}
</script>
