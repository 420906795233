/*
 *     Copytop (c) 2020 Bcame S.r.l.. All tops reserved.
 *     Bcame S.r.l. Technology Business Unit
 *
 *     Company confidential.
 *
 *     This software is proprietary  to and embodies the confidential
 *     technology of Bcame S.r.l..  Possession, use, or copying of this
 *     software and media is authorized only pursuant to a valid written
 *     license from Bcame S.r.l.  or an authorized sublicensor.
 *
 *     Bcame S.r.l.
 */

<template>
  <div class="language-selector-with-box-search">
    <div class="dropdown">
      <button :id="`box-btn-select-${name}`" class="btn dropdown-toggle dropdown-btn-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
        {{(selectedValue && selectedValue.label) ? selectedValue.label : $t('languageSelectorWithBoxSearch.defaultPlaceholder')}}
      </button>
      <div :id="`box-select-${name}`" class="dropdown-menu dropdown-menu-bottom" :aria-labelledby="`box-btn-select-${name}`">
        <div class="dropdown-menu-search">
          <div class="dropdown-menu-search__box">
            <input type="text" :id="`box-search-${name}`" class="box-search" :placeholder="$t('languageSelectorWithBoxSearch.search')" @keyup="doSearch()">
          </div>
          <div class="dropdown-menu-search__icon">
            <svg class="o-icon">
              <use xlink:href="#search"></use>
            </svg>
          </div>
        </div>
        <div class="dropdown-menu-items">
          <a class="dropdown-item" :href="option.url" v-for="(option, index) in options" :key="index" @click="setLocations(option.originalObj.area, option.originalObj)">
            {{option.label}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelectorWithBoxSearch',
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    selectedValue: {
      type: Object,
      required: false,
      default: null
    }
  },
  methods: {
    doSearch () {
      let input = document.getElementById(`box-search-${this.name}`)
      let filter = input.value.toUpperCase()
      let div = document.getElementById(`box-select-${this.name}`)
      let a = div.getElementsByTagName('a')
      for (let i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = ''
        } else {
          a[i].style.display = 'none'
        }
      }
    },
    setLocations (currentArea, currentCountry) {
      // if not exist "currentArea" or "currentCountry", return false
      if (!currentArea || !currentCountry) {
        return false
      }
      this.$store.commit('ui/setCountry', currentCountry)
      this.$store.commit('ui/setStateArea', currentArea)
      return true
    }
  }
}
</script>
