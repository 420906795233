<template>
  <vue-slick v-bind="sliderOptions" ref="mediaSlider">
    <template v-for="(image, index) in sources">
      <image-gallery
      :key="index"
      :img-path="image[srcValue]"
      :alt="image.label"
      size="LG"
      :hasVideo="image.video_content ? image.video_content : {}"
      />
    </template>
  </vue-slick>
</template>

<script>
import ImageGallery from 'theme/components/Product/Image.vue'

export default {
  name: 'ImageSlider',
  props: {
    sources: {
      type: Array,
      required: false,
      default: () => []
    },
    srcValue: {
      type: String,
      default: 'urlResize'
    }
  },
  data () {
    return {
      // slick
      sliderOptions: {
        slidesToShow: 1,
        dots: true,
        arrows: false
      }
    }
  },
  watch: {
    sources: function () {
      this.$refs.mediaSlider.goTo(0)
    }
  },
  components: {
    ImageGallery
  }
}
</script>
