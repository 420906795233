import $ from 'jquery'
import { EventBus } from '@/helpers'
import { isMobile } from '@/helpers/utils'

var attrProcessed = 'data-bcm-processed'

export function init () {
  if (process.client && !isMobile()) {
    EventBus.$on('cms:update', () => {
      buildWidget()
    })
    $(document)
      .on({
        mouseenter: function () {
          let $pagebuilderColumn = $(this)
          let $pagebuilderColumnSibling = $pagebuilderColumn.siblings('.pagebuilder-column')
          let $background = $(`#${$pagebuilderColumn.attr('id')}.background`)
          let $backgroundSibling = $background.siblings('.background')
          $pagebuilderColumn.addClass('is-active')
          $background.addClass('is-active')
          $backgroundSibling.removeClass('is-active')
          $pagebuilderColumnSibling.removeClass('is-active')
        }
      }, '.widget-b01 .pagebuilder-column')
  }
}

function buildWidget () {
  $(function () {
    let $elements = $('.widget-b01')
    $elements.each(function () {
      let $element = $(this)
      let attr = $element.attr(attrProcessed)
      if (attr === undefined || attr === false) {
        // set $el as processed
        $element.attr(attrProcessed, '')
        let $pagebuilderColumns = $element.find('[data-content-type="column"]')
        let $wrapperBackgroundImages = $('<div class="backgrounds"></div>')
        $pagebuilderColumns.each((index, element) => {
          let $column = $(element)
          let randomId = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
          $column.attr('id', randomId)
          // remove background on fake column
          $column.parent('.pagebuilder-column')
            .css('background-image', 'none')

          let $imageObject = JSON.parse($column.data('backgroundImages').replace(/\\(.)/mg, '$1')) || {}
          let $background = $(`<div class="background">`)

          if (index === 0) {
            $column.addClass('is-active')
            $background.addClass('is-active')
            $element.prepend(`<img class="img-fluid invisible" src="${$imageObject.desktop_image}" />`)
          }

          $background.attr('id', randomId)
          $background.attr('data-background', $imageObject.desktop_image)
          $background.css('background-image', 'url(' + $imageObject.desktop_image + ')')
          $background.appendTo($wrapperBackgroundImages)
        })
        $wrapperBackgroundImages.appendTo($element)
      }
    })
  })
}
