export default `
{
  checkoutAgreements {
    agreement_id
    checkbox_text
    content
    is_html
    name
    mode
  }
}
`
