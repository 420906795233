export default store => {
  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'checkout/setShippingAddress':
        if (process.client) {
          global.$cookies.set('lastShippingAddress', state.checkout.shippingAddress)
        }
        break
      case 'checkout/setBillingAddress':
        if (process.client) {
          global.$cookies.set('lastBillingAddress', state.checkout.billingAddress)
        }
        break
      case 'checkout/setPaymentSelected':
        if (process.client) {
          global.$cookies.set('lastBillingAddress', state.checkout.billingAddress)
        }
        break
    }
  })
}
