import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Logger from '@/services/Logger'
import Config from 'config'
import Magento from '@/services/Magento'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
dayjs.extend(localeData)

Vue.use(VueI18n)

export let ajaxMessages = null

export let i18n

/* function loadLocaleMessages () {
  const locales = Config.Theme.enablei18n ? require.context('theme/i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i) : require.context('./i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
} */
let loadedMesg = {}
let promiseArr = {}

function loadAsyncMsg (locale, storeviewcode, localeCountry) {
  if (promiseArr[locale]) {
    return promiseArr[locale]
  }
  if (loadedMesg[locale]) {
    return Promise.resolve(loadedMesg[locale])
  }
  promiseArr[locale] = Magento.get(
    Config.API.locale
      .replace(/{{storeViewCode}}/g, storeviewcode)
      .replace('{{locale}}', localeCountry))
    .then(res => {
      if (res.data[0]) {
        return res.data[0]
      } else {
        return require('@/i18n/en.json')
      }
    })
    .finally(() => {
      promiseArr[locale] = null
    })
  return promiseArr[locale]
}

export async function loadLanguageAsync (locale, storeviewcode, localeCountry) {
  let message = {}
  if (process.env.VUE_APP_LOCALEDB === 'false') {
    message = loadedMesg[locale] ? loadedMesg[locale] : require(`theme/i18n/${locale}.json`)
  } else {
    message = await loadAsyncMsg(locale, storeviewcode, localeCountry)
  }
  if (loadedMesg[locale]) {
    Logger.debug(`loaded locale ${storeviewcode} message from cache`)
  } else {
    loadedMesg[locale] = message
    Logger.debug(`loaded locale ${storeviewcode} message from call`)
  }

  try {
    dayjs.locale(require(`dayjs/locale/${locale}.js`))
  } catch (e) {
    console.error('cannot load locale')
  }
  i18n.setLocaleMessage(locale, message)
  return message
}

export default (store) => {
  const localeComplete = store.state.ui.locale ? store.state.ui.locale : process.env.VUE_APP_I18N_LOCAL
  const language = localeComplete.substr(0, 2) || 'en'
  if (Object.keys(store.state.ui.messages).length) {
    loadedMesg = { [language]: store.state.ui.messages }
  }
  global.$i18n = new VueI18n({
    locale: language,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadedMesg
  })
  i18n = global.$i18n
  return global.$i18n
}
