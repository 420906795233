import Config from '@/config'

export default `query suggestionsProduct ($inputText: String!) {
  products (search: $inputText, currentPage: 1, pageSize: ${Config.Theme.searchSuggestions.productLimit}) {
    aggregations {
      label
      count
      attribute_code
      options {
        label
        value
      }
    }
    aggregationsStore {
      label
      count
      attribute_code
      options {
        label
        value
      }
    }
    items {
      id
      name
      sku
      small_image {
        url
        label
      }
      url_key
      url_suffix
      price {
        regularPrice {
          amount {
            value
            currency
          }
        }
      }
    }
    page_info {
      total_pages
    }
    total_count
  }
}`
