
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export function feelbuy () {
  return {
    namespaced: true,
    state: {
      attributes: []
    },
    getters,
    actions,
    mutations
  }
}
