
export default {
  it: {
    'return-and-refunds': 'returns-and-refunds',
    'blog': 'world-of'
  },
  en: {
    // CUSTOMER CARE
    'contacts': 'contacts',
    'faq': 'faq',
    'follow-your-order': 'follow-your-order',
    'time-of-delivery': 'time-of-delivery',
    'returns-and-refunds': 'returns-and-refunds',
    'payments': 'payments',
    'fashion-expert': 'fashion-expert',
    'book-an-appointment': 'book-an-appointment',
    // THE COMPANY
    'boutiques': 'negozi',
    'work-with-us': 'lavora-con-noi.html',
    // LEGAL AREA
    'seller-legal-warranty': 'garanzia-venditore'
  }
}
