const orderInfo = `
  base_order_info {
    created_at
    grand_total
    id
    increment_id
    status
  }
  shipping_info {
    shipping_address {
      postcode
      city
      firstname
      lastname
      telephone
      street
      region
      country_id
    }
    shipping_description
    shipping_method
  }
  payment_info {
    additional_information {
      method_title
    }
    method
  }
`
const orderProduct = `
  my_order_products {
    id
    name
    color
    size
    row_total
    qty
    image {
      url
      label
    }
    sku
    stock_status
    special_price
    price_tiers {
      discount {
        amount_off
        percent_off
      }
      final_price {
        value,
          currency
      }
      quantity
    }
    price_range {
      minimum_price {
        discount {
          amount_off
          percent_off
        }
        final_price {
          value
          currency
        }
        regular_price {
          value
          currency
        }
      },
      maximum_price {
        discount {
          amount_off
          percent_off
        }
        final_price {
          value
          currency
        }
        regular_price {
          value
          currency
        }
      }
    }
  }
`

export const ordersList = `
{
  getMyOrderList {
    items {
      ${orderInfo}
      ${orderProduct}
    }
  }
}
`

export const getOrderById = `
query GetOrderById($id: Int!) {
  getOrderById (id: $id) {
    ${orderInfo}
    ${orderProduct}
  }
}
`
