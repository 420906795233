import Config from 'config'
import Graphql from '@/services/Graphql'
import get from 'lodash/get'
import actions from '@/store/modules/cms/actions'
import cmsBlocks from '@/services/graphQlquery/cmsBlocks'

const storeViewCodeRegex = /(({)+(storeViewCode)(})+)|(store_view_code)/g

actions.global = (ctx) => {
  return Graphql.genericCallCash({
    token: ctx.rootState.user.token,
    storeViewCode: ctx.rootState.ui.storeViewCode,
    graphSchemaType: '',
    useInlineQuery: cmsBlocks,
    variables: {
      identifiers: Config.API.cms.globalIDs
    },
    useCache: true
  })
    .then((res) => {
      // load data
      const blocks = get(res.data, 'cmsBlocks.items', [])
      for (let index = 0; index < blocks.length; index++) {
        if (blocks[index]) {
          // block exist
          const content = blocks[index].content_ext.replace(storeViewCodeRegex, ctx.rootState.ui.codeLang)
          const identifier = blocks[index].identifier
          ctx.commit('setContent', { identifier, content })
          if (!process.client) {
            ctx.commit('setSsrIDS', identifier)
          }
        }
      }

      return res.data
    })
}

export default {
  ...actions
}
