import Config from 'config'
import Magento from '@/services/Magento'
import { restWithCache } from '@/store/lib/search'
import { ordersList, getOrderById } from './graphQlquery/orders'
import { saveWishlistItem, removeWishlistItem, moveAllToCart, addGifCardToWishlist, updateProductsInWishlist, getWishlistBySharingCode, shareWishlist } from './graphQlquery/wishlist'
import { setPickupIdOnCart, getPickupLocationIdFromCode, getStorePickupByCountry, getStoreLocatorConfig } from './graphQlquery/storePickup'
import { addGiftCardOnCart } from './graphQlquery/giftCardCart'
import { getFeelBuyAttributes } from './graphQlquery/feelbuy'
import { graphSocialLogin } from './graphQlquery/socialLogin'
import graphListing from './graphQlquery/listing'
import graphProduct from './graphQlquery/product'
import productConfigurable from './graphQlquery/productConfigurable'
import graphCart from './graphQlquery/cart'
import blogCategories from './graphQlquery/blogCategories'
import blogCategory from './graphQlquery/blogCategory'
import blogPosts from './graphQlquery/blogPosts'
import blogCategoryWithPosts from './graphQlquery/blogCategoryWithPosts'
import blogPost from './graphQlquery/blogPost'
import blogPostsTags from './graphQlquery/blogPostsTags'
import graphCustomer from './graphQlquery/customer'
import updateCustomer from './graphQlquery/updateCustomer'
import updateAddress from './graphQlquery/updateAddress'
import createAddress from './graphQlquery/createAddress'
import deleteAddress from './graphQlquery/deleteAddress'
import rmaList from './graphQlquery/rmaList'
import rmaDetail from './graphQlquery/rmaDetail'
import rmaOrdersAvailable from './graphQlquery/rmaOrdersAvailable'
import checkGuestOrder from './graphQlquery/checkGuestOrder'
import rmaNewPrepare from './graphQlquery/rmaNewPrepare'
import rmaCreate from './graphQlquery/rmaCreate'
import cardList from './graphQlquery/cardList'
import cardDisable from './graphQlquery/cardDisable'
import urlResolve from './graphQlquery/urlResolve'
import storeConfig from './graphQlquery/storeConfig'
import suggestionsProduct from './graphQlquery/suggestionsProduct'
import singleLookbook from './graphQlquery/singleLookBook'
import mergeCarts from './graphQlquery/mergeCarts'
import customerCart from './graphQlquery/customerCart'
import customerChangePassword from './graphQlquery/customerChangePassword'
import unsubscribeEmail from './graphQlquery/unsubscribeEmail'
import categoryById from './graphQlquery/categoryById'
import variantExt from './graphQlquery/variantExt'
import vaultManagement from './graphQlquery/vaultManagement'

const graphSchema = {
  graphListing,
  graphProduct,
  productConfigurable,
  blogCategories,
  blogCategory,
  blogPosts,
  blogCategoryWithPosts,
  blogPost,
  blogPostsTags,
  ordersList,
  getOrderById,
  graphCart,
  graphCustomer,
  graphSocialLogin,
  getFeelBuyAttributes,
  saveWishlistItem,
  removeWishlistItem,
  moveAllToCart,
  addGifCardToWishlist,
  updateProductsInWishlist,
  getWishlistBySharingCode,
  shareWishlist,
  updateCustomer,
  updateAddress,
  createAddress,
  deleteAddress,
  rmaList,
  rmaDetail,
  rmaOrdersAvailable,
  checkGuestOrder,
  rmaNewPrepare,
  rmaCreate,
  cardList,
  cardDisable,
  urlResolve,
  storeConfig,
  suggestionsProduct,
  singleLookbook,
  mergeCarts,
  customerCart,
  addGiftCardOnCart,
  customerChangePassword,
  unsubscribeEmail,
  categoryById,
  variantExt,
  setPickupIdOnCart,
  getPickupLocationIdFromCode,
  getStorePickupByCountry,
  getStoreLocatorConfig,
  vaultManagement
}

if (Config.Theme.extGraph) {
  const extGraphJS = require.context('theme/services/graphQlquery', false, /.js$/i)
  extGraphJS.keys().forEach(key => {
    graphSchema[Config.Theme.extGraph[key]] = extGraphJS(key).default
  })
}
class Graphql {
  // constructor () { }
  toGraphQlFilter (jsonObj) {
    let convertedStr = ''
    let value
    for (var key in jsonObj) {
      value = typeof jsonObj[key] === 'object' && !Array.isArray(jsonObj[key]) ? `{${this.toGraphQlFilter(jsonObj[key])}}` : JSON.stringify(jsonObj[key])
      convertedStr += `${key}:${value},`
    }
    return convertedStr.slice(0, -1)
  }

  parserGraphQl (filter) {
    return this.toGraphQlFilter(filter)
      .replace(':"DESC"', ':DESC')
      .replace(':"ASC"', ':ASC')
  }

  genericCall (config, token, storeViewCode, graphSchemaType, variables = null, useCache = false, option = null, shortAge = false) {
    let query = option && option.query ? option.query : graphSchema[graphSchemaType]
    for (var key in config) {
      query = query.replace(`{{${key}}}`, this.parserGraphQl(config[key]))
    }
    return this.doCall(query, variables, token, storeViewCode, useCache, shortAge)
  }

  // CART MUTATION
  addCouponOnCart (cartID, couponCode, token, storeViewCode) {
    let query = graphCart.addCoupon()
    let variables = {
      input: {
        cart_id: cartID,
        coupon_code: couponCode
      }
    }
    return { query, variables, token, storeViewCode }
    // doCall on page src\theme\components\Checkout\Cart\Coupon.vue
  }

  removeCouponFromCart (cartID, token, storeViewCode) {
    let query = graphCart.removeCoupon()
    let variables = {
      input: {
        cart_id: cartID
      }
    }
    return { query, variables, token, storeViewCode }
    // doCall on page src\theme\components\Checkout\Cart\Coupon.vue
  }

  clearCart (cartID, token, storeViewCode) {
    let query = graphCart.clearLoggedCart()
    let variables = {
      input: {
        cart_id: cartID
      }
    }
    return { query, variables, token, storeViewCode }
  }

  doCall (query, variables = null, token, storeViewCode, useCache = false, shortAge = false) {
    let config = {
      headers: {
        'Authorization': '',
        'Store': storeViewCode
      }
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    // useCache on call for GET request
    // do not use cache when request need a data update
    if (useCache) {
      // return restWithCache({
      //   query: { header: config.headers },
      //   body: { query, variables, storeViewCode },
      //   restPromise: Magento.post(Config.API.graphql, { query, variables }, config)
      // })
      let data = {
        params: { query, variables, store: storeViewCode },
        headers: {
          'Authorization': '',
          'Store': storeViewCode
        }
      }
      if (token) {
        data.headers.Authorization = `Bearer ${token}`
      }

      data.headers['Cache-Control'] = 's-maxage=864000, max-age=0'
      data.headers.pragma = 'cache'
      if (shortAge) {
        data.headers['Cache-Control'] = 's-maxage=3600, max-age=0'
      }

      data['params']['query'] = data['params']['query']
        .replace(/#.*\n/g, '')
        .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
        .replace(/:\s/g, ':')
        .replace(/,\s/g, ',')
        .replace(/\)\s\{/g, '){')
        .replace(/\}\s/g, '}')
        .replace(/\{\s/g, '{')
        .replace(/\s\}/g, '}')
        .replace(/\s\{/g, '{')
        .replace(/\)\s/g, ')')
        .replace(/\(\s/g, '(')
        .replace(/\s\)/g, ')')
        .replace(/\s\(/g, '(')
        .replace(/=\s/g, '=')
        .replace(/\s=/g, '=')
        .replace(/@\s/g, '@')
        .replace(/\s@/g, '@')
        .replace(/\s\$/g, '$')
        .replace(/\s\./g, '.')
        .trim()
      return restWithCache({
        query: { header: config.headers },
        body: data,
        restPromise: Magento.get(Config.API.graphql + '?usecache=true', data)
      })
    } else {
      return Magento.post(Config.API.graphql, { query, variables }, config).then(res => res.data)
    }
  }

  genericCallCash ({ token, storeViewCode, graphSchemaType, variables, useCache = false, useInlineQuery = false, shortAge = false }) {
    let query = useInlineQuery || graphSchema[graphSchemaType].replace(/(\s){2,}/g, ' ')
    return this.graphCash({ query, variables, token, storeViewCode, useCache, id: graphSchemaType, shortAge })
  }

  graphCash ({ query, variables, token, storeViewCode, useCache, id, shortAge }) {
    let data = {
      params: { query, variables, store: storeViewCode },
      headers: {
        'Authorization': '',
        'Store': storeViewCode
      }
    }
    if (token) {
      data.headers.Authorization = `Bearer ${token}`
    }

    // useCash on call for GET request
    // do not use cache when request need a data update
    if (useCache) {
      // Cache-Control
      // https://developer.fastly.com/learning/concepts/cache-freshness/#cache-in-fastly-not-in-browsers
      data.headers['Cache-Control'] = 's-maxage=864000, max-age=0'
      data.headers.pragma = 'cache'
      if (shortAge) {
        data.headers['Cache-Control'] = 's-maxage=3600, max-age=0'
      }

      data['params']['query'] = data['params']['query']
        .replace(/#.*\n/g, '')
        .replace(/[\s|,]*\n+[\s|,]*/g, ' ')
        .replace(/:\s/g, ':')
        .replace(/,\s/g, ',')
        .replace(/\)\s\{/g, '){')
        .replace(/\}\s/g, '}')
        .replace(/\{\s/g, '{')
        .replace(/\s\}/g, '}')
        .replace(/\s\{/g, '{')
        .replace(/\)\s/g, ')')
        .replace(/\(\s/g, '(')
        .replace(/\s\)/g, ')')
        .replace(/\s\(/g, '(')
        .replace(/=\s/g, '=')
        .replace(/\s=/g, '=')
        .replace(/@\s/g, '@')
        .replace(/\s@/g, '@')
        .replace(/\s\$/g, '$')
        .replace(/\s\./g, '.')
        .trim()
      return restWithCache({
        query: id,
        body: data,
        restPromise: Magento.get(Config.API.graphql + '?usecache=true', data)
      })
    } else {
      let headers = { headers: {
        'Authorization': '',
        'Store': storeViewCode
      } }
      // return Magento.post(Config.API.graphql + '?usecache=false', { query, variables }, data).then(res => res.data)
      return Magento.post(Config.API.graphql + '?usecache=false', { query, variables }, headers).then(res => res.data)
    }
  }
}

const instanceGraph = new Graphql()

export default instanceGraph
