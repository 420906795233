import Config from 'config'

export default [
  {
    path: `/:lang(${Config.Theme.storeviewRegex})/:slug*`,
    name: 'factoryPage',
    component: () => import('./pages/Factory.vue'),
    meta: {
      tplName: 'factoryPage'
    }
  }
]
