<template>
  <div>
    <component :is="componentToLoad" />
  </div>
</template>

<script>
import InstantSearchDesktop from 'theme/components/InstantSearch/InstantSearchDesktop.vue'
// import InstantSearchMobile from 'theme/components/InstantSearch/InstantSearchMobile.vue'
export default {
  name: 'InstantSearch',
  data () {
    return {
    }
  },
  computed: {
    componentToLoad () {
      return 'InstantSearchDesktop'
      // return this.$store.state.ui.isMobile ? 'InstantSearchMobile' : 'InstantSearchDesktop'
    }
  },
  components: {
    InstantSearchDesktop
    // InstantSearchMobile
  }
}
</script>
