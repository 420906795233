<template>
  <!-- Card product require
      - image
      - name
      -url
      -price
      -custom attribute (Exclusive online)
    -->
  <div class="block-product card-product card-product-teaser">
    <div class="card-product__media">
      <router-link
        :to="productLink"
        :title="product.name"
        class="card-product__info__intro__name">
        <image-gallery
          :imgPath="getCropUrl"
          :alt="product.name"
          size="MD"
          :defaultLoading="require('theme/assets/image/dump.png')"
          :styleImage="{ maxWidth: '100%', height: 'auto' }"
          :changeAble="false"/>
      </router-link>
    </div>
    <div class="card-product__info">
      <div class="card-product__info__intro">
        <router-link
        :to="productLink"
        :title="product.name"
        class="card-product__info__intro__name"><h2 class="name-title" v-html="product.name"></h2></router-link>
      </div>
      <div class="card-product__info__price">
        <product-price :product="product"></product-price>
      </div>
    </div>
  </div>
</template>

<script>
import ImageGallery from '@/components/Product/Image.vue'
import ProductPrice from '@/components/Listing/ProductPrice.vue'
import Config from '@/config'
// import { localizedByName, prodCatPaths } from '@/filters/routers'
import { localizedByName } from '@/filters/routers'
import { resizeService } from '@/helpers/utils'
import { sortBy } from 'lodash'

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    productLink () {
      if (this.product) {
        let path = '/'
        // let path = '/' + prodCatPaths(this.product, this.$store)
        // to do testare la categoria
        /* if (this.$store.state.category.current && this.$store.state.category.current.url_path) {
          path += this.$store.state.category.current.url_path + '/'
        } */
        return localizedByName('factoryPage', this.$store, this.$router) + path + this.product.url_key + this.product.url_suffix
      }
      return ''
    },
    getCropUrl () {
      if (this.product && this.product.media_gallery && this.product.media_gallery.length) {
        let mediaGallery = sortBy(this.product.media_gallery, ['position'])
        const { width, height, quality } = Config.Theme.listingOpt.imageCrop
        return `${resizeService(mediaGallery[0].url)}?width=${width}${height ? '&height=' + height : ''}&q=${quality}`
      }
      return ''
    }
  },
  components: {
    ImageGallery,
    ProductPrice
  }
}
</script>
