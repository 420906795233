<template>
  <div id="chat-overlay" v-show="showButton">
    <div id="live-chat-tab" @click.prevent="openChat" role="button" aria-label="tab chat" tabindex="0" class="live-chat-offline">
      <img class="chat-tab-image" :src="require('theme/assets/image/chat-monogram.png')" alt="Icon chat">
    </div>
  </div>
</template>

<script>
// CHAT CREATED FOR ZENDESCK
// ANYWAY IT IS POSSIBLE ADAPT IT TO VARIUS CHAT
import { EventBus } from '@/helpers'
import { chatSettings } from 'theme/helpers'

export default {
  name: 'Chat',
  props: {
    openInPage: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      chatKey: '3f9100c9-6b43-4445-b08d-8f965fd753d6',
      showButton: false
    }
  },
  methods: {
    openChat () {
      if (window.zE) {
        if (this.openInPage) {
          window.zE.activate && window.zE.activate()
        } else {
          window.zE('webWidget', 'popout')
        }
      }
    },
    hideChat () {
      if (window.zE) {
        window.zE('webWidget', 'hide')
        window.zE.hide && window.zE.hide()
      }
    },
    initChat () {
      // await 2s to be sure
      setTimeout(() => {
        this.showButton = true
        this.hideChat()
      }, 2000)
    }
  },
  created () {
    const disabledLogger = function (method, ...args) {
      console.log('Zendesk is disabled, you called:', { method, args })
    }
    window.zE = disabledLogger
    window.zESettings = chatSettings()
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.id = 'ze-snippet'
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${this.chatKey}`
    delete window.zE
    const first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(script, first)
    script.onload = event => {
      window.zE('webWidget', 'hide')
      EventBus.$emit('chat-loaded', event)
    }
  },
  mounted () {
    EventBus.$on('chat-loaded', this.initChat)
    EventBus.$on('chat::open', this.openChat)
  },
  destroyed () {
    EventBus.$off('chat-loaded', this.initChat)
    EventBus.$off('chat::open', this.openChat)
  }
}
</script>

<style lang="scss" scoped>
  #chat-overlay {
    position: fixed;
    width:70px;
    height:70px;
    /* top: 445px; */
    right: 10px;
    /* margin-top: -157px; */
    bottom:50px;
    cursor:pointer;

    #live-chat-tab {
      img {
        width:70px;
        height:70px;
        max-width: 100%;
      }
    }
  }
</style>

<style lang="scss">
  // hide native chat button launcher from css to avoid async problems
  #launcher {
    display: none!important;
  }
</style>
