import getters from '@/store/modules/cart/getters'
import sortBy from 'lodash/sortBy'

getters.totalSegments = (state, getters, rootState) => {
  let excluded = ['amasty_checkout', 'giftwrapping', 'customerbalance', 'tax', 'reward']
  if (rootState.ui.storeViewCode.startsWith('ph_us')) { // check later if ph_us is correct for philosophy
    excluded = ['amasty_checkout', 'giftwrapping', 'customerbalance', 'reward']
  }
  let filteredSegments = state.total && state.total.total_segments ? state.total.total_segments.filter(item => (excluded).indexOf(item.code) === -1) : []

  // sort segments with this array, it will take the codes position in the array and will sort the segments according to them
  // some of these codes are excluded above, they could be removed from 'sortSegmentCodes' but is safer to leave them there
  const sortSegmentCodes = ['subtotal', 'giftwrapping', 'shipping', 'discount', 'tax', 'grand_total', 'customerbalance', 'reward']
  let sortedSegments = filteredSegments.map(segment => {
    return {
      ...segment,
      position: sortSegmentCodes.indexOf(segment.code) // add the sort position to the native segments objects
    }
  })
  sortedSegments = sortBy(sortedSegments, ['position'])
  return sortedSegments
}

// getters.totalShippingAmounts = (state) => {
//   const shippingAmounts = state.total ? {
//     'shipping_amount': state.total.shipping_amount,
//     'shipping_discount_amount': state.total.shipping_discount_amount,
//     'shipping_incl_tax': state.total.shipping_incl_tax,
//     'shipping_tax_amount': state.total.shipping_tax_amount
//   } : {}
//   return shippingAmounts
// }

getters.totals = (state) => {
  return state.total ? state.total : {}
}

getters.grandTotalBeforeCoupon = (state) => {
  return state.grandTotalBeforeCoupon
}

export default {
  ...getters
}
