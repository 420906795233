import Graphql from '@/services/Graphql'
import Magento from '@/services/Magento'
import Logger from '@/services/Logger'
import Config from 'config'
import find from 'lodash/find'

const headerConfig = {
  headers: {
    'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
  }
}

class Rma {
  list ({ token, storeViewCode, graphQuery = 'rmaList', variables = null }) {
    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      graphQuery,
      variables,
      false) // use cache
      .then(resp => {
        return resp && resp.data && resp.data.rmasExt ? resp.data.rmasExt.items : []
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  detail ({ token, storeViewCode, variables }) {
    return this.list({ token, storeViewCode, graphQuery: 'rmaDetail', variables })
      .then(resp => resp.length > 0 ? resp[0] : {})
  }

  rmaFields ({ storeViewCode }) {
    let query = Config.rma.endpoint.fields
      .replace('{{storeViewCode}}', storeViewCode)

    return Magento.get(query, headerConfig)
      .then((res) => {
        let result = res.data
        let converted = Object.assign({}, ...result.items.map((object) => ({ [object.parent_option_id]: object })))
        // let searchCriteria = `searchCriteria[filter_groups][0][filters][0][field]=refers&
        // searchCriteria[filter_groups][0][filters][0][value]=item`
        let query = Config.rma.endpoint.customFieldSearch
          .replace('{{storeViewCode}}', storeViewCode)
          // .replace('searchCriteria', searchCriteria)
        return Magento.get(query, headerConfig)
          .then((res) => {
            result = res.data
            result.items.forEach((element) => {
              element.options.forEach((option) => {
                if (converted.hasOwnProperty(option.id)) {
                  option.relation = converted[option.id]
                  element.isParent = true
                }
              })
            })
            return result
          })
      })
      .catch(error => {
        throw error
      })
  }

  getOrderAvailable ({ token, storeViewCode }) {
    return Graphql.genericCall(
      {},
      token,
      storeViewCode,
      'rmaOrdersAvailable',
      null,
      false) // use cache
      .then(resp => {
        return resp && resp.data ? resp.data.rmaOrdersAvailable : {}
      })
      .catch(err => {
        Logger.error(err)
        throw err
      })
  }

  checkGuestOrder ({ token, storeViewCode, orderId, email }) {
    let searchCriteria = `searchCriteria[filter_groups][0][filters][0][field]=customer_email&
    searchCriteria[filter_groups][0][filters][0][value]=${encodeURIComponent(email)}&
    searchCriteria[filter_groups][1][filters][0][field]=increment_id&
    searchCriteria[filter_groups][1][filters][0][value]=${orderId}&
    searchCriteria[filter_groups][2][filters][0][field]=state&
    searchCriteria[filter_groups][2][filters][0][value]=complete
    `
    let query = Config.rma.endpoint.getOrders
      .replace('{{storeViewCode}}', storeViewCode)
      .replace('searchCriteria', searchCriteria)

    return Magento.get(query)
      .then((res) => {
        return res.data
      })
      .catch(error => {
        throw error
      })
  }

  getUserOrder ({ storeViewCode, token, email }) {
    email = encodeURIComponent(email.split('@')[0]) + '@' + email.split('@')[1]
    let searchCriteria = `searchCriteria[filter_groups][0][filters][0][field]=customer_email&
    searchCriteria[filter_groups][0][filters][0][value]=${email}&
    searchCriteria[filter_groups][2][filters][0][field]=state&
    searchCriteria[filter_groups][2][filters][0][value]=complete
    `
    let query = Config.rma.endpoint.getOrders
      .replace('{{storeViewCode}}', storeViewCode)
      .replace('searchCriteria', searchCriteria)

    return Magento.get(query, headerConfig)
      .then((res) => {
        if (res && res.data && res.data.items) {
          return res.data.items
        } else {
          return []
        }
      })
      .catch(error => {
        throw error
      })
  }

  getReturnableOrders ({ storeViewCode, customerId }) {
    if (customerId) {
      // get all order of current user
      let query = Config.rma.endpoint.listReturnable.replace('{{storeViewCode}}', storeViewCode)
      query += `?customer=${customerId}`

      return Magento.get(query, headerConfig)
        .then((res) => {
          return res.data.items
        })
        .catch(error => {
          throw error
        })
    } else {
      throw new Error('no customer id provided')
    }
  }

  getNonReturnableItems ({ storeViewCode }) {
    // get all order of current user
    let query = Config.rma.endpoint.getNotReturnableItems.replace('{{storeViewCode}}', storeViewCode)
    return Magento.get(query, headerConfig)
      .then((res) => {
        return res.data
      })
      .catch(error => {
        throw error
      })
  }

  getOrderHistory ({ storeViewCode, email, orderId }) {
    let searchCriteria = `searchCriteria[filter_groups][0][filters][0][field]=customer_email&searchCriteria[filter_groups][0][filters][0][value]=${encodeURIComponent(email)}`

    if (orderId) {
      searchCriteria += `&searchCriteria[filter_groups][0][filters][1][field]=order_id&searchCriteria[filter_groups][0][filters][1][value]=${orderId}`
    }

    let query = Config.rma.endpoint.rmaHistory
      .replace('{{storeViewCode}}', storeViewCode)
      .replace('searchCriteria', searchCriteria)

    return Magento.get(query, headerConfig)
      .then((res) => {
        if (res.data && res.data.items) {
          return res.data.items
        }
      })
      .catch(error => {
        throw error
      })
  }

  getEstimateShipping ({ storeViewCode, requestId }) {
    let query = Config.rma.endpoint.shippingFees.replace('{{storeViewCode}}', storeViewCode)
    query += `/${requestId}`
    return Magento.get(query)
      .then((res) => {
        return res.data
      })
      .catch(error => {
        throw error
      })
  }

  getShippingFeesEstimate ({ storeViewCode, request }) {
    let query = Config.rma.endpoint.shippingFeesEstimate
      .replace('{{storeViewCode}}', storeViewCode)
    query += `?storeCode=${storeViewCode}`
    return Magento.post(
      query,
      request)
      .then((res) => {
        return res.data
      })
      .catch(error => {
        throw error
      })
  }

  async getRequestById ({ storeViewCode, rmaId }) {
    let query = Config.rma.endpoint.getRequestById
      .replace('{{storeViewCode}}', storeViewCode)
      .replace('{{requestId}}', rmaId)

    return Magento.get(query, headerConfig)
      .then((res) => {
        if (res && res.data) {
          const _this = this
          let rmaRequest = res.data
          // get rma information
          let orderId = rmaRequest.order_id
          let searchCriteria = `searchCriteria[filter_groups][0][filters][0][field]=entity_id&
          searchCriteria[filter_groups][0][filters][0][value]=${orderId}`

          let query = Config.rma.endpoint.getOrders
            .replace('{{storeViewCode}}', storeViewCode)
            .replace('searchCriteria', searchCriteria)
          return Magento.get(query, headerConfig)
            .then((orderResp) => {
              // get order information
              return this.rmaFields({ storeViewCode })
                .then(async (fields) => {
                  let rmaFields = (fields && fields.items) ? fields.items : false
                  let order = []
                  let orderItems = []
                  if (orderResp && orderResp.data) {
                    if (orderResp.data && orderResp.data.items && orderResp.data.items.length) {
                      // get order from result always [0]
                      order = orderResp.data.items[0]
                      // add order information to rma
                      rmaRequest['order'] = order
                      // overrite items with matching of rma response
                      rmaRequest.order_items.forEach((item) => {
                        item.custom_fields = _this.restoreLabelFromId(rmaFields, item.custom_fields)
                        let orderItem = find(order.items, { item_id: item.item_id })
                        orderItems.push({ ...item, ...orderItem })
                      })
                      rmaRequest.order_items = orderItems
                    }
                    return rmaRequest
                  } else {
                    return rmaRequest
                  }
                })
            })
        }
      })
      .catch(error => {
        throw error
      })
  }

  getThreadById ({ storeViewCode, rmaId }) {
    let searchCriteria = `searchCriteria[filter_groups][0][filters][0][field]=request_id&
    searchCriteria[filter_groups][0][filters][0][value]=${rmaId}`
    let query = Config.rma.endpoint.getThreadById
      .replace('{{storeViewCode}}', storeViewCode)
      .replace('searchCriteria', searchCriteria)
    return Magento.get(query,
      {
        headers: {
          'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
        }
      })
      .then((res) => {
        if (res && res.data && res.data.items) {
          return res.data.items
        } else {
          return []
        }
      })
      .catch(error => {
        throw error
      })
  }

  getStatusById ({ storeViewCode, rmaStatus }) {
    let query = Config.rma.endpoint.getStatusById
      .replace('{{storeViewCode}}', storeViewCode)
      .replace('{{statusId}}', rmaStatus)

    return Magento.get(query, headerConfig)
      .then((res) => {
        if (res && res.data) {
          return res.data
        }
      })
      .catch(error => {
        throw error
      })
  }

  createRma ({ storeViewCode, request }) {
    let query = Config.rma.endpoint.create.replace('{{storeViewCode}}', storeViewCode)
    return Magento.post(
      query,
      request, headerConfig)
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  }

  addThreadMessage ({ storeViewCode, threadMessage }) {
    let query = Config.rma.endpoint.addThreadMessage
    return Magento.post(query, { 'threadMessage': threadMessage }, {
      headers: {
        'Authorization': `Bearer ${process.env.VUE_APP_MAGENTO_TOKEN}`
      }
    })
      .then((res) => {
        return res
      })
      .catch(error => {
        throw error
      })
  }

  // HELPERS
  getOrderFromHistory (history, order) {
    let userRmaHistory = history
    // check on all rma order
    userRmaHistory.forEach(singleRma => {
      // find current order in order history
      if (singleRma.order_id === order.entity_id) {
        // loop order items
        order.items.forEach(singleItem => {
          if (singleItem.product_type === 'configurable') {
            // loop order items of rma
            singleRma.order_items.forEach(singleItemInRma => {
              // find a match between two item
              if (singleItemInRma.extension_attributes.sku === singleItem.sku) {
                // find for remaining qty
                if (!('qty_remaining_rma' in singleItem)) {
                  // calculate rma remaining
                  let remainQtyToRma = singleItem.qty_ordered - singleItemInRma.qty
                  singleItem['qty_remaining_rma'] = remainQtyToRma
                } else {
                  let remainQtyToRma = singleItem.qty_remaining_rma - singleItemInRma.qty
                  singleItem.qty_remaining_rma = remainQtyToRma
                }
              }
            }, singleItem)
          }
        }, singleRma)
      }
    })
    return order
  }

  setOrder (history, order) {
    order = this.getOrderFromHistory(history, order)
    return this.skipGrouped(order)
  }

  skipGrouped (order) {
    order.items = order.items.filter((item) => {
      return !item.parent_item_id
    })
    return order
  }

  mergeRmaWithOrder (rma, order) {

  }

  restoreLabelFromId (fields, customFields) {
    let fieldsLabel = []
    customFields.forEach((field) => {
      let fieldMatch = find(fields, { id: field.field_id })
      fieldsLabel.push({
        label: fieldMatch.name || null,
        field_name: fieldMatch.name || null,
        value: find(fieldMatch.options, { id: parseInt(field.value) }).storefront_label
      })
    })
    return fieldsLabel
  }

  returnedForItem (history, currentItem) {
    let qtyArray = []
    history.forEach((order) => {
      order.order_items.forEach((item) => { if (item.item_id === currentItem) { qtyArray.push(item) } })
    })
    return qtyArray.reduce((acc, object) => { return acc + object.qty }, 0)
  }
}

const classSingleton = new Rma()

export default classSingleton
