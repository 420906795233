export default {
  // authorize: `/rest/{{storeViewCode}}/V1/ext/amazon/login/authorize/{{access_token}}`,
  initConfig: 'rest/V1/amazon-checkout-session/config',
  updateSession: 'rest/V1/amazon-checkout-session/{{checkoutSessionId}}/update',
  checkoutSession: 'https://pay-api.amazon.{{country_extension}}/checkoutSessions/{{checkoutSessionId}}',
  authorize: `/amazon_pay/login/authorize/?buyerToken={{buyerToken}}`,
  getShippingAddress: '/rest/{{storeViewCode}}/V1/amazon-checkout-session/{{orderReferenceId}}/shipping-address',
  getBillingAddress: '/rest/{{storeViewCode}}/V1/amazon-checkout-session/{{orderReferenceId}}/billing-address',
  getPaymentDescriptor: '/rest/{{storeViewCode}}/V1/amazon-checkout-session/{{orderReferenceId}}/payment-descriptor',
  shippingMethods: '/rest/{{storeViewCode}}/V1/carts/mine/estimate-shipping-methods',
  placeOrder: '/rest/V1/amazon-checkout-session/{{checkoutSessionId}}/complete',
  paymentInformation: `/rest/{{storeViewCode}}/V1/carts/mine/payment-information`,
  paymentInformationGuest: `/rest/{{storeViewCode}}/V1/guest-carts/{{cartId}}/payment-information`
}
