export default `query GetPosts($currentPage: Int, $pageSize: Int, $categoryId: String, $filters: BlogPostsFilterInput) {
  blogPosts(
    currentPage: $currentPage,
    pageSize: $pageSize,
    sort: ["DESC"],
    sortFiled: "publish_time",
    filter: $filters
  ) {
    total_count
    items {
      identifier
      post_id
      title
      short_content
      short_filtered_content
      author {
        author_id
        name
        author_url
      }
      post_url
      creation_time
      tags {
        tag_id
        title
        tag_url
      }
      categories {
        identifier
        category_id
        title
        category_url
      }
      first_image
      featured_image
    }
    total_pages
  }
  blogCategory (id: $categoryId) {
    category_id
    parent_category_id
    category_url
    category_level
    identifier
    is_hero
    title
    meta_title
    meta_keywords
    meta_description
    canonical_url
    content_heading
    content
    path
    position
    posts_sort_by
    include_in_menu
    is_active
    display_mode
    page_layout
    layout_update_xml
    custom_theme
    custom_layout
    custom_layout_update_xml
    custom_theme_from
    custom_theme_to
    category_url_path
    posts_count
  }
}`
