export default `{
  blogCategories {
    items {
      category_id
      parent_category_id
      category_url
      content
      featured_img
      featured_img_alt
      content_heading
      custom_layout
      custom_layout_update_xml
      custom_theme
      custom_theme_from
      custom_theme_to
      display_mode
      identifier
      include_in_menu
      is_active
      layout_update_xml
      meta_description
      meta_keywords
      meta_title
      page_layout
      path
      position
      posts_sort_by
      title
    }
  }
}`
