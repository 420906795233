import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export function user () {
  return {
    namespaced: true,
    state: {
      token: null,
      current: null,
      guest: {
        email: '',
        wishlist: {
          items: [],
          sharing_code: ''
        }
      },
      ip: '0.0.0.0',
      newUser: false
    },
    getters,
    actions,
    mutations
  }
}
